import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "app/store";

interface LoginState {
    isLoggedIn: boolean;
    user: object;
}

const initialState: LoginState = {
    isLoggedIn: false,
    user: {},
};

export const loginSlice = createSlice({
    name: "loggedin",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<JSON>) => {
            // @ts-ignore
            // @ts-ignore
            state = {
                ...state,
                isLoggedIn: true,
                user: action.payload,
            }
            return state;
            },
        logout: (state) => {
            // @ts-ignore
            state = {
                ...state,
                isLoggedIn: false,
                user: {},
            }
            return state;
            },
    },
});

export const { login, logout } =
    loginSlice.actions;

export const selectLoginState = (state: RootState) =>
    state.loggedin.isLoggedIn;

export const selectUserState = (state: RootState) =>
    state.loggedin.user;

export default loginSlice.reducer;
