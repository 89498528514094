import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface HighchartsProps {
    prices: number[][];
    theme: string;
}

const CoinHighChart: React.FC<HighchartsProps> = ({ prices, theme }) => {
    const [timeframe, setTimeframe] = useState('1Y');
    const [filteredData, setFilteredData] = useState<any[]>([]);

    const filterPricesByTimeframe = (prices: number[][], timeframe: string) => {
        const now = Date.now();
        let filteredPrices = prices;

        switch (timeframe) {
            case '1D':
                filteredPrices = prices.filter(price => now - price[0] <= 24 * 60 * 60 * 1000);
                break;
            case '1W':
                filteredPrices = prices.filter(price => now - price[0] <= 7 * 24 * 60 * 60 * 1000);
                break;
            case '1M':
                filteredPrices = prices.filter(price => now - price[0] <= 30 * 24 * 60 * 60 * 1000);
                break;
            case '3M':
                filteredPrices = prices.filter(price => now - price[0] <= 90 * 24 * 60 * 60 * 1000);
                break;
            case '6M':
                filteredPrices = prices.filter(price => now - price[0] <= 180 * 24 * 60 * 60 * 1000);
                break;
            case '1Y':
                filteredPrices = prices.filter(price => now - price[0] <= 365 * 24 * 60 * 60 * 1000);
                break;
            case 'Max':
                filteredPrices = prices; // No filtering, show all data
                break;
            default:
                filteredPrices = prices;
        }

        return filteredPrices.map(price => [price[0], price[1]]);
    };

    useEffect(() => {
        const data = filterPricesByTimeframe(prices, timeframe);
        setFilteredData(data);
    }, [timeframe, prices]);

    const options = {
        chart: {
            type: 'area',
            backgroundColor: theme === 'dark' ? '#333' : '#fff',
        },
        title: {
            text: '',
            style: {
                color: theme === 'dark' ? '#fff' : '#000'
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                style: {
                    color: theme === 'dark' ? '#fff' : '#000'
                }
            },
        },
        yAxis: {
            title: {
                text: 'السعر (دولار)',
                style: {
                    color: theme === 'dark' ? '#fff' : '#000'
                }
            },
            labels: {
                style: {
                    color: theme === 'dark' ? '#fff' : '#000'
                }
            }
        },
        tooltip: {
            shared: true,
            useHTML: true, // Use HTML for the tooltip to allow RTL styling
            xDateFormat: '%A, %e %b %Y',
            formatter: function (): string {
                // @ts-ignore
                const arabicDate = new Date(this.x).toLocaleDateString('ar-EG', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
                // @ts-ignore
                const price = Highcharts.numberFormat(this.y, 2);
                return `
                    <div style="direction: rtl; text-align: right;">
                        <b>التاريخ:</b> ${arabicDate}<br />
                        <b>السعر:</b> <span style="direction: ltr; display: inline-block;">${price}</span> دولار
                    </div>`;
            },
            backgroundColor: theme === 'dark' ? '#2b2b2b' : '#f7f7f7',
            style: {
                color: theme === 'dark' ? '#fff' : '#000'
            }
        },
        series: [{
            name: 'Price',
            data: filteredData,
            color: theme === 'dark' ? '#0DC18E' : '#0DC18E',
            fillOpacity: 0.3,
            marker: {
                enabled: false // Disable markers for a smoother line
            },
            threshold: null,
        }],
        plotOptions: {
            area: {
                marker: {
                    enabled: false // Disable markers globally
                },
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 2
                    }
                },
                threshold: null
            }
        },
        navigation: {
            buttonOptions: {
                enabled: false
            }
        }
    };

    return (
        <div className="coin-highchart">
            <div className="timeframe-selector grid-container mb-20">
                {[
                    { label: 'يوم', value: '1D' },
                    { label: 'أسبوع', value: '1W' },
                    { label: 'شهر', value: '1M' },
                    { label: '3 أشهر', value: '3M' },
                    { label: '6 أشهر', value: '6M' },
                    { label: 'سنة', value: '1Y' },
                    { label: 'الكل', value: 'Max' }
                ].map((time) => (
                    <button
                        key={time.value}
                        className={`timeframe-button ${timeframe === time.value ? 'active' : ''}`}
                        onClick={() => setTimeframe(time.value)}
                    >
                        {time.label}
                    </button>
                ))}
            </div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    );
};

export default CoinHighChart;
