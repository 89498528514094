import React, {ChangeEvent, FC, FormEvent, useEffect, useRef, useState} from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import LayoutPage from "components/LayoutPage/LayoutPage";
import SocialsList from "components/SocialsList/SocialsList";
import Textarea from "components/Textarea/Textarea";
import {Helmet} from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {SocialType} from "../../components/SocialsShare/SocialsShare";
import ReactGA from "react-ga4";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export interface PageContactProps {
  className?: string;
}

type FormState = {
  email: string;
  name: string;
  message: string;
};

type ServiceMessage = {
  class: string;
  text: string;
};

interface resultProps {
}


const info = [
  {
    title: "🗺 العنوان",
    desc: "في مكان ما في الكريبتو فيرس ...",
  },
  {
    title: "💌 البريد الإلكتروني",
    desc: "contact@alcryptocast.com",
  },
];



const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Arabic - Contact Us" });
  },[]);

  const formId = "L8cc8Bkl";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6LeUupYhAAAAAMBcmzzsfjlfjICexwMl8a0IoKJ-';
  const recaptchaRef = useRef<any>();

  const initialFormState = {
    email: "",
    name: "",
    message: "",
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken,
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      setMessage({
        class: "bg-green-500 rounded-xl",
        text: "شكرا ، سوف نكون على اتصال بك قريبا.",
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      setMessage({
        class: "bg-red-500 rounded-xl",
        text: "عذرا، حدثت مشكلة. يرجى المحاولة مرة أخرى أو الاتصال بنا على صفحات التواصل الإجتماعي.",
      });
    }
  };

  const updateFormControl = (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  const socialsContactForm: SocialType[] = [
    { id: "Twitter", name: "Twitter", icon: "lab la-twitter", href: "https://twitter.com/AlCryptoCast" },
    { id: "Youtube", name: "Youtube", icon: "lab la-youtube", href: "https://www.youtube.com/channel/UCL7b2il3jLlmXithY-x-_lg" },
    { id: "Discord", name: "Discord", icon: "lab la-discord", href: "https://discord.com" },
  ];

  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>منصة كريبتو كاست || اتصل بنا</title>
      </Helmet>
      <LayoutPage
        subHeading="اترك لنا رسالة وسنقوم بالرد عليك في أقرب وقت ممكن."
        headingEmoji=""
        heading="اتصل بنا"
      >
        <div className="grid gap-8 lg:grid-cols-2">
          <div className="max-w-sm space-y-6">
            {info.map((item, index) => (
              <div key={index}>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  {item.title}
                </h3>
                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                  {item.desc}
                </span>
              </div>
            ))}
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                🌏 مواقع التواصل الإجتماعي
              </h3>
              <SocialsList className="mt-2" socials={socialsContactForm}/>
            </div>
          </div>
          <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>

          <div>
            <form className="grid grid-cols-1 gap-6" onSubmit={submitForm}>
              <label className="block">
                <Label>الإسم</Label>

                <Input placeholder="ساتوشي ناكاموتو" type="text" className="mt-1" id="name" onChange={updateFormControl} value={formState?.name}/>
              </label>
              <label className="block">
                <Label>البريد الإلكتروني</Label>

                <Input
                  type="email"
                  placeholder="satoshi@bitcoin.com"
                  className="mt-1"
                  id="email"
                  onChange={updateFormControl}
                  value={formState?.email}
                />
              </label>
              <label className="block">
                <Label>رسالتك</Label>

                <Textarea className="mt-1" rows={6} id="message" onChange={updateFormControl} value={formState?.message}/>
              </label>
              <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaKey}
                  onChange={updateRecaptchaToken}
              />
              {message && (
                  <div className={`my-4 text-white w-full p-4 text-center rounded-xl ${message.class}`}>
                    {message.text}
                  </div>
              )}
              <ButtonPrimary type="submit" disabled={submitting}>{submitting ? "جاري الإرسال..." : "إرسال"}</ButtonPrimary>
            </form>
          </div>
        </div>
      </LayoutPage>


      {/* OTHER SECTIONS */}

    </div>
  );
};

export default PageContact;
