//@ts-nocheck
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import LayoutPageAvatar from "components/LayoutPage/LayoutPageAvatar";
import {useParams, useLocation} from "react-router-dom";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import ReactGA from "react-ga4";
import axios from "axios";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {arCategoriesNames} from "../../data/jsons/translations/arCategories";
import CoinCard from "../../components/MatchCard/CoinCard";
import Skeleton from "react-loading-skeleton";
import Input from "../../components/Input/Input";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageSingleCategory: React.FC = () => {
    // @ts-ignore
    const {slug} = useParams();
    const location = useLocation();
    const [isLoadingCategory, setLoadingCategory] = React.useState(true);
    const [categoryData, setCategoryData] = React.useState<any[]>([]);

    const { marketCap, marketCapChange, volume24h } = location.state || {};


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: `${slug}` });
    },[]);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/categories/${slug}`)
            .then(function (response) {
                setCategoryData(response.data)
                setLoadingCategory(false)
            }).catch(function (error) {
            console.log(error);
        })
    }, []);

    const [coinQuery, setCoinQuery] = useState("");
    const venturesIds = ["andreessen-horowitz-a16z-portfolio", "multicoin-capital-portfolio", "alameda-research-portfolio", "delphi-digital-portfolio", "galaxy-digital-portfolio", "pantera-capital-portfolio", "blockchain-capital-portfolio", "animoca-brands", "dragonfly-capital-portfolio", "binance-labs-portfolio", "circle-ventures-portfolio", "defiance-capital-portfolio", "okx-ventures-portfolio", "sequoia-capital-portfolio", "egirl-capital-portfolio", "consensys-portfolio"]

    function formatLargeNumbers(number: number) {
        const absNumber = Math.abs(number);
        if (absNumber >= 1.0e+12) {
            return (absNumber / 1.0e+12).toFixed(2) + " تريليون";
        }
        else if (absNumber >= 1.0e+9) {
            return (absNumber / 1.0e+9).toFixed(2) + " مليار";
        } else if (absNumber >= 1.0e+6) {
            return (absNumber / 1.0e+6).toFixed(2) + " مليون";
        } else if (absNumber >= 1.0e+3) {
            return (absNumber / 1.0e+3).toFixed(2) + " ألف";
        } else {
            return absNumber.toLocaleString('ar-EG');
        }
    }



    // @ts-ignore
    return (
        <div className="">
            <Helmet>
                <title>{arCategoriesNames.find(team => team.id === slug) ? arCategoriesNames.find(team => team.id === slug).ar : slug}</title>
            </Helmet>
            <LayoutPage
                subHeading={arCategoriesNames.find(team => team.id === slug) ? arCategoriesNames.find(team => team.id === slug).arContent : slug}
                headingEmoji=""
                heading={arCategoriesNames.find(team => team.id === slug) ? arCategoriesNames.find(team => team.id === slug).ar : slug}
            >
                <div className={"flex flex-col justify-items-center text-center"}>
                    <div className="block flex flex-col sm:flex-row justify-items-center mb-10 gap-4">

                        <div className="block sm:w-1/3  sm:mx-auto border-2 rounded-xl sm:py-6 py-2">
                            <p className={marketCapChange > 0 ? "text-green-500 font-extrabold text-2xl sm:mb-6 mb-4" : "text-red-500 font-extrabold text-2xl sm:mb-6 mb-4"}>{marketCap ? formatLargeNumbers(marketCap) : 'N/A'}</p>
                            <h2 className={"text-xl font-bold mb-6"}>القيمة السوقية</h2>
                        </div>
                        <div className="block sm:w-1/3  sm:mx-auto border-2 rounded-xl sm:py-6 py-2">
                            <p className={marketCapChange > 0 ? "text-green-500 font-extrabold text-2xl sm:mb-6 mb-4" : "text-red-500 font-extrabold text-2xl sm:mb-6 mb-4"}>{volume24h ? formatLargeNumbers(volume24h) : 'N/A'}</p>
                            <h2 className={"text-xl font-bold mb-6"}>حجم التداول اليوم</h2>
                        </div>
                        <div className="block sm:w-1/3  sm:mx-auto border-2 rounded-xl sm:py-6 py-2">
                            <p dir={"ltr"} className={marketCapChange > 0 ? "text-green-500 font-extrabold text-2xl sm:mb-6 mb-4" : "text-red-500 font-extrabold text-2xl sm:mb-6 mb-4"}>
                                {marketCapChange !== undefined ? `${parseFloat(marketCapChange).toFixed(2)} %` : 'N/A'}
                            </p>
                            <h2 className={"text-xl font-bold"}>نسبة التغير اليومي</h2>
                        </div>

                    </div>
                    <div className="block flex-grow  mx-auto w-3/4 mb-10">
                        <h3 className={"mb-6 font-bold text-xl"}>قائمة العملات</h3>
                        <form className="relative">
                        <Input
                                type="search"
                                placeholder="أكتب رمز العملة للبحث"
                                className="pr-10 w-full text-center"
                                sizeClass="h-[42px] pl-4 py-3"
                                onChange={e => setCoinQuery(e.target.value)}
                            />
                            <span
                                className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                            <input type="submit" hidden value=""/>
                        </form>
                    </div>

                    {isLoadingCategory ? (
                        <Skeleton
                            count={10}
                            baseColor="#f7f5f5"
                            highlightColor="#ede4e4"
                        />
                    ) : (
                        categoryData.filter((coin: { symbol: string; }) => coin.symbol.toLowerCase().includes(coinQuery.toLowerCase())).slice(0,250).map((coin: any) => {
                            return <CoinCard data={coin} />;
                        })

                    )}

                    <div className={"flex justify-center items-center font-bold"}>
                        <ButtonPrimary className="" href={venturesIds.some(id => slug.includes(id)) ? "/venture-capitals" : "/categories"}>العودة للفئات</ButtonPrimary>
                    </div>

                </div>


                {/* HEADER */}

            </LayoutPage>
        </div>

    );
}

export default PageSingleCategory;
