import React from 'react';

function formatLargeNumbers(number: number) {
    const absNumber = Math.abs(number);
    if (absNumber >= 1.0e+12) {
        return (absNumber / 1.0e+12).toFixed(2) + " تريليون";
    }
    else if (absNumber >= 1.0e+9) {
        return (absNumber / 1.0e+9).toFixed(2) + " مليار";
    } else if (absNumber >= 1.0e+6) {
        return (absNumber / 1.0e+6).toFixed(2) + " مليون";
    } else if (absNumber >= 1.0e+3) {
        return (absNumber / 1.0e+3).toFixed(2) + " ألف";
    } else {
        return absNumber.toLocaleString('ar-EG');
    }
}


// @ts-ignore
function TotalDefiStats({defiData}) {
    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 p-4 text-center">

            <div className="card dark:bg-black dark:bg-opacity-20 bg-white p-4 rounded-md dark:border-4 dark:border-white">
                <p className="text-3xl lg:text-5xl font-extrabold dark:text-white mb-6 ">
                    {formatLargeNumbers(defiData.defi_market_cap)} دولار
                </p>
                <h2 className="text-xl font-bold dark:text-white">إجمالي القيمة السوقية</h2>
            </div>
            <div className="card dark:bg-black dark:bg-opacity-20 bg-white p-4 rounded-md dark:border-4 dark:border-white">
                <p className="text-3xl lg:text-5xl font-extrabold dark:text-gray-300 mb-6">
                    {formatLargeNumbers(defiData.trading_volume_24h)} دولار
                </p>
                <h2 className="text-xl font-bold dark:text-white">إجمالي حجم التداول</h2>
            </div>
        </div>
    );
}

export default TotalDefiStats;
