//@ts-nocheck
import React from 'react';
import GaugeChart from 'react-gauge-chart';
import {useAppSelector} from "../../app/hooks";
import {selectDarkmodeState} from "../../app/darkmode/darkmode";

// Define a mapping from English to Arabic classifications
const classificationMapping = {
    "Extreme Fear": "خوف شديد",
    "Fear": "خوف",
    "Greed": "طمع",
    "Extreme Greed": "طمع شديد",
    "Neutral": "محايد",
};

// @ts-ignore
const FearAndGreedChart = ({ value, classification }) => {

    const isDarkMode = useAppSelector(selectDarkmodeState);
    // @ts-ignore
    const chartData = [
        {
            value: value / 100, // Assuming the value is between 0 and 100
            label: 'مؤشر الخوف والطمع', // Arabic label
            classification: classificationMapping[classification] || classification, // Use Arabic mapping or the original value
        },
    ];

    return (
        <div className="w-3/4 mx-auto relative">
            <div className="dark:bg-black dark:bg-opacity-20 bg-white rounded-lg p-4 shadow-md dark:border-4 dark:border-white">
                <div className="text-center">
                    <GaugeChart
                        id="fear-and-greed-chart"
                        textColor={isDarkMode ? "#fff" : "#000"}
                        colors={["#ff4e50", "#f9ca24", "#44bd32"]}
                        arcWidth={0.3}
                        cornerRadius={5}
                        nrOfLevels={10}
                        percent={chartData[0].value}
                        needleColor={"#333"}
                        arcPadding={0.02}
                    />
                    <p className="text-xl lg:text-4xl font-semibold dark:text-white text-gray-800 mt-4">
                        قيمة المؤشر: {value}
                    </p>
                    <p className="text-xl lg:text-4xl font-semibold dark:text-white text-gray-800 mt-2">
                        التصنيف:  {chartData[0].classification}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FearAndGreedChart;
