//@ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "../../components/NcImage/NcImage";
import { selectLoginState, selectUserState } from "app/login/login";
import { useAppSelector } from "../../app/hooks";
import CoinCard from "../../components/MatchCard/CoinCard";
import { useQuery } from "@tanstack/react-query";
import * as api from "../../api/matchesApi";
import Skeleton from "react-loading-skeleton";
import homeCover2 from "../../images/cryptoCover.webp";
import defiMarketIcon from "../../images/decentralized-finance.svg";
import dominanceIcon from "../../images/dominance.svg";
import membershipIcon from "../../images/member.png";
import DominanceStats from "../../components/MatchCard/DominanceStats";
import SectionVideos from "./SectionVideos";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import ReactGA from "react-ga4";
import Input from "../../components/Input/Input";
import TopCoins  from "../../components/MatchCard/TopCoins";
import NFTCard from "../../components/MatchCard/NFTCard";
import ExchangeCard from "../../components/MatchCard/ExchangeCard";
import BitcoinHoldings from "../../components/MatchCard/BitcoinHoldings";
import TotalStats from "../../components/MatchCard/TotalStats";
import cryptoAnalysisIcon from "../../images/crypto-analysis.svg";
import cryptoMarketIcon from "../../images/crypto-market.svg";
import gainLossIcon from "../../images/gain-loss.svg";
import nftIcon from "../../images/nft.svg";
import cryptoExchange from "../../images/money-exchange.svg";
import publicCompaniesBitcoinHolding  from "../../images/holding-bitcoin.svg";
import FearAndGreedChart from "../../components/MatchCard/FearAndGreedChart";
import marketSentiments from "../../images/market-sentiment.svg";
import axios from "axios";
import TotalDefiStats from "../../components/MatchCard/TotalDefiStats";

const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const people = [{ id: 0, name: "22/23" }];

export interface PageHomeProps {
  className?: string;
}

interface Coin {
  id: string;
  symbol: string;
  // ... other coin fields ...
  sparkline_in_7d: {
    price: number[];
  };
}


const PageHome: React.FC<PageHomeProps> = ({ className = "" }) => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "الرئيسية",
    });
  }, []);
  const [isInteracting, setIsInteracting] = useState(false);
  const user = useAppSelector(selectUserState);
  const isLoggedIn = useAppSelector(selectLoginState);
  const [fearAndGreedData, setFearAndGreedData] = useState<any>([]);
  const { data: latestCoinsMarket, isLoading: isLoadingLatestCoinsMarket } = useQuery(
      ["latestCoinsMarket"],
      api.getLatestCoinsMarket,
      { cacheTime: 1000 * 30 }
  );

  const { data: trendingCoins, isLoading: isLoadingTrendingCoins } = useQuery(
      ["trendingCoins"],
      api.getTrendingCoins,
      { cacheTime: 1000 * 60 }
  );

  const { data: nftMarketData, isLoading: isLoadingNFTMarketData } = useQuery(
      ["nftMarketData"],
      api.getNFTMarketData,
      { cacheTime: 1000 * 30 }
  );

  const { data: exchangesData, isLoading: isLoadingExchangesData } = useQuery(
      ["exchangesData"],
      api.getExchangesData,
      { cacheTime: 1000 * 30 }
  );

  const { data: companiesBitcoinHoldings, isLoading: isLoadingCompaniesBitcoinHoldings } = useQuery(
      ["companiesBitcoinHoldings"],
      api.getCompaniesBitcoinData,
      { cacheTime: 1000 * 30 }
  );

  const { data: topGainsersLoosers, isLoading: isLoadingTopGainersLoosers } = useQuery(
      ["topGainsersLoosers"],
      api.getTopGainersLoosers,
      { cacheTime: 1000 * 30 }
  );

  const { data: globalStats, isLoading: isLoadingGlobalStats } = useQuery(
      ["globalStats"],
      api.getGlobalStats,
      { cacheTime: 1000 * 60 * 20 }
  );

  const { data: globalDefiStats, isLoading: isLoadingGlobalDefiStats } = useQuery(
      ["globalDefiStats"],
      api.getGlobalDefiStats,
      { cacheTime: 1000 * 60 * 20 }
  );

  const { data: fngData, isLoading: isLoadingFngData } = useQuery(
      ["fngData"],
      api.getFearNGreedData,
      { cacheTime: 1000 * 60 * 5  }
  );

  useEffect(() => {
    axios.get("https://api.alternative.me/fng/")
        .then(function (response) {
          setFearAndGreedData(response.data);
        }).catch(function (error) {
      console.log(error);
    })
  }, []);





  const [selected, setSelected] = useState(people[0]);



  const [coinQuery, setCoinQuery] = useState("");

  const [coinTrendingQuery, setCoinTrendingQuery] = useState("");





  const renderNFTMarketData = () => {
    return (
        <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {nftMarketData.slice(0,18).map((project: { id: React.Key | null | undefined; }) => (
              <NFTCard key={project.id} project={project} />
          ))}
        </div>
    )
  }

  const renderExchangesData = () => {
    return (
          <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {exchangesData.slice(0,24).map((exchange: { id: React.Key | null | undefined; }) => (
                <ExchangeCard key={exchange.id} exchange={exchange} />
            ))}
          </div>

    )
  }

  const renderCompaniesBitcoinData = () => {
    return (
        <div className="relative">
              <BitcoinHoldings data={companiesBitcoinHoldings} />
        </div>

    )
  }

  const renderGlobalStats = () => {
    return (
        <div className="relative">
          <TotalStats data={globalStats.data} />
          <img
              src={defiMarketIcon}
              className={"relative mx-auto mb-4 mt-4 w-48"}
              alt={"Next Football Matches Icon"}
          />
          <h2 className={"relative text-center text-slate-900 text-2xl mt-6 mb-12 lg:mb-12 sm:text-5xl dark:text-white font-semibold"}>سوق عملات التمويل اللامركزي</h2>
          {isLoadingGlobalDefiStats ? <Skeleton count={10} /> : <TotalDefiStats defiData={globalDefiStats.data} />}
          <img
              src={dominanceIcon}
              className={"relative mx-auto mb-4 mt-6 w-48 h-20"}
              alt={"Next Football Matches Icon"}
          />
          <h2 className={"relative text-center text-slate-900 text-2xl mt-6 mb-12 lg:mb-12 sm:text-5xl dark:text-white font-semibold"}>نسبة القيمة السوقية</h2>
            <DominanceStats data={globalStats.data.market_cap_percentage} />
        </div>

    )
  }


  // @ts-ignore
  // @ts-ignore
  return (
    <div className={`nc-PageAuthor  ${className}`} data-nc-id="PageAuthor">
      <Helmet>
        <title>منصة كريبتو كاست || الرئيسية</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto mt-6 sm:mt-10">

        <div
            className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-7 lg:aspect-h-12 sm:aspect-h-7 xl:sm:aspect-h-6 overflow-hidden border-4 border-primary-6000">
          <NcImage
            containerClassName="absolute inset-0"
            src={homeCover2}
            className="object-cover md:w-full md:h-full"
          />
          {/*<video width="600" controls className="absolute inset-0">
            <source src={homeVideo} type="video/webm"/>
            Your browser does not support the video tag.
          </video>*/}
        </div>

        <div className="relative container -mt-5 lg:-mt-10 sm:flex sm:flex-col sm:justify-items-center sm:items-center">
          <div className="bg-white dark:bg-neutral-900 dark:border-4 dark:border-primary-6000 p-4 lg:p-10 rounded-[40px] shadow-2xl border-4 border-primary-6000">
            <span
              className={
                "font-bold text-xl ml-14 md:ml-2 break-words text-center flex lg:text-6xl  font-display text-primary-6000 drop-shadow-xl dark:text-white"
              }
            >
              {user && isLoggedIn
                ? "منصة كريبتو كاست"
                : "منصة كريبتو كاست"}
            </span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>

          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={cryptoMarketIcon}
                className={"relative mx-auto mb-8 -mt-4 w-20 lg:w-28"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-12 sm:text-5xl dark:text-white font-semibold"}>سوق
              العملات الرقمية اليوم</h2>
            {isLoadingGlobalStats && isLoadingGlobalDefiStats ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : renderGlobalStats()}


          </div>




          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={marketSentiments}
                className={"relative mx-auto mb-8 -mt-4 w-20 lg:w-28"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-12 sm:text-5xl dark:text-white font-semibold"}>مؤشر
              الخوف والطمع</h2>

            {isLoadingFngData ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : <FearAndGreedChart value={fngData.data[0].value}
                                   classification={fngData.data[0].value_classification}/>}


          </div>

          <div className="relative py-16 mb-20">
            <BackgroundSection/>
            <img
                src={cryptoAnalysisIcon}
                className={"relative mx-auto mb-8 -mt-4 w-14 lg:w-20"}
                alt={"Next Football Matches Icon"}
            />
            <div className={"flex flex-col"}>
              <h2
                  className={
                    "relative text-center text-slate-900 -mt-4 text-2xl mb-16 sm:text-5xl dark:text-white font-semibold"
                  }
              >
                أسعار العملات
              </h2>
              <div className="md:col-span-2 lg:col-span-3">
                <div className="block flex-grow  mx-auto w-3/4 mb-10">
                  <form className="relative">
                    <Input
                        type="search"
                        placeholder="أكتب رمز العملة للبحث"
                        className="pr-10 w-full text-center"
                        sizeClass="h-[42px] pl-4 py-3"
                        onChange={e => setCoinQuery(e.target.value)}
                    />
                    <span
                        className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                    <input type="submit" hidden value=""/>
                  </form>
                </div>
              </div>
              {isLoadingLatestCoinsMarket ? (
                  <Skeleton
                      count={10}
                      baseColor="#f7f5f5"
                      highlightColor="#ede4e4"
                  />
              ) : (
                  latestCoinsMarket.filter((coin: {
                    symbol: string;
                  }) => coin.symbol.toLowerCase().includes(coinQuery.toLowerCase())).slice(0, 10).map((coin: any) => {
                    return <CoinCard data={coin}/>;
                  })

              )}
              <ButtonPrimary className="mt-10" href="/coins">المزيد</ButtonPrimary>
            </div>
          </div>

          <SectionVideos className="py-16 lg:py-28"/>


          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={gainLossIcon}
                className={"relative mx-auto mb-8 -mt-4 w-14 lg:w-20"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-16 sm:text-5xl dark:text-white font-semibold"}>أكثر
              العملات المشفرة ربحًا و خسارةً اليوم</h2>
            {isLoadingTopGainersLoosers || isLoadingTrendingCoins ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : <TopCoins data={topGainsersLoosers} trendingData={trendingCoins}/>}
          </div>


          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={nftIcon}
                className={"relative mx-auto mb-8 -mt-4 w-14 lg:w-20"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-16 sm:text-5xl dark:text-white font-semibold"}>أسعار
              و تحركات سوق NFT</h2>
            {isLoadingNFTMarketData ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : renderNFTMarketData()}
          </div>

          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={cryptoExchange}
                className={"relative mx-auto mb-8 -mt-4 w-14 lg:w-20"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-12 sm:text-5xl dark:text-white font-semibold"}>منصات
              التداول</h2>
            {isLoadingExchangesData ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : renderExchangesData()}
            <div className={"flex justify-center items-center font-extrabold"}>
              <ButtonPrimary className="mt-16 w-1/4" href="/exchanges">
                المزيد من منصات التداول
              </ButtonPrimary>
            </div>
          </div>

          <div className={"relative py-16 mb-20"}>
            <BackgroundSection/>
            <img
                src={publicCompaniesBitcoinHolding}
                className={"relative mx-auto mb-8 -mt-4 w-14 lg:w-20"}
                alt={"Next Football Matches Icon"}
            />
            <h2 className={"relative text-center text-slate-900 -mt-4 text-2xl mb-12 sm:text-5xl dark:text-white font-semibold"}>استحواذات
              الشركات العامة على بيتكوين</h2>
            {isLoadingCompaniesBitcoinHoldings ? (
                <Skeleton
                    count={10}
                    baseColor="#f7f5f5"
                    highlightColor="#ede4e4"
                />
            ) : renderCompaniesBitcoinData()}
          </div>


          {/*<div className="relative py-16 mb-20">
                        <BackgroundSection />
                        <SectionSliderNewCategories
                            heading="قريبا إصدار أول مجموعة توكنز خاصة بموقعنا"
                            subHeading="إكتشف مجموعة توكنز الزومبي ليغ التي سشغل تطبيقنا اللامركزي على بلوكشين الأفالانش"
                            categories={TOP_SCORERS}
                            uniqueSliderClass="PageHome"
                        />
                    </div>*/}


          {/*
           === SECTION 7 ===
          <div className="relative py-16 mb-16">
            <BackgroundSection />
            <SectionSliderNewAuthors
              heading="أغاني الفيراج"
              subHeading="استمع إلى أفضل أغاني الفيراج أثناء تصفح منصتنا"
              authors={DEMO_AUTHORS.filter((_, i) => i < 10)}
              uniqueSliderClass="PageHomeDemo2"
            />
          </div>*/}


          {/* === SECTION 1 === */}
          {/*<SectionAds
            className="mt-10 lg:pb-8 mb-10"
            imgAds={pass23}
            href={"https://clubafricain.com/passeport/"}
          />*/}

          {/*<div
            className={`nc-SectionAds mt-10 lg:pb-2 mb-4 block text-center mx-auto ${className}`}
          >
            <Adsense
              client="ca-pub-5724020986103870"
              slot="5200134090"
              style={{ display: "block" }}
              layout="in-article"
              format="fluid"
            />
          </div>*/}
        </main>

        {/* === SECTION 5 === */}
      </div>
    </div>
  );
};

export default PageHome;
