import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import {CustomLink} from "data/types";
import React from "react";
import {selectLoginState} from "app/login/login";
import {useAppSelector} from "app/hooks";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "سوق الكريبتو",
    menus: [
      { href: "/coins", label: "أسعار العملات" },
      { href: "/top-gainers", label: "الأكثر ربحًا" },
      { href: "/top-loosers", label: "الأكثر خسارة" },
      { href: "/categories", label: "فئات المشاريع" },
    ],
  },
  {
    id: "1",
    title: "منصات التداول",
    menus: [
      { href: "/exchanges", label: "المنصات" },
      { href: "/dex", label: "المنصات اللامركزية" },
      { href: "/derivatives", label: "المشتقات" },
    ],
  },
  {
    id: "2",
    title: "محفظة الكريبتو كاست",
    menus: [
      { href: "/large-caps", label: "المشاريع الكبرى" },
      { href: "/medium-caps", label: "المشاريع المتوسطة" },
      { href: "/small-caps", label: "المشاريع الصغرى" },
      { href: "/guide", label: "دليل الكريبتو كاست الإستثماري" },
    ],
  },
  {
    id: "4",
    title: "بودكاست و أخبار",
    menus: [
      { href: "/podcasts", label: "كريبتو بودكاست" },
      { href: "/upodcasts", label: "أخبار العملات الرقمية" },
      { href: "/learn/glossary", label: "مصطلحات" },
      { href: "/learn/tutorials", label: "دروس" },
      { href: "/contact", label: "تواصل معنا" },
    ],
  },
];

const Footer: React.FC = () => {
  const [user, setUser] = useAuthState(getAuth());
  const isLoggedIn = useAppSelector(selectLoginState);
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };



  return (
    <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
