export const arCategoriesNames = [
    {
        "id": "ftx-holdings",
        "en": "FTX Holdings",
        "ar": "إف تي إكس هولدنجز",
        "enContent": "Cryptocurrencies currently held by the FTX estate, disclosed in Stakeholder Update Materials. The estate comprises FTX.com, FTX.US, and Alameda Research.",
        "arContent": "العملات المشفرة التي تمتلكها حاليًا إف تي إكس، والتي تم الكشف عنها في وثائق تحديث المساهمين. تتألف الممتلكات من FTX.com و FTX.US و Alameda Research."
    },
    {
        "id": "layer-1",
        "en": "Layer 1 (L1)",
        "ar": "الطبقة الأولى (L1)",
        "enContent": "Layer 1 serves as the primary and autonomous chain on which transactions are directly executed and confirmed, as well as providing the essential infrastructure for the blockchain network.",
        "arContent": "تعمل الطبقة الأولى كسلسلة أساسية ومستقلة يتم فيها تنفيذ المعاملات مباشرة وتأكيدها، بالإضافة إلى توفير البنية التحتية الأساسية لشبكة البلوكشين."
    },
    {
        "id": "smart-contract-platform",
        "en": "Smart Contract Platform",
        "ar": "مشاريع العقود الذكية",
        "enContent": "Smart contract platforms are usually blockchains that host smart contracts or decentralized applications. Users can interact with different smart contracts on these platforms to perform various actions such as lending and borrowing funds, staking their assets or minting NFTs. Smart contracts are programs that are stored on the blockchain and are automatically executed when certain conditions are met.",
        "arContent": "منصات العقود الذكية هي عادة سلاسل كتل تستضيف العقود الذكية أو التطبيقات اللامركزية. يمكن للمستخدمين التفاعل مع مختلف العقود الذكية على هذه المنصات لأداء أنشطة مختلفة مثل إقراض واقتراض الأموال، ورهن أصولهم أو إصدار الرموز غير القابلة للتبادل. العقود الذكية هي برامج تخزن على البلوكشين وتُنفذ تلقائيًا عند تحقيق شروط معينة."
    },
    {
        "id": "stablecoins",
        "en": "Stablecoins",
        "ar": "العملات المستقرة",
        "enContent": "A stablecoin is a type of cryptocurrency that is designed to maintain a stable value by being pegged to an external reference, such as a fiat currency.",
        "arContent": "العملة المستقرة هي نوع من العملات المشفرة التي تم تصميمها للحفاظ على قيمة مستقرة من خلال ربطها بمرجع خارجي، مثل العملة الورقية."
    },
    {
        "id": "alleged-sec-securities",
        "en": "Alleged SEC Securities",
        "ar": "أوراق مالية مزعومة من الهيئة الأمريكية للأوراق المالية والبورصات",
        "enContent": "Tokens and cryptocurrencies alleged to be securities by the U.S. Securities and Exchange Commission (SEC).Note: The labeling of these cryptocurrencies as securities by the SEC doesn't equate to the legal classification till proven in a court.",
        "arContent": "الرموز والعملات المشفرة المزعومة بأنها أوراق مالية من قبل هيئة الأوراق المالية والبورصات الأمريكية (SEC).ملاحظة: لا يعني تصنيف هذه العملات المشفرة كأوراق مالية من قبل SEC التصنيف القانوني حتى يتم إثباته في المحكمة."
    },
    {
        "id": "exchange-based-tokens",
        "en": "Exchange-based Tokens",
        "ar": "العملات المشفرة المدعومة من المنصات",
        "enContent": "These tokens are associated with both centralized and decentralized exchanges which share the same purposes such as enjoy discounted trading fees and gain access to exclusive token sales on the exchange. While decentralized exchanges tokens also function as governance as a governance tokens, centralized exchange token does not.",
        "arContent": "ترتبط هذه الرموز بكل من المنصات المركزية واللامركزية التي تشترك في نفس الأغراض مثل الاستمتاع برسوم تداول مخفضة والوصول إلى مبيعات الرموز الحصرية على المنصة. بينما تعمل رموز المنصات اللامركزية أيضًا كرموز حوكمة."
    },
    {
        "id": "decentralized-finance-defi",
        "en": "Decentralized Finance (DeFi)",
        "ar": "التمويل اللامركزي ( DEFI )",
        "enContent": "Decentralized Finance, is a financial system that operates without central authorities or intermediaries, using blockchain technology and smart contracts to provide a range of financial services such as lending, borrowing, and trading.",
        "arContent": "التمويل اللامركزي هو نظام مالي يعمل بدون سلطات مركزية أو وسطاء، باستخدام تكنولوجيا البلوكشين والعقود الذكية لتوفير مجموعة من الخدمات المالية مثل الإقراض والاقتراض والتداول."
    },
    {
        "id": "centralized-exchange-token-cex",
        "en": "Centralized Exchange (CEX)",
        "ar": "عملات المنصات المركزية",
        "enContent": "These utility tokens are issued by a centralized exchange and can be used for a variety of purposes. Although holders are not able to exercise governance, they still carry many other benefits such as enjoy discounted trading fees and gain access to exclusive token sales on the exchange.",
        "arContent": "تصدر هذه العملات المشفرة من قبل منصة مركزية ويمكن استخدامها لأغراض متنوعة. على الرغم من عدم قدرة المالكين على ممارسة الحوكمة، إلا أنها تحمل العديد من الفوائد الأخرى مثل الاستمتاع برسوم تداول مخفضة والوصول إلى مبيعات الرموز الحصرية على المنصة."
    },
    {
        "id": "liquid-staking-tokens",
        "en": "Liquid Staking Tokens",
        "ar": "رموز التوقيع السائلة ( Liquid Staking )",
        "enContent": "Liquid staking token is a tokenized representation of staked tokens that can be traded or used in various DeFi applications.",
        "arContent": "رمز التوقيع السائل هو تمثيل للرموز المرهونة التي يمكن تداولها أو استخدامها في تطبيقات التمويل اللامركزي المختلفة."
    },
    {
        "id": "eth-2-0-staking",
        "en": "Eth 2.0 Staking",
        "ar": "تدقيق شبكة إثيريوم 2.0",
        "enContent": "In Ethereum 2.0, the consensus algorithm is transitioned from Proof-of-Work to Proof-of-Stake, allowing users to stake their Ether and validate transactions in exchange for staking rewards. When you participate in ETH 2.0 staking solutions, you will usually receive a token which represents your staked assets but are also liquid and can be used in other protocols. Here, you can find different tokens which represent staked Ether from various protocols.",
        "arContent": "في إثيريوم 2.0، يتم تحويل خوارزمية التوافق من دليل العمل إلى دليل الحصة، مما يتيح للمستخدمين تراكم عملات الإثير وتحقق المعاملات مقابل مكافآت التراكم. عند المشاركة في حلول تدقيق ETH 2.0، عادة ما ستتلقى رمزًا يمثل أصولك المرهونة ولكنها أيضًا سائلة ويمكن استخدامها في بروتوكولات أخرى."
    },
    {
        "id": "meme-token",
        "en": "Meme",
        "ar": "عملات الميم",
        "enContent": "Meme coins derive their relevance from memes, thriving on hype and picking up momentum as the community grows.",
        "arContent": "تستمد عملات الميم أهميتها من النكات، وتزدهر على الضجة وتكتسب زخمًا مع نمو المجتمع."
    },
    {
        "id": "non-fungible-tokens-nft",
        "en": "NFT",
        "ar": "الرموز غير القابلة للتبادل ( NFT )"
    },
    {
        "id": "governance",
        "en": "Governance",
        "ar": "الحوكمة",
        "enContent": "Governance tokens are used to determine the direction or decisions taken by a protocol. A protocol’s team or even its community members can create proposals to introduce new features or even change the terms of governance itself. Once a proposal has been discussed and further fleshed out, token holders can vote on the decision to be made. The weight of your votes is determined based on the number of governance tokens held. ",
        "arContent": "تُستخدم رموز الحوكمة لتحديد الاتجاه أو القرارات التي يتخذها بروتوكول ما. يمكن لفريق البروتوكول أو حتى أعضاء مجتمعه إنشاء مقترحات لإدخال ميزات جديدة أو حتى تغيير شروط الحوكمة نفسها. بمجرد مناقشة المقترح وتفصيله بشكل أكبر، يمكن لحاملي الرموز التصويت على القرار الذي سيتخذ. يتم تحديد وزن أصواتك استنادًا إلى عدد الرموز التي تحملها."
    },
    {
        "id": "binance-launchpad",
        "en": "Binance Launchpad",
        "ar": "منصة بينانس للإطلاق"
    },
    {
        "id": "layer-2",
        "en": "Layer 2 (L2)",
        "ar": "الطبقة الثانية (L2)",
        "enContent": "Layer 2s are off-chain vertical scaling solutions that run on top of Layer 1 blockchains like Ethereum to improve scalability, while maintaining the decentralization and security standards of the Layer 1.",
        "arContent": "مشاريع الطبقة الثانية هي حلول توسيعية عمودية خارج السلسلة تعمل فوق سلاسل الكتل من الطبقة الأولى مثل إثيريوم لتحسين قابلية التوسع، مع الحفاظ على معايير اللامركزية والأمان للطبقة الأولى."
    },
    {
        "id": "gaming",
        "en": "Gaming (GameFi)",
        "ar": "الألعاب ( GameFi )",
        "enContent": "GameFi combines blockchain technology, gaming, and decentralized finance (DeFi), allowing players to earn cryptocurrency and NFT rewards while also offering true ownership and control over in-game assets.",
        "arContent": "تجمع GameFi بين تكنولوجيا البلوكشين والألعاب والتمويل اللامركزي (DeFi)، مما يتيح للاعبين كسب العملات المشفرة ومكافآت NFT بالإضافة إلى توفير الملكية الحقيقية والتحكم في الأصول داخل اللعبة."
    },
    {
        "id": "zero-knowledge-zk",
        "en": "Zero Knowledge (ZK)",
        "ar": "المعرفة الصفرية ( ZK )",
        "enContent": "Zero-knowledge proof or Zero-knowledge protocol is a method by which one party (the prover) can prove to another party (the verifier) that a given statement is true while the prover avoids conveying any additional information apart from the fact that the statement is indeed true.\r\n\r\nThis category tracks cryptocurrency that utilises Zero Knowledge implementation to execute transactions, which may include smart contract execution, value transfer, and others.",
        "arContent": "إثبات المعرفة الصفرية أو بروتوكول المعرفة الصفرية هو أسلوب يُمكّن أحد الأطراف (المُثبت) من إثبات صحة بيان معين لطرف آخر (المُتحقق) دون أن يكشف المُثبت أي معلومات إضافية سوى حقيقة أن البيان صحيح.\n" +
            "\n" +
            "هذا التصنيف يتابع العملات المشفرة التي تستخدم تطبيقات المعرفة الصفرية لتنفيذ المعاملات، والتي قد تشمل تنفيذ العقود الذكية، تحويل القيمة، وغيرها."
    },
    {
        "id": "decentralized-exchange",
        "en": "Decentralized Exchange (DEX)",
        "ar": "المنصات اللامركزية ( DEX )",
        "enContent": "Decentralized exchanges (DEXs) allow users to trade with one another in a permissionless manner and without any intermediaries.",
        "arContent": "تسمح منصات التداول اللامركزية (DEXs) للمستخدمين بالتداول مع بعضهم البعض بطريقة غير مقيدة ودون الحاجة إلى وسطاء."
    },
    {
        "id": "yield-farming",
        "en": "Yield Farming",
        "ar": "زراعة العائد ( Yield Farming )",
        "enContent": "Yield farming involves putting cryptocurrency into a DeFi protocol to collect interest on trading fees. Liquidity providers can profit by providing liquidity in DeFi protocols like Uniswap, utilizing assets that would otherwise sit idle on an exchange or hot wallet.",
        "arContent": "تتضمن زراعة العائد وضع العملات المشفرة في بروتوكول DeFi لجمع الفائدة على رسوم التداول. يمكن لمزودي السيولة تحقيق أرباح من خلال توفير السيولة في بروتوكولات DeFi مثل Uniswap، باستخدام الأصول التي ستبقى خاملة على منصة تبادل أو محفظة ساخنة."
    },
    {
        "id": "oracle",
        "en": "Oracle",
        "ar": "منصات الأوراكل",
        "enContent": "Oracles are used by protocols in their smart contracts in order to obtain external data. This allows the closed-off blockchain environment to communicate with data points on the outside world. For example, a protocol offering synthetic Nasdaq-listed stock on the blockchain will need to pull external data from the stock market. With an oracle, the protocol will be able to do so. ",
        "arContent": "تستخدم منصات الأوراكل من قبل البروتوكولات في عقودها الذكية للحصول على بيانات خارجية. يتيح هذا لبيئة البلوكشين المغلقة التواصل مع نقاط البيانات في العالم الخارجي. على سبيل المثال، سيحتاج بروتوكول يقدم سهمًا مدرجًا في ناسداك على البلوكشين إلى سحب بيانات خارجية من سوق الأسهم. باستخدام منصة الأوراكل، سيتمكن البروتوكول من القيام بذلك."
    },
    {
        "id": "metaverse",
        "en": "Metaverse",
        "ar": "العوالم الافتراضية",
        "enContent": "The metaverse refers to a digital world where participants can interact with each other in a shared virtual reality.",
        "arContent": "العوالم الافتراضية تشير إلى عالم رقمي يمكن للمشاركين التفاعل فيه مع بعضهم البعض في واقع افتراضي مشترك."
    },
    {
        "id": "play-to-earn",
        "en": "Play To Earn",
        "ar": "إلعب و أربح ( Play To Earn )",
        "enContent": "Play to Earn games are built on the blockchain, giving players full control over their gaming accounts and related assets, which they can sell on exchanges or secondary marketplaces.",
        "arContent": "تعتمد ألعاب اللعب للربح على البلوكشين، مما يمنح اللاعبين السيطرة الكاملة على حساباتهم اللعبية والأصول المتصلة بها، التي يمكنهم بيعها على منصات التبادل أو الأسواق الثانوية."
    },
    {
        "id": "wrapped-tokens",
        "en": "Wrapped-Tokens",
        "ar": "العملات المغلفة",
        "enContent": "Wrapped tokens offer a way to circumvent the lack of interoperability between different blockchains. The wrapping of a token happens when the original asset is put into a “wrapper” and deployed on a different blockchain. With it being wrapped, the cryptocurrency can now be used on a blockchain where it previously wasn’t available. One example of a wrapped token would be Wrapped Bitcoin (WBTC). It is an ERC-20 version of Bitcoin, which allows it to be used on protocols based on Ethereum. Watch this video to learn more about Wrapped tokens.",
        "arContent": "تقدم العملات المغلفة وسيلة لتجاوز نقص التشغيل المشترك بين سلاسل الكتل المختلفة. يحدث تغليف الرمز عندما يتم وضع الأصل الأصلي في \"غلاف\" ونشره على سلسلة كتل مختلفة. بعد تغليفه، يمكن استخدام العملة المشفرة الآن على سلسلة كتل حيث لم تكن متاحة من قبل. أحد أمثلة العملات المغلفة هو البيتكوين المغلف (WBTC). و هو إصدار ERC-20 من بيتكوين، مما يتيح استخدامه في البروتوكولات المعتمدة على شبكة إيثيريوم."
    },
    {
        "id": "privacy-coins",
        "en": "Privacy Coins",
        "ar": "العملات الخاصة",
        "enContent": "Privacy coins are cryptocurrencies which allow users to conduct transactions privately and anonymously. This ensures untraceable transactions and the identity of the parties involved are kept private.This is done by hiding the origin and destination of transactions. In some cases, multiple transactions are mixed together in order to make onchain analysis tougher.",
        "arContent": "عملات الخصوصية هي عملات مشفرة تسمح للمستخدمين بإجراء المعاملات بشكل خاص ومجهول. وهذا يضمن عدم تتبع المعاملات والحفاظ على خصوصية هوية الأطراف المعنية. يتم ذلك عن طريق إخفاء مصدر ووجهة المعاملات. في بعض الحالات، يتم مزج عدة معاملات معًا من أجل جعل تحليل السلسلة الكتلية أكثر صعوبة."
    },
    {
        "id": "automated-market-maker-amm",
        "en": "Automated Market Maker (AMM)",
        "ar": "صانع السوق الآلي ( AMM )",
        "enContent": "Automated market makers (AMMs) make use of liquidity pools containing 2 or more assets, and prices are derived based on the proportion of each asset in that pool as well as the mathematical function used. Orders through an AMM are executed automatically via a smart contract that will calculate the trade prices algorithmically, including any slippage from the trade execution.",
        "arContent": "يستخدم صانعو السوق الآليون (AMMs) أحواض السيولة التي تحتوي على 2 أو أكثر من الأصول، ويتم استقرار الأسعار استنادًا إلى نسبة كل أصل في تلك الأحواض والدالة الرياضية المستخدمة. تُنفذ الطلبات من خلال AMM تلقائيًا عبر عقد ذكي سيحسب أسعار التداول بشكل خوارزمي، بما في ذلك أي انزلاق من تنفيذ التداول."
    },
    {
        "id": "storage",
        "en": "Storage",
        "ar": "التخزين",
        "enContent": "Storage protocols allow users to store their data on the blockchain network in a decentralized manner. This negates the need for a centralized storage host such as Amazon, OneDrive, DropBox, and others. This removes the issue of a single point of failure. Decentralized servers make use of independent nodes which in turn use spare storage space on computers. Users are able to rent out their spare storage on  In exchange for storage space, users are able to use a storage protocol’s token to pay for the service.",
        "arContent": "تتيح بروتوكولات التخزين للمستخدمين تخزين بياناتهم على شبكة البلوكشين بطريقة لامركزية. وهذا يلغي الحاجة إلى مضيف تخزين مركزي مثل Amazon و OneDrive و DropBox وغيرها. وهذا يزيل مشكلة نقطة الفشل الواحدة. تستخدم الخوادم اللامركزية العقد المستقلة التي بدورها تستخدم مساحة تخزين الفائضة على الكمبيوترات. يمكن للمستخدمين تأجير مساحة التخزين الفائضة لديهم. وفي المقابل، يمكن للمستخدمين استخدام رمز البروتوكول للدفع مقابل الخدمة."
    },
    {
        "id": "lending-borrowing",
        "en": "Lending/Borrowing",
        "ar": "الإقراض / الاقتراض",
        "enContent": "Lending/borrowing protocols allow users to offer crypto loans by staking their assets in them without the need for a bank account. Users are given rewards, usually in the form of the protocol’s native token.",
        "arContent": "تسمح بروتوكولات الإقراض / الاقتراض للمستخدمين بتقديم قروض مشفرة عن طريق رهن أصولهم فيها دون الحاجة إلى حساب بنكي. يتم منح المستخدمين مكافآت، عادةً في شكل رمز البروتوكول الأصلي."
    },
    {
        "id": "finance-banking",
        "en": "Finance / Banking",
        "ar": "المالية / الخدمات المصرفية"
    },
    {
        "id": "artificial-intelligence",
        "en": "Artificial Intelligence (AI)",
        "ar": "الذكاء الاصطناعي ( AI )"
    },
    {
        "id": "liquid-staking-governance-tokens",
        "en": "Liquid Staking Governance Tokens",
        "ar": "رموز التوقيع السائلة للحوكمة"
    },
    {
        "id": "internet-of-things-iot",
        "en": "Internet of Things (IOT)",
        "ar": "انترنت الأشياء ( IOT )",
        "enContent": "The Internet of Things (IoT) ecosystem consists of interconnected computing devices, with the ability to transfer data over a network without the need for any human interaction.",
        "arContent": "تتكون نظام الأشياء على الإنترنت (IoT) من أجهزة الحوسبة المتصلة ببعضها البعض، مع القدرة على نقل البيانات عبر شبكة دون الحاجة إلى أي تفاعل بشري."
    },
    {
        "id": "decentralized-derivatives",
        "en": "Derivatives",
        "ar": "المشتقات"
    },
    {
        "id": "asset-backed-tokens",
        "en": "Asset-backed Tokens",
        "ar": "العملات المدعومة بالأصول"
    },
    {
        "id": "sports",
        "en": "Sports",
        "ar": "الرياضة"
    },
    {
        "id": "seigniorage",
        "en": "Seigniorage",
        "ar": "التنقيب"
    },
    {
        "id": "bridge-governance-tokens",
        "en": "Bridge Governance Tokens",
        "ar": "رموز التوقيع للحوكمة"
    },
    {
        "id": "socialfi",
        "en": "SocialFi",
        "ar": "منصات التواصل الإجتماعي"
    },
    {
        "id": "decentralized-perpetuals",
        "en": "Perpetuals",
        "ar": "العقود الدائمة"
    },
    {
        "id": "masternodes",
        "en": "Masternodes",
        "ar": "العقد الرئيسية"
    },
    {
        "id": "analytics",
        "en": "Analytics",
        "ar": "التحليلات"
    },
    {
        "id": "tokenized-gold",
        "en": "Tokenized Gold",
        "ar": "الذهب المشفر"
    },
    {
        "id": "synthetic-assets",
        "en": "Synthetic Issuer",
        "ar": "المصدر الاصطناعي"
    },
    {
        "id": "wallets",
        "en": "Wallets",
        "ar": "المحافظ"
    },
    {
        "id": "olympus-pro",
        "en": "Olympus Pro",
        "ar": "أوليمبوس برو"
    },
    {
        "id": "real-world-assets-rwa",
        "en": "Real World Assets (RWA)",
        "ar": "الأصول الحقيقية",
        "enContent": "Real World Assets (RWAs) refer to tangible or physical assets that have value in the real world. The tokenization of RWAs involves representing these assets digitally on a blockchain, allowing for fractional ownership, increased liquidity, and more efficient management and trading of these assets.",
        "arContent": "تشير الأصول الحقيقية (RWAs) إلى الأصول الملموسة أو الفعلية التي تمتلك قيمة في العالم الحقيقي. يتضمن تشفير الأصول الحقيقية تمثيل هذه الأصول رقميًا على سلسلة الكتل، مما يسمح بالملكية الجزئية، وزيادة السيولة، وإدارة أكثر كفاءة وتداول هذه الأصول."
    },
    {
        "id": "yield-aggregator",
        "en": "Yield Aggregator",
        "ar": "مجمع العائد"
    },
    {
        "id": "gambling",
        "en": "Gambling",
        "ar": "المقامرة"
    },
    {
        "id": "insurance",
        "en": "Insurance",
        "ar": "التأمين"
    },
    {
        "id": "nft-marketplace",
        "en": "NFT Marketplace",
        "ar": "سوق الرموز غير القابلة للتبادل"
    },
    {
        "id": "mev-protection",
        "en": "MEV Protection",
        "ar": "حماية MEV"
    },
    {
        "id": "yearn-yfi-partnerships-mergers",
        "en": "Yearn Ecosystem",
        "ar": "نظام ييرن"
    },
    {
        "id": "prediction-markets",
        "en": "Prediction Markets",
        "ar": "أسواق التنبؤ"
    },
    {
        "id": "move-to-earn",
        "en": "Move To Earn",
        "ar": "التحرك و المشي للربح"
    },
    {
        "id": "daomaker-ecosystem",
        "en": "DaoMaker Ecosystem",
        "ar": "نظام داوميكر"
    },
    {
        "id": "account-abstraction",
        "en": "Account Abstraction",
        "ar": "مشاريع تجريد الحساب"
    },
    {
        "id": "coinbase-ventures-portfolio",
        "en": "Coinbase Ventures Portfolio",
        "ar": "محفظة كوينبيس"
    },
    {
        "id": "dwf-labs-portfolio",
        "en": "DWF Labs Portfolio",
        "ar": "محفظة دبليو دبليو إف"
    },
    {
      "id": "layer-0-l0",
        "en": "Layer 0 (L0)",
        "ar": "الطبقة الصفرية (Layer Zero)",
        "enContent": "Layer 0 is the foundational infrastructure upon which multiple Layer 1 blockchains can be built.",
        "arContent": "الطبقة الصفرية هي البنية الأساسية التي يمكن بناء عدة سلاسل كتل من الطبقة الأولى عليها."
    },
    {
        "id": "defi-pulse-index-dpi",
        "en": "Index Coop Defi Index",
        "ar": "مؤشر ديفي بولس",
        "enContent": "The DeFi Pulse Index (DPI) is a capitalization-weighted index that tracks the performance of some of the largest protocols in the DeFi space.",
        "arContent": "مؤشر DeFi Pulse (DPI) هو مؤشر مرجح بالرأسمال يتتبع أداء أكبر البروتوكولات في مجال التمويل اللامركزي."

    },
    {
        "id": "gaming-utility-token",
        "en": "Gaming Utility Token",
        "ar": "رموز الألعاب"
    },
    {
        "id": "gaming-governance-token",
        "en": "Gaming Governance Token",
        "ar": "رموز الحوكمة للألعاب"
    },
    {
        "id": "simulation-games",
        "en": "Simulation Games",
        "ar": "ألعاب المحاكاة"
    },
    {
        "id": "parallel-evm",
        "en": "Parallelized EVM",
        "ar": "مشاريع EVM المتوازية"
    },
    {
        "id": "gaming-platform",
        "en": "Gaming Platform",
        "ar": "منصات الألعاب"
    },
    {
        "id": "gaming-blockchains",
        "en": "Gaming Blockchains",
        "ar": "سلاسل الكتل للألعاب"
    },
    {
        "id": "restaking",
        "en": "Restaking",
        "ar": "إعادة الرهن",
        "enContent": "Restaking allows users who have staked their tokens to participate in extending the underlying security of the base network to other protocols and networks.",
        "arContent": "تتيح إعادة الرهن للمستخدمين الذين قاموا برهن رموزهم المشاركة في توسيع الأمان الأساسي للشبكة الأساسية إلى بروتوكولات وشبكات أخرى."
    },
    {
        "id": "layer-3-l3",
        "en": "Layer 3 (L3)",
        "ar": "مشاريع الطبقة الثالثة"
    },
    {
        "id": "LSDFi",
        "en": "LSDFi",
        "ar": "LSDFi"
    },
    {
        "id": "adventure-games",
        "en": "Adventure Games",
        "ar": "ألعاب المغامرة"
    },
    {
        "id": "sports-games",
        "en": "Sports Games",
        "ar": "ألعاب الرياضة"
    },
    {
        "id": "nftfi",
        "en": "NFTFi",
        "ar": "نفتفاي"
    },
    {
        "id": "launchpad",
        "en": "Launchpad",
        "ar": "منصات الإطلاق"
    },
    {
        "id": "paradigm-portfolio",
        "en": "Paradigm Portfolio",
        "ar": "محفظة بارادايم"
    },
    {
        "id": "card-games",
        "en": "Card Games",
        "ar": "ألعاب البطاقات الرقمية"
    },
    {
        "id": "theta-ecosystem",
        "en": "Theta Ecosystem",
        "ar": "نظام ثيتا"
    },
    {
        "id": "rollups-as-a-service-raas",
        "en": "Rollups-as-a-Service (RaaS)",
        "ar": "التجميعات كخدمة"
    },
    {
        "id": "decentralized-science-desci",
        "en": "Decentralized Science (DeSci)",
        "ar": "مشاريع العلوم اللامركزية"
    },
    {
        "id": "identity",
        "en": "Identity",
        "ar": "مشاريع الهوية"
    },
    {
        "id": "rebase-tokens",
        "en": "Rebase Tokens",
        "ar": "رموز إعادة التوازن"
    },
    {
        "id": "decentralized-options",
        "en": "Options",
        "ar": "الخيارات"
    },
    {
        "id": "metagovernance",
        "en": "Metagovernance",
        "ar": "الحوكمة الفوقية"
    },
    {
        "id": "fan-token",
        "en": "Fan Token",
        "ar": "رموز المشجعين"
    },
    {
        "id": "music",
        "en": "Music",
        "ar": "الموسيقى"
    },
    {
        "id": "telegram_apps",
        "en": "Telegram Apps",
        "ar": "تطبيقات التليجرام"
    },
    {
        "id": "lsdfi",
        "en": "LSDFi",
        "ar": "LSDFi"
    },
    {
        "id": "eco-friendly",
        "en": "Environment",
        "ar": "البيئة"
    },
    {
        "id": "guild-scholarship",
        "en": "Guild and Scholarship",
        "ar": "النقابة والمنحة"
    },
    {
        "id": "discord-bots",
        "en": "Discord Bots",
        "ar": "روبوتات ديسكورد"
    },
    {
        "id": "synths",
        "en": "Synths",
        "ar": "المركبات"
    },
    {
        "id": "ohm-fork",
        "en": "Ohm Fork",
        "ar": "فورك أوم"
    },
    {
        "id": "tourism",
        "en": "Tourism",
        "ar": "السياحة"
    },
    {
        "id": "defi-index",
        "en": "DeFi Index",
        "ar": "مؤشر العملات المشفرة"
    },
    {
        "id": "animal-racing",
        "en": "Animal Racing",
        "ar": "سباق الحيوانات"
    },
    {
        "id": "kommunitas",
        "en": "Kommunitas Launchpad",
        "ar": "منصة كوميونيتاس للإطلاق"
    },
    {
        "id": "chaingpt-pad",
        "en": "ChainGPT Pad",
        "ar": "منصة تشين جي بي تي للإطلاق"
    },
    {
        "id": "reddit-points",
        "en": "Reddit Points",
        "ar": "نقاط ريديت"
    },
    {
        "id": "idr-stablecoin",
        "en": "IDR Stablecoin",
        "ar": "العملات المستقرة IDR"
    },
    {
        "id": "jpy-stablecoin",
        "en": "JPY Stablecoin",
        "ar": "العملات المستقرة JPY"
    },
    {
        "id": "dog-themed-coins",
        "en": "Dog-Themed",
        "ar": "عملات الكلاب",
        "enContent": "Meme coins which are inspired by dog.",
        "arContent": "عملات الميم المستوحاة من الكلاب."
    },
    {
        "id": "elon-musk-inspired-coins",
        "en": "Elon Musk-Inspired",
        "ar": "عملات حول إيلون ماسك",
        "enContent": "Meme coins inspired by Elon Musk, none of these projects are (necessarily) endorsed or created by Elon Musk.",
        "arContent": "عملات الميم المستوحاة من إيلون ماسك، لا يتم تأييد أو إنشاء أي من هذه المشاريع بواسطة إيلون ماسك."
    },
    {
        "id": "depin",
        "en": "DePIN",
        "ar": "عملات البنية التحتية المادية اللامركزية",
        "enContent": "DePIN refers to Decentralized Physical Infrastructure Networks which utilize blockchain and tokenized incentives to build real-world infrastructure across areas like wireless networks, storage, geolocation, transportation and energy.",
        "arContent": "تشير DePIN إلى شبكات البنية التحتية المادية اللامركزية التي تستخدم تكنولوجيا البلوكشين و العملات المشفرة لبناء بنية تحتية حقيقية عبر مجالات مثل الشبكات اللاسلكية والتخزين والموقع الجغرافي والنقل والطاقة."
    },
    {
        "id": "binance-launchpool",
        "en": "Binance Launchpool",
        "ar": "بينانس لانشبول"
    },
    {
        "id": "brc-20",
        "en": "BRC-20",
        "ar": "عملات BRC-20"
    },
    {
        "id": "data-availability",
        "en": "Data Availability",
        "ar": "توافر البيانات",
        "enContent": "Data availability on the blockchain ensures that all participants have the transaction data needed to verify blocks, even with resource limitations and scalability needs. Without it, independent verification crumbles, compromising the entire system. Data availability centric protocols aim to allow nodes to verify data availability more efficiently.",
        "arContent": "تضمن توافر البيانات على البلوكشين أن جميع المشاركين لديهم بيانات المعاملات اللازمة للتحقق من الكتل، حتى مع قيود الموارد واحتياجات التوسع. بدونه، ينهار التحقق المستقل، مما يعرض النظام بأكمله للخطر. تهدف البروتوكولات المركزية لتوافر البيانات إلى السماح للعقد بالتحقق من توافر البيانات بشكل أكثر كفاءة."
    },
    {
        "id": "modular-blockchain",
        "en": "Modular Blockchain",
        "ar": "سلاسل الكتل المعيارية"
    },
    {
        "id": "decentralized-identity",
        "en": "Decentralized Identity",
        "ar": "الهوية اللامركزية"
    },
    {
        "id": "gmci-30-index",
        "en": "GMCI 30 Index",
        "ar": "أكبر 30 عملة في السوق",
        "enContent": "The GMCI 30 Index (GM30) is an index tracking the performance of the top 30 digital assets based on their circulating market capitalization by GMCI. ",
        "arContent": "مؤشر `GMCI 30` هو مؤشر يتتبع أداء أفضل 30 عملة رقمية بناءً على القيمة السوقية."
    },
    {
        "id": "proof-of-work-pow",
        "en": "Proof of Work (PoW)",
        "ar": "دليل العمل ( PoW )",
        "enContent": "Proof of Work (PoW) is a blockchain consensus mechanism to validate transactions and create new blocks through computational efforts by miners.",
        "arContent": "دليل العمل (PoW) هو آلية توافق البلوكشين للتحقق من المعاملات وإنشاء كتل جديدة من خلال الجهود الحسابية التي يبذلها المعدنون."
    },
    {
        "id": "proof-of-stake-pos",
        "en": "Proof of Stake (PoS)",
        "ar": "دليل الحصة ( PoS )",
        "enContent": "Proof of Stake (PoS) is a blockchain consensus mechanism where validators are selected to create new blocks based on the amount of cryptocurrency they hold and are willing to stake as collateral.",
        "arContent": "دليل الحصة (PoS) هو آلية توافق البلوكشين حيث يتم اختيار المحققين لإنشاء كتل جديدة بناءً على كمية العملة المشفرة التي يمتلكونها والتي يرغبون في رهنها كضمان."
    },
    {
        "id": "andreessen-horowitz-a16z-portfolio",
        "en": "Andreessen Horowitz (a16z) Portfolio",
        "ar": "محفظة أندريسن هورويتز",
        "enContent": "Protocols and tokens that Andreessen Horowitz (a16z)  have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها أندريسن هورويتز."
    },
    {
        "id": "defi-protocol",
        "en": "DeFi Protocol",
        "ar": "بروتوكولات العملات المشفرة"
    },
    {
        "id": "multicoin-capital-portfolio",
        "en": "Multicoin Capital Portfolio",
        "ar": "محفظة مالتيكوين كابيتال",
        "enContent": "Protocols and tokens that Multicoin Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها مالتيكوين كابيتال."
    },
    {
        "id": "alameda-research-portfolio",
        "en": "Alameda Research Portfolio",
        "ar": "محفظة الامادا ريسيرش",
        "enContent": "Protocols and tokens that Alameda Research have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها الامادا ريسيرش."
    },
    {
        "id": "delphi-digital-portfolio",
        "en": "Delphi Digital Portfolio",
        "ar": "محفظة دلفي ديجيتال",
        "enContent": "Protocols and tokens that Delphi Digital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها دلفي ديجيتال."
    },
    {
        "id": "galaxy-digital-portfolio",
        "en": "Galaxy Digital Portfolio",
        "ar": "محفظة جالاكسي ديجيتال",
        "enContent": "Protocols and tokens that Galaxy Digital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها جالاكسي ديجيتال."
    },
    {
        "id": "pantera-capital-portfolio",
        "en": "Pantera Capital Portfolio",
        "ar": "محفظة بانتيرا كابيتال",
        "enContent": "Protocols and tokens that Pantera Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها بانتيرا كابيتال."
    },
    {
        "id": "blockchain-capital-portfolio",
        "en": "Blockchain Capital Portfolio",
        "ar": "محفظة بلوكشين كابيتال",
        "enContent": "Protocols and tokens that Blockchain Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها بلوكشين كابيتال."
    },
    {
        "id": "animoca-brands",
        "en": "Animoca Brands Portfolio",
        "ar": "محفظة أنيموكا براندز",
        "enContent": "Protocols and tokens that Animoca Brands have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها أنيموكا براندز."
    },
    {
        "id": "dragonfly-capital-portfolio",
        "en": "DragonFly Capital Portfolio",
        "ar": "محفظة دراجون فلاي كابيتال",
        "enContent": "Protocols and tokens that DragonFly Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها دراجون فلاي كابيتال."
    },
    {
        "id": "binance-labs-portfolio",
        "en": "Binance Labs Portfolio",
        "ar": "محفظة بينانس لابز",
        "enContent": "Protocols and tokens that Binance Labs have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها بينانس لابز."
    },
    {
        "id": "circle-ventures-portfolio",
        "en": "Circle Ventures Portfolio",
        "ar": "محفظة سيركل فينتشرز",
        "enContent": "Protocols and tokens that Circle Ventures have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها سيركل فينتشرز."
    },
    {
        "id": "defiance-capital-portfolio",
        "en": "DeFiance Capital Portfolio",
        "ar": "محفظة ديفايانس كابيتال",
        "enContent": "Protocols and tokens that DeFiance Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها ديفايانس كابيتال."
    },
    {
        "id": "okx-ventures-portfolio",
        "en": "OKX Ventures Portfolio",
        "ar": "محفظة أوكيكس فينتشرز",
        "enContent": "Protocols and tokens that OKX Ventures have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها أوكيكس فينتشرز."
    },
    {
        "id": "sequoia-capital-portfolio",
        "en": "Sequoia Capital Portfolio",
        "ar": "محفظة سيكويا كابيتال",
        "enContent": "Protocols and tokens that Sequoia Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها سيكويا كابيتال."
    },
    {
        "id": "egirl-capital-portfolio",
        "en": "eGirl Capital Portfolio",
        "ar": "محفظة إيجيرل كابيتال",
        "enContent": "Protocols and tokens that eGirl Capital have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها إيجيرل كابيتال."
    },
    {
        "id": "consensys-portfolio",
        "en": "Consensys Portfolio",
        "ar": "محفظة كونسينسيس",
        "enContent": "Protocols and tokens that Consensys have invested in.",
        "arContent": "البروتوكولات والعملات المشفرة التي استثمرت فيها كونسينسيس."
    },
    {
        "id": "fiat-backed-stablecoin",
        "en": "Fiat-backed Stablecoin",
        "ar": "العملات المستقرة المدعومة بالعملات الحقيقية",
        "enContent": "A fiat-backed stablecoin is designed to maintain a stable value by being pegged to a reserve of fiat currency, such as the US dollar, euro, or other national currencies. ",
        "arContent": "العملة المستقرة المدعومة بالعملات الحقيقية مصممة للحفاظ على قيمة مستقرة من خلال ربطها بمخزون من العملة الورقية، مثل الدولار الأمريكي، اليورو، أو عملات أخرى."
    },
    {
        "id": "usd-stablecoin",
        "en": "USD Stablecoin",
        "ar": "العملات المستقرة USD",
        "enContent": "A USD stablecoin is a type of cryptocurrency that is designed to maintain a stable value by being pegged to the United States dollar.",
        "arContent": "العملة المستقرة USD هي نوع من العملات المشفرة التي تم تصميمها للحفاظ على قيمة مستقرة من خلال ربطها بالدولار الأمريكي."
    },
    {
        "id": "gmci-layer-1-index",
        "en": "GMCI Layer 1 Index",
        "ar": "مؤشر مشاريع الطبقة الأولى",
        "enContent": "The GMCI Layer 1 Index (GML1) is an index tracking the performance of the top Layer 1 protocols based on their circulating market capitalization by GMCI.",
        "arContent": "مؤشر `GMCI Layer 1` هو مؤشر يتتبع أداء أفضل مشاريع الطبقة الأولى بناءً على القيمة السوقية."
    },
    {
        "id": "infrastructure",
        "en": "Infrastructure",
        "ar": "البنية التحتية"
    },
    {
        "id": "bitcoin-layer-2",
        "en": "Bitcoin Sidechains",
        "ar": "سلاسل البيتكوين الفرعية",
    },
    {
        "id": "cross-chain-communication",
        "en": "Cross-chain Communication",
        "ar": "التواصل بين السلاسل",
    }
]