import {SocialType} from "components/SocialsShare/SocialsShare";
import React, {FC} from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { id: "Twitter", name: "تويتر", icon: "lab la-twitter", href: "https://twitter.com/AlCryptoCast" },
  { id: "Youtube", name: "يوتيوب", icon: "lab la-youtube", href: "https://www.youtube.com/channel/UCL7b2il3jLlmXithY-x-_lg" },
  { id: "Discord", name: "ديسكورد", icon: "lab la-discord", href: "https://discord.com" },
];

export const SOCIALS_2 = socialsDemo;

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <a
          key={i}
          className={`${itemClass}`}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          title={item.name}
        >
          <i className={item.icon}></i>
        </a>
      ))}
    </nav>
  );
};

export default SocialsList;
