//@ts-nocheck
import React, {FC, useEffect, useState} from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import {Helmet} from "react-helmet";
import axios from "axios";
import {toNumber} from "lodash";
import Skeleton from "react-loading-skeleton";
import {Adsense} from '@ctrl/react-adsense';
import {arCategoriesNames} from "../../data/jsons/translations/arCategories";
import ReactGA from "react-ga4";
import {Link} from "react-router-dom";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


export interface PageFirstTeamResultsProps {
    className?: string;
}



const PageCryptoCategories: FC<PageFirstTeamResultsProps> = ({ className = "" }) => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "فئات العملات المشفرة" });
    },[]);

    const [loadingCategories, setLoadingCategories] = useState(true);
    const [categories, setCategories] = useState<any[]>([]);
    function formatLargeNumbers(number: number) {
        const absNumber = Math.abs(number);
        if (absNumber >= 1.0e+12) {
            return (absNumber / 1.0e+12).toFixed(2) + " تريليون";
        }
        else if (absNumber >= 1.0e+9) {
            return (absNumber / 1.0e+9).toFixed(2) + " مليار";
        } else if (absNumber >= 1.0e+6) {
            return (absNumber / 1.0e+6).toFixed(2) + " مليون";
        } else if (absNumber >= 1.0e+3) {
            return (absNumber / 1.0e+3).toFixed(2) + " ألف";
        } else {
            return absNumber.toLocaleString('ar-EG');
        }
    }
    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/categories`)
            .then(function (response) {
                const excludedIds = ["coinbase-ventures-portfolio", "consensys-portofolio", "ftx-holdings", "paradigm-portfolio", "dwf-labs-portfolio","andreessen-horowitz-a16z-portfolio", "multicoin-capital-portfolio", "alameda-research-portfolio", "delphi-digital-portfolio", "galaxy-digital-portfolio", "pantera-capital-portfolio", "blockchain-capital-portfolio", "animoca-brands", "dragonfly-capital-portfolio", "binance-labs-portfolio", "circle-ventures-portfolio", "defiance-capital-portfolio", "okx-ventures-portfolio", "sequoia-capital-portfolio", "egirl-capital-portfolio", "consensys-portfolio"]
                const filteredCategories = response.data.filter((category: any) => !excludedIds.includes(category.id));
                const excludedCategories = response.data.filter((category: any) => excludedIds.includes(category.id));
                setCategories(filteredCategories);
                //setCategories(response.data)
                setLoadingCategories(false)
            }).catch(function (error) {
            console.log(error);
        })
    }, []);



    const categoryNameRow = () => {
        return (
            <div className="text-slate-800 dark:text-white font-semibold mr-1 text-xs md:text-sm"><Link to={`categories/${category.id}`} className={"hover:opacity-50"}>{arCategoriesNames.find(team => team.en === category.name) ? arCategoriesNames.find(team => team.en === category.name).ar : category.name}</Link></div>
        )
    }


    const renderStanding = () => {
        return (
            categories.slice(0,90).map((category, index) => {
                return (
                    <tr key={index}>
                        <td className="p-2 text-lg font-bold">{index + 1}</td>
                        <td className="p-2">
                            <div className="text-slate-800 dark:text-white font-semibold mr-1 text-xs md:text-sm"><Link to={{
                                pathname: `categories/${category.id}`,
                                state: {
                                    marketCap: category.market_cap,
                                    marketCapChange: category.market_cap_change_24h,
                                    volume24h: category.volume_24h
                                }
                            }}  className={"hover:opacity-50"}>{arCategoriesNames.find(team => team.en === category.name) ? arCategoriesNames.find(team => team.en === category.name).ar : category.name}</Link></div>

                        </td>
                        <td className="p-2">
                            <div className="text-center font-bold">{formatLargeNumbers(category.market_cap)}</div>
                        </td>
                        <td className="p-2">
                            <div className={parseFloat(category.market_cap_change_24h) > 0 ? "text-center font-bold text-green-500" : "text-center font-bold text-red-500"} dir={"ltr"}>{parseFloat(category.market_cap_change_24h).toFixed(2)} %</div>
                        </td>
                        <td className="p-2">
                            <div className="text-center font-bold">{formatLargeNumbers(category.volume_24h)}</div>
                        </td>
                        <td className="p-2">
                            <div className={"flex flex-col lg:flex-row justify-center categorys-center"}>
                                <img src={category.top_3_coins[0]} className={"rounded-full w-6 h-6 lg:mr-4 mb-2"}/>
                                <img src={category.top_3_coins[1]} className={"rounded-full w-6 h-6 lg:mr-4 mb-2"}/>
                                <img src={category.top_3_coins[2]} className={"rounded-full w-6 h-6 lg:mr-4"}/>
                            </div>
                        </td>
                    </tr>
                )
            })
        )
    }

    return (
        <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
            <Helmet>
                <title>منصة كريبتو كاست || فئات العملات المشفرة</title>
            </Helmet>
            <LayoutPage
                subHeading="أهم فئات العملات المشفرة حسب القيمة السوقية"
                headingEmoji=""
                heading="فئات العملات المشفرة"
            >

                <div className="p-3">
                    <div className="overflow-x-auto">
                        <table className="table-auto w-full mt-8">
                            {/* Table header */}
                            <thead className="text-xs uppercase text-slate-400 dark:text-neutral-100 dark:bg-primary-6000 bg-slate-50 rounded-sm">
                            <tr>
                                <th className="p-2">
                                    <div className="font-semibold text-left">الترقيم</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-left">التصنيف</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">القيمة السوقية $</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">24 ساعة</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">حجم التداول اليوم $</div>
                                </th>
                                <th className="p-2">
                                    <div className="font-semibold text-center">أهم 3 عملات</div>
                                </th>


                            </tr>
                            </thead>
                            {/* Table body */}
                            <tbody className="text-sm font-medium divide-y divide-slate-100">
                            {loadingCategories && <Skeleton width={"1600%"} count={10} baseColor="#f7f5f5" highlightColor="#ede4e4"/>}
                            {renderStanding()}
                            </tbody>
                        </table>

                    </div>
                </div>

                <div
                    className={`nc-SectionAds mt-10 lg:pb-8 mb-2 block text-center mx-auto`}
                >
                    <Adsense
                        client="ca-pub-5724020986103870"
                        slot="5200134090"
                        style={{ display: 'block' }}
                        layout="in-article"
                        format="fluid"
                    />
                </div>

            </LayoutPage>


            {/* OTHER SECTIONS */}

        </div>
    );
};

export default PageCryptoCategories
