//@ts-nocheck
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import LayoutPageAvatar from "components/LayoutPage/LayoutPageAvatar";
import {useParams} from "react-router-dom";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import ReactGA from "react-ga4";
import axios from "axios";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {arCategoriesNames} from "../../data/jsons/translations/arCategories";
import CoinCard from "../../components/MatchCard/CoinCard";
import Skeleton from "react-loading-skeleton";
import Input from "../../components/Input/Input";
import {glossaryData} from "../../data/jsons/translations/arGlossary";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageGlossary: React.FC = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: `Arabic - Text` });
    },[]);

    const [searchTerm, setSearchTerm] = useState("");
    const [filterLetter, setFilterLetter] = useState("");

    const filteredData = glossaryData.filter(entry =>
        (filterLetter ? entry.enTerm.startsWith(filterLetter) : true) &&
        entry.enTerm.toLowerCase().includes(searchTerm.toLowerCase())
    );



    // @ts-ignore
    return (
        <div className="">
            <Helmet>
                <title>منصة كريبتو كاست || مصطلحات تقنية</title>
            </Helmet>
            <LayoutPage
                subHeading="Glossary"
                headingEmoji=""
                heading="مصطلحات تقنية"
            >

                <div>
                    <form className="relative">
                        <Input
                            type="search"
                            placeholder="إبحث عن مصطلح"
                            className="pr-10 w-full text-center"
                            sizeClass="h-[42px] pl-4 py-3"
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                        <span
                            className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                        <input type="submit" hidden value=""/>
                    </form>


                    <div className="p-4">
                        {filteredData.map((entry, index) => (
                            <div key={index} className="rounded-lg overflow-hidden shadow-md bg-white dark:bg-neutral-800 p-4 mb-4">
                                <div className={"flex items-center mb-2"}>
                                    <h2 className="font-bold text-xl mr-4 font-display" dir={"ltr"}>{entry.enTerm}</h2>
                                    <span>( {entry.arTerm} )</span>
                                </div>
                                <p className="text-gray-600 dark:text-gray-300">{entry.description}</p>
                            </div>
                        ))}
                    </div>
                </div>



                {/* HEADER */}

            </LayoutPage>
        </div>

    );
}

export default PageGlossary;
