// @ts-nocheck
import React from 'react';

const BitcoinHoldingsComponent = ({ data }) => {

    const countryCodesTranslations = [
        {
            "en": "US",
            "ar": "الولايات المتحدة"
        },
        {
            "en": "CA",
            "ar": "كندا"
        },
        {
            "en": "GB",
            "ar": "المملكة المتحدة"
        },
        {
            "en": "JP",
            "ar": "اليابان"
        },
        {
            "en": "HK",
            "ar": "هونغ كونغ"
        },
        {
            "en": "DE",
            "ar": "ألمانيا"
        },
        {
            "en": "AU",
            "ar": "أستراليا"
        },
        {
            "en": "TH",
            "ar": "تايلاند"
        }
    ]

    const companiesTranslations = [
        {
            "en": "MicroStrategy Inc.",
            "ar": "مايكروستراتيجي",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/MicroStrategy.webp"
        },
        {
            "en": "Marathon Digital Holdings",
            "ar": "ماراثون القابضة الرقمية",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/MARA.svg"
        },
        {
            "en": "Galaxy Digital Holdings",
            "ar": "جالاكسي القابضة الرقمية",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/7LX.F.svg"
        },
        {
            "en": "Tesla, Inc.",
            "ar": "تسلا",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/TSLA.svg"
        },
        {
            "en": "Coinbase Global, Inc",
            "ar": "كوينبيس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/COIN.svg"
        },
        {
            "en": "Hut 8 Mining Corp",
            "ar": "هات 8 ماينينج",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/HUT.svg"
        },
        {
            "en": "Block Inc.",
            "ar": "بلوك",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/SQ.png"
        },
        {
            "en": "Riot Platforms, Inc",
            "ar": "ريوت بلوكشين",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/RIOT.svg"
        },
        {
            "en": "Hive Blockchain",
            "ar": "هايف بلوكشين",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/HIVE.svg"
        },
        {
            "en": "NEXON Co Ltd",
            "ar": "نيكسون",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/3659.T.svg"
        },
        {
            "en": "CleanSpark Inc.",
            "ar": "كلين سبارك",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/CLSK.svg"
        },
        {
            "en": "Exodus Movement Inc",
            "ar": "إكسودس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/exodus.svg"
        },
        {
            "en": "Meitu Inc",
            "ar": "ميتو",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/meitu.png"
        },
        {
            "en": "Bit Digital, Inc",
            "ar": "بيت ديجيتال",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/BTBT.png"
        },
        {
            "en": "NFT Investments PLC",
            "ar": "إن إف تي إنفستمنتس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/nft.jpeg"
        },
        {
            "en": "DMG Blockchain Solutions Inc.",
            "ar": "دي إم جي بلوكشين سوليوشنز",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/dmg.png"
        },
        {
            "en": "Bitfarms Limited",
            "ar": "بيت فارمز",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/BITF.svg"
        },
        {
            "en": "Cipher Mining",
            "ar": "سيفر ماينينج",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/CIFR.svg"
        },
        {
            "en": "BIGG Digital Assets Inc.",
            "ar": "بيج ديجيتال أسيتس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/bigg.png"
        },
        {
            "en": "Neptune Digital Assets Corp.",
            "ar": "نبتون ديجيتال أسيتس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/neptune.png"
        },
        {
            "en": "Advanced Bitcoin Technologies AG",
            "ar": "أدفانسد بيتكوين تكنولوجيز",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/advanced.png"
        },
        {
            "en": "DigitalX",
            "ar": "ديجيتال إكس",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/digitalx.png"
        },
        {
            "en": "FRMO Corp.",
            "ar": "إف آر إم أو",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/frmo.png"
        },
        {
            "en": "The Brooker Group",
            "ar": "بروكر",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/broker.png"
        },
        {
            "en": "LQwD Technologies Corp",
            "ar": "إل كيو دي تكنولوجيز",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/lqwd.webp"
        },
        {
            "en": "Cypherpunk Holdings Inc",
            "ar": "سايفربانك هولدنجز",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/cyber.jpeg"
        },
        {
            "en": "Mogo Inc.",
            "ar": "موجو",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/MOGO.svg"
        },
        {
            "en": "Core Scientific",
            "ar": "كور ساينتفيك",
            "image": "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/CORZQ.svg"
        }
    ]

    const formatCurrency = (num) => {
        if (num >= 1_000_000_000) {
            return `${(num / 1_000_000_000).toFixed(2)} مليار`;
        } else if (num >= 1_000_000) {
            return `${(num / 1_000_000).toFixed(2)} مليون`;
        } else {
            return num.toString();
        }
    };

    return (
        <div dir="rtl" className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">

            {/* Global Stats Cards */}
            <div className="col-span-1 lg:col-span-3 grid grid-cols-1 lg:grid-cols-3 gap-4 mb-6">
                <div className="border p-6 rounded-lg shadow-md bg-white dark:bg-black dark:bg-opacity-20 text-center border-4 border-red-500">
                    <p className="dark:text-gray-300 font-bold text-3xl lg:text-5xl mb-6">
                        {formatCurrency(data.total_holdings)} بيتكوين
                    </p>
                    <p className="text-xl lg:text-2xl font-bold mb-4 dark:text-white">
                        إجمالي الاستحواذ
                    </p>
                </div>
                <div className="border p-6 rounded-lg shadow-md bg-white dark:bg-black dark:bg-opacity-20 text-center border-4 border-red-500">
                    <p className="dark:text-gray-300 font-bold text-3xl lg:text-5xl mb-6">
                        ${formatCurrency(data.total_value_usd)}
                    </p>
                    <p className="text-xl lg:text-2xl font-bold mb-4 dark:text-white">
                        القيمة الإجمالية بالدولار
                    </p>
                </div>
                <div className="border p-6 rounded-lg shadow-md bg-white dark:bg-black dark:bg-opacity-20 text-center border-4 border-red-500">
                    <p className="dark:text-gray-300 font-bold text-3xl lg:text-5xl mb-6">
                        {data.market_cap_dominance}%
                    </p>
                    <p className="text-xl lg:text-2xl font-bold mb-4 dark:text-white">
                        نسبة الهيمنة السوقية
                    </p>
                </div>
            </div>

            {/* Companies Data Cards */}
            {data.companies.map((item, index) => (
                <div key={index} className="border p-4 rounded-lg shadow-md bg-white dark:bg-slate-300 dark:bg-opacity-30">
                    <div className={"flex items-center mb-6"}>
                        <img src={companiesTranslations.find(company => company.en === item.name) ? companiesTranslations.find(company => company.en === item.name).image : "https://sociosclubiste.nyc3.digitaloceanspaces.com/companies/MicroStrategy.webp"} alt={item.name} className="w-12 h-12 mb-2 mr-4" />
                        <h2 className="text-xl font-bold mb-4 dark:text-white">
                            {companiesTranslations.find(company => company.en === item.name) ? companiesTranslations.find(company => company.en === item.name).ar : item.name}
                        </h2>
                    </div>
                    <p className="mb-2 dark:text-white">
                        <span className="font-semibold">الرمز:</span> {item.symbol}
                    </p>
                    <p className="mb-2 dark:text-white">
                        <span className="font-semibold">البلد:</span> {countryCodesTranslations.find(country => country.en === item.country) ? countryCodesTranslations.find(country => country.en === item.country).ar : item.country}
                    </p>
                    <p className="mb-2 dark:text-white">
                        <span className="font-semibold">إجمالي الاستحواذ:</span> {item.total_holdings}
                    </p>
                    <p className="mb-2 dark:text-white">
                        <span className="font-semibold">القيمة الإجمالية عند الشراء بالدولار:</span> {formatCurrency(item.total_entry_value_usd)}
                    </p>
                    <p className="mb-2 dark:text-white">
                        <span className="font-semibold">القيمة الحالية بالدولار:</span> {formatCurrency(item.total_current_value_usd)}
                    </p>
                    <p className=" dark:text-white">
                        <span className="font-semibold">نسبة من إجمالي العرض:</span> {item.percentage_of_total_supply} %
                    </p>
                </div>
            ))}
        </div>
    );
};

export default BitcoinHoldingsComponent;
