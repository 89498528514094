import React from "react";
import {Link} from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import rbjLogo from "../../images/rbjLogo.jpeg";
import rbjRtl from "../../images/rbjRtl.jpeg";
import lefri9iLogo from "../../images/lefri9iLogo.jpeg";
import lefri9iCa from "../../images/lefri9iLogo.jpeg"
import lefri9iCaDark from "../../images/lefri9iLogo.jpeg";
import {selectDarkmodeState} from "../../app/darkmode/darkmode";
import {useAppSelector} from "../../app/hooks";
import avaxLogo from "../../images/avax.png";
import bitcoinPodcastLogo from "../../images/cryptoCast.jpeg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
                                     img = logoImg,
                                     imgLight = logoLightImg,
                                   }) => {
    const isDarkMode = useAppSelector(selectDarkmodeState);
  return (
      <Link to="/" className="ttnc-logo inline-block text-primary-6000">
        {/* THIS USE FOR MY MULTI DEMO */}
        {/* IF YOU ARE MY CLIENT. PLESE DELETE THIS CODE AND YOU YOUR IMAGE PNG BY BELLOW CODE */}
          <img src={isDarkMode ? bitcoinPodcastLogo : bitcoinPodcastLogo } className={"w-12 h-12 sm:w-20 sm:h-20 rounded-full border-4 border-red-500 dark:border-4 dark:border-white"}/>
      </Link>
  );
};

export default Logo;
