import {NavItemType,} from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


const dashboardChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
  },
  {
    id: ncNanoId(),
    href: "/dashboard/posts",
    name: "Posts",
  },
  {
    id: ncNanoId(),
    href: "/dashboard/edit-profile",
    name: "Edit profile",
  },
  {
    id: ncNanoId(),
    href: "/dashboard/billing-address",
    name: "Billing address",
  },
  {
    id: ncNanoId(),
    href: "/dashboard/subscription",
    name: "Subscription",
  },
  {
    id: ncNanoId(),
    href: "/dashboard/submit-post",
    name: "Submit post",
  },
];

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Signup",
  },
  {
    id: ncNanoId(),
    href: "/forgot-pass",
    name: "Forgot Password",
  },
  {
    id: ncNanoId(),
    href: "/dashboard",
    name: "Dashboard",
    type: "dropdown",
    children: dashboardChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
  },
];

const archviePageChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/history",
    name: "Archive Page",
  },
  {
    id: ncNanoId(),
    href: "/archive-audio/the-demo-archive-slug",
    name: "Archive Audio",
  },
  {
    id: ncNanoId(),
    href: "/archive-video/the-demo-archive-slug",
    name: "Archive Video",
  },
  {
    id: ncNanoId(),
    href: "/author/the-demo-author-slug",
    name: "Author Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/author/the-demo-author-slug",
        name: "Author Page 1",
      },
      {
        id: ncNanoId(),
        href: "/author-v2/the-demo-author-slug",
        name: "Author Page 2",
      },
    ],
  },
  {
    id: ncNanoId(),
    href: "/search",
    name: "Search Pages",
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/search",
        name: "Seach Page 1",
      },
      {
        id: ncNanoId(),
        href: "/search-v2",
        name: "Search Page 2",
      },
    ],
  },
];

const singleChildrenMenus: NavItemType = {
  id: ncNanoId(),
  href: "/single/this-is-single-slug",
  name: "Single Templates",
  isNew: true,
  type: "dropdown",
  children: [
    {
      id: ncNanoId(),
      href: "/single-sidebar/this-is-single-slug",
      name: "Single style 1",
    },
    {
      id: ncNanoId(),
      name: "Single 1 sidebar",
      href: "/single/this-is-single-slug-2",
    },
    {
      id: ncNanoId(),
      href: "/single-template-2/this-is-single-slug-2",
      name: "Single style 2",
    },
    {
      id: ncNanoId(),
      href: "/single-2-sidebar/this-is-single-slug",
      name: "Single 2 sidebar",
    },
    {
      id: ncNanoId(),
      href: "/single-template-3/this-is-single-slug-3",
      name: "Single style 3",
    },
    {
      id: ncNanoId(),
      href: "/single-3-sidebar/this-is-single-slug",
      name: "Single 3 sidebar",
    },
    {
      id: ncNanoId(),
      href: "/single-4-sidebar/this-is-single-slug",
      name: "Single style 4 ",
      isNew: true,
    },
  ],
};

const demoChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/home-header-style2",
    name: "Header Styles",
    isNew: true,
    type: "dropdown",
    children: [
      {
        id: ncNanoId(),
        href: "/home-header-style1",
        name: "Header - 1",
      },
      {
        id: ncNanoId(),
        href: "/home-header-style2",
        name: "Header - 2",
        isNew: true,
      },
      {
        id: ncNanoId(),
        href: "/home-header-style2-logedin",
        name: "Header Logedin",
        isNew: true,
      },
    ],
  },
  {
    id: ncNanoId(),
    name:
      import.meta.env.VITE_LRT_OR_RTL === "rtl"
        ? "Default Demo - LTR"
        : "Home Demo 1",
    targetBlank: import.meta.env.VITE_LRT_OR_RTL === "rtl",
    href:
      import.meta.env.VITE_LRT_OR_RTL === "rtl"
        ? "https://chisnghiax.com/ncmaz/"
        : "/",
  },

  {
    id: ncNanoId(),
    href: "/home-demo-2",
    name: "Home Demo 2",
  },
  {
    id: ncNanoId(),
    href: "/home-demo-3",
    name: "Home Demo 3",
  },
  {
    id: ncNanoId(),
    href: "/home-demo-4",
    name: "Home Demo 4",
  },
  {
    id: ncNanoId(),
    href: "/home-demo-6",
    name: "Home - News",
  },
  {
    id: ncNanoId(),
    href:
      import.meta.env.VITE_LRT_OR_RTL !== "rtl"
        ? "https://chisnghiax.com/ncmaz-rtl/"
        : "/",
    name: "Home Demo - RTL",
    targetBlank: import.meta.env.VITE_LRT_OR_RTL !== "rtl",
  },
];

const templateChilds: NavItemType[] = [
  ...archviePageChildrenMenus,
  singleChildrenMenus,
  {
    id: ncNanoId(),
    href: "/single-gallery/this-is-single-slug",
    name: "Single Gallery",
  },
  {
    id: ncNanoId(),
    href: "/single-audio/this-is-single-slug",
    name: "Single Audio",
  },
  {
    id: ncNanoId(),
    href: "/single-video/this-is-single-slug",
    name: "Single Video",
  },
];

const clubChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/coins",
    name: "أسعار العملات",
  },
  {
    id: ncNanoId(),
    href: "/top-gainers",
    name: "الأكثر ربحًا",
  },
  {
    id: ncNanoId(),
    href: "/top-loosers",
    name: "الأكثر خسارة",
  },
  {
    id: ncNanoId(),
    href: "/categories",
    name: "فئات المشاريع",
  },
  {
    id: ncNanoId(),
    href: "/venture-capitals",
    name: "محافظ الشركات الإستثمارية",
  },

];

const firstTeamChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/first-team-squad",
    name: "قائمة اللاعبين",
  },
  {
    id: ncNanoId(),
    href: "/fixtures",
    name: "المباريات القادمة",
  },
  {
    id: ncNanoId(),
    href: "/results",
    name: "النتائج",
  },
  {
    id: ncNanoId(),
    href: "/standing",
    name: "الترتيب",
  },
  {
    id: ncNanoId(),
    href: "/historical-data",
    name: "المواسم السابقة",
  },
  {
    id: ncNanoId(),
    href: "/football-charts",
    name: "رسوم بيانية",
  },
  {
    id: ncNanoId(),
    href: "/first-team-news",
    name: "الأخبار",
  },
];

const firstTeamHandballChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/handball/first-team-squad",
    name: "قائمة اللاعبين",
  },
  {
    id: ncNanoId(),
    href: "/handball/fixtures",
    name: "المباريات القادمة",
  },
  {
    id: ncNanoId(),
    href: "/handball/results",
    name: "النتائج",
  },
  {
    id: ncNanoId(),
    href: "/handball/standing",
    name: "الترتيب",
  },
  {
    id: ncNanoId(),
    href: "/handball/first-team-news",
    name: "الأخبار",
  },
];

const firstTeamBasketballChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/basketball/first-team-squad",
    name: "قائمة اللاعبين",
  },
  {
    id: ncNanoId(),
    href: "/basketball/fixtures",
    name: "المباريات القادمة",
  },
  {
    id: ncNanoId(),
    href: "/basketball/results",
    name: "النتائج",
  },
  {
    id: ncNanoId(),
    href: "/basketball/standing",
    name: "الترتيب",
  },
  {
    id: ncNanoId(),
    href: "/basketball/first-team-news",
    name: "الأخبار",
  },
];

const ladiesSubChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/handball-womens-first-team-squad",
    name: "Effectif",
  },
  {
    id: ncNanoId(),
    href: "/fixtures",
    name: "Prochains matchs",
  },
  {
    id: ncNanoId(),
    href: "/standing",
    name: "Tableaux",
  },
  {
    id: ncNanoId(),
    href: "/first-news",
    name: "Actualités",
  },
];

const ladiesChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/ladies/handball",
    name: "Handball",
    type: "dropdown",
    children: ladiesSubChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/ladies/volleyball",
    name: "Volleyball",
    type: "dropdown",
    children: ladiesSubChildMenus,
  },
];

const leadersChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/leaders/alerta",
    name: "آليرتا",
  },
  {
    id: ncNanoId(),
    href: "/leaders/confessions",
    name: "كونفيسيون دان أولترا",
  },
  {
    id: ncNanoId(),
    href: "/leaders/faitesgaffe",
    name: "فات جاف",
  },
  {
    id: ncNanoId(),
    href: "/leaders/ultrassystem",
    name: "أولترا سيستيم",
  },

];

const curvaChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/curva/manifesto",
    name: "مانيفستو",
  },
  {
    id: ncNanoId(),
    href: "/curva/mouvement",
    name: "موفمون 4 أكتوبر",
  },
  {
    id: ncNanoId(),
    href: "/curva/ultras-therapy",
    name: "آلتراس ثيرابي",
  },
  {
    id: ncNanoId(),
    href: "/curva/singles",
    name: "أغاني متفرقة",
  },

];

const vandalsChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/vandals/13",
    name: "13",
  },
  {
    id: ncNanoId(),
    href: "/vandals/ln7",
    name: "لاكي نامبر 07",
  },
  {
    id: ncNanoId(),
    href: "/vandals/anarchia",
    name: "آنارشيا",
  },
  {
    id: ncNanoId(),
    href: "/vandals/Rebels",
    name: "ريبيلز لفريقي",
  },

];

const winnersChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/winners/ca-forever",
    name: "سيا فور إيفر",
  },
  {
    id: ncNanoId(),
    href: "/winners/la-doce",
    name: "لا دوتشي",
  },
  {
    id: ncNanoId(),
    href: "/winners/memories",
    name: "ميموريز",
  },
  {
    id: ncNanoId(),
    href: "/winners/singles",
    name: "أغاني متفرقة",
  },

];

const ultrasChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/predictions",
    name: "التصويت",
  },
  
  {
    id: ncNanoId(),
    href: "/ultras/curva-nord",
    name: "كورفا نور",
    type: "dropdown",
    children: curvaChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/ultras/winners",
    name: "أفريكان وينارز",
    type: "dropdown",
    children: winnersChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/ultras/leaders",
    name: "ليدرز كلوبيست",
    type: "dropdown",
    children: leadersChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/ultras/vandals",
    name: "نورث فاندالز",
    type: "dropdown",
    children: vandalsChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/ultras/dodgers",
    name: "دودجرز كلوبيست",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "تواصل معنا",
  }

];

const mediaChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "media/news",
    name: "News",
  },
  {
    id: ncNanoId(),
    href: "media/videos",
    name: "Videos",
  },
  {
    id: ncNanoId(),
    href: "/podcasts",
    name: "Podcasts",
  },
  {
    id: ncNanoId(),
    href: "/photos",
    name: "Photos",
  },
  {
    id: ncNanoId(),
    href: "/ultras",
    name: "Ultras Play",
    type: "dropdown",
    children: ultrasChildMenus,
  },
];

const academyChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/learn/glossary",
    name: "مصطلحات",
  },
  {
    id: ncNanoId(),
    href: "/learn/tutorials",
    name: "دروس",
  }
]


const fansChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/token",
    name: "Token Lefri9i",
  },
  {
    id: ncNanoId(),
    href: "/predictions",
    name: "Votes",
  },
  {
    id: ncNanoId(),
    href: "/points",
    name: "Gagnez des points",
  },
  {
    id: ncNanoId(),
    href: "/rewards",
    name: "Récompenses",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Nous contacter",
  },
];

const exchangesChildMenus: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/exchanges",
        name: "المنصات",
    },
    {
        id: ncNanoId(),
        href: "/dex",
        name: "المنصات اللامركزية",
    },
    {
        id: ncNanoId(),
        href: "/derivatives",
        name: "المشتقات",
    }
];

const web3ChildMenus: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/large-caps",
        name: "المشاريع الكبرى",
    },
    {
        id: ncNanoId(),
        href: "/medium-caps",
        name: "المشاريع المتوسطة",
    },
    {
        id: ncNanoId(),
        href: "/small-caps",
        name: "المشاريع الصغرى",
    },
    {
        id: ncNanoId(),
        href: "/guide",
        name: "دليل كريبتو كاست الإستثماري",
    }
];

const podcastNewsChildMenus: NavItemType[] = [
    {
        id: ncNanoId(),
        href: "/podcasts",
        name: "كريبتو بودكاست",
    },
    {
        id: ncNanoId(),
        href: "/news",
        name: "أخبار العملات الرقمية",
    },
];

const storeChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/kits",
    name: "Official Kits",
  },
  {
    id: ncNanoId(),
    href: "/training",
    name: "Training",
  },
  {
    id: ncNanoId(),
    href: "/mens",
    name: "Mens",
  },
  {
    id: ncNanoId(),
    href: "/womens",
    name: "Womens",
  },
  {
    id: ncNanoId(),
    href: "/kids",
    name: "Kids",
  },
  {
    id: ncNanoId(),
    href: "/retro",
    name: "Retro",
  },
];







export const NAVIGATION_SHORT_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    type: "dropdown",
    children: demoChildMenus,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "Discover",
    type: "dropdown",
    children: templateChilds,
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "How it works",
  },
];

export const NAVIGATION_SOCIOS: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "#",
    name: "سوق الكريبتو",
    type: "dropdown",
    children: clubChildMenus,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "منصات التداول",
    type: "dropdown",
    children: exchangesChildMenus,
  },
  {
    id: ncNanoId(),
    href: "#",
    name: "محفظة كريبتو كاست",
    type: "dropdown",
    children: web3ChildMenus,
  },

  {
    id: ncNanoId(),
    href: "#",
    name: "بودكاست و أخبار",
    type: "dropdown",
    children: podcastNewsChildMenus,
  },

  {
    id: ncNanoId(),
    href: "/media",
    name: "تعلم",
    type: "dropdown",
    children: academyChildMenus,
  }

];
