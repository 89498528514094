import React from 'react';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any;
    label?: number;
    isDarkMode: boolean;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isDarkMode }) => {
    if (active && payload && payload.length) {
        return (
            <div className={isDarkMode ? 'text-white bg-gray-800 p-2' : 'text-black bg-white p-2'}>
                <p className="label" dir={"rtl"}>{`التاريخ: ${typeof label === 'number' ? new Date(label).toLocaleDateString() : 'Invalid Date'}`}</p>
                <p className="intro" dir={"rtl"}>{`السعر: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

export default CustomTooltip;
