import React, {FC} from "react";
import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import MenuBar from "components/MenuBar/MenuBar";
import DarkModeContainer from "containers/DarkModeContainer/DarkModeContainer";
import ErrorBoundary from "components/ErrorBoundary";
import {selectLoginState} from "app/login/login";
import {useAppSelector} from "app/hooks";
import AvatarDropdown from "./AvatarDropdown";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import {selectDarkmodeState} from "../../app/darkmode/darkmode";
import ButtonPrimary from "../Button/ButtonPrimary";



export interface MainNav1Props {
  isTop?: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const [user, setUser] = useAuthState(getAuth());
  const isLoggedIn = useAppSelector(selectLoginState);
  const isDarkMode = useAppSelector(selectDarkmodeState);


  return (
      <div className={`nc-MainNav nc-MainNav1 relative z-10`}>
        <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
          <div className="flex lg:flex-grow justify-around items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            <Navigation />
          </div>
          <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center xl:flex space-x-1">
              <DarkModeContainer />
            </div>
            <div className="flex items-center xl:hidden">
              <ErrorBoundary>
                <MenuBar />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
  );
};

export default MainNav1;
