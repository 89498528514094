import axios from 'axios';

const matchesApi = axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
});

export const getLatestCoinsMarket =  async () => {
    const response = await matchesApi.get('/api/markets');
    return response.data;
};

export const getTrendingCoins =  async () => {


        const response = await matchesApi.get('/api/coins/trending');
        console.log("Trending Data:", response.data);
        const trendingData = response.data;

        const coinIds = trendingData.coins.map((coin: { item: { id: any; }; }) => coin.item.id).join(',');

        try {
            const requestUrl = `/api/coin-details?ids=${coinIds}`;
            console.log("Requesting URL:", requestUrl); // Log the request URL

            const response = await matchesApi.get(requestUrl);

            // Check if the response is ok (status in the range 200-299)
            if (!response) {
                throw new Error(`HTTP error! Status: ${response}`);
            }

            const data = response.data;
            console.log("Received Data:", data); // Log the received data
            return data;
        } catch (error) {
            console.error('Error fetching coin details');
            return null;
        }


};


export const getAbuMotaPotfolio =  async () => {
    const response = await matchesApi.get('/api/abumota');
    return response.data;
};

export const getAbuMotaMidCapsPotfolio =  async () => {
    const response = await matchesApi.get('/api/abumota/medium');
    return response.data;
};

export const getAbuMotaSmallCapsPotfolio =  async () => {
    const response = await matchesApi.get('/api/abumota/small');
    return response.data;
};

export const getGlobalMarketData =  async () => {
    const response = await matchesApi.get('/api/global/markets');
    return response.data;
};


export const getGlobalStats =  async () => {
    const response = await matchesApi.get('/api/global');
    return response.data;
};

export const getGlobalDefiStats =  async () => {
    const response = await matchesApi.get('/api/global/defi-markets');
    return response.data;
};

export const getNFTMarketData =  async () => {
    const response = await matchesApi.get('/api/nft/markets');
    return response.data;
};

export const getFearNGreedData =  async () => {
    const response = await matchesApi.get('/api/fng');
    return response.data;
};


export const getExchangesData =  async () => {
    const response = await matchesApi.get('/api/exchanges');
    return response.data;
};

export const getCompaniesBitcoinData =  async () => {
    const response = await matchesApi.get('/api/companies/bitcoin');
    return response.data;
};

export const getTopGainersLoosers =  async () => {
    const response = await matchesApi.get('/api/coins/top-gainers-loosers');
    return response.data;
};

export const getCryptoCategories =  async () => {
    const response = await matchesApi.get('/api/categories');
    return response.data;
};

export const getTopGainers =  async () => {
    const response = await matchesApi.get('/api/coins/top-gainers-loosers');
    const coinIds = response.data.top_gainers.sort((a: { usd_24h_change: number; }, b: { usd_24h_change: number; }) => b.usd_24h_change - a.usd_24h_change).map((coin: { id: any; }) => coin.id).join(',');
    const gainers = axios.get(`https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-qDtj7jDCVLFFz2XVQ95457Aq&vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=true&price_change_percentage=24h%2C7d%2C30d%2C200d%2C1y&ids=${coinIds}`)
        .then(function (gainersReponse) {
            return gainersReponse.data;
        }).catch(function (error) {
        console.log(error);
    })

    return gainers;

};

export const getTopLoosers =  async () => {
    const response = await matchesApi.get('/api/coins/top-gainers-loosers');
    const coinIds = response.data.top_losers.sort((a: { usd_24h_change: number; }, b: { usd_24h_change: number; }) => a.usd_24h_change - b.usd_24h_change).map((coin: { id: any; }) => coin.id).join(',');
    const loosers = axios.get(`https://pro-api.coingecko.com/api/v3/coins/markets?x_cg_pro_api_key=CG-qDtj7jDCVLFFz2XVQ95457Aq&vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=true&price_change_percentage=24h%2C7d%2C30d%2C200d%2C1y&ids=${coinIds}`)
        .then(function (loosersReponse) {
            return loosersReponse.data;
        }).catch(function (error) {
            console.log(error);
        })

    return loosers;

};


