import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import SectionGridAuthorBox from "../../components/SectionGridAuthorBox/SectionGridAuthorBox";
import LayoutPage from "components/LayoutPage/LayoutPage";
import {Adsense} from '@ctrl/react-adsense';
import ReactGA from "react-ga4";
import CoinCard from "../../components/MatchCard/CoinCard";
import {useQuery} from "@tanstack/react-query";
import * as api from "../../api/matchesApi";
import Input from "../../components/Input/Input";
import Skeleton from "react-loading-skeleton";
import ExchangeDetailsCard from "../../components/MatchCard/ExchangeDetailsCard";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageCentExchanges: React.FC = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: " منصات التداول" });
    },[]);

    const { data: latestCoinsMarket, isLoading: isLoadingLatestCoinsMarket } = useQuery(
        ["latestCoinsMarket"],
        api.getLatestCoinsMarket,
        { cacheTime: 1000 * 30 }
    );

    const { data: exchangesData, isLoading: isLoadingExchangesData } = useQuery(
        ["exchangesData"],
        api.getExchangesData,
        { cacheTime: 1000 * 30 }
    );


    const [coinQuery, setCoinQuery] = useState("");
    return (
        <div className="">
            <Helmet>
                <title>منصة كريبتو كاست || منصات التداول</title>
            </Helmet>
            <LayoutPage
                subHeading="إطلع على بيانات منصات التداول"
                headingEmoji=""
                heading="منصات التداول"
            >

                <div className="md:col-span-2 lg:col-span-3">
                    <div className="block flex-grow  mx-auto w-3/4 mb-10">
                        <form className="relative">
                            <Input
                                type="search"
                                placeholder="أكتب رمز العملة للبحث"
                                className="pr-10 w-full text-center"
                                sizeClass="h-[42px] pl-4 py-3"
                                onChange={e => setCoinQuery(e.target.value)}
                            />
                            <span
                                className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                            <input type="submit" hidden value=""/>
                        </form>
                    </div>
                </div>
                {isLoadingExchangesData ? (
                    <Skeleton
                        count={10}
                        baseColor="#f7f5f5"
                        highlightColor="#ede4e4"
                    />
                ) : (
                    exchangesData.filter((exch: { year_established: null; }) => exch.year_established !== null).map((exch: any) => {
                        return <ExchangeDetailsCard data={exch} />;
                    })

                )}

            </LayoutPage>
        </div>

    );
}

export default PageCentExchanges;
