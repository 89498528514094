//@ts-nocheck
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";

import LayoutPageAvatar from "components/LayoutPage/LayoutPageAvatar";
import {useParams} from "react-router-dom";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import ReactGA from "react-ga4";
import axios from "axios";
import LayoutNftPage from "../../components/LayoutPage/LayoutNftPage";
import {arCategoriesNames} from "../../data/jsons/translations/arCategories";
import CoinCard from "../../components/MatchCard/CoinCard";
import Skeleton from "react-loading-skeleton";
import Input from "../../components/Input/Input";
import {arNfts} from "../../data/jsons/translations/arNfts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faDiscord, faExternalLinkAlt } from '@fortawesome/free-brands-svg-icons';
import { SocialIcon } from 'react-social-icons'


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageNftDetails: React.FC = () => {
    // @ts-ignore
    const {slug} = useParams();
    const [isLoadingNftDetails, setisLoadingNftDetails] = React.useState(true);
    const [nftDetails, setnftDetails] = React.useState<any[]>([]);
    const [nftImageUrl, setnftImageUrl] = React.useState<any[]>("");


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: `Arabic - Text` });
    },[]);

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/nft/${slug}`)
            .then(function (response) {
                setnftDetails(response.data)
                setnftImageUrl(response.data.image.small)
                setisLoadingNftDetails(false)
            }).catch(function (error) {
            console.log(error);
        })
    }, []);

    console.log("Alloooo",nftDetails)

    const formatNumber = (number) => {
        return number.toLocaleString(); // Example number formatting
    };

    const renderNftDetails = () => {
        return (
            <div className="container mx-auto py-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* Display Cards for the Specified Data */}
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{nftDetails.native_currency_symbol}</p>
                        <h3 className="text-lg">عملة التداول</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.floor_price.native_currency)} <span className={"text-sm font-semibold"}>{nftDetails.native_currency_symbol}</span></p>
                        <h3 className="text-lg mt-2">السعر</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.market_cap.native_currency)} <span className={"text-sm font-semibold"}>{nftDetails.native_currency_symbol}</span></p>
                        <h3 className="text-lg mt-2">القيمة السوقية</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.volume_24h.native_currency)} <span className={"text-sm font-semibold"}>{nftDetails.native_currency_symbol}</span></p>
                        <h3 className="text-lg mt-2">حجم التداول (24 ساعة)</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.floor_price_24h_percentage_change.native_currency.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.floor_price_24h_percentage_change.native_currency.toFixed(2)} %</p>
                        <h3  className="text-lg mt-2">نسبة تغير السعر</h3>
                    </div>
                    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.market_cap_24h_percentage_change.native_currency.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"}>{nftDetails.market_cap_24h_percentage_change.native_currency.toFixed(2)} %</p>
                        <h3  className="text-lg mt-2">نسبة تغير القيمة السوقية</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.volume_24h_percentage_change.native_currency.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.volume_24h_percentage_change.native_currency.toFixed(2)} %</p>
                        <h3  className="text-lg mt-2">نسبة تغير حجم التداول (24 ساعة) (ETH)</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{nftDetails.number_of_unique_addresses ? formatNumber(nftDetails.number_of_unique_addresses) : 'N/A'}</p>
                        <h3  className="text-lg mt-2">عدد المحافظ</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.number_of_unique_addresses_24h_percentage_change.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.number_of_unique_addresses_24h_percentage_change.toFixed(2)} %</p>
                        <h3  className="text-lg mt-2">نسبة تغير عدد المحافظ (24 ساعة)</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.volume_in_usd_24h_percentage_change.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.volume_in_usd_24h_percentage_change.toFixed(2)} %</p>
                        <h3 className="text-lg mt-2">نسبة تغير حجم التداول (24 ساعة) (USD)</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.total_supply)}</p>
                        <h3 className="text-lg mt-2">إجمالي العرض</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.one_day_sales)}</p>
                        <h3 className="text-lg mt-2">مبيعات يوم واحد</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.one_day_sales_24h_percentage_change.toFixed(2) >= 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.one_day_sales_24h_percentage_change.toFixed(2)} %</p>
                        <h3 className="text-lg mt-2">نسبة تغير مبيعات يوم واحد</h3>
                    </div>
                    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={"text-2xl font-bold"}>{formatNumber(nftDetails.one_day_average_sale_price)}</p>
                        <h3 className="text-lg mt-2">متوسط سعر البيع ليوم واحد</h3>
                    </div>
                    <div className="flex flex-col justify-around items-center bg-white dark:bg-gray-800 shadow-lg rounded-lg p-4">
                        <p className={nftDetails.one_day_average_sale_price_24h_percentage_change.toFixed(2) > 0 ? "text-2xl font-bold text-green-500": "text-2xl font-bold text-red-500"} dir={"ltr"}>{nftDetails.one_day_average_sale_price_24h_percentage_change.toFixed(2)} %</p>
                        <h3 className="text-lg mt-2">نسبة تغير متوسط سعر البيع ليوم واحد</h3>
                    </div>
                </div>
                <div className="mt-4 flex items-center justify-center mt-8 mb-4">
                    <h3 className="text-lg font-semibold">روابط المشروع:</h3>
                    <ul className="list-none ml-4 flex space-x-2">
                        <li>
                            <a
                                className="text-blue-500 hover:text-blue-700"
                                href={nftDetails.links.homepage}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialIcon network="email" />
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-blue-500 hover:text-blue-700"
                                href={nftDetails.links.twitter}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialIcon network="twitter" />
                            </a>
                        </li>
                        <li>
                            <a
                                className="text-blue-500 hover:text-blue-700"
                                href={nftDetails.links.discord}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <SocialIcon network="discord" />
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
        )
    }




    // @ts-ignore
    return (
        <div className="">
            <Helmet>
                <title>{arNfts.find(item => item.en === nftDetails.name) ? arNfts.find(item => item.en === nftDetails.name).ar : nftDetails.name}</title>
            </Helmet>
            <LayoutNftPage
                subHeading={nftDetails.name}
                headingEmoji=""
                nftImage={nftImageUrl}
                heading={arNfts.find(item => item.en === nftDetails.name) ? arNfts.find(item => item.en === nftDetails.name).ar : nftDetails.name}
            >
                <div className={"flex flex-col justify-items-center text-center"}>

                    {isLoadingNftDetails ? (
                        <Skeleton
                            count={10}
                            baseColor="#f7f5f5"
                            highlightColor="#ede4e4"
                        />
                    ) : (
                        renderNftDetails()

                    )}

                    <div className={"flex justify-center items-center font-bold"}>
                        <ButtonPrimary className="" href="/categories">العودة للفئات</ButtonPrimary>
                    </div>

                </div>


                {/* HEADER */}

            </LayoutNftPage>
        </div>

    );
}

export default PageNftDetails;
