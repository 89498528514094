import {getApp, getApps, initializeApp} from "firebase/app";
import {FacebookAuthProvider, getAuth, GoogleAuthProvider, TwitterAuthProvider} from 'firebase/auth'
import {doc, getDoc, getFirestore, setDoc} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MSG_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()
export const db = getFirestore(app)
export const storage = getStorage(app)
export const auth = getAuth()
export const google = new GoogleAuthProvider().addScope('email')
export const facebook = new FacebookAuthProvider()
export const twitter = new TwitterAuthProvider()

export const createUserDocument = async (user) => {
    if (!user) return

    const userRef = doc(db, `users/${user.uid}`)
    const snapshot = await getDoc(userRef)

    if (!snapshot.exists()) {
        const { displayName, email, providerId } = user
        const createdAt = new Date()
        try {
            await setDoc(userRef, {
                displayName,
                email,
                providerId,
                createdAt,
            })
        } catch (error) {
            console.error('Error creating user document', error)
        }
    }
}