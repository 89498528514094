export const arNfts = [
    {
        "en": "Bored Ape Yacht Club",
        "ar": "بورد آب ياخت كلوب"
    },
    {
        "en": "Mutant Ape Yacht Club",
        "ar": "ميوتانت آب ياخت كلوب"
    },
    {
        "en": "Azuki",
        "ar": "أزوكي"
    },
    {
        "en": "Pudgy Penguins",
        "ar": "بادجي بينجوينز"
    },
    {
        "en": "CryptoPunks",
        "ar": "كريبتوبانكس"
    },
    {
        "en": "Bored Ape Kennel Club",
        "ar": "بورد آب كينيل كلوب"
    },
    {
        "en": "Milady Maker",
        "ar": "ميلادي ميكر"
    },
    {
        "en": "DeGods",
        "ar": "ديجودز"
    },
    {
        "en": "Clone X",
        "ar": "كلون إكس"
    },
    {
        "en": "Winds of Yawanawa by Yawanawa and Refik Anadol",
        "ar": "ويندز أوف ياواناوا "
    },
    {
        "en": "Doodles",
        "ar": "دودلز"
    },
    {
        "en": "Otherside Koda",
        "ar": "أوثرسايد كودا"
    },
    {
        "en": "Velocity Series: Velocity Pass",
        "ar": "فيلوسيتي سيريز"
    },
    {
        "en": "Otherdeed Expanded",
        "ar": "أوثرديد إكسباندد"
    },
    {
        "en": "WHAT THE FFFF",
        "ar": "وات ذا إف إف إف إف"
    },
    {
        "en": "Moonbirds",
        "ar": "مون بيردز"
    },
    {
        "en": "Azuki Elementals",
        "ar": "أزوكي إليمنتالز"
    },
    {
        "en": "Gutter Cat Gang",
        "ar": "جاتر كات جانج"
    },
    {
        "en": "Redacted Remilio Babies",
        "ar": "ريداكتد ريميليو بيبيز"
    },
    {
        "en": "The Potatoz",
        "ar": "ذا بوتاتوز"
    },
    {
        "en": "Captainz",
        "ar": "كابتنز"

    },
    {
        "en": "Wrapped Cryptopunks",
        "ar": "رابت كريبتوبانكس"
    },
    {
        "en": "CryptoPunks V1 (wrapped)",
        "ar": "كريبتوبانكس"
    },
    {
        "en": "ForgeToken",
        "ar": "فورج توكن"
    },
    {
        "en": "Otherdeed for Otherside",
        "ar": "أوثرديد فور أوثرسايد"
    },
    {
        "en": "RTFKT x Nike Dunk Genesis CRYPTOKICKS",
        "ar": "نايك دانك جينيسيس"
    },
    {
        "en": "Meebits",
        "ar": "ميبيتس"
    },
    {
        "en": "raW Pass",
        "ar": "راو باس"
    },
    {
        "en": "Sugartown Oras",
        "ar": "سوجارتاون أوراس"
    },
    {
        "en": "The Wolfgang",
        "ar": "ذا وولفجانج"
    },
    {
        "en": "Autoglyphs",
        "ar": "أوتوغليفس"
    },
    {
        "en": "Fidenza by Tyler Hobbs",
        "ar": "فيدينزا"
    },
    {
        "en": "The Crop Collective",
        "ar": "ذا كروب كولكتيف"
    },
    {
        "en": "Chromie Squiggle by Snowfro",
        "ar": "كرومي سكويغل"
    },
    {
        "en": "LAND",
        "ar": "لاند"
    },
    {
        "en": "Space Doodles",
        "ar": "سبيس دودلز"
    },
    {
        "en": "Madness Trader by CryptoNobles",
        "ar": "مادنيس تريدر"
    },
    {
        "en": "Decentraland",
        "ar": "ديسنترالاند"
    },
    {
        "en": "Nouns",
        "ar": "ناونز"
    },
    {
        "en": "Ringers by Dmitri Cherniak",
        "ar": "رينجرز"
    },
    {
        "en": "The Almighty Sparrows",
        "ar": "ذا ألمايتي سباروز"
    },
    {
        "en": "More Loot",
        "ar": "مور لوت"
    },
    {
        "en": "YOU THE REAL MVP",
        "ar": "يو ذا ريل إم في بي"
    },
    {
        "en": "NeoTokyo Citizens",
        "ar": "نيو توكيو سيتيزنز"
    },
    {
        "en": "TOPIA Worlds",
        "ar": "توبيا ورلدز"
    },
    {
        "en": "Neo Tokyo Citizen V2",
        "ar": "نيو توكيو سيتيزن"
    },
    {
        "en": "ARCStellars",
        "ar": "أركستيلارز"
    },
    {
        "en": "Mad Cat Militia",
        "ar": "ماد كات ميليشيا"
    },
    {
        "en": "MyCryptoHeroes:Hero",
        "ar": "ماي كريبتو هيرو"
    },
    {
        "en": "MyCryptoHeroes:Land",
        "ar": "ماي كريبتو لاند"
    },
    {
        "en": "MyCryptoHeroes:Extension",
        "ar": "ماي كريبتو إكستنشن"
    },
    {
        "en": "Cometh Spaceships",
        "ar": "كوميث سبيس شيبس"
    },
    {
        "en": "Blendkemon",
        "ar": "بليندكيمون"
    },
    {
        "en": "FancyBabyBirds",
        "ar": "فانسي بيبي بيردز"
    },
    {
        "en": "Impostors Genesis Aliens",
        "ar": "إمبوسترز جينيسيس ألينز"
    },
    {
        "en": "Feral File #009 - Unsupervised by Refik Anadol",
        "ar": "فيرال فايل"
    },
    {
        "en": "Mocaverse",
        "ar": "موكافيرس"
    },
    {
        "en": "Lil Pudgys",
        "ar": "ليل بادجيز"
    },
    {
        "en": "DeFrogs",
        "ar": "ديفروجز"
    },
    {
        "en": "YOHO PASS",
        "ar": "يوهو باس"
    },
    {
        "en": "The Sandbox LANDS",
        "ar": "ذا ساندبوكس لاندز"
    },
    {
        "en": "Creepz by OVERLORD",
        "ar": "كريبز"
    },
    {
        "en": "NFT Worlds",
        "ar": "إن إف تي ورلدز"
    },
    {
        "en": "Pandora",
        "ar": "باندورا"
    }
]