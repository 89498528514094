//@ts-nocheck
import React from 'react';

// @ts-ignore
const ExchangeCard = ({ exchange }) => {

    const countriesTranslations = [
        {
            "en": "United States",
            "ar": "الولايات المتحدة"
        },
        {
            "en": "Cayman Islands",
            "ar": "جزر كايمان"
        },
        {
            "en": "British Virgin Islands",
            "ar": "جزر العذراء البريطانية"
        },
        {
            "en": "Seychelles",
            "ar": "سيشيل"
        },
        {
            "en": "Luxembourg",
            "ar": "لوكسمبورغ"
        },
        {
            "en": "Malta",
            "ar": "مالطا"
        },
        {
            "en": "Singapore",
            "ar": "سنغافورة"
        },
        {
            "en": "Gibraltar",
            "ar": "جبل طارق"
        },
        {
            "en": "United Arab Emirates",
            "ar": "الإمارات العربية المتحدة"
        },
        {
            "en": "Lithuania",
            "ar": "ليتوانيا"
        },
        {
            "en": "United Kingdom",
            "ar": "المملكة المتحدة"
        },
        {
            "en": "Netherlands",
            "ar": "هولندا"
        },
        {
            "en": "Saint Vincent and the Grenadines",
            "ar": "سانت فنسنت وجزر غرينادين"
        },
        {
            "en": "Belize",
            "ar": "بليز"
        },
        {
            "en": "Thailand",
            "ar": "تايلاند"
        },
        {
            "en": "Argentina",
            "ar": "الأرجنتين"
        },
        {
            "en": "Japan",
            "ar": "اليابان"
        },
        {
            "en": "Taiwan",
            "ar": "تايوان"
        },
        {
            "en": "Indonesia",
            "ar": "إندونيسيا"
        },
        {
            "en": "Philippines",
            "ar": "الفلبين"
        },
        {
            "en": "Slovenia",
            "ar": "سلوفينيا"
        },
        {
            "en": "South Korea",
            "ar": "كوريا الجنوبية"
        },
        {
            "en": "Estonia",
            "ar": "إستونيا"
        },
        {
            "en": "South Africa",
            "ar": "جنوب أفريقيا"
        },
        {
            "en": "Australia",
            "ar": "أستراليا"
        }
    ]

    const exchangeTranslations = [
        {
            "en": "Binance",
            "ar": "بينانس"
        },
        {
            "en": "Coinbase Exchange",
            "ar": "كوينبيس"
        },
        {
            "en": "Kraken",
            "ar": "كراكن"
        },
        {
            "en": "Bitfinex",
            "ar": "بتفينكس"
        },
        {
            "en": "Bitstamp",
            "ar": "بيتستامب"
        },
        {
            "en": "Gemini",
            "ar": "جيميني"
        },
        {
            "en": "Crypto.com Exchange",
            "ar": "كريبتو.كوم"
        },
        {
            "en": "KuCoin",
            "ar": "كوكوين"
        },
        {
            "en": "Binance US",
            "ar": "بينانس الولايات المتحدة"
        },
        {
            "en": "Bybit",
            "ar": "بايبيت"
        },
        {
            "en": "HTX",
            "ar": "إتش تي إكس"
        },
        {
            "en": "OKX",
            "ar": "أوكيكس"
        },
        {
            "en": "Gate.io",
            "ar": "جيت.آي.أو"
        },
        {
            "en": "Bitget",
            "ar": "بيتجيت"
        },
        {
            "en": "BingX",
            "ar": "بينج إكس"
        },
        {
            "en": "BTSE",
            "ar": "بي تي إس إي"
        },
        {
            "en": "Phemex",
            "ar": "فيميكس"
        },
        {
            "en": "Bitso",
            "ar": "بيتسو"
        },
        {
            "en": "Fastex",
            "ar": "فاستكس"
        },
        {
            "en": "DigiFinex",
            "ar": "ديجيفينكس"
        },
        {
            "en": "CoinW",
            "ar": "كوين دبليو"
        },
        {
            "en": "BitMart",
            "ar": "بيت مارت"
        },
        {
            "en": "MEXC",
            "ar": "ميكس"
        },
        {
            "en": "P2B",
            "ar": "بي تو بي"
        },
        {
            "en": "Bitrue",
            "ar": "بيترو"
        },
        {
            "en": "XT.COM",
            "ar": "إكس تي"
        },
        {
            "en": "Upbit",
            "ar": "أببيت"
        },
        {
            "en": "BitMEX",
            "ar": "بيتمكس"
        },
        {
            "en": "NiceHash",
            "ar": "نايس هاش"
        },
        {
            "en": "Blockchain.com",
            "ar": "بلوكشين"
        },
        {
            "en": "Coins.ph",
            "ar": "كوينز"
        },
        {
            "en": "Indodax",
            "ar": "إندوداكس"
        },
        {
            "en": "Bullish",
            "ar": "بوليش"
        },
        {
            "en": "Max Maicoin",
            "ar": "مايكوين"
        },
        {
            "en": "Bitbank",
            "ar": "بيت بانك"
        },
        {
            "en": "C-Patex",
            "ar": "سي باتكس"
        },
        {
            "en": "Cryptology",
            "ar": "كريبتولوجي"
        },
        {
            "en": "Bitkub",
            "ar": "بيتكوب"
        },
        {
            "en": "WOO X",
            "ar": "وو إكس"
        },
        {
            "en": "Dex-Trade",
            "ar": "ديكس تريد"
        },
        {
            "en": "QMall",
            "ar": "كيو مول"
        },
        {
            "en": "PointPay",
            "ar": "بوينت باي"
        },
        {
            "en": "BigONE",
            "ar": "بيج ون"
        },
        {
            "en": "BigONE",
            "ar": "بيج ون"
        },
        {
            "en": "EXMO",
            "ar": "إكسمو"
        },
        {
            "en": "WhiteBIT",
            "ar": "وايت بيت"
        },
        {
            "en": "LATOKEN",
            "ar": "لا توكن"
        },
        {
            "en": "LBank",
            "ar": "إل بانك"
        },
        {
            "en": "Coinsbit",
            "ar": "كوينزبيت"
        },
        {
            "en": "Coinstore",
            "ar": "كوين ستور"
        },
        {
            "en": "Poloniex",
            "ar": "بولونيكس"
        },
        {
            "en": "Toobit",
            "ar": "توبيت"
        },
        {
            "en": "VALR",
            "ar": "فالر"
        },
        {
            "en": "Kuna Exchange",
            "ar": "كونا إكسشينج"
        },
        {
            "en": "Coinmetro",
            "ar": "كوين ميترو"
        },
        {
            "en": "BitoPro",
            "ar": "بيتوبرو"
        },
        {
            "en": "FameEX",
            "ar": "فيم إكس"
        },
        {
            "en": "OrangeX",
            "ar": "أورانج إكس"
        },
        {
            "en": "Icrypex",
            "ar": "آي كريبكس"
        },
        {
            "en": "Bitforex",
            "ar": "بيت فوركس"
        },
        {
            "en": "TokoCrypto",
            "ar": "توكو كريبتو"
        },
        {
            "en": "Nominex",
            "ar": "نومينكس"
        },
        {
            "en": "HashKey Exchange",
            "ar": "هاش كي إكسشينج"
        }
    ]
    return (
        <div className="p-4 bg-white dark:bg-black dark:bg-opacity-20 rounded-lg dark:border-2 dark:border-white dark:shadow-gray-300 shadow-sm flex justify-between items-center mb-4">
            <div className="flex flex-col items-start">
                <img src={exchange.image} alt={exchange.name} className="w-12 h-12 rounded-full mb-2" />
                <h3 className="text-md font-semibold dark:text-white">
                    {exchangeTranslations.find(item => item.en === exchange.name) ? exchangeTranslations.find(item => item.en === exchange.name).ar : exchange.name}
                </h3>
                <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
          ({countriesTranslations.find(item => item.en === exchange.country) ? countriesTranslations.find(item => item.en === exchange.country).ar : exchange.country})
        </span>
            </div>
            <div className="flex flex-col justify-between">
                <div className={"flex flex-col justify-center items-center mb-2"}>
                    <p>حجم التداول 24 ساعة</p>
                    <p className="font-bold mt-2 text-xl text-black dark:text-white" dir="rtl">
                        {exchange.trade_volume_24h_btc.toFixed(2)} بيتكوين
                    </p>
                </div>
                <div className={"flex flex-col justify-center items-center mt-2"}>
                    <p>معدل الثقة</p>
                    <p className={"font-bold mt-2 text-xl text-black dark:text-white"} dir={"ltr"}>
                        {exchange.trust_score} / 10
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ExchangeCard;
