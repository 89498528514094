import React, {useEffect, useState} from "react";
import ChainContext from "./Chain";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Page} from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import PageContact from "containers/PageContact/PageContact";
import PageLogin from "containers/PageLogin/PageLogin";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHome from "containers/PageHome/PageHome";
import MediaRunningContainer from "containers/MediaRunningContainer/MusicPlayer";
import PageComingSoon from "../containers/PageComingSoon/PageComingSoon";
import {selectLoginState} from "app/login/login";
import {useAppSelector} from "../app/hooks";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import ReactGA from 'react-ga';
import PageMagazine from "../containers/PageMagazine/PageMagazine"
import PageCoins from "../containers/PageCoins/PageCoins";
import PageTopGainers from "../containers/PageTopGainers/PageTopGainers";
import PageTopLoosers  from "../containers/PageTopLoosers/PageTopLoosers";
import PageCryptoCategories from "../containers/PageCryptoCategories/PageCryptoCategories";
import PageSingleCategory from "../containers/PageCryptoCategories/PageSingleCategory";
import PageGlossary from "../containers/PageGlossary/PageGlossary";
import PageNftDetails from "../containers/PageNftDetails/PageNftDetails";
import PageCentExchanges from "../containers/PageExchanges/PageCentExchanges";
import PageAbuMotaPortfolio from "../containers/PageAbuMotaPortfolio/PageAbuMotaPortfolio";
import PageMediumCapsPotfolio from "../containers/PageAbuMotaPortfolio/PageMediumCapsPotfolio";
import PageTrendingCoins from "../containers/PageTrendingCoins/PageTrendingCoins";
import PageSmallCapsPotfolio from "../containers/PageAbuMotaPortfolio/PageSmallCapsPotfolio";
import PageVentureCapitals   from "../containers/PageCryptoCategories/PageVentureCapitals";



const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  //{ path: "/login", exact: true, component: PageLogin },
  { path: "/#", exact: true, component: PageHome },
  //
  { path: "/coins", exact: true, component: PageCoins },
  {path: "/exchanges", exact: true, component: PageCentExchanges},
  { path: "/learn/glossary", exact: true, component: PageGlossary },

  { path: "/top-gainers", exact: true, component: PageTopGainers },
  { path: "/top-loosers", exact: true, component: PageTopLoosers },
  { path: "/trending", exact: true, component: PageTrendingCoins },
  { path: "/large-caps", exact: true, component: PageAbuMotaPortfolio },
  { path: "/medium-caps", exact: true, component: PageMediumCapsPotfolio },
  { path: "/small-caps", exact: true, component: PageSmallCapsPotfolio },
  { path: "/categories", exact: true, component: PageCryptoCategories },
  {path: "/venture-capitals", exact: true, component: PageVentureCapitals},
  { path: "/nfts/:slug", exact: true, component: PageNftDetails },
  { path: "/categories/:slug", exact: true, component: PageSingleCategory },
  { path: "/ventures/:slug", exact: true, component: PageSingleCategory },
  { path: "/contact", exact: true, component: PageContact },


];

const Routes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[]);

  //if (!isChrome && !isEdge && !isOpera && !isChromium && !isFirefox && !isIE &&  !isMobileSafari && !isSafari && !isSamsungBrowser) return <PageNotSupportedBrowser />
  return (
            <BrowserRouter
            >
              <MediaRunningContainer />

              <ScrollToTop />
              <HeaderContainer />
              <Switch>
                {pages.map(({ component, path, exact }) => {
                  return (
                      <Route
                          key={path}
                          component={component}
                          exact={exact}
                          path={path}
                      />
                  );
                })}
                <Route component={PageComingSoon} />
              </Switch>
              <Footer />
              {/* MEDIA */}
            </BrowserRouter>

  );
};

export default Routes;
