import ButtonPrimary from "components/Button/ButtonPrimary";
import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageComingSoon: React.FC = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Arabic - Coming Soon" });
    },[]);
    return (
        <div className="nc-PageComingSoon">
            <Helmet>
                <title>Lefri9i Platform || Coming Soon</title>
            </Helmet>
            <div className="container relative py-16 lg:py-20">
                {/* HEADER */}
                <header className="text-center max-w-2xl mx-auto space-y-7">
                    <h2 className="text-3xl md:text-7xl">🪔</h2>
                    <h1 className="text-3xl md:text-5xl font-semibold tracking-widest">
                        محتوى في طور الإنجاز
                    </h1>
                    <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          الصفحة التي تبحث عنها لا تزال قيد التطوير ..{" "}
        </span>
                    <ButtonPrimary href="/" className="mt-4 font-bold">
                        الصفحة الرئيسية
                    </ButtonPrimary>
                </header>
            </div>
        </div>
    );
}

export default PageComingSoon;
