//ts-nocheck
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import SectionGridAuthorBox from "../../components/SectionGridAuthorBox/SectionGridAuthorBox";
import LayoutPage from "components/LayoutPage/LayoutPage";
import {Adsense} from '@ctrl/react-adsense';
import ReactGA from "react-ga4";
import AbuMotaCard from "../../components/MatchCard/AbuMotaCard";
import {useQuery} from "@tanstack/react-query";
import * as api from "../../api/matchesApi";
import Input from "../../components/Input/Input";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import {getAbuMotaPotfolio, getTopGainers} from "../../api/matchesApi";


const TRACKING_ID = import.meta.env.VITE_TRACKING_ID; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const PageAbuMotaPotfolio: React.FC = () => {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "المشاريع الصغرى" });
    },[]);

    const { data: abuMotaSmallCapsPotfolio, isLoading: isLoadingSmallCaps } = useQuery(
        ["abuMotaSmallCapsPotfolio"],
        api.getAbuMotaSmallCapsPotfolio,
        { cacheTime: 1000 * 60 }
    );
    const [coinQuery, setCoinQuery] = useState("");





    // @ts-ignore
    return (
        <div className="">
            <Helmet>
                <title>محفظة كريبتو كاست الإستثمارية || المشاريع الصغرى</title>
            </Helmet>
            <LayoutPage
                subHeading="محفظة كريبتو كاست الإستثمارية للبول ران القادم"
                headingEmoji=""
                heading="المشاريع الصغرى"
            >

                <div className="md:col-span-2 lg:col-span-3">
                    <div className="block flex-grow  mx-auto w-3/4 mb-10">
                        <form className="relative">
                            <Input
                                type="search"
                                placeholder="أكتب رمز العملة للبحث"
                                className="pr-10 w-full text-center"
                                sizeClass="h-[42px] pl-4 py-3"
                                onChange={e => setCoinQuery(e.target.value)}
                            />
                            <span
                                className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500 dark:text-neutral-400">
                <svg
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  <path
                      d="M22 22L20 20"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                </svg>
              </span>
                            <input type="submit" hidden value=""/>
                        </form>
                    </div>
                </div>
                {isLoadingSmallCaps ? (
                    <Skeleton
                        count={10}
                        baseColor="#f7f5f5"
                        highlightColor="#ede4e4"
                    />
                ) : (
                    abuMotaSmallCapsPotfolio.map((coin: any) => {
                        return <AbuMotaCard data={coin} />;
                    })

                )}

            </LayoutPage>
        </div>

    );
}

export default PageAbuMotaPotfolio;
