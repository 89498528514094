import React from 'react';
import bitcoinIcon from "../../images/btc.svg";
import ethereumIcon from "../../images/ethereum.svg";


// @ts-ignore
function DominanceStats({data}) {
    return (
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 p-4 text-center">
            <div className="card dark:bg-black dark:bg-opacity-20 bg-white p-4 rounded-md dark:border-4 dark:border-white">
                <p className="text-5xl lg:text-5xl font-extrabold dark:text-white mb-6 ">
                    {Math.ceil(data.btc)} %
                </p>
                <div className={"flex justify-center items-center"}>
                    <img src={bitcoinIcon} className={"w-12 h-12 sm:w-12 sm:h-12 mr-4 rounded-full"}/>
                    <h2 className="text-xl font-bold dark:text-white">بيتكوين</h2>
                </div>
            </div>
            <div className="card dark:bg-black dark:bg-opacity-20 bg-white p-4 rounded-md dark:border-4 dark:border-white">
                <p className="text-5xl lg:text-5xl font-extrabold dark:text-gray-300 mb-6">
                    {Math.ceil(data.eth)}  %
                </p>
                <div className={"flex justify-center items-center"}>
                    <img src={ethereumIcon} className={"w-12 h-12 sm:w-12 sm:h-12 mr-4 rounded-full"}/>
                    <h2 className="text-xl font-bold dark:text-white">إثيريوم</h2>
                </div>
            </div>
        </div>
    );
}

export default DominanceStats;
