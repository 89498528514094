export const glossaryData = [
    {
        "enTerm": "0x Protocol",
        "arTerm": "بروتوكول 0x",
        "description": "0x هو منصة مفتوحة المصدر تعتمد على Ethereum لتبادل العملات المشفرة. يسمح بإنشاء منصة تداول لا مركزية (DEX)، محفظة أو سوق."
    },
    {
        "enTerm": "1hr",
        "arTerm": "1 ساعة",
        "description": "يمثل البيانات للساعة الماضية."
    },
    {
        "enTerm": "24hr",
        "arTerm": "24 ساعة",
        "description": "يمثل البيانات للـ 24 ساعة الماضية."
    },
    {
        "enTerm": "30d",
        "arTerm": "30 يوم",
        "description": "يمثل البيانات للـ 30 يومًا الماضية."
    },
    {
        "enTerm": " Plan 401(k)",
        "arTerm": "خطة 401k",
        "description": "خطة 401(k) هي برنامج توفير للتقاعد يقدمه المشغلين الأمريكيين حيث يساهم الموظفون بجزء من دخلهم ويقابلها المشغل بنفس نسبة مساهمة الموظف."
    },
    {
        "enTerm": "51% Attack",
        "arTerm": "هجوم 51%",
        "description": "إذا تم تشغيل أكثر من نصف قوة الحاسوب أو معدل التعدين في شبكة من قبل شخص واحد أو مجموعة واحدة من الأشخاص، فإن هجوم 51% قيد التنفيذ."
    },
    {
        "enTerm": "52-Week High/Low",
        "arTerm": "أعلى/أدنى سعر في 52 أسبوع",
        "description": "أعلى وأدنى سعر سوقي لأصل معين خلال 52 أسبوعًا أو سنة واحدة."
    },
    {
        "enTerm": "52-Week Range",
        "arTerm": "نطاق 52 أسبوع",
        "description": "نطاق 52 أسبوع هو الفرق بين أعلى وأدنى أسعار للأصل خلال الـ 52 أسبوعًا الماضية."
    },
    {
        "enTerm": "7d",
        "arTerm": "7 أيام",
        "description": "يمثل البيانات للـ 7 أيام الماضية."
    },
    {
        "enTerm": "80/20 Rule (Pareto Principle)",
        "arTerm": "قاعدة 80/20 (مبدأ باريتو)",
        "description": "قاعدة 80/20، المعروفة باسم مبدأ باريتو، تنص على أن 20% من أفعالك تمثل 80% من النتائج."
    },
    {
        "enTerm": "Accumulation Phase",
        "arTerm": "مرحلة التجميع",
        "description": "هي المرحلة التي تتوقع فيها المؤسسات إمكانات كبيرة في الأوراق المالية المقومة بأقل من قيمتها الحقيقية وتبدأ في بناء مراكزها. لا يمكنهم شراء مركزهم بالكامل في أمر واحد حيث سترتفع أحجام الأوراق المالية، مما يؤدي إلى ملاحظة السوق لنشاط غير عادي. ولذلك، فإنهم يشترون هذه الأوراق المالية على دفعات لتجنب اكتشافها من قبل المشاركين في السوق."
    },
    {
        "enTerm": "Acquisition",
        "arTerm": "الاستحواذ على شركة",
        "description": "يحدث الاستحواذ عندما تشتري شركة كل أو معظم أسهم شركة أخرى للسيطرة على الشركة. يمنح شراء أكثر من 50% من أسهم الشركة القوة للمشتري لاتخاذ القرار النهائي المتعلق بعمليات الشركة."
    },
    {
        "enTerm": "Address",
        "arTerm": "العنوان الرقمي",
        "description": "\n" +
            " العنوان الرقمي هو سلسلة فريدة من الأحرف تمثل محفظة يمكنها إرسال واستقبال العملة الرقمية. يشبه إلى حد كبير عنوان الحياة الواقعية، البريد الإلكتروني أو موقع الويب. كل عنوان فريد ويدل على موقع المحفظة على سلسلة الكتل.\n" +
            "\n" +
            "العناوين على سلسلة الكتل عامة، ويمكنك استخدام مستكشف سلسلة الكتل لرؤية المعاملات الداخلة والخارجة من العنوان. يمكنك أيضًا رؤية كمية الأصول التي يمتلكها العنوان. الاستثناء الوحيد هو سلاسل الكتل المبنية على الخصوصية (مثل مونيرو).\n"
    },
    {
        "enTerm": "Adoption Curve",
        "arTerm": "منحنى الاعتماد",
        "description": "يشير منحنى الاعتماد إلى وتيرة اعتماد التكنولوجيا الجديدة من قبل الناس. قد يشمل أيضًا تقسيم الجمهور المستهدف لفهم استعداد السوق. عندما يظهر شيء جديد في السوق، سواء كان طرازًا جديدًا للسيارة أو أداة/مفهوم تكنولوجي جديد، لا يتم اعتماده دفعة واحدة. في البداية، فقط عدد قليل من الناس يجربونه قبل أن يصل إلى باقي السوق."
    }
];
