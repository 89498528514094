import HeadBackgroundCommon from "components/HeadBackgroundCommon/HeadBackgroundCommon";
import Heading2 from "components/Heading/Heading2";
import React, {FC} from "react";
import Avatar from "../Avatar/Avatar";
import {PostAuthorType} from "../../data/types";

export interface LayoutNftPageProps {
    className?: string;
    heading: string;
    headingEmoji?: string;
    subHeading?: string;
    nftImage?:string;
    player?: PostAuthorType;
    children: React.ReactNode;
}

const LayoutNftPage: FC<LayoutNftPageProps> = ({
                                                   className = "",
                                                   heading,
                                                   subHeading,
                                                   headingEmoji,
                                                   children,
                                                   nftImage="",
                                                   player={},
                                               }) => {

    return (
        <div
            className={`nc-LayoutPage relative ${className}`}
            data-nc-id="LayoutPage"
        >
            <HeadBackgroundCommon />
            <div className="container relative pt-10 pb-16 lg:pt-20 lg:pb-28">
                {/* HEADER */}
                <header className="text-center max-w-2xl mx-auto">
                    <Avatar
                        sizeClass="w-20 h-20 text-2xl mb-4"
                        radius="rounded-full"
                        imgUrl={nftImage}
                        containerClassName={"mx-auto"}
                    />
                    <Heading2 emoji={headingEmoji}>{heading}</Heading2>
                    {subHeading && (
                        <span className="block text-md mt-2 text-white sm:text-md dark:text-neutral-100 break-word font-bold">
              {subHeading}
            </span>
                    )}
                </header>

                {/* CONTENT */}
                <div className="p-5 mx-auto bg-white rounded-[40px] shadow-lg sm:p-10 mt-10 lg:mt-20 lg:p-16 dark:bg-neutral-900">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default LayoutNftPage;
