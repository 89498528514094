import { useEffect } from "react";
import { PostDataType } from "data/types";
import { createGlobalState } from "react-hooks-global-state";

const initialState: {
  playing: boolean;
  volume: number;
  muted: boolean;
  played: number;
  playedSeconds: number;
  loaded: number;
  duration: number;
  loadedSeconds: number;
  playbackRate: number;
  loop: "1" | "all" | "";
  postData?: PostDataType;
  listPostData: PostDataType[];
  currentPageListPostData: PostDataType[];
  currentIndex: number;
} = {
  playing: false,
  volume: 0.8,
  muted: false,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  loadedSeconds: 0,
  playbackRate: 1.0,
  postData: undefined,
  // demo lists - ThisThis is just a demo track lits.
  listPostData: [],
  currentPageListPostData: [],
  loop: "",
  currentIndex: -1,
};

const { useGlobalState } = createGlobalState(initialState);

export const useMusicPlayer = () => {
  const [playbackRate, setplaybackRate] = useGlobalState("playbackRate");
  const [duration, setDuration] = useGlobalState("duration");
  const [loaded, setLoaded] = useGlobalState("loaded");
  const [played, setPlayed] = useGlobalState("played");
  const [muted, setMuted] = useGlobalState("muted");
  const [volume, setVolume] = useGlobalState("volume");
  const [playing, setPlaying] = useGlobalState("playing");
  const [postData, setPostData] = useGlobalState("postData");
  const [loadedSeconds, setLoadedSeconds] = useGlobalState("loadedSeconds");
  const [playedSeconds, setPlayedSeconds] = useGlobalState("playedSeconds");
  const [loop, setLoop] = useGlobalState("loop");
  const [currentIndex, setCurrentIndex] = useGlobalState("currentIndex");
  const [listPostData, setListPostData] = useGlobalState("listPostData");
  const [currentPageListPostData, setCurrentPageListPostData] = useGlobalState(
    "currentPageListPostData"
  );

  const onChangeCurrentIndex = (nextIndex: number) => {
    setCurrentIndex(nextIndex);
    if (nextIndex === -1) {
      setPostData(undefined);
      return;
    }
    setPostData(listPostData[nextIndex]);
  };

  const handleResetPlayerTimeLoading = () => {
    setDuration(initialState["duration"]);
    setLoaded(initialState["loaded"]);
    setPlayed(initialState["played"]);
    setLoadedSeconds(initialState["loadedSeconds"]);
    setPlayedSeconds(initialState["playedSeconds"]);
  };

  return {
    currentPageListPostData,
    setCurrentPageListPostData,
    onChangeCurrentIndex,
    LIST_AUDIO_POSTS: listPostData,
    setListPostData,
    handleResetPlayerTimeLoading,
    loop,
    currentIndex,
    setCurrentIndex,
    setLoop,
    duration,
    playedSeconds,
    setPlayedSeconds,
    loadedSeconds,
    setLoadedSeconds,
    setDuration,
    loaded,
    setLoaded,
    played,
    setPlayed,
    muted,
    setMuted,
    volume,
    setVolume,
    playing,
    setPlaying,
    playbackRate,
    setplaybackRate,
    postData,
    setPostData,
  };
};
