import React from 'react';
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid
} from 'recharts';
import 'tailwindcss/tailwind.css';
import CustomTooltip from './CustomTooltip';
import {useAppSelector} from "../../app/hooks";
import {selectDarkmodeState} from "../../app/darkmode/darkmode"; // Adjust the path as needed

interface RechartProps {
    prices: number[][];
    theme: string;
}

const CoinChart: React.FC<RechartProps> = ({ prices, theme }) => {
    const isDarkMode = useAppSelector(selectDarkmodeState);

    // Preparing data for the chart
    const data = prices.map(price => ({ time: price[0], value: price[1] }));

    return (
        <ResponsiveContainer width="100%" height={400} className={isDarkMode ? 'dark' : ''}>
            <LineChart data={data} className="dark:bg-gray-800 p-4 rounded-md">
                <CartesianGrid strokeDasharray="3 3" stroke={isDarkMode ? "#444" : "#ccc"} />
                <XAxis
                    dataKey="time"
                    tickFormatter={(time) => new Date(time).toLocaleDateString()}
                    stroke={isDarkMode ? 'white' : 'black'}
                    tick={{ fill: isDarkMode ? 'white' : 'black' }}
                    tickMargin={10}
                />
                <YAxis
                    stroke={isDarkMode ? 'white' : 'black'}
                    tick={{ fill: isDarkMode ? 'white' : 'black', dx: -40 }} // Adjust the dx value as needed
                    tickMargin={10}
                />
                <Tooltip content={<CustomTooltip isDarkMode={isDarkMode} />} />
                <Line type="monotone" dataKey="value" stroke={isDarkMode ? "#0DC18E" : "#0DC18E"} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default CoinChart;
