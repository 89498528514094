//@ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import {arNfts} from "../../data/jsons/translations/arNfts";

// @ts-ignore
const NFTCard = ({ project }) => {


    return (
        <Link to={`/nfts/${project.id}`}>
            <div key={project.id} className="p-4 rounded-lg bg-white dark:bg-black dark:bg-opacity-20  dark:border-2 dark:border-white dark:shadow-gray-300 shadow-sm flex justify-between items-center">
                <div className="flex flex-col">
                    <img src={project.image.small} alt={project.name} className="w-12 h-12 rounded-full mb-2" />
                    <h3 className="text-md font-semibold dark:text-white">
                        {arNfts.find(item => item.en === project.name) ? arNfts.find(item => item.en === project.name).ar : project.name}
                    </h3>
                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
          ({project.symbol.toUpperCase()})
        </span>
                </div>
                <div className="flex flex-col">
                    <div className={"flex flex-col justify-center items-center mt-2"}>
                        <p>24 ساعة</p>
                        <p className={(project.floor_price_24h_percentage_change.usd || 0) > 0 ? "font-bold mt-2 text-xl text-green-500" : "font-bold mt-2 text-xl text-primary-6000"} dir={"ltr"}>
                            {project.floor_price_24h_percentage_change.usd && project.floor_price_24h_percentage_change.usd.toFixed(2)}%
                        </p>
                    </div>
                    <div className={"flex flex-col justify-center items-center mt-2"}>
                        <p>السعر</p>
                        <span className="text-lg md:text-xl font-bold text-slate-700 dark:text-white font-body">
                    {project.floor_price.usd.toFixed(2)} $
                </span>
                    </div>

                </div>
            </div>
        </Link>
    );
};

export default NFTCard;
