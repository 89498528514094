//@ts-nocheck
export const coinsArabicName = [
    {
        "en": "Bitcoin",
        "ar": "بيتكوين"
    },
    {
        "en": "Ethereum",
        "ar": "إيثيريوم"
    },
    {
        "en": "Tether",
        "ar": "تيثر"
    },
    {
        "en": "BNB",
        "ar": "بينانس كوين"
    },
    {
        "en": "XRP",
        "ar": "ريبل"
    },
    {
        "en": "USDC",
        "ar": "يو إس دي سي"
    },
    {
        "en": "Lido Staked Ether",
        "ar": "ليدو"
    },
    {
        "en": "Solana",
        "ar": "سولانا"
    },
    {
        "en": "Cardano",
        "ar": "كاردانو"
    },
    {
        "en": "Dogecoin",
        "ar": "دوجكوين"
    },
    {
        "en": "TRON",
        "ar": "ترون"
    },
    {
        "en": "Toncoin",
        "ar": "تونكوين"
    },
    {
        "en": "Polkadot",
        "ar": "بولكادوت"
    },
    {
        "en": "Polygon",
        "ar": "بوليجون"
    },
    {
        "en": "Litecoin",
        "ar": "لايتكوين"
    },
    {
        "en": "Bitcoin Cash",
        "ar": "بيتكوين كاش"
    },
    {
        "en": "Wrapped Bitcoin",
        "ar": "بيتكوين مغلف"
    },
    {
        "en": "Shiba Inu",
        "ar": "شيبا إينو"
    },
    {
        "en": "Chainlink",
        "ar": "شين لينك"
    },
    {
        "en": "Dai",
        "ar": "داي"
    },
    {
        "en": "Avalanche",
        "ar": "أفالانش"
    },
    {
        "en": "LEO Token",
        "ar": "ليو توكن"
    },
    {
        "en": "TrueUSD",
        "ar": "ترو يو إس دي"
    },
    {
        "en": "eUSD",
        "ar": "إي يو إس دي"
    },
    {
        "en": "Uniswap",
        "ar": "يوني سواب"
    },
    {
        "en": "Stellar",
        "ar": "ستيلار"
    },
    {
        "en": "Monero",
        "ar": "مونيرو"
    },
    {
        "en": "OKB",
        "ar": "أوكي بي"
    },
    {
        "en": "BUSD",
        "ar": "بينانس دولار"
    },
    {
        "en": "Ethereum Classic",
        "ar": "إيثيريوم كلاسيك"
    },
    {
        "en": "Cosmos Hub",
        "ar": "كوزموس"
    },
    {
        "en": "Hedera",
        "ar": "هيديرا"
    },
    {
        "en": "Filecoin",
        "ar": "فايل كوين"
    },
    {
        "en": "Lido DAO",
        "ar": "ليدو داو"
    },
    {
        "en": "Internet Computer",
        "ar": "إنترنت كمبيوتر"
    },
    {
        "en": "Maker",
        "ar": "ماكر"
    },
    {
        "en": "Cronos",
        "ar": "كرونوس"
    },
    {
        "en": "Mantle",
        "ar": "مانتل"
    },
    {
        "en": "Quant",
        "ar": "كوانت"
    },
    {
        "en": "Aptos",
        "ar": "أبتوس"
    },
    {
        "en": "VeChain",
        "ar": "في تشاين"
    },
    {
        "en": "Optimism",
        "ar": "أوبتيميزم"
    },
    {
        "en": "Arbitrum",
        "ar": "أربيترام"
    },
    {
        "en": "NEAR Protocol",
        "ar": "نير بروتوكول"
    },
    {
        "en": "Kaspa",
        "ar": "كاسبا"
    },
    {
        "en": "Aave",
        "ar": "أيف"
    },
    {
        "en": "Rocket Pool ETH",
        "ar": "روكيت بول"
    },
    {
        "en": "Algorand",
        "ar": "ألغوراند"
    },
    {
        "en": "The Graph",
        "ar": "ذا جراف"
    },
    {
        "en": "WhiteBIT Coin",
        "ar": "وايت بيت كوين"
    },
    {
        "en": "USDD",
        "ar": "يو إس دي دي"
    },
    {
        "en": "Bitcoin SV",
        "ar": "بيتكوين اس في"
    },
    {
        "en": "Stacks",
        "ar": "ستاكس"
    },
    {
        "en": "XDC Network",
        "ar": "إكس دي سي نتورك"
    },
    {
        "en": "ImmutableX",
        "ar": "إميوتابل إكس"
    },
    {
        "en": "Frax",
        "ar": "فراكس"
    },
    {
        "en": "Render",
        "ar": "ريندر"
    },
    {
        "en": "Synthetix Network",
        "ar": "سينثيتيكس نتورك"
    },
    {
        "en": "Bitget Token",
        "ar": "بيت جيت توكن"
    },
    {
        "en": "Tezos",
        "ar": "تيزوس"
    },
    {
        "en": "MultiversX",
        "ar": "مولتيفيرس إكس"
    },
    {
        "en": "Injective",
        "ar": "إنجكتيف"
    },
    {
        "en": "Theta Network",
        "ar": "ثيتا نتورك"
    },
    {
        "en": "The Sandbox",
        "ar": "ذا ساندبوكس"
    },
    {
        "en": "THORChain",
        "ar": "ثور تشاين"
    },
    {
        "en": "Axie Infinity",
        "ar": "أكسي إنفينيتي"
    },
    {
        "en": "Decentraland",
        "ar": "ديسنترالاند"
    },
    {
        "en": "Fantom",
        "ar": "فانتوم"
    },
    {
        "en": "Gate",
        "ar": "جايت"
    },
    {
        "en": "Kava",
        "ar": "كافا"
    },
    {
        "en": "NEO",
        "ar": "نيو"
    },
    {
        "en": "Rollbit Coin",
        "ar": "رولبيت كوين"
    },
    {
        "en": "eCash",
        "ar": "إي كاش"
    },
    {
        "en": "Pax Dollar",
        "ar": "باكس دولار"
    },
    {
        "en": "Radix",
        "ar": "راديكس"
    },
    {
        "en": "PAX Gold",
        "ar": "باكس جولد"
    },
    {
        "en": "Flow",
        "ar": "فلو"
    },
    {
        "en": "Tether Gold",
        "ar": "تيثر جولد"
    },
    {
        "en": "cETH",
        "ar": "سي إي تش"
    },
    {
        "en": "KuCoin",
        "ar": "كوكوين"
    },
    {
        "en": "Frax Ether",
        "ar": "فراكس إيثر"
    },
    {
        "en": "Chiliz",
        "ar": "تشيليز"
    },
    {
        "en": "IOTA",
        "ar": "أيوتا"
    },
    {
        "en": "Curve DAO",
        "ar": "كيرف داو"
    },
    {
        "en": "First Digital USD",
        "ar": "فيرست يو إس دي"
    },
    {
        "en": "ApeCoin",
        "ar": "أب كوين"
    },
    {
        "en": "Rocket Pool",
        "ar": "روكيت بول"
    },
    {
        "en": "GALA",
        "ar": "جالا"
    },
    {
        "en": "Frax Share",
        "ar": "فراكس شير"
    },
    {
        "en": "Trust Wallet",
        "ar": "تراست واليت"
    },
    {
        "en": "Tokenize Xchange",
        "ar": "توكنايز إكس تشينج"
    },
    {
        "en": "Mina Protocol",
        "ar": "مينا بروتوكول"
    },
    {
        "en": "Sui",
        "ar": "سوي"
    },
    {
        "en": "Huobi",
        "ar": "هوبي"
    },
    {
        "en": "Klaytn",
        "ar": "كلايتن"
    },
    {
        "en": "BitTorrent",
        "ar": "بيت تورنت"
    },
    {
        "en": "Casper Network",
        "ar": "كاسبر نتورك"
    },
    {
        "en": "GMX",
        "ar": "جي إم إكس"
    },
    {
        "en": "dYdX",
        "ar": "دي واي دي إكس"
    },
    {
        "en": "Terra Luna Classic",
        "ar": "تيرا لونا كلاسيك"
    },
    {
        "en": "EOS",
        "ar": "إي أو إس"
    },
    {
        "en": "Request",
        "ar": "ريكويست"
    },
    {
        "en": "Stratis",
        "ar": "ستراتيس"
    },
    {
        "en": "tomiNet",
        "ar": "تومي نت"
    },
    {
        "en": "Flux",
        "ar": "فلوكس"
    },
    {
        "en": "Kujira",
        "ar": "كوجيرا"
    },
    {
        "en": "Echelon Prime",
        "ar": "إيكلون برايم"
    },
    {
        "en": "Blox",
        "ar": "بلوكس"
    },
    {
        "en": "Liquity",
        "ar": "ليكويتي"
    },
    {
        "en": "CANTO",
        "ar": "كانتو"
    },
    {
        "en": "Dynex",
        "ar": "دينكس"
    },
    {
        "en": "Venus",
        "ar": "فينوس"
    },
    {
        "en": "STP",
        "ar": "إس تي بي"
    },
    {
        "en": "Bancor Network",
        "ar": "بانكور نتورك"
    },
    {
        "en": "inSure DeFi",
        "ar": "إنشور ديفاي"
    },
    {
        "en": "Akash Network",
        "ar": "أكاش نتورك"
    },
    {
        "en": "ARK",
        "ar": "أرك"
    },
    {
        "en": "Bone ShibaSwap",
        "ar": "بون شيبا سواب"
    },
    {
        "en": "LUKSO [OLD]",
        "ar": "لوكسو"
    },
    {
        "en": "SwissBorg",
        "ar": "سويس بورج"
    },
    {
        "en": "WAX",
        "ar": "واكس"
    },
    {
        "en": "Orbs",
        "ar": "أوربس"
    },
    {
        "en": "Moonbeam",
        "ar": "مون بيم"
    },
    {
        "en": "0x0.ai: AI Smart Contract",
        "ar": "إي أي سمارت كونتراكت"
    },
    {
        "en": "Wrapped Centrifuge",
        "ar": "سنتريفيوج"
    },
    {
        "en": "GameAI",
        "ar": "جيم إي إي"
    },
    {
        "en": "Wall Street Memes",
        "ar": "وول ستريت ميمز"
    },
    {
        "en": "Centrifuge",
        "ar": "سنتريفيوج"
    },
    {
        "en": "Vega Protocol",
        "ar": "فيغا بروتوكول"
    },
    {
        "en": "SSV Network",
        "ar": "إس إس في نتورك"
    },
    {
        "en": "Flare",
        "ar": "فلير"
    },
    {
        "en": "Amp",
        "ar": "أمب"
    },
    {
        "en": "Horizen",
        "ar": "هورايزن"
    },
    {
        "en": "StormX",
        "ar": "ستورم إكس"
    },
    {
        "en": "Worldcoin",
        "ar": "وورلد كوين"
    },
    {
        "en": "Hive",
        "ar": "هايف"
    },
    {
        "en": "Lisk",
        "ar": "ليسك"
    },
    {
        "en": "Escoin",
        "ar": "إس كوين"
    },
    {
        "en": "Golem",
        "ar": "جوليم"
    },
    {
        "en": "CryptoPawCoin",
        "ar": "كريبتو باو كوين"
    },
    {
        "en": "Constellation",
        "ar": "كونستيليشن"
    },
    {
        "en": "TerraClassicUSD",
        "ar": "تيرا كلاسيك يو إس دي"
    },
    {
        "en": "TomoChain",
        "ar": "تومو تشاين"
    },
    {
        "en": "Sweat Economy",
        "ar": "سويت"
    },
    {
        "en": "Numeraire",
        "ar": "نيوميرير"
    },
    {
        "en": "NEXO",
        "ar": "نيكسو"
    },
    {
        "en": "WEMIX",
        "ar": "ويميكس"
    },
    {
        "en": "Illuvium",
        "ar": "إيلوفيوم"
    },
    {
        "en": "Energy Web",
        "ar": "إنرجي ويب"
    },
    {
        "en": "IoTeX",
        "ar": "إيو تيكس"
    },
    {
        "en": "Loom Network (OLD)",
        "ar": "لوم نتورك"
    },
    {
        "en": "Merit Circle",
        "ar": "ميريت سيركل"
    },
    {
        "en": "OKT Chain",
        "ar": "أوكيتي شين"
    },
    {
        "en": "Holo",
        "ar": "هولو"
    },
    {
        "en": "SafePal",
        "ar": "سيف بال"
    },
    {
        "en": "VVS Finance",
        "ar": "في في إس فاينانس"
    },
    {
        "en": "Covalent",
        "ar": "كوفالينت"
    },
    {
        "en": "Waves",
        "ar": "ويفز"
    },
    {
        "en": "Osmosis",
        "ar": "أوسموسيس"
    },
    {
        "en": "SafeMoon",
        "ar": "سيف مون"
    },
    {
        "en": "EthereumPoW",
        "ar": "إيثيريوم بو دبليو"
    },
    {
        "en": "Tellor Tributes",
        "ar": "تيلور تريبيوتس"
    },
    {
        "en": "FLOKI",
        "ar": "فلوكي"
    },
    {
        "en": "Power Ledger",
        "ar": "باور ليدجر"
    },
    {
        "en": "Blur",
        "ar": "بلور"
    },
    {
        "en": "Pepe",
        "ar": "بيبي"
    },
    {
        "en": "Chia",
        "ar": "تشيا"
    },
    {
        "en": "Magic",
        "ar": "ماجيك"
    },
    {
        "en": "Convex Finance",
        "ar": "كونفيكس فاينانس"
    },
    {
        "en": "Baby Doge Coin",
        "ar": "بيبي دوج كوين"
    },
    {
        "en": "Pundi X",
        "ar": "بوندي إكس"
    },
    {
        "en": "Marlin",
        "ar": "مارلين"
    },
    {
        "en": "Telcoin",
        "ar": "تيلكوين"
    },
    {
        "en": "Decred",
        "ar": "ديكريد"
    },
    {
        "en": "CoinEx",
        "ar": "كوين إكس"
    },
    {
        "en": "Axelar",
        "ar": "أكسيلار"
    },
    {
        "en": "Biconomy",
        "ar": "بيكونومي"
    },
    {
        "en": "Helium",
        "ar": "هيليوم"
    },
    {
        "en": "Bitcoin Gold",
        "ar": "بيتكوين جولد"
    },
    {
        "en": "Bitkub Coin",
        "ar": "بيتكوب كوين"
    },
    {
        "en": "ECOMI",
        "ar": "إيكومي"
    },
    {
        "en": "Medibloc",
        "ar": "ميدي بلوك"
    },
    {
        "en": "dKargo",
        "ar": "دي كارجو"
    },
    {
        "en": "Band Protocol",
        "ar": "باند بروتوكول"
    },
    {
        "en": "Kinesis Silver",
        "ar": "كينيسيس سيلفر"
    },
    {
        "en": "Prom",
        "ar": "بروم"
    },
    {
        "en": "Everscale",
        "ar": "إيفرسكيل"
    },
    {
        "en": "RSK Infrastructure Framework",
        "ar": "آر إس كي إنفراستركتشر فريم ورك"
    },
    {
        "en": "FINSCHIA",
        "ar": "فينشيا"
    },
    {
        "en": "Dogelon Mars",
        "ar": "دوجيلون مارس"
    },
    {
        "en": "iExec RLC",
        "ar": "آي إكسيك ري إل سي"
    },
    {
        "en": "UMA",
        "ar": "يو إم إيه"
    },
    {
        "en": "Marinade staked SOL",
        "ar": "ماريناد ستاكد سول"
    },
    {
        "en": "MX",
        "ar": "إم إكس"
    },
    {
        "en": "Lido Staked SOL",
        "ar": "ليدو ستاكد سول"
    },
    {
        "en": "HarryPotterObamaSonic10Inu (ETH)",
        "ar": "هاري بوتر أوباما سونيك إنو"
    },
    {
        "en": "Civic",
        "ar": "سيفيك"
    },
    {
        "en": "SKALE",
        "ar": "سكال"
    },
    {
        "en": "Status",
        "ar": "ستاتوس"
    },
    {
        "en": "Metadium",
        "ar": "ميتاديوم"
    },
    {
        "en": "MVL",
        "ar": "إم في إل"
    },
    {
        "en": "Open Campus",
        "ar": "أوبن كامبس"
    },
    {
        "en": "Kyber Network Crystal",
        "ar": "كايبر نتورك كريستال"
    },
    {
        "en": "Conflux",
        "ar": "كونفلوكس"
    },
    {
        "en": "JUST",
        "ar": "جاست"
    },
    {
        "en": "ORDI",
        "ar": "أوردي"
    },
    {
        "en": "Sei",
        "ar": "سي"
    },
    {
        "en": "PlayDapp",
        "ar": "بلاي داب"
    },
    {
        "en": "Ardor",
        "ar": "أردور"
    },
    {
        "en": "Polymesh",
        "ar": "بوليميش"
    },
    {
        "en": "AirTor Protocol",
        "ar": "أير تور بروتوكول"
    },
    {
        "en": "Polymath",
        "ar": "بوليماث"
    },
    {
        "en": "Dock",
        "ar": "دوك"
    },
    {
        "en": "01coin",
        "ar": "صفر واحد كوين"
    },
    {
        "en": "Zus",
        "ar": "زوس"
    },
    {
        "en": "0 Knowledge Network",
        "ar": "شبكة صفر معرفة"
    },
    {
        "en": "Bifrost",
        "ar": "بيفروست"
    },
    {
        "en": "VeThor",
        "ar": "فيثور"
    },
    {
        "en": "Verus Coin",
        "ar": "فيروس كوين"
    },
    {
        "en": "Carbify",
        "ar": "كاربيفاي"
    },
    {
        "en": "Open Exchange Token",
        "ar": "أوبن إكس تشينج توكن"
    },
    {
        "en": "ROACORE",
        "ar": "رواكور"
    },
    {
        "en": "RSS3",
        "ar": "آر إس إس 3"

    },
    {
        "en": "Undead Blocks",
        "ar": "أندد بلوكس"
    },
    {
        "en": "Zebec Protocol",
        "ar": "زيبيك بروتوكول"
    },
    {
        "en": "SOMESING Exchange",
        "ar": "سوم سينج إكس تشينج"
    },
    {
        "en": "ArbDoge AI",
        "ar": "أرب دوج إي إي"
    },
    {
        "en": "Bficoin",
        "ar": "بي إف آي كوين"
    },
    {
        "en": "TENET",
        "ar": "تينيت"
    },
    {
        "en": "Vite",
        "ar": "فيت"
    },
    {
        "en": "Stride",
        "ar": "سترايد"
    },
    {
        "en": "SIX Network",
        "ar": "سكس نتورك"
    },
    {
        "en": "Raydium",
        "ar": "رايديوم"

    },
    {
        "en": "Taboo",
        "ar": "تابو"
    },
    {
        "en": "BarnBridge",
        "ar": "بارن بوند"
    },
    {
        "en": "HarryPotterObamaPacMan8Inu",
        "ar": "هاري بوتر أوباما باك مان إنو"
    },
    {
        "en": "PAAL AI",
        "ar": "بال إي إي"
    },
    {
        "en": "Unibot",
        "ar": "يوني بوت"
    },
    {
        "en": "Nest Protocol",
        "ar": "نست بروتوكول"
    },
    {
        "en": "Popsicle Finance",
        "ar": "بوبسيكل فاينانس"
    },
    {
        "en": "district0x",
        "ar": "ديستريكت 0 إكس"
    },
    {
        "en": "Turbo",
        "ar": "تيربو"
    },
    {
        "en": "Ēnosys",
        "ar": "إينوسيس"
    },
    {
        "en": "Mdex (HECO)",
        "ar": "ميديكس"
    },
    {
        "en": "Altered State Machine",
        "ar": "ألترد ستيت ماشين"
    },
    {
        "en": "Empyreal",
        "ar": "إمبيريال"
    },
    {
        "en": "ALEX Lab",
        "ar": "أليكس لاب"
    },
    {
        "en": "Karura",
        "ar": "كارورا"
    },
    {
        "en": "ALVA",
        "ar": "ألفا"
    },
    {
        "en": "BitShares",
        "ar": "بيت شيرز"
    },
    {
        "en": "Unibright",
        "ar": "يوني برايت"
    },
    {
        "en": "Ben",
        "ar": "بن"
    },
    {
        "en": "JasmyCoin",
        "ar": "جاسمي كوين"
    },
    {
        "en": "NFT Worlds",
        "ar": "إن إف تي وورلدز"
    },
    {
        "en": "Talken",
        "ar": "تالكن"
    },
    {
        "en": "Pocket Network",
        "ar": "بوكيت نتورك"
    },
    {
        "en": "Staked Frax Ether",
        "ar": "ستاكد فراكس إيثر"
    },
    {
        "en": "Coinbase Wrapped Staked ETH",
        "ar": "كوين بيس ستاكد إيثر"
    },
    {
        "en": "APENFT",
        "ar": "أبين إف تي"
    },
    {
        "en": "Nexus Mutual",
        "ar": "نيكسوس ميوتوال"
    },
    {
        "en": "WOO Network",
        "ar": "وو نتورك"
    },
    {
        "en": "Dash",
        "ar": "داش"
    },
    {
        "en": "Zilliqa",
        "ar": "زيليكا"
    },
    {
        "en": "Compound",
        "ar": "كومباوند"
    },
    {
        "en": "Basic Attention",
        "ar": "بيسيك أتنشن"
    },
    {
        "en": "Huobi BTC",
        "ar": "هوبي بي تي سي"
    },
    {
        "en": "1inch",
        "ar": "ون إنش"
    },
    {
        "en": "Arweave",
        "ar": "أرويف"
    },
    {
        "en": "Gnosis",
        "ar": "جنوسيس"
    },
    {
        "en": "NEM",
        "ar": "نيم"
    },
    {
        "en": "PancakeSwap",
        "ar": "بانكيك سواب"
    },
    {
        "en": "Qtum",
        "ar": "كيو تي يو إم"
    },
    {
        "en": "Liquity USD",
        "ar": "ليكويتي يو إس دي"
    },
    {
        "en": "Fetch.ai",
        "ar": "فيتش"
    },
    {
        "en": "cUSDT",
        "ar": "سي يو إس دي تي"
    },
    {
        "en": "Astar",
        "ar": "أستار"
    },
    {
        "en": "Celo",
        "ar": "سيلو"
    },
    {
        "en": "Theta Fuel",
        "ar": "ثيتا فويل"
    },
    {
        "en": "Enjin Coin",
        "ar": "إنجين كوين"
    },
    {
        "en": "Mask Network",
        "ar": "ماسك نتورك"
    },
    {
        "en": "Loopring",
        "ar": "لوبرينج"
    },
    {
        "en": "SingularityNET",
        "ar": "سنجولاريتي نتورك"
    },
    {
        "en": "Oasis Network",
        "ar": "أواسيس نتورك"
    },
    {
        "en": "DeFiChain",
        "ar": "دي فاي تشين"
    },
    {
        "en": "Ankr Network",
        "ar": "أنكر نتورك"
    },
    {
        "en": "Zcash",
        "ar": "زي كاش"
    },
    {
        "en": "Fasttoken",
        "ar": "فاست توكن"
    },
    {
        "en": "Olympus",
        "ar": "أوليمبوس"
    },
    {
        "en": "STEPN",
        "ar": "ستيبن"
    },
    {
        "en": "Aragon",
        "ar": "أراجون"
    },
    {
        "en": "cUSDC",
        "ar": "سي يو إس دي سي"
    },
    {
        "en": "Aleph Zero",
        "ar": "أليف زيرو"
    },
    {
        "en": "ICON",
        "ar": "آيكون"
    },
    {
        "en": "Ethereum Name Service",
        "ar": "إيثيريوم نيم"
    },
    {
        "en": "Beldex",
        "ar": "بيلدكس"
    },
    {
        "en": "Ravencoin",
        "ar": "ريفن كوين"
    },
    {
        "en": "Alchemix USD",
        "ar": "ألكيميكس يو إس دي"
    },
    {
        "en": "yearn.finance",
        "ar": "يرن فاينانس"
    },
    {
        "en": "Gemini Dollar",
        "ar": "جيميني دولار"
    },
    {
        "en": "Terra",
        "ar": "تيرا"
    },
    {
        "en": "BTSE Token",
        "ar": "بي تي إس إي توكن"
    },
    {
        "en": "Livepeer",
        "ar": "لايف بير"
    },
    {
        "en": "aelf",
        "ar": "ألف"
    },
    {
        "en": "cDAI",
        "ar": "سي داي"
    },
    {
        "en": "SXP",
        "ar": "إس إكس بي"
    },
    {
        "en": "Kusama",
        "ar": "كوساما"
    },
    {
        "en": "Audius",
        "ar": "أوديوس"
    },
    {
        "en": "Siacoin",
        "ar": "سياكوين"
    },
    {
        "en": "Ontology",
        "ar": "أونتولوجي"
    },
    {
        "en": "IOST",
        "ar": "آي أو أس تي"
    },
    {
        "en": "Ribbon Finance",
        "ar": "ريبون فاينانس"
    },
    {
        "en": "sETH2",
        "ar": "سي إي تش 2"
    },
    {
        "en": "STASIS EURO",
        "ar": "ستاسيس يورو"
    },
    {
        "en": "Bitcoin Avalanche Bridged (BTC.b)",
        "ar": "بيكوين أفالانش"
    },
    {
        "en": "Balancer",
        "ar": "بالانسر"
    },
    {
        "en": "PayPal USD",
        "ar": "باي بال يو إس دي"
    },
    {
        "en": "Ocean Protocol",
        "ar": "أوشن بروتوكول"
    },
    {
        "en": "BORA",
        "ar": "بورا"
    },
    {
        "en": "Wrapped Beacon ETH",
        "ar": "ويثرابد بيكون إيثر"
    },
    {
        "en": "Tribe",
        "ar": "ترايب"
    },
    {
        "en": "crvUSD",
        "ar": "سي آر في يو إس دي"
    },
    {
        "en": "Harmony",
        "ar": "هارموني"
    },
    {
        "en": "DigiByte",
        "ar": "ديجي بايت"
    },
    {
        "en": "Kadena",
        "ar": "كادينا"
    },
    {
        "en": "Ronin",
        "ar": "رونين"
    },
    {
        "en": "Sushi",
        "ar": "سوشي"
    },
    {
        "en": "Gains Network",
        "ar": "جينز نتورك"
    },
    {
        "en": "NuCypher",
        "ar": "نيوسيفر"
    },
    {
        "en": "DAO Maker",
        "ar": "دي أي أو ميكر"
    },
    {
        "en": "API3",
        "ar": "أي بي آي 3"
    },
    {
        "en": "Decimal",
        "ar": "ديسيمال"
    },
    {
        "en": "Cartesi",
        "ar": "كارتيسي"
    },
    {
        "en": "XEN Crypto",
        "ar": "زين كريبتو"
    },
    {
        "en": "XELS",
        "ar": "إكسلس"
    },
    {
        "en": "FUNToken",
        "ar": "فان توكن"
    },
    {
        "en": "Wise",
        "ar": "وايز"
    },
    {
        "en": "UFO Gaming",
        "ar": "يو إف أو جيمنج"
    },
    {
        "en": "Mog Coin",
        "ar": "موج كوين"
    },
    {
        "en": "AVINOC",
        "ar": "أفينوك"
    },
    {
        "en": "Circuits of Value",
        "ar": "سيركيتس أوف فاليو"
    },
    {
        "en": "Dione",
        "ar": "ديون"
    },
    {
        "en": "Orbcity",
        "ar": "أورب سيتي"
    },
    {
        "en": "Steem",
        "ar": "ستيم"
    },
    {
        "en": "Pluton",
        "ar": "بلوتون"
    },
    {
        "en": "Step Finance",
        "ar": "ستيب فاينانس"
    },
    {
        "en": "StarLink",
        "ar": "ستار لينك"
    },
    {
        "en": "LIF3 (OLD)",
        "ar": "ليف 3"
    },
    {
        "en": "Agoric",
        "ar": "أجوريك"
    },
    {
        "en": "XANA",
        "ar": "زانا"
    },
    {
        "en": "Everdome",
        "ar": "إيفردوم"
    },
    {
        "en": "Adventure Gold",
        "ar": "أدفنتشر جولد"
    },
    {
        "en": "WAGMI Games",
        "ar": "واجمي جيمز"
    },
    {
        "en": "Kishu Inu",
        "ar": "كيشو إنو"
    },
    {
        "en": "Viberate",
        "ar": "فايبريت"
    },
    {
        "en": "Vectorspace AI",
        "ar": "فيكتور سبيس إي إي"
    },
    {
        "en": "Jesus Coin",
        "ar": "جيزس كوين"
    },
    {
        "en": "Bad Idea AI",
        "ar": "باد إيديا إي إي"
    },
    {
        "en": "AMATERASU OMIKAMI",
        "ar": "أماتيراسو أوميكامي"
    },
    {
        "en": "Rari Governance",
        "ar": "راري جوفرنانس"
    },
    {
        "en": "Not Financial Advice",
        "ar": "نوت فاينانشال أدفايس"
    },
    {
        "en": "Radio Caca",
        "ar": "راديو كاكا"
    },
    {
        "en": "Steem Dollars",
        "ar": "ستيم دولارز"
    },
    {
        "en": "Gods Unchained",
        "ar": "جودز أنشايند"
    },
    {
        "en": "Kwenta",
        "ar": "كوينتا"
    },
    {
        "en": "Image Generation AI",
        "ar": "إيماج جينيريشن إي إي"
    },
    {
        "en": "Kyber Network Crystal Legacy",
        "ar": "كايبر نتورك كريستال"
    },
    {
        "en": "Hourglass",
        "ar": "هور جلاس"
    },
    {
        "en": "Bonk",
        "ar": "بونك"
    },
    {
        "en": "Arc",
        "ar": "أرك"
    },
    {
        "en": "Mango",
        "ar": "مانجو"
    },
    {
        "en": "Gas",
        "ar": "غاز"
    },
    {
        "en": "Alephium",
        "ar": "أليفيوم"
    },
    {
        "en": "RocketX exchange",
        "ar": "روكيت إكس تشينج"
    },
    {
        "en": "ONBUFF",
        "ar": "أونبوف"
    },
    {
        "en": "Optimus AI",
        "ar": "أوبتيموس إي إي"
    },
    {
        "en": "Swarm",
        "ar": "سوارم"
    },
    {
        "en": "World$tateCoin",
        "ar": "وورلد ستيت كوين"
    },
    {
        "en": "ZambesiGold",
        "ar": "زامبيسي جولد"
    },
    {
        "en": "Alpha Quark",
        "ar": "ألفا كوارك"
    },
    {
        "en": "Unifi Protocol DAO",
        "ar": "يونيفي بروتوكول داو"
    },
    {
        "en": "Source Protocol",
        "ar": "سورس بروتوكول"
    },
    {
        "en": "Marinade",
        "ar": "ماريناد"
    },
    {
        "en": "D-Drops",
        "ar": "دي دروبس"
    },
    {
        "en": "Revain",
        "ar": "ريفاين"
    },
    {
        "en": "Pepe 2.0",
        "ar": "بيبي 2.0"
    },
    {
        "en": "AQTIS",
        "ar": "أكتيس"
    },
    {
        "en": "Autonolas",
        "ar": "أوتونولاس"
    },
    {
        "en": "Milady Meme Coin",
        "ar": "ميلادي ميم كوين"
    },
    {
        "en": "Flamengo Fan Token",
        "ar": "فلامينجو فان توكن"
    },
    {
        "en": "Bluzelle",
        "ar": "بلوزيل"
    },
    {
        "en": "JUNO",
        "ar": "جونو"
    },
    {
        "en": "Era Swap",
        "ar": "إيرا سواب"
    },
    {
        "en": "Qredo",
        "ar": "كيوريدو"
    },
    {
        "en": "CyberConnect",
        "ar": "سايبر كونيكت"
    },
    {
        "en": "Real Smurf Cat",
        "ar": "ريل سميرف كات"
    },
    {
        "en": "Evadore",
        "ar": "إيفادور"
    },
    {
        "en": "Adshares",
        "ar": "أدشيرز"
    },
    {
        "en": "Sologenic",
        "ar": "سولوجينيك"
    },
    {
        "en": "Botto",
        "ar": "بوتو"
    },
    {
        "en": "Sovryn",
        "ar": "سوفرين"
    },
    {
        "en": "STFX",
        "ar": "إس تي إف إكس"
    },
    {
        "en": "Propchain",
        "ar": "بروبشين"
    },
    {
        "en": "Bazed Games",
        "ar": "بازد جيمز"
    },
    {
        "en": "Celsius Network",
        "ar": "سيلسيوس نتورك"
    },
    {
        "en": "Divi",
        "ar": "ديفي"
    },
    {
        "en": "Strips Finance",
        "ar": "ستريبس فاينانس"
    },
    {
        "en": "RabbitX",
        "ar": "رابيت إكس"
    },
    {
        "en": "Dream Machine Token",
        "ar": "دريم ماشين توكن"
    },
    {
        "en": "Astroport",
        "ar": "أستروبورت"
    },
    {
        "en": "Spool",
        "ar": "سبول"
    },
    {
        "en": "Aurora",
        "ar": "أورورا"
    },
    {
        "en": "Vulcan Forged",
        "ar": "فولكان فورجد"
    },
    {
        "en": "WINR Protocol",
        "ar": "وينر بروتوكول"
    },
    {
        "en": "Synapse",
        "ar": "سينابس"
    },
    {
        "en": "Redacted",
        "ar": "ريداكتد"
    },
    {
        "en": "Shiba Saga",
        "ar": "شيبا ساجا"
    },
    {
        "en": "MicrovisionChain",
        "ar": "مايكروفيجن تشين"
    },
    {
        "en": "Ternoa",
        "ar": "تيرنوا"
    },
    {
        "en": "PLEX",
        "ar": "بليكس"
    },
    {
        "en": "Hifi Finance",
        "ar": "هاي فاي فاينانس"
    },
    {
        "en": "Wojak",
        "ar": "ووجاك"
    },
    {
        "en": "Hydra",
        "ar": "هايدرا"
    },
    {
        "en": "HMX",
        "ar": "إتش إم إكس"
    },
    {
        "en": "Frontier",
        "ar": "فرونتير"
    },
    {
        "en": "Myria",
        "ar": "ميريا"
    },
    {
        "en": "Pink BNB",
        "ar": "بينك بي إن بي"
    },
    {
        "en": "Function X",
        "ar": "فنكشن إكس"
    },
    {
        "en": "Voxel X Network",
        "ar": "فوكسل إكس نتورك"
    },
    {
        "en": "Star Atlas",
        "ar": "ستار أطلس"
    },
    {
        "en": "Galactic Quadrant",
        "ar": "غالاكتيك كوادرانت"
    },
    {
        "en": "Maverick Protocol",
        "ar": "مافريك بروتوكول"
    },
    {
        "en": "Step App",
        "ar": "ستيب أب"
    },
    {
        "en": "Dogechain",
        "ar": "دوج تشين"
    },
    {
        "en": "Manifold Finance",
        "ar": "مانيفولد فاينانس"
    },
    {
        "en": "Katana Inu",
        "ar": "كاتانا إنو"
    },
    {
        "en": "Cosplay Token",
        "ar": "كوسبلاي توكن"
    },
    {
        "en": "Dimitra",
        "ar": "ديميترا"
    },
    {
        "en": "Manifold Finance",
        "ar": "مانيفولد فاينانس"
    },
    {
        "en": "Step App",
        "ar": "ستيب أب"
    },
    {
        "en": "Santos FC Fan Token",
        "ar": "سانتوس فان توكن"
    },
    {
        "en": "Galaxia",
        "ar": "جالاكسيا"
    },
    {
        "en": "OG Fan Token",
        "ar": "أو جي فان توكن"
    },
    {
        "en": "QANplatform",
        "ar": "كيو إيه إن بلاتفورم"
    },
    {
        "en": "ChainX",
        "ar": "شين إكس"
    },
    {
        "en": "Alpaca Finance",
        "ar": "ألباكا فاينانس"
    },
    {
        "en": "Rarible",
        "ar": "راريبل"
    },
    {
        "en": "Gamium",
        "ar": "جاميوم"
    },
    {
        "en": "Aura Finance",
        "ar": "أورا فاينانس"
    },
    {
        "en": "e-Radix",
        "ar": "إي راديكس"
    },
    {
        "en": "Revest Finance",
        "ar": "ريفيست فاينانس"
    },
    {
        "en": "Banana Gun",
        "ar": "بنانا جان"
    },
    {
        "en": "Hydranet",
        "ar": "هايدرانت"
    },
    {
        "en": "blockbank",
        "ar": "بلوك بانك"
    },
    {
        "en": "Bounce",
        "ar": "باونس"
    },
    {
        "en": "Enjinstarter",
        "ar": "إنجين ستارتر"

    },
    {
        "en": "r/CryptoCurrency Moons",
        "ar": "ريدت كريبتو كيرنسي مونز"
    },
    {
        "en": "IguVerse IGU",
        "ar": "إيجوفيرس إي جي يو"
    },
    {
        "en": "ECOx",
        "ar": "إي كو إكس"
    },
    {
        "en": "Propy",
        "ar": "بروبي"
    },
    {
        "en": "Opulous",
        "ar": "أوبولوس"
    },
    {
        "en": "Zephyr Protocol",
        "ar": "زيفير بروتوكول"
    },
    {
        "en": "RAI Finance",
        "ar": "راي فاينانس"
    },
    {
        "en": "STEPN Green Satoshi Token on Solana",
        "ar": "ستيبن غرين ساتوشي توكن"
    },
    {
        "en": "Vaiot",
        "ar": "فايوت"
    },
    {
        "en": "Media Licensing Token",
        "ar": "ميديا لايسنسينج توكن"
    },
    {
        "en": "00 Token",
        "ar": "00 توكن"
    },
    {
        "en": "VIDT DAO",
        "ar": "في دي تي داو"
    },
    {
        "en": "Forta",
        "ar": "فورتا"
    },
    {
        "en": "UX Chain",
        "ar": "يو إكس تشين"
    },
    {
        "en": "Oraichain",
        "ar": "أورايتشين"
    },
    {
        "en": "SPACE ID",
        "ar": "سبيس آي دي"
    },
    {
        "en": "FEG BSC",
        "ar": "فيج بي إس سي"
    },
    {
        "en": "HAVAH",
        "ar": "هافا"
    },
    {
        "en": "Cult DAO",
        "ar": "كلت داو"
    },
    {
        "en": "Neoxa",
        "ar": "نيوكسا"
    },
    {
        "en": "Refinable",
        "ar": "ريفينابل"
    },
    {
        "en": "TokenSight",
        "ar": "توكن سايت"
    },
    {
        "en": "Smooth Love Potion",
        "ar": "سموث لوف بوشن"
    },
    {
        "en": "AIOZ Network",
        "ar": "أي أي أو زد نتورك"
    },
    {
        "en": "Argentine Football Association Fan Token",
        "ar": "أرجنتين فوتبول فان توكن"
    },
    {
        "en": "Humans.ai",
        "ar": "هيومانز إي إي"
    },
    {
        "en": "Metacade",
        "ar": "ميتاكاد"
    },
    {
        "en": "BSCPAD",
        "ar": "بي إس سي باد"
    },
    {
        "en": "Bitrock",
        "ar": "بيت روك"
    },
    {
        "en": "VEED",
        "ar": "فيد"
    },
    {
        "en": "Sidus",
        "ar": "سيدوس"
    },
    {
        "en": "Big Time",
        "ar": "بيج تايم"
    },
    {
        "en": "Star Atlas DAO",
        "ar": "ستار أطلس داو"
    },
    {
        "en": "Tectum",
        "ar": "تيكتوم"
    },
    {
        "en": "Uquid Coin",
        "ar": "يوكويد كوين"
    },
    {
        "en": "Pirate Chain",
        "ar": "بايرت شين"
    },
    {
        "en": "Gifto",
        "ar": "جيفتو"
    },
    {
        "en": "Dejitaru Tsuka",
        "ar": "ديجيتارو تسوكا"
    },
    {
        "en": "Efinity",
        "ar": "إيفينيتي"
    },
    {
        "en": "Nimiq",
        "ar": "نيميك"
    },
    {
        "en": "Rich Quack",
        "ar": "ريتش كواك"
    },
    {
        "en": "Bytom",
        "ar": "بيتوم"
    },
    {
        "en": "Stargate Finance",
        "ar": "ستارجيت فاينانس"
    },
    {
        "en": "Solend",
        "ar": "سوليند"
    },
    {
        "en": "Kin",
        "ar": "كين"
    },
    {
        "en": "Aurory",
        "ar": "أوروري"
    },
    {
        "en": "Bonfida",
        "ar": "بونفيدا"
    },
    {
        "en": "Serum",
        "ar": "سيرم"
    },
    {
        "en": "Aleph.im",
        "ar": "أليف إم"
    },
    {
        "en": "Oxygen",
        "ar": "أوكسجين"
    },
    {
        "en": "MAPS",
        "ar": "مابس"
    },
    {
        "en": "Polis",
        "ar": "بوليس"
    },
    {
        "en": "FTX",
        "ar": "إف تي إكس"
    },
    {
        "en": "Metaplex",
        "ar": "ميتابليكس"
    },
    {
        "en": "WETH",
        "ar": "ويث"
    },
    {
        "en": "Parrot Protocol",
        "ar": "باروت بروتوكول"
    },
    {
        "en": "BitDAO",
        "ar": "بيت داو"
    },
    {
        "en": "Ergo",
        "ar": "إيرجو"
    },
    {
        "en": "Dusk",
        "ar": "داسك"
    },
    {
        "en": "Concordium",
        "ar": "كونكورديوم"
    },
    {
        "en": "Moonriver",
        "ar": "مون ريفر"
    },
    {
        "en": "Taraxa",
        "ar": "تاراكسا"
    },
    {
        "en": "Nexa",
        "ar": "نيكسا"
    },
    {
        "en": "Cudos",
        "ar": "كودوس"
    },
    {
        "en": "Radiant",
        "ar": "راديانت"
    },
    {
        "en": "Fuse",
        "ar": "فيوز"
    },
    {
        "en": "Phantasma",
        "ar": "فانتاسما"
    },
    {
        "en": "Witnet",
        "ar": "ويتنت"
    },
    {
        "en": "Aura Network",
        "ar": "أورا نتورك"
    },
    {
        "en": "Neurai",
        "ar": "نيوراي"
    },
    {
        "en": "Epic Cash",
        "ar": "إيبيك كاش"
    },
    {
        "en": "MultiVAC",
        "ar": "مولتي فاك"
    },
    {
        "en": "Interlay",
        "ar": "إنترلاي"
    },
    {
        "en": "Aura Network [OLD]",
        "ar": "أوراو نتورك"
    },
    {
        "en": "Humanode",
        "ar": "هيومانود"
    },
    {
        "en": "Unique Network",
        "ar": "يونيك نتورك"
    },
    {
        "en": "Kintsugi",
        "ar": "كينتسوجي"
    },
    {
        "en": "MUU",
        "ar": "مو"
    },
    {
        "en": "Empire Network",
        "ar": "إمباير نتورك"
    },
    {
        "en": "Dogcoin",
        "ar": "دوج كوين"
    },
    {
        "en": "ULTRON",
        "ar": "ألترون"
    },
    {
        "en": "Vela Token",
        "ar": "فيلا توكن"
    },
    {
        "en": "Revolution Populi",
        "ar": "ريفوليوشن بوبولي"
    },
    {
        "en": "Euler",
        "ar": "أويلر"
    },
    {
        "en": "SnailBrook",
        "ar": "سنيل بروك"
    },
    {
        "en": "Crypto Gladiator League",
        "ar": "كريبتو جلاديتور ليج"
    },
    {
        "en": "Bitcicoin",
        "ar": "بيتسي كوين"
    },
    {
        "en": "Meter Governance",
        "ar": "ميتر جوفرنانس"
    },
    {
        "en": "Hera Finance",
        "ar": "هيرا فاينانس"
    },
    {
        "en": "League of Kingdoms",
        "ar": "ليج أوف كينجدومز"
    },
    {
        "en": "Alkimi",
        "ar": "ألكيمي"
    },
    {
        "en": "OctaSpace",
        "ar": "أوكتاسبيس"
    },
    {
        "en": "LeverFi",
        "ar": "ليفر فاي"
    },
    {
        "en": "Somnium Space CUBEs",
        "ar": "سومنيوم سبيس كيوبز"
    },
    {
        "en": "Samoyedcoin",
        "ar": "سامويد كوين"
    },
    {
        "en": "Domani Protocol",
        "ar": "دوماني بروتوكول"
    },
    {
        "en": "TokenFi",
        "ar": "توكن فاي"
    },
    {
        "en": "Evmos",
        "ar": "إيفموس"
    },
    {
        "en": "Shadow Token",
        "ar": "شادو توكن"
    },
    {
        "en": "DinoLFG",
        "ar": "دينو إل إف جي"
    },
    {
        "en": "Fulcrom",
        "ar": "فولكروم"
    },
    {
        "en": "Rebel Bots",
        "ar": "ريبل بوتس"
    },
    {
        "en": "Osaka Protocol",
        "ar": "أوساكا بروتوكول"
    },
    {
        "en": "TrustPad",
        "ar": "تراست باد"
    },
    {
        "en": "MovieBloc",
        "ar": "موفي بلوك"
    },
    {
        "en": "Tenset",
        "ar": "تنسيت"
    },
    {
        "en": "Ethix",
        "ar": "إيثيكس"
    },
    {
        "en": "HOPR",
        "ar": "هوبر"
    },
    {
        "en": "Buying.com",
        "ar": "باينج دوت كوم"
    },
    {
        "en": "Viction",
        "ar": "فيكشن"
    },
    {
        "en": "GroveCoin",
        "ar": "جروف كوين"
    },
    {
        "en": "Mars Protocol",
        "ar": "مارس بروتوكول"
    },
    {
        "en": "Ren",
        "ar": "رين"
    },
    {
        "en": "WeSendit",
        "ar": "وي سند إت"
    },
    {
        "en": "Telos",
        "ar": "تيلوس"
    },
    {
        "en": "Efforce",
        "ar": "إيفورس"
    },
    {
        "en": "Toshi",
        "ar": "توشي"
    },
    {
        "en": "Maple",
        "ar": "مابل"
    },
    {
        "en": "AMO Coin",
        "ar": "أمو كوين"
    },
    {
        "en": "Guild of Guardians",
        "ar": "جيلد أوف جارديانز"
    },
    {
        "en": "TrustSwap",
        "ar": "تراست سواب"
    },
    {
        "en": "xSUSHI",
        "ar": "إكس سوشي"
    },
    {
        "en": "Tap",
        "ar": "تاب"
    },
    {
        "en": "SENATE",
        "ar": "سينات"
    },
    {
        "en": "Camelot Token",
        "ar": "كاميلوت توكن"
    },
    {
        "en": "PLAYA3ULL GAMES",
        "ar": "بلايا ثريول جيمز"
    },
    {
        "en": "Tokamak Network",
        "ar": "توكاماك نتورك"
    },
    {
        "en": "HELLO",
        "ar": "هيلو"
    },
    {
        "en": "Voyager VGX",
        "ar": "فوياجر"
    },
    {
        "en": "TRAC (Ordinals)",
        "ar": "تراك"
    },
    {
        "en": "xExchange",
        "ar": "إكس تشينج"
    },
    {
        "en": "Itheum",
        "ar": "إيثيوم"
    },
    {
        "en": "Cobak",
        "ar": "كوباك"
    },
    {
        "en": "Memecoin",
        "ar": "ميم كوين"
    },
    {
        "en": "Wanchain",
        "ar": "وانشاين"
    },
    {
        "en": "Badger DAO",
        "ar": "بادجر داو"
    },
    {
        "en": "Joe Coin",
        "ar": "جو كوين"
    },
    {
        "en": "PlayZap",
        "ar": "بلاي زاب"
    },
    {
        "en": "XDEFI",
        "ar": "إكس ديفي"
    },
    {
        "en": "Decimated",
        "ar": "ديسيميتد"
    },
    {
        "en": "GuildFi",
        "ar": "جيلد فاي"
    },
    {
        "en": "LimeWire",
        "ar": "ليم واير"
    },
    {
        "en": "ApolloX",
        "ar": "أبولو إكس"
    },
    {
        "en": "Magpie",
        "ar": "ماجبي"
    },
    {
        "en": "Clash of Lilliput",
        "ar": "كلاش أوف ليليبوت"
    },
    {
        "en": "Shiba Predator",
        "ar": "شيبا بريداتور"
    },
    {
        "en": "Dagger",
        "ar": "داجر"
    },
    {
        "en": "Planet IX",
        "ar": "بلانيت إكس"
    },
    {
        "en": "CoW Protocol",
        "ar": "كاو بروتوكول"
    },
    {
        "en": "Ordinals",
        "ar": "أوردينالز"
    },
    {
        "en": "CorgiAI",
        "ar": "كورجي إي إي"
    },
    {
        "en": "Onyxcoin",
        "ar": "أونيكس كوين"
    },
    {
        "en": "Crust Network",
        "ar": "كراست نتورك"
    },
    {
        "en": "Areon Network",
        "ar": "أريون نتورك"
    },
    {
        "en": "Orca",
        "ar": "أوركا"
    },
    {
        "en": "Clearpool",
        "ar": "كليربول"
    },
    {
        "en": "Globiance Exchange",
        "ar": "جلوبيانس إكسشينج"
    },
    {
        "en": "NuNet",
        "ar": "نيو نت"
    },
    {
        "en": "LCX",
        "ar": "إل سي إكس"
    },
    {
        "en": "LooksRare",
        "ar": "لوكس رير"
    },
    {
        "en": "THORSwap",
        "ar": "ثور سواب"
    },
    {
        "en": "Diamond",
        "ar": "دياموند"
    },
    {
        "en": "Badger",
        "ar": "بادجر"
    },
    {
        "en": "HyperCash",
        "ar": "هايبر كاش"
    },
    {
        "en": "DeXe",
        "ar": "ديكسي"
    },
    {
        "en": "DFI.money",
        "ar": "دي إف آي موني"
    },
    {
        "en": "Persistence",
        "ar": "بيرسيستانس"
    },
    {
        "en": "Yield Guild Games",
        "ar": "ييلد جيلد جيمز"
    },
    {
        "en": "Bitcoin Diamond",
        "ar": "بيتكوين دايموند"
    },
    {
        "en": "R",
        "ar": "آر"
    },
    {
        "en": "Fei USD",
        "ar": "في يو إس دي"
    },
    {
        "en": "Juventus Fan Token",
        "ar": "يوفنتوس فان توكن"
    },
    {
        "en": "Keep3rV1",
        "ar": "كيب 3 آر في 1"
    },
    {
        "en": "Bitrue Coin",
        "ar": "بيترو كوين"
    },
    {
        "en": "Shido",
        "ar": "شيدو"
    },
    {
        "en": "Pangolin",
        "ar": "بانجولين"
    },
    {
        "en": "Aventus",
        "ar": "أفنتوس"
    },
    {
        "en": "Castello Coin",
        "ar": "كاستيلو كوين"
    },
    {
        "en": "BENQI Liquid Staked AVAX",
        "ar": "بينكي ليكويد ستاكد أفاكس"
    },
    {
        "en": "Zano",
        "ar": "زانو"
    },
    {
        "en": "PIP",
        "ar": "بيب"
    },
    {
        "en": "Cortex",
        "ar": "كورتكس"
    },
    {
        "en": "CHEQD Network",
        "ar": "تشيكد نتورك"
    },
    {
        "en": "ParagonsDAO",
        "ar": "باراجونز داو"
    },
    {
        "en": "PIVX",
        "ar": "بيفكس"
    },
    {
        "en": "Index Cooperative",
        "ar": "إندكس كوبراتيف"
    },
    {
        "en": "Gamma Strategies",
        "ar": "جاما ستراتيجيز"
    },
    {
        "en": "HILO",
        "ar": "هيلو"
    },
    {
        "en": "Beam",
        "ar": "بيم"
    },
    {
        "en": "Hegic",
        "ar": "هيجيك"
    },
    {
        "en": "pNetwork",
        "ar": "بي نتورك"
    },
    {
        "en": "ChainGPT",
        "ar": "شين جي بي تي"
    },
    {
        "en": "Carbon Browser",
        "ar": "كاربون براوزر"
    },
    {
        "en": "chrono.tech",
        "ar": "كرونو تيك"
    },
    {
        "en": "Colony",
        "ar": "كولوني"
    },
    {
        "en": "Roko Network",
        "ar": "روكو نتورك"
    },
    {
        "en": "LitLab Games",
        "ar": "ليت لاب جيمز"
    },
    {
        "en": "IX Swap",
        "ar": "آي إكس سواب"
    },
    {
        "en": "MATH",
        "ar": "ماث"
    },
    {
        "en": "MASQ",
        "ar": "ماسك"
    },
    {
        "en": "Aladdin DAO",
        "ar": "علاء الدين داو"
    },
    {
        "en": "Dogami",
        "ar": "دوجامي"
    },
    {
        "en": "Koinos",
        "ar": "كوينوس"
    },
    {
        "en": "Zenon",
        "ar": "زينون"
    },
    {
        "en": "Xpense",
        "ar": "إكسبنس"
    },
    {
        "en": "GooseFX",
        "ar": "جوس إف إكس"
    },
    {
        "en": "Pyth Network",
        "ar": "بيث نتورك"
    },
    {
        "en": "Hermez Network",
        "ar": "هيرميز نتورك"
    },
    {
        "en": "Metahero",
        "ar": "ميتا هيرو"
    },
    {
        "en": "SuperVerse",
        "ar": "سوبر فيرس"
    },
    {
        "en": "Victoria VR",
        "ar": "فيكتوريا في آر"
    },
    {
        "en": "Dust Protocol",
        "ar": "داست بروتوكول"
    },
    {
        "en": "ResearchCoin",
        "ar": "ريسيرش كوين"
    },
    {
        "en": "Stader BNBx",
        "ar": "ستادر بي إن بي إكس"
    },
    {
        "en": "CateCoin",
        "ar": "كات كوين"
    },
    {
        "en": "Asymetrix",
        "ar": "أسيمتريكس"
    },
    {
        "en": "Biswap",
        "ar": "بي سواب"
    },
    {
        "en": "AimBot",
        "ar": "إيم بوت"
    },
    {
        "en": "Mute",
        "ar": "ميوت"
    },
    {
        "en": "Parrot USD",
        "ar": "باروت يو إس دي"
    },
    {
        "en": "DeFi Land",
        "ar": "ديفي لاند"
    },
    {
        "en": "Goldfinch",
        "ar": "جولدفينش"
    },
    {
        "en": "GameSwift",
        "ar": "جيم سويفت"
    },
    {
        "en": "PARSIQ",
        "ar": "بارسيك"
    },
    {
        "en": "Epik Prime",
        "ar": "إبيك برايم"
    },
    {
        "en": "HydraDX",
        "ar": "هايدرا دي إكس"
    },
    {
        "en": "DFUK",
        "ar": "دي إف يو كيه"
    },
    {
        "en": "INSC",
        "ar": "إن إس سي"
    },
    {
        "en": "Battle World",
        "ar": "باتل ورلد"
    },
    {
        "en": "ONINO",
        "ar": "أونينو"
    },
    {
        "en": "Polytrade",
        "ar": "بولي تريد"
    },
    {
        "en": "Blockchain Bets",
        "ar": "بلوكشين بيتس"
    },
    {
        "en": "Ultima",
        "ar": "ألتيما"
    },
    {
        "en": "Chainflip",
        "ar": "شين فليب"
    },
    {
        "en": "Spell",
        "ar": "سبيل"
    },
    {
        "en": "Symbiosis",
        "ar": "سيمبيوسيس"
    },
    {
        "en": "Dopex Rebate",
        "ar": "دوبكس ريبيت"
    },
    {
        "en": "Sylo",
        "ar": "سايلو"
    },
    {
        "en": "Syscoin",
        "ar": "سيسكوين"
    },
    {
        "en": "Niza Global",
        "ar": "نيزا جلوبال"
    },
    {
        "en": "Measurable Data",
        "ar": "ميزورابل داتا"
    },
    {
        "en": "Myro",
        "ar": "مايرو"
    },
    {
        "en": "Hivemapper",
        "ar": "هايف مابر"
    },
    {
        "en": "KAP Games",
        "ar": "كاب جيمز"
    },
    {
        "en": "FLEX Coin",
        "ar": "فليكس كوين"
    },
    {
        "en": "Solcasino Token",
        "ar": "سولكاسينو توكن"
    },
    {
        "en": "Whiteheart",
        "ar": "وايت هارت"
    },
    {
        "en": "PLEARN",
        "ar": "بليرن"
    },
    {
        "en": "Chain Games",
        "ar": "شين جيمز"
    },
    {
        "en": "Virtua",
        "ar": "فيرتوا"
    },
    {
        "en": "Guacamole",
        "ar": "جواكامول"
    },
    {
        "en": "REVV",
        "ar": "ريف"
    },
    {
        "en": "DOMO",
        "ar": "دومو"
    },
    {
        "en": "MINATIVERSE",
        "ar": "ميناتيفرس"
    },
    {
        "en": "BEAM",
        "ar": "بيم"
    },
    {
        "en": "Cornucopias",
        "ar": "كورنوكوبياس"
    },
    {
        "en": "LORDS",
        "ar": "لوردز"
    },
    {
        "en": "Saber",
        "ar": "سابر"
    },
    {
        "en": "PAID Network",
        "ar": "بايد نتورك"
    },
    {
        "en": "Clore.ai",
        "ar": "كلوري إي إي"
    },
    {
        "en": "FORE Protocol",
        "ar": "فور بروتوكول"
    },
    {
        "en": "DigitalBits",
        "ar": "ديجيتال بيتس"
    },
    {
        "en": "Flooring Lab Credit",
        "ar": "فلورينج لاب كريديت"
    },
    {
        "en": "Cashtree Token",
        "ar": "كاش تري توكن"
    },
    {
        "en": "ShapeShift FOX",
        "ar": "شيب شيفت فوكس"
    },
    {
        "en": "Sperax",
        "ar": "سبيراكس"
    },
    {
        "en": "Arcblock",
        "ar": "أرك بلوك"
    },
    {
        "en": "Shina Inu",
        "ar": "شينا إنو"
    },
    {
        "en": "OriginTrail",
        "ar": "أوريجين تريل"
    },
    {
        "en": "AirSwap",
        "ar": "إير سواب"
    },
    {
        "en": "Wagmi",
        "ar": "واجمي"
    },
    {
        "en": "Connex",
        "ar": "كونكس"
    },
    {
        "en": "DeFi Kingdoms",
        "ar": "ديفي كينجدومز"
    },
    {
        "en": "Handy",
        "ar": "هاندي"
    },
    {
        "en": "Impossible Finance Launchpad",
        "ar": "إمبوسيبل فاينانس لانشباد"
    },
    {
        "en": "Bostrom",
        "ar": "بوستروم"
    },
    {
        "en": "The Doge NFT",
        "ar": "ذا دوج نفت"
    },
    {
        "en": "Chihuahua Chain",
        "ar": "شيهواهوا تشين"
    },
    {
        "en": "BOB Token",
        "ar": "بوب توكن"
    },
    {
        "en": "BENQI",
        "ar": "بينكي"
    },
    {
        "en": "Hubble",
        "ar": "هابل"
    },
    {
        "en": "TOR",
        "ar": "تور"
    },
    {
        "en": "QASH",
        "ar": "كواش"
    },
    {
        "en": "WazirX",
        "ar": "وازير إكس"
    },
    {
        "en": "Octopus Network",
        "ar": "أوكتوبوس نتورك"
    },
    {
        "en": "Gala Music",
        "ar": "جالا ميوزك"
    },
    {
        "en": "Stargaze",
        "ar": "ستارجايز"
    },
    {
        "en": "Onomy Protocol",
        "ar": "أونومي بروتوكول"
    },
    {
        "en": "pSTAKE Finance",
        "ar": "بي ستيك فاينانس"
    },
    {
        "en": "GoGoPool",
        "ar": "جو جو بول"
    },
    {
        "en": "Decubate",
        "ar": "ديكيوبات"
    },
    {
        "en": "Polkastarter",
        "ar": "بولكاستارتر"
    },
    {
        "en": "Stella",
        "ar": "ستيلا"
    },
    {
        "en": "Utility Web3Shot",
        "ar": "يوتيليتي ويب 3 شوت"
    },
    {
        "en": "Picasso",
        "ar": "بيكاسو"
    },
    {
        "en": "Streamr",
        "ar": "ستريمر"
    },
    {
        "en": "Streamr XDATA",
        "ar": "ستريمر إكس داتا"
    },
    {
        "en": "DIMO",
        "ar": "ديمو"
    },
    {
        "en": "Neon",
        "ar": "نيون"
    },
    {
        "en": "Vita Inu",
        "ar": "فيتا إنو"
    },
    {
        "en": "NADA Protocol Token",
        "ar": "نادا بروتوكول توكن"
    },
    {
        "en": "Helium IOT",
        "ar": "هيليوم إي أو تي"
    },
    {
        "en": "Coq Inu",
        "ar": "كوك إنو"
    },
    {
        "en": "DIA",
        "ar": "ديا"
    },
    {
        "en": "Elastos",
        "ar": "إيلاستوس"
    },
    {
        "en": "WiFi Map",
        "ar": "واي فاي ماب"
    },
    {
        "en": "Shyft Network",
        "ar": "شيفت نتورك"
    },
    {
        "en": "Prism",
        "ar": "بريزم"
    },
    {
        "en": "Lazio Fan Token",
        "ar": "لازيو فان توكن"
    },
    {
        "en": "Astrafer",
        "ar": "أسترافير"
    },
    {
        "en": "dogwifhat",
        "ar": "دوج ويف هات"
    },
    {
        "en": "Helium Mobile",
        "ar": "هيليوم موبايل"
    },
    {
        "en": "Volt Inu",
        "ar": "فولت إنو"
    },
    {
        "en": "Presearch",
        "ar": "بريسيرش"
    },
    {
        "en": "VinuChain",
        "ar": "فينو تشين"
    },
    {
        "en": "Cronos ID",
        "ar": "كرونوس آي دي"
    },
    {
        "en": "Honeyland",
        "ar": "هوني لاند"
    },
    {
        "en": "Wicrypt",
        "ar": "واي كريبت"
    },
    {
        "en": "KRYLL",
        "ar": "كريل"
    },
    {
        "en": "Velodrome Finance",
        "ar": "فيلودروم فاينانس"
    },
    {
        "en": "Hypr Network",
        "ar": "هايبر نتورك"
    },
    {
        "en": "Rowan Coin",
        "ar": "روان كوين"
    },
    {
        "en": "Access Protocol",
        "ar": "أكسس بروتوكول"
    },
    {
        "en": "Popcat",
        "ar": "بوب كات"
    },
    {
        "en": "Multibit",
        "ar": "مولتي بيت"
    },
    {
        "en": "Passage",
        "ar": "باسيج"
    },
    {
        "en": "Counterparty",
        "ar": "كونتربارتي"
    },
    {
        "en": "MXC",
        "ar": "إم إكس سي"
    },
    {
        "en": "Phoenix",
        "ar": "فينيكس"
    },
    {
        "en": "Boba Network",
        "ar": "بوبا نتورك"
    },
    {
        "en": "Comsats",
        "ar": "كومساتس"
    },
    {
        "en": "GXChain",
        "ar": "جي إكس تشين"
    },
    {
        "en": "Sakai Vault",
        "ar": "ساكاي فولت"
    },
    {
        "en": "Solidly",
        "ar": "سوليدلي"
    },
    {
        "en": "Taki",
        "ar": "تاكي"
    },
    {
        "en": "Suiswap",
        "ar": "سوي سواب"
    },
    {
        "en": "ZKSpace",
        "ar": "زد كي سبيس"
    },
    {
        "en": "Fideum",
        "ar": "فيديوم"
    },
    {
        "en": "Prisma Governance Token",
        "ar": "بريزما جوفرنانس توكن"
    },
    {
        "en": "KIRA Network",
        "ar": "كيرا نتورك"
    },
    {
        "en": "OmniCat",
        "ar": "أومني كات"
    },
    {
        "en": "Saitama",
        "ar": "سايتاما"
    },
    {
        "en": "Bittensor",
        "ar": "بيتنسور"
    },
    {
        "en": "BakerySwap",
        "ar": "بيكري سواب"
    },
    {
        "en": "Grok",
        "ar": "جروك"
    },
    {
        "en": "holoride",
        "ar": "هولورايد"
    },
    {
        "en": "Numbers Protocol",
        "ar": "نمبرز بروتوكول"
    },
    {
        "en": "Quantum Resistant Ledger",
        "ar": "كوانتوم ريسيستانت ليدجر"
    },
    {
        "en": "FEG ETH",
        "ar": "فيج إي تش"
    },
    {
        "en": "Creditcoin",
        "ar": "كريديت كوين"
    },
    {
        "en": "TriasLab",
        "ar": "ترياس لاب"
    },
    {
        "en": "MangoMan Intelligent",
        "ar": "مانجومان إنتيليجنت"
    },
    {
        "en": "Vyvo Smart Chain",
        "ar": "فايفو سمارت شين"
    },
    {
        "en": "Seedify.fund",
        "ar": "سيديفاي دوت فاند"
    },
    {
        "en": "JOE",
        "ar": "جو"
    },
    {
        "en": "Coin98",
        "ar": "كوين 98"
    },
    {
        "en": "Reserve Rights",
        "ar": "ريزيرف رايتس"
    },
    {
        "en": "Prisma mkUSD",
        "ar": "بريزما إم كي يو إس دي"
    },
    {
        "en": "DODO",
        "ar": "دودو"
    },
    {
        "en": "Pendle",
        "ar": "بندل"
    },
    {
        "en": "Ampleforth",
        "ar": "أمبل فورث"
    },
    {
        "en": "Perpetual Protocol",
        "ar": "بربتشوال بروتوكول"
    },
    {
        "en": "Nerve Finance",
        "ar": "نيرف فاينانس"
    },
    {
        "en": "Hashflow",
        "ar": "هاش فلو"
    },
    {
        "en": "Keep Network",
        "ar": "كيب نتورك"
    },
    {
        "en": "Linear",
        "ar": "لينير"
    },
    {
        "en": "Tokenlon",
        "ar": "توكنلون"
    },
    {
        "en": "Wrapped NXM",
        "ar": "رابط نكس إم"
    },
    {
        "en": "TrueFi",
        "ar": "ترو فاي"
    },
    {
        "en": "StakeWise Staked ETH",
        "ar": "ستيك وايز ستاكد إي تش"
    },
    {
        "en": "Alchemix",
        "ar": "ألكيميكس"
    },
    {
        "en": "sUSD",
        "ar": "سي يو إس دي"
    },
    {
        "en": "tBTC",
        "ar": "تي بي تي سي"
    },
    {
        "en": "Flamingo Finance",
        "ar": "فلامنجو فاينانس"
    },
    {
        "en": "Magic Internet Money",
        "ar": "ماجيك إنترنت موني"
    },
    {
        "en": "IDEX",
        "ar": "آي دي إي إكس"
    },
    {
        "en": "MUX Protocol",
        "ar": "ماكس بروتوكول"
    },
    {
        "en": "Aura BAL",
        "ar": "أورا بال"
    },
    {
        "en": "Bella Protocol",
        "ar": "بيلا بروتوكول"
    },
    {
        "en": "Tectonic",
        "ar": "تكتونيك"
    },
    {
        "en": "Bean",
        "ar": "بين"
    },
    {
        "en": "Quickswap [OLD]",
        "ar": "كويك سواب"
    },
    {
        "en": "Quickswap",
        "ar": "كويك سواب"
    },
    {
        "en": "DeGate",
        "ar": "دي جيت"
    },
    {
        "en": "Harvest Finance",
        "ar": "هارفست فاينانس"
    },
    {
        "en": "cBAT",
        "ar": "سي بات"
    },
    {
        "en": "Tranchess",
        "ar": "ترانشيس"
    },
    {
        "en": "Instadapp",
        "ar": "إنستاداب"
    },
    {
        "en": "sETH",
        "ar": "سي إي تش"
    },
    {
        "en": "Enzyme",
        "ar": "إنزيم"
    },
    {
        "en": "Wing Finance",
        "ar": "وينج فاينانس"
    },
    {
        "en": "Kryptonite",
        "ar": "كريبتونايت"
    },
    {
        "en": "ConstitutionDAO",
        "ar": "كونستيتيوشن داو"
    },
    {
        "en": "BLOCKv",
        "ar": "بلوك في"
    },
    {
        "en": "ZMINE",
        "ar": "زد ماين"
    },
    {
        "en": "KYVE Network",
        "ar": "كايف نتورك"
    },
    {
        "en": "Firo",
        "ar": "فيرو"
    },
    {
        "en": "Silly Dragon",
        "ar": "سيلي دراجون"
    },
    {
        "en": "Crypto Asset Governance Alliance",
        "ar": "كريبتو أسيت جوفرنانس أليانس"
    },
    {
        "en": "Velas",
        "ar": "فيلاس"
    },
    {
        "en": "Creo Engine",
        "ar": "كريو إنجين"
    },
    {
        "en": "Immutable",
        "ar": "إميوتابل"
    },
    {
        "en": "Calamari Network",
        "ar": "كالاماري نتورك"
    },
    {
        "en": "SEDA Protocol",
        "ar": "سيدا بروتوكول"
    },
    {
        "en": "Vertex Protocol",
        "ar": "فيرتيكس بروتوكول"
    },
    {
        "en": "Floor Protocol",
        "ar": "فلور بروتوكول"
    },
    {
        "en": "Metis",
        "ar": "ميتيس"
    },
    {
        "en": "Chromia",
        "ar": "كروميا"
    },
    {
        "en": "Shrapnel",
        "ar": "شرابنل"
    },
    {
        "en": "CHAX",
        "ar": "تشاكس"
    },
    {
        "en": "The Big Five",
        "ar": "ذا بيج فايف"
    },
    {
        "en": "MAP Protocol",
        "ar": "ماب بروتوكول"
    },
    {
        "en": "MainnetZ",
        "ar": "ماين نت زد"
    },
    {
        "en": "Gearbox",
        "ar": "جيربوكس"
    },
    {
        "en": "Solidus Ai Tech",
        "ar": "سوليدوس آي تيك"
    },
    {
        "en": "Ovr",
        "ar": "أوفر"
    },
    {
        "en": "SideShift",
        "ar": "سايد شيفت"
    },
    {
        "en": "GMT",
        "ar": "جي إم تي"
    },
    {
        "en": "Stronghold",
        "ar": "سترونغهولد"
    },
    {
        "en": "Xai",
        "ar": "إكساي"
    },
    {
        "en": "Carry",
        "ar": "كاري"
    },
    {
        "en": "MaidSafeCoin",
        "ar": "مايد سيف كوين"
    },
    {
        "en": "D3D Social",
        "ar": "دي تري دي سوشيال"
    },
    {
        "en": "Bit2Me",
        "ar": "بيت تو مي"
    },
    {
        "en": "enqAI",
        "ar": "إن كيو آي"
    },
    {
        "en": "Magic Square",
        "ar": "ماجيك سكوير"
    },
    {
        "en": "Poof Token",
        "ar": "بوف توكن"
    },
    {
        "en": "BFICoin",
        "ar": "بي إف آي كوين"
    },
    {
        "en": "Syntropy",
        "ar": "سينتروبي"
    },
    {
        "en": "FC Barcelona Fan Token",
        "ar": "إف سي برشلونة فان توكن"
    },
    {
        "en": "Connext",
        "ar": "كونكست"
    },
    {
        "en": "Gains",
        "ar": "جينز"
    },
    {
        "en": "Crown by Third Time Games",
        "ar": "كراون باي ثيرد تايم جيمز"
    },
    {
        "en": "Restake Finance",
        "ar": "ريستاك فاينانس"
    },
    {
        "en": "Phala",
        "ar": "فالا"
    },
    {
        "en": "KLEVA",
        "ar": "كليفا"
    },
    {
        "en": "Mochi",
        "ar": "موشي"
    },
    {
        "en": "Blockchain Brawlers",
        "ar": "بلوكشين براولرز"
    },
    {
        "en": "Baby Grok",
        "ar": "بيبي جروك"
    },
    {
        "en": "Jito",
        "ar": "جيتو"
    },
    {
        "en": "Conic",
        "ar": "كونيك"
    },
    {
        "en": "Inspect",
        "ar": "إنسبكت"
    },
    {
        "en": "Kenshi",
        "ar": "كينشي"
    },
    {
        "en": "Sora Validator",
        "ar": "سورا فاليداتور"
    },
    {
        "en": "StakeWise",
        "ar": "ستيك وايز"
    },
    {
        "en": "Polkaswap",
        "ar": "بولكا سواب"
    },
    {
        "en": "Ordiswap",
        "ar": "أوردي سواب"
    },
    {
        "en": "Radworks",
        "ar": "رادووركس"
    },
    {
        "en": "Prosper",
        "ar": "بروسبر"
    },
    {
        "en": "Karate Combat",
        "ar": "كاراتي كومبات"
    },
    {
        "en": "Ondo",
        "ar": "أوندو"
    },
    {
        "en": "Manta Network",
        "ar": "مانتا نتورك"
    },
    {
        "en": "Celestia",
        "ar": "سيليستيا"
    },
    {
        "en": "GamerCoin",
        "ar": "جيمر كوين"
    },
    {
        "en": "PondCoin",
        "ar": "بوند كوين"
    },
    {
        "en": "Frax Price Index Share",
        "ar": "فراكس برايس إندكس شير"
    },
    {
        "en": "Ampleforth Governance",
        "ar": "أمبل فورث جوفرنانس"
    },
    {
        "en": "MANTRA",
        "ar": "مانترا"
    },
    {
        "en": "Joystream",
        "ar": "جويستريم"
    },
    {
        "en": "UNKJD",
        "ar": "يونك جي دي"
    },
    {
        "en": "Galxe",
        "ar": "جالكس"
    },
    {
        "en": "Stader ETHx",
        "ar": "ستادر إي تش إكس"
    },
    {
        "en": "Oasys",
        "ar": "أواسيس"
    },
    {
        "en": "Threshold Network",
        "ar": "ثريشولد نتورك"
    },
    {
        "en": "Neutron",
        "ar": "نيوترون"
    },
    {
        "en": "Decentralized Social",
        "ar": "ديسنترالايزد سوشيال"
    },
    {
        "en": "Swell Ethereum",
        "ar": "سويل إيثريوم"
    },
    {
        "en": "Core",
        "ar": "كور"
    },
    {
        "en": "cWBTC",
        "ar": "سي دبليو بي تي سي"
    },
    {
        "en": "WOO",
        "ar": "وو"
    },
    {
        "en": "Cheelee",
        "ar": "تشيلي"
    },
    {
        "en": "SATS (Ordinals)",
        "ar": "ساتس"
    },
    {
        "en": "Doge Killer",
        "ar": "دوج كيلر"
    },
    {
        "en": "Eggdog",
        "ar": "إيج دوج"
    },
    {
        "en": "Tamadoge",
        "ar": "تامادوج"
    },
    {
        "en": "PolyDoge",
        "ar": "بولي دوج"
    },
    {
        "en": "Husky Avax",
        "ar": "هاسكي أفاكس"
    },
    {
        "en": "Feisty Doge NFT",
        "ar": "فيستي دوج نفت"
    },
    {
        "en": "Any Inu",
        "ar": "أني إنو"
    },
    {
        "en": "Linda",
        "ar": "ليندا"
    },
    {
        "en": "Wrapped OptiDoge",
        "ar": "رابط أوبتي دوج"
    },
    {
        "en": "Doge Eat Doge",
        "ar": "دوج إيت دوج"
    },
    {
        "en": "Hosky",
        "ar": "هوسكي"
    },
    {
        "en": "WELSH CORGI COIN",
        "ar": "ويلش كورجي كوين"
    },
    {
        "en": "Solabrador",
        "ar": "سولابرادور"
    },
    {
        "en": "WOOF",
        "ar": "ووف"
    },
    {
        "en": "LUKSO",
        "ar": "لوكسو"
    },
    {
        "en": "MELD",
        "ar": "ميلد"
    },
    {
        "en": "LTO Network",
        "ar": "إل تي أو نتورك"
    },
    {
        "en": "Jackal Protocol",
        "ar": "جاكال بروتوكول"
    },
    {
        "en": "AirDAO",
        "ar": "إير داو"
    },
    {
        "en": "Saito",
        "ar": "سايتو"
    },
    {
        "en": "Thought",
        "ar": "ثوت"
    },
    {
        "en": "Electra Protocol",
        "ar": "إليكترا بروتوكول"
    },
    {
        "en": "Hacash",
        "ar": "هاكاش"
    },
    {
        "en": "LiquidLayer",
        "ar": "ليكويد لاير"
    },
    {
        "en": "Script Network",
        "ar": "سكريبت نتورك"
    },
    {
        "en": "Bitnet IO",
        "ar": "بيت نت آي أو"
    },
    {
        "en": "Kylacoin",
        "ar": "كيلاكوين"
    },
    {
        "en": "Lyncoin",
        "ar": "لينكوين"
    },
    {
        "en": "Coreum",
        "ar": "كوريوم"
    },
    {
        "en": "Spacemesh",
        "ar": "سبيس ميش"
    },
    {
        "en": "The Root Network",
        "ar": "ذا روت نتورك"
    },
    {
        "en": "Nym",
        "ar": "نيم"
    },
    {
        "en": "Songbird",
        "ar": "سونجبيرد"
    },
    {
        "en": "Secret",
        "ar": "سيكريت"
    },
    {
        "en": "OMG Network",
        "ar": "أو إم جي نتورك"
    },
    {
        "en": "COTI",
        "ar": "كوتي"
    },
    {
        "en": "Polygon Ecosystem Token",
        "ar": "ماتيك إيكوسيستم توكن"
    },
    {
        "en": "Aergo",
        "ar": "أيرجو"
    },
    {
        "en": "ThunderCore",
        "ar": "ثاندر كور"
    },
    {
        "en": "Komodo",
        "ar": "كومودو"
    },
    {
        "en": "Dero",
        "ar": "ديرو"
    },
    {
        "en": "Shiden Network",
        "ar": "شيدن نتورك"
    },
    {
        "en": "NULS",
        "ar": "نولس"
    },
    {
        "en": "KardiaChain",
        "ar": "كارديا تشين"
    },
    {
        "en": "XPR Network",
        "ar": "إكس بي آر نتورك"
    },
    {
        "en": "FUSION",
        "ar": "فيوجن"
    },
    {
        "en": "Obyte",
        "ar": "أوبايت"
    },
    {
        "en": "Cypherium",
        "ar": "سيفيريوم"
    },
    {
        "en": "Aeternity",
        "ar": "إيتيرنيتي"
    },
    {
        "en": "Plian",
        "ar": "بليان"
    },
    {
        "en": "Signum",
        "ar": "سيجنوم"
    },
    {
        "en": "Edgeware",
        "ar": "إيدجوير"
    },
    {
        "en": "OneLedger",
        "ar": "ون ليدجر"
    },
    {
        "en": "Raven Protocol",
        "ar": "ريفن بروتوكول"
    },
    {
        "en": "Sandclock",
        "ar": "ساندكلوك"
    },
    {
        "en": "PERL.eco",
        "ar": "بيرل"
    },
    {
        "en": "Naka Bodhi",
        "ar": "ناكا بودي"
    },
    {
        "en": "Yocoin",
        "ar": "يوكوين"
    },
    {
        "en": "XTRABYTES",
        "ar": "إكسترابايتس"
    },
    {
        "en": "Agoras: Currency of Tau",
        "ar": "أغوراس: كيرنسي أوف تاو"
    },
    {
        "en": "RealTract",
        "ar": "ريل تراكت"
    },
    {
        "en": "XDAI",
        "ar": "إكس داي"
    },
    {
        "en": "Wrapped XDC",
        "ar": "رابط إكس دي سي"
    },
    {
        "en": "Wrapped TOMO",
        "ar": "رابط تومو"
    },
    {
        "en": "Wrapped EOS",
        "ar": "رابط إي أو أس"
    },
    {
        "en": "Wrapped Bitrock",
        "ar": "رابط بيتروك"
    },
    {
        "en": "Wrapped Astar",
        "ar": "رابط أستار"
    },
    {
        "en": "WDOT",
        "ar": "دبليو دوت"
    },
    {
        "en": "LUXCoin",
        "ar": "لوكس كوين"
    },
    {
        "en": "Secret (ERC20)",
        "ar": "سيكريت"
    },
    {
        "en": "Binance-Peg EOS",
        "ar": "بينانس بيج إي أو أس"
    },
    {
        "en": "Expanse",
        "ar": "إكسبانس"
    },
    {
        "en": "High Performance Blockchain",
        "ar": "هاي بيرفورمانس بلوكشين"
    },
    {
        "en": "FYDcoin",
        "ar": "فاي دي كوين"
    },
    {
        "en": "Crown",
        "ar": "كراون"
    },
    {
        "en": "TERA",
        "ar": "تيرا"
    },
    {
        "en": "Datamine",
        "ar": "داتاماين"
    },
    {
        "en": "Datamine FLUX",
        "ar": "داتاماين فلوكس"
    },
    {
        "en": "STAKE",
        "ar": "ستاك"
    },
    {
        "en": "Enecuum",
        "ar": "إينيكيوم"
    },
    {
        "en": "MASS",
        "ar": "ماس"
    },
    {
        "en": "Neblio",
        "ar": "نيبليو"
    },
    {
        "en": "Ubiq",
        "ar": "يوبيك"
    },
    {
        "en": "Nebulas",
        "ar": "نيبولاس"
    },
    {
        "en": "Enigma",
        "ar": "إنجما"
    },
    {
        "en": "Hush",
        "ar": "هش"
    },
    {
        "en": "Gather",
        "ar": "جاثر"
    },
    {
        "en": "EOSForce",
        "ar": "إي أو أس فورس"
    },
    {
        "en": "Omni",
        "ar": "أومني"
    },
    {
        "en": "NXT",
        "ar": "إن إكس تي"
    },
    {
        "en": "XYO Network",
        "ar": "إكس واي أو نتورك"
    },
    {
        "en": "Rally",
        "ar": "رالي"
    },
    {
        "en": "Dragonchain",
        "ar": "دراجون تشين"
    },
    {
        "en": "DFX Finance",
        "ar": "دي إف إكس فاينانس"
    },
    {
        "en": "DerivaDAO",
        "ar": "ديريفا داو"
    },
    {
        "en": "Monolith",
        "ar": "مونوليث"
    },
    {
        "en": "Mirror Protocol",
        "ar": "ميرور بروتوكول"
    },
    {
        "en": "NAGA",
        "ar": "ناجا"
    },
    {
        "en": "Kromatika",
        "ar": "كروماتيكا"
    },
    {
        "en": "SALT",
        "ar": "سولت"
    },
    {
        "en": "IHT Real Estate Protocol",
        "ar": "آي إتش تي ريال إستيت بروتوكول"
    },
    {
        "en": "EthereumMax",
        "ar": "إيثيريوم ماكس"
    },
    {
        "en": "PulseChain",
        "ar": "بولس تشين"
    },
    {
        "en": "HEX",
        "ar": "هكس"
    },
    {
        "en": "LBRY Credits",
        "ar": "إل بي آر واي كريديتس"
    },
    {
        "en": "Ethena USDe",
        "ar": "إيثينا يو إس دي"
    },
    {
        "en": "BiLira",
        "ar": "بيليرا"
    },
    {
        "en": "Euro Tether",
        "ar": "يورو تيثر"
    },
    {
        "en": "GHO",
        "ar": "جي إتش أو"
    },
    {
        "en": "DOLA",
        "ar": "دولا"
    },
    {
        "en": "XSGD",
        "ar": "إكس إس جي دي"
    },
    {
        "en": "Celo Dollar",
        "ar": "سيلو دولار"
    },
    {
        "en": "flexUSD",
        "ar": "فليكس يو إس دي"
    },
    {
        "en": "Destablecoin HAY",
        "ar": "هاي"
    },
    {
        "en": "MAI",
        "ar": "ماي"
    },
    {
        "en": "Verified USD",
        "ar": "فيريفايد يو إس دي"
    },
    {
        "en": "agEUR",
        "ar": "أج يور"
    },
    {
        "en": "ZUSD",
        "ar": "زد يو إس دي"
    },
    {
        "en": "dForce USD",
        "ar": "دي فورس يو إس دي"
    },
    {
        "en": "Origin Dollar",
        "ar": "أوريجين دولار"
    },
    {
        "en": "GYEN",
        "ar": "جي ين"
    },
    {
        "en": "Celo Euro",
        "ar": "سيلو يورو"
    },
    {
        "en": "YUSD Stablecoin",
        "ar": "واي يو إس دي ستابل كوين"
    },
    {
        "en": "USDX",
        "ar": "يو إس دي إكس"
    },
    {
        "en": "eUSD (OLD)",
        "ar": "إي يو إس دي"
    },
    {
        "en": "HUSD",
        "ar": "إتش يو إس دي"
    },
    {
        "en": "Bridged Dai (Lightlink)",
        "ar": "بريدجد داي"
    },
    {
        "en": "Bridged USDC (Lightlink)",
        "ar": "بريدجد يو إس دي سي"
    },
    {
        "en": "Bridged USDT (Lightlink)",
        "ar": "بريدجد يو إس دي تي"
    },
    {
        "en": "Binance-Peg BUSD",
        "ar": "بينانس بيج بي يو إس دي"
    },
    {
        "en": "xDollar Stablecoin",
        "ar": "إكس دولار ستابل كوين"
    },
    {
        "en": "Wrapped USTC",
        "ar": " يو إس تي سي مغلف"
    },
    {
        "en": "JPY Coin",
        "ar": "جي بي واي كوين"
    },
    {
        "en": "JPY Coin v1",
        "ar": "جي بي واي كوين فرجن 1"
    },
    {
        "en": "Jarvis Synthetic Swiss Franc",
        "ar": "جارفيس سينثيتيك سويس فرانك"
    },
    {
        "en": "Utopia USD",
        "ar": "يوتوبيا يو إس دي"
    },
    {
        "en": "Monerium EUR emoney",
        "ar": "مونيريوم يورو إي موني"
    },
    {
        "en": "Klaytn Dai",
        "ar": "كلايتن داي"
    },
    {
        "en": "bDollar",
        "ar": "بي دولار"
    },
    {
        "en": "Jarvis Synthetic Euro",
        "ar": "جارفيس سينثيتيك يورو"
    },
    {
        "en": "Qcash",
        "ar": "كيو كاش"
    },
    {
        "en": "Bridged USDC (Polygon PoS Bridge)",
        "ar": "بريدجد يو إس دي سي"
    },
    {
        "en": "CAD Coin",
        "ar": "كاد كوين"
    },
    {
        "en": "Solv Protocol stUSD",
        "ar": "سولف بروتوكول سي تي إي"
    },
    {
        "en": "SORA Synthetic USD",
        "ar": "سورا سينثيتيك يو إس دي"
    },
    {
        "en": "TrueCNH",
        "ar": "ترو سي إن إتش"
    },
    {
        "en": "USDS Classic",
        "ar": "يو إس دي إس كلاسيك"
    },
    {
        "en": "Jarvis Synthetic British Pound",
        "ar": "جارفيس سينثيتيك بريتش باوند"
    },
    {
        "en": "Decentralized USD",
        "ar": "ديسنترالايزد يو إس دي"
    },
    {
        "en": "Synth oUSD",
        "ar": "سينث أو يو إس دي"
    },
    {
        "en": "Iron BSC",
        "ar": "آيرون بي إس سي"
    },
    {
        "en": "Iron",
        "ar": "آيرون"
    },
    {
        "en": "USD Balance",
        "ar": "يو إس دي بالانس"
    },
    {
        "en": "Coin98 Dollar",
        "ar": "كوين 98 دولار"
    },
    {
        "en": "Statik",
        "ar": "ستاتيك"
    },
    {
        "en": "One Cash",
        "ar": "ون كاش"
    },
    {
        "en": "Convertible JPY Token",
        "ar": "جي بي واي توكن"
    },
    {
        "en": "Basis Cash",
        "ar": "بيسيس كاش"
    },
    {
        "en": "BOB",
        "ar": "بوب"
    },
    {
        "en": "USDK",
        "ar": "يو إس دي كيه"
    },
    {
        "en": "Vesta Stable",
        "ar": "فيستا ستابل"
    },
    {
        "en": "XIDR",
        "ar": "إكس إي دي آر"
    },
    {
        "en": "Zasset zUSD",
        "ar": "زي أسيت زد يو إس دي"
    },
    {
        "en": "SpiceUSD",
        "ar": "سبايس يو إس دي"
    },
    {
        "en": "Sperax USD",
        "ar": "سبيراكس يو إس دي"
    },
    {
        "en": "VNX EURO",
        "ar": "في إن إكس يورو"
    },
    {
        "en": "poundtoken",
        "ar": "باوند توكن"
    },
    {
        "en": "sEUR",
        "ar": "سي يور"
    },
    {
        "en": "USDP Stablecoin",
        "ar": "يو إس دي بي ستابل كوين"
    },
    {
        "en": "Parallel",
        "ar": "بارالليل"
    },
    {
        "en": "CNH Tether",
        "ar": "سي إن إتش تيثر"
    },
    {
        "en": "mStable USD",
        "ar": "إم ستابل يو إس دي"
    },
    {
        "en": "Silk",
        "ar": "سيلك"
    },
    {
        "en": "Djed",
        "ar": "جيد"
    },
    {
        "en": "Equilibrium EOSDT",
        "ar": "إكويليبريوم إي أو إس دي تي"
    },
    {
        "en": "Vai",
        "ar": "فاي"
    },
    {
        "en": "USK",
        "ar": "يو إس كيه"
    },
    {
        "en": "0x Protocol",
        "ar": "بروتوكول زيرو إكس"
    },
    {
        "en": "RocketX Exchange",
        "ar": "روكيت إكس تشينج"
    },
    {
        "en": "SaucerSwap",
        "ar": "سوسر سواب"
    },
    {
        "en": "Orion",
        "ar": "أوريون"
    },
    {
        "en": "ParaSwap",
        "ar": "بارا سواب"
    },
    {
        "en": "Beefy",
        "ar": "بيفي"
    },
    {
        "en": "Stafi",
        "ar": "ستافي"
    },
    {
        "en": "RAMP [OLD]",
        "ar": "رامب"
    },
    {
        "en": "Kava Lend",
        "ar": "كافا ليند"
    },
    {
        "en": "Index Coop - ETH 2x Flexible Leverage Index",
        "ar": "إندكس كوب - إيث"
    },
    {
        "en": "UniLend Finance",
        "ar": "يوني ليند فاينانس"
    },
    {
        "en": "DXdao",
        "ar": "دي إكس داو"
    },
    {
        "en": "DeFi Pulse Index",
        "ar": "دي فاي بالس إندكس"
    },
    {
        "en": "Dopex",
        "ar": "دوبيكس"
    },
    {
        "en": "Thales",
        "ar": "ثاليس"
    },
    {
        "en": "Tokemak",
        "ar": "توكيماك"
    },
    {
        "en": "dForce",
        "ar": "دي فورس"
    },
    {
        "en": "Poolz Finance",
        "ar": "بولز فاينانس"
    },
    {
        "en": "Bifrost Native Coin",
        "ar": "بيفروست نايتف كوين"
    },
    {
        "en": "Ellipsis [OLD]",
        "ar": "إيليبسيس"
    },
    {
        "en": "Inverse Finance",
        "ar": "إنفيرس فاينانس"
    },
    {
        "en": "Stake DAO",
        "ar": "ستاك داو"
    },
    {
        "en": "cUNI",
        "ar": "سي يو إن آي"
    },
    {
        "en": "Ferrum Network",
        "ar": "فيرم نتورك"
    },
    {
        "en": "Premia",
        "ar": "بريميا"
    },
    {
        "en": "Carbon Protocol",
        "ar": "كاربون بروتوكول"
    },
    {
        "en": "bZx Protocol",
        "ar": "بي زد إكس بروتوكول"
    },
    {
        "en": "Interest Compounding ETH Index",
        "ar": "إنترست كومباوندينج إيث"
    },
    {
        "en": "SpartaDEX",
        "ar": "سبارتاديكس"
    },
    {
        "en": "Mozaic",
        "ar": "موزايك"
    },
    {
        "en": "sBTC",
        "ar": "سي بي تي سي"
    },
    {
        "en": "Cream",
        "ar": "كريم"
    },
    {
        "en": "ICHI",
        "ar": "إيتش آي"
    },
    {
        "en": "ForTube",
        "ar": "فور تيوب"
    },
    {
        "en": "PowerPool Concentrated Voting Power",
        "ar": "باور بول كونسنتريتد فوتينج باور"
    },
    {
        "en": "Push Protocol",
        "ar": "بوش بروتوكول"
    },
    {
        "en": "Cryptex Finance",
        "ar": "كريبتكس فاينانس"
    },
    {
        "en": "Ferro",
        "ar": "فيرو"
    },
    {
        "en": "Hector Network",
        "ar": "هيكتور نتورك"
    },
    {
        "en": "Rhino.fi",
        "ar": "راينو"
    },
    {
        "en": "NFTX",
        "ar": "إن إف تي إكس"
    },
    {
        "en": "HXRO",
        "ar": "إتش إكس آر أو"
    },
    {
        "en": "AscendEx",
        "ar": "أسند إكس"
    },
    {
        "en": "BitMart",
        "ar": "بيت مارت"
    },
    {
        "en": "BitMEX",
        "ar": "بيت مكس"
    },
    {
        "en": "Coinmetro",
        "ar": "كوين ميترو"
    },
    {
        "en": "SWFTCOIN",
        "ar": "سويفت كوين"
    },
    {
        "en": "OpenOcean",
        "ar": "أوبن أوشن"
    },
    {
        "en": "Gameswap",
        "ar": "جيم سواب"
    },
    {
        "en": "Nash",
        "ar": "ناش"
    },
    {
        "en": "Mettalex",
        "ar": "ميتاليكس"
    },
    {
        "en": "BitKan",
        "ar": "بيت كان"
    },
    {
        "en": "LuaSwap",
        "ar": "لوا سواب"
    },
    {
        "en": "dHEDGE DAO",
        "ar": "دي هيدج داو"
    },
    {
        "en": "LocalCoinSwap",
        "ar": "لوكال كوين سواب"
    },
    {
        "en": "LA",
        "ar": "لا"
    },
    {
        "en": "TigerCash",
        "ar": "تايجر كاش"
    },
    {
        "en": "Jarvis Reward",
        "ar": "جارفيس ريوارد"
    },
    {
        "en": "Delta Exchange",
        "ar": "دلتا إكسشينج"
    },
    {
        "en": "OceanEX",
        "ar": "أوشن إكس"
    },
    {
        "en": "Probit",
        "ar": "بروبيت"
    },
    {
        "en": "SpiritSwap",
        "ar": "سبيريت سواب"
    },
    {
        "en": "FlowX Finance",
        "ar": "فلو إكس فاينانس"
    },
    {
        "en": "Nominex",
        "ar": "نومينكس"
    },
    {
        "en": "Belt",
        "ar": "بيلت"
    },
    {
        "en": "UniLayer",
        "ar": "يوني لاير"
    },
    {
        "en": "Unicly",
        "ar": "يونيكلي"
    },
    {
        "en": "Zipmex",
        "ar": "زيبمكس"
    },
    {
        "en": "Futureswap",
        "ar": "فيوتشر سواب"
    },
    {
        "en": "DexKit",
        "ar": "ديكس كيت"
    },
    {
        "en": "Sashimi",
        "ar": "ساشيمي"
    },
    {
        "en": "Leverj Gluon",
        "ar": "ليفرج جلون"
    },
    {
        "en": "Tidex",
        "ar": "تيديكس"
    },
    {
        "en": "FlypMe",
        "ar": "فلايب مي"
    },
    {
        "en": "Birake",
        "ar": "بيراك"
    },
    {
        "en": "Bibox",
        "ar": "بيبوكس"
    },
    {
        "en": "ACryptoSI",
        "ar": "أي كريبتو إس آي"
    },
    {
        "en": "0.exchange",
        "ar": "0 إكسشينج"
    },
    {
        "en": "PowerTrade Fuel",
        "ar": "باور تريد فويل"
    },
    {
        "en": "CoFiX",
        "ar": "كوفي إكس"
    },
    {
        "en": "Oikos",
        "ar": "أويكوس"
    },
    {
        "en": "AstroSwap",
        "ar": "أسترو سواب"
    },
    {
        "en": "Falconswap",
        "ar": "فالكون سواب"
    },
    {
        "en": "BUX",
        "ar": "بوكس"
    },
    {
        "en": "EXMO Coin",
        "ar": "إكسمو كوين"
    },
    {
        "en": "ApeSwap",
        "ar": "أيب سواب"
    },
    {
        "en": "Ardana",
        "ar": "أردانا"
    },
    {
        "en": "WaultSwap",
        "ar": "وولت سواب"
    },
    {
        "en": "Snowball",
        "ar": "سنوبول"
    },
    {
        "en": "Worldcore",
        "ar": "وورلد كور"
    },
    {
        "en": "Catex",
        "ar": "كاتكس"
    },
    {
        "en": "ApeBond",
        "ar": "أيب بوند"
    },
    {
        "en": "OKX Staked DOT2",
        "ar": "أوكيكس ستاكد دوت"
    },
    {
        "en": "One",
        "ar": "ون"
    },
    {
        "en": "Bitpanda Ecosystem",
        "ar": "بيتباندا إيكوسيستم"
    },
    {
        "en": "BNS",
        "ar": "بي إن إس"
    },
    {
        "en": "BITO Coin",
        "ar": "بيتو كوين"
    },
    {
        "en": "Phemex Token",
        "ar": "فيمكس توكن"
    },
    {
        "en": "Minswap",
        "ar": "مين سواب"
    },
    {
        "en": "BSCEX",
        "ar": "بي إس سي إكس"
    },
    {
        "en": "MAX",
        "ar": "ماكس"
    },
    {
        "en": "Bitforex",
        "ar": "بيت فوركس"
    },
    {
        "en": "ChangeNOW",
        "ar": "تشينج ناو"
    },
    {
        "en": "INFLIV",
        "ar": "إنفليف"
    },
    {
        "en": "DigiFinex",
        "ar": "ديجي فنكس"
    },
    {
        "en": "Digitex",
        "ar": "ديجيتكس"
    },
    {
        "en": "Bankera",
        "ar": "بانكيرا"
    },
    {
        "en": "AnimeSwap",
        "ar": "أنيمي سواب"
    },
    {
        "en": "ViteX Coin",
        "ar": "فيتكس كوين"
    },
    {
        "en": "Zaif",
        "ar": "زيف"
    },
    {
        "en": "UZXCoin",
        "ar": "يو زد إكس كوين"
    },
    {
        "en": "VinDax Coin",
        "ar": "فين داكس كوين"
    },
    {
        "en": "Bitcointry Token",
        "ar": "بيتكوين تراي توكن"
    },
    {
        "en": "Tarmex",
        "ar": "تارمكس"
    },
    {
        "en": "Tarmex [OLD]",
        "ar": "تارمكس [OLD]"

    },
    {
        "en": "Alchemy Pay",
        "ar": "ألكيمي باي"
    },
    {
        "en": "World Mobile Token",
        "ar": "وورلد موبايل توكن"
    },
    {
        "en": "Marblex",
        "ar": "ماربلكس"
    },
    {
        "en": "Unizen",
        "ar": "يونيزن"
    },
    {
        "en": "ARPA",
        "ar": "آربا"
    },
    {
        "en": "Zignaly",
        "ar": "زيجنالي"
    },
    {
        "en": "CEEK Smart VR",
        "ar": "سيك سمارت فر"
    },
    {
        "en": "Sentinel Protocol",
        "ar": "سينتينل بروتوكول"
    },
    {
        "en": "Velo",
        "ar": "فيلو"
    },
    {
        "en": "WeWay",
        "ar": "وي واي"
    },
    {
        "en": "SOLVE",
        "ar": "سولف"
    },
    {
        "en": "GensoKishi Metaverse",
        "ar": "جينسوكيشي ميتافيرس"
    },
    {
        "en": "Unmarshal",
        "ar": "أنمارشال"
    },
    {
        "en": "Sinverse",
        "ar": "سينفيرس"
    },
    {
        "en": "MARS4",
        "ar": "مارس 4"
    },
    {
        "en": "BOSagora",
        "ar": "بوساجورا"
    },
    {
        "en": "BreederDAO",
        "ar": "بريدر داو"
    },
    {
        "en": "Lattice",
        "ar": "لاتيس"
    },
    {
        "en": "Adappter",
        "ar": "أدابتر"
    },
    {
        "en": "Walken",
        "ar": "والكن"
    },
    {
        "en": "Exorde",
        "ar": "إكسورد"
    },
    {
        "en": "Mineral",
        "ar": "مينيرال"
    },
    {
        "en": "Solcial",
        "ar": "سولسيال"
    },
    {
        "en": "Parex",
        "ar": "باركس"
    },
    {
        "en": "LayerAI",
        "ar": "لير أي"
    },
    {
        "en": "Polylastic",
        "ar": "بوليلاستيك"
    },
    {
        "en": "Uno Re",
        "ar": "أونو ري"
    },
    {
        "en": "LABEL Foundation",
        "ar": "لابل فاونديشن"
    },
    {
        "en": "Bitune",
        "ar": "بيتون"
    },
    {
        "en": "apM Coin",
        "ar": "أي بي إم كوين"
    },
    {
        "en": "Reality Metaverse",
        "ar": "رياليتي ميتافيرس"
    },
    {
        "en": "PUMLx",
        "ar": "بوملكس"
    },
    {
        "en": "Kunji Finance",
        "ar": "كونجي فاينانس"
    },
    {
        "en": "ClearDAO",
        "ar": "كلير داو"
    },
    {
        "en": "EpiK Protocol",
        "ar": "إيبيك بروتوكول"
    },
    {
        "en": "NFTY",
        "ar": "إن إف تي واي"
    },
    {
        "en": "Hot Cross",
        "ar": "هوت كروس"
    },
    {
        "en": "Tokoin",
        "ar": "توكوين"
    },
    {
        "en": "Okratech",
        "ar": "أوكراتيك"
    },
    {
        "en": "LABSV2",
        "ar": "لابس فرجن 2"
    },
    {
        "en": "SO-COL",
        "ar": "سو كول"
    },
    {
        "en": "impactMarket",
        "ar": "إمباكت ماركت"
    },
    {
        "en": "Corite",
        "ar": "كوريت"
    },
    {
        "en": "Throne",
        "ar": "ثرون"
    },
    {
        "en": "Onston",
        "ar": "أونستون"
    },
    {
        "en": "Metatime Coin",
        "ar": "ميتاتايم كوين"
    },
    {
        "en": "Metal Blockchain",
        "ar": "ميتال بلوكشين"
    },
    {
        "en": "Sprint",
        "ar": "سبرينت"
    },
    {
        "en": "Nestree",
        "ar": "نيستري"
    },
    {
        "en": "OpenLeverage",
        "ar": "أوبن ليفريج"
    },
    {
        "en": "Realy Metaverse",
        "ar": "ريالي ميتافيرس"
    },
    {
        "en": "Roseon",
        "ar": "روزيون"
    },
    {
        "en": "SENSO",
        "ar": "سينسو"
    },
    {
        "en": "Zone of Avoidance",
        "ar": "زون أوف أفويدانس"
    },
    {
        "en": "Shimmer",
        "ar": "شيمر"
    },
    {
        "en": "Berry",
        "ar": "بيري"
    },
    {
        "en": "Bio Passport",
        "ar": "بيو باسبورت"
    },
    {
        "en": "XDAO",
        "ar": "إكس داو"
    },
    {
        "en": "Wrapped NCG",
        "ar": "إن سي جي مغلف"
    },
    {
        "en": "Caduceus",
        "ar": "كادوسيوس"
    },
    {
        "en": "Deliq",
        "ar": "ديليك"
    },
    {
        "en": "DIVA Protocol",
        "ar": "ديفا بروتوكول"
    },
    {
        "en": "Dreams Quest",
        "ar": "دريمز كويست"
    },
    {
        "en": "Vixco",
        "ar": "فيكسكو"
    },
    {
        "en": "fanC",
        "ar": "فان سي"
    },
    {
        "en": "Fantaverse",
        "ar": "فانتافيرس"
    },
    {
        "en": "Fanzee Token",
        "ar": "فانزي توكن"
    },
    {
        "en": "XGLI DAO Protocol",
        "ar": "إكس جي إل آي داو بروتوكول"
    },
    {
        "en": "GOAL Token",
        "ar": "جول توكن"
    },
    {
        "en": "Highstreet",
        "ar": "هاي ستريت"
    },
    {
        "en": "TopGoal",
        "ar": "توب جول"
    },
    {
        "en": "KonPay",
        "ar": "كون باي"
    },
    {
        "en": "Mars Token",
        "ar": "مارس توكن"
    },
    {
        "en": "Tap Fantasy",
        "ar": "تاب فانتسي"
    },
    {
        "en": "Stader MaticX",
        "ar": "ستادر ماتيكس"
    },
    {
        "en": "Ankr Staked ETH",
        "ar": "أنكر ستاكد إيث"
    },
    {
        "en": "Stride Staked Atom",
        "ar": "سترايد ستاكد أتوم"
    },
    {
        "en": "Stride Staked Osmo",
        "ar": "سترايد ستاكد أوسمو"
    },
    {
        "en": "Sanctum Staked SOL",
        "ar": "سانكتوم ستاكد سول"
    },
    {
        "en": "Bridge Oracle",
        "ar": "بريدج أوراكل"
    },
    {
        "en": "rETH2",
        "ar": "آر إي تي إتش 2"
    },
    {
        "en": "Stride Staked Stars",
        "ar": "سترايد ستاكد ستارز"
    },
    {
        "en": "Diversified Staked ETH",
        "ar": "دايفرسيفايد ستاكد إيث"
    },
    {
        "en": "Stride Staked Juno",
        "ar": "سترايد ستاكد جونو"
    },
    {
        "en": "Stride Staked Injective",
        "ar": "سترايد ستاكد إنجكتيف"
    },
    {
        "en": "Stride Staked Luna",
        "ar": "سترايد ستاكد لونا"
    },
    {
        "en": "Monsterra MAG",
        "ar": "مونستيرا ماج"
    },
    {
        "en": "pSTAKE Staked BNB",
        "ar": "بي ستاك ستاكد بي إن بي"
    },
    {
        "en": "Quark Protocol Staked KUJI",
        "ar": "كوارك بروتوكول ستاكد كوجي"
    },
    {
        "en": "Quark Protocol Staked MNTA",
        "ar": "كوارك بروتوكول ستاكد إم إن تي إيه"
    },
    {
        "en": "StaFi Staked ETH",
        "ar": "ستافي ستاكد إيث"
    },
    {
        "en": "SafeStake",
        "ar": "سيف ستاك"
    },
    {
        "en": "StaFi Staked BNB",
        "ar": "ستافي ستاكد بي إن بي"
    },
    {
        "en": "StaFi Staked MATIC",
        "ar": "ستافي ستاكد ماتيك"
    },
    {
        "en": "Staked Ethos Reserve Note",
        "ar": "ستاكد إيثوس ريسيرف نوت"
    },
    {
        "en": "Wrapped Staked Tether",
        "ar": "ستاكد تيثر مغلف"
    },
    {
        "en": "Amnis Staked Aptos Coin",
        "ar": "أمنيس ستاكد أبتوس كوين"
    },
    {
        "en": "Amulet Staked SOL",
        "ar": "أموليت ستاكد سول"
    },
    {
        "en": "Ankr Staked FTM",
        "ar": "أنكر ستاكد إف تي إم"
    },
    {
        "en": "Astrid Restaked cbETH",
        "ar": "أستريد ريستاكد سي بي إي تش"
    },
    {
        "en": "pSTAKE Staked OSMO",
        "ar": "بي ستاك ستاكد أوسمو"
    },
    {
        "en": "ClayStack Staked ETH",
        "ar": "كلاي ستاك ستاكد إيث"
    },
    {
        "en": "StaFi Staked SOL",
        "ar": "ستافي ستاكد سول"
    },
    {
        "en": "Dinero Staked ETH",
        "ar": "دينيرو ستاكد إيث"
    },
    {
        "en": "ether.fi Staked ETH",
        "ar": "إيثر فاي ستاكد إيث"
    },
    {
        "en": "Orchai Protocol Staked Compound ATOM",
        "ar": "أورشاي بروتوكول ستاكد كومباوند أتوم"
    },
    {
        "en": "Staked Thala APT",
        "ar": "ستاكد ثالا أبت"
    },
    {
        "en": "Astrid Restaked rETH",
        "ar": "أستريد ريستاكد آر إي تش"
    },
    {
        "en": "Stride Staked DYDX",
        "ar": "سترايد ستاكد داي داي إكس"
    },
    {
        "en": "Staked Aurora",
        "ar": "ستاكد أورورا"
    },
    {
        "en": "Staked USDT",
        "ar": "ستاكد يو إس دي تي"
    },
    {
        "en": "Staked TRX",
        "ar": "ستاكد تي آر إكس"
    },
    {
        "en": "Staked TLOS",
        "ar": "ستاكد تي لوس"
    },
    {
        "en": "Staked NEAR",
        "ar": "ستاكد نير"
    },
    {
        "en": "Staked KCS",
        "ar": "ستاكد كي سي إس"
    },
    {
        "en": "Snek",
        "ar": "سنيك"
    },
    {
        "en": "Ethernity Chain",
        "ar": "إيثرنيتي تشين"
    },
    {
        "en": "Hathor",
        "ar": "هاثور"
    },
    {
        "en": "AsMatch",
        "ar": "أس ماتش"
    },
    {
        "en": "Wilder World",
        "ar": "وايلدر ورلد"
    },
    {
        "en": "My Neighbor Alice",
        "ar": "ماي نيبور أليس"
    },
    {
        "en": "Origin Protocol",
        "ar": "أوريجين بروتوكول"
    },
    {
        "en": "Mobox",
        "ar": "موبوكس"
    },
    {
        "en": "Artificial Liquid Intelligence",
        "ar": "أرتيفيشال ليكويد إنتيليجنس"
    },
    {
        "en": "Ultra",
        "ar": "ألترا"
    },
    {
        "en": "SuperRare",
        "ar": "سوبر رير"
    },
    {
        "en": "DEAPCOIN",
        "ar": "ديب كوين"
    },
    {
        "en": "Alien Worlds",
        "ar": "ألين ورلدز"
    },
    {
        "en": "COMBO",
        "ar": "كومبو"
    },
    {
        "en": "Aavegotchi",
        "ar": "أف جوتشي"
    },
    {
        "en": "Games for a Living",
        "ar": "جيمز فور أ ليفينج"
    },
    {
        "en": "Contentos",
        "ar": "كونتنتوس"
    },
    {
        "en": "Altura",
        "ar": "ألتورا"
    },
    {
        "en": "DeRace",
        "ar": "دي ريس"
    },
    {
        "en": "Handshake",
        "ar": "هاند شيك"
    },
    {
        "en": "RFOX",
        "ar": "آر فوكس"
    },
    {
        "en": "Boson Protocol",
        "ar": "بوسون بروتوكول"
    },
    {
        "en": "RMRK",
        "ar": "آر إم آر كيه"
    },
    {
        "en": "Decentral Games",
        "ar": "ديسنترال جيمز"
    },
    {
        "en": "GAMEE",
        "ar": "جامي"
    },
    {
        "en": "BurgerCities",
        "ar": "برجر سيتيز"
    },
    {
        "en": "Wombat",
        "ar": "وومبات"
    },
    {
        "en": "Muse DAO",
        "ar": "ميوز داو"
    },
    {
        "en": "Dego Finance",
        "ar": "ديجو فاينانس"
    },
    {
        "en": "GET Protocol",
        "ar": "جي إي تي بروتوكول"
    },
    {
        "en": "Unisocks",
        "ar": "يوني سوكس"
    },
    {
        "en": "Ridotto",
        "ar": "ريدوتو"
    },
    {
        "en": "Splintershards",
        "ar": "سبلينتر شاردز"
    },
    {
        "en": "Genopets",
        "ar": "جينوبيتس"
    },
    {
        "en": "Raini",
        "ar": "رايني"
    },
    {
        "en": "Decentral Games Governance",
        "ar": "ديسنترال جيمز جوفرنانس"
    },
    {
        "en": "Spheroid Universe",
        "ar": "سفيرويد يونيفيرس"
    },
    {
        "en": "AssetMantle",
        "ar": "أسيت مانتل"
    },
    {
        "en": "NFTb",
        "ar": "إن إف تي بي"
    },
    {
        "en": "Catgirl",
        "ar": "كات جيرل"
    },
    {
        "en": "Moonsama",
        "ar": "مونساما"
    },
    {
        "en": "Kitty Inu",
        "ar": "كيتي إنو"
    },
    {
        "en": "Dark Frontiers",
        "ar": "دارك فرونتيرز"
    },
    {
        "en": "Banana",
        "ar": "بانانا"
    },
    {
        "en": "MagicCraft",
        "ar": "ماجيك كرافت"
    },
    {
        "en": "Netvrk",
        "ar": "نيتفرك"
    },
    {
        "en": "Dope Wars Paper",
        "ar": "دوب وارز بيبر"
    },
    {
        "en": "WHALE",
        "ar": "ويل"
    },
    {
        "en": "KONDUX",
        "ar": "كوندكس"
    },
    {
        "en": "The Unfettered Ecosystem",
        "ar": "ذا أنفيترد إيكوسيستم"
    },
    {
        "en": "Exeedme",
        "ar": "إكسيد مي"
    },
    {
        "en": "Dypius [OLD]",
        "ar": "ديبيوس"
    },
    {
        "en": "Non-Playable Coin",
        "ar": "نون بلايبل كوين"
    },
    {
        "en": "Fable Of The Dragon",
        "ar": "فابل أوف ذا دراجون"
    },
    {
        "en": "BendDAO",
        "ar": "بند داو"
    },
    {
        "en": "BlueMove",
        "ar": "بلو موف"
    },
    {
        "en": "DSLA Protocol",
        "ar": "دي إس إل إيه بروتوكول"
    },
    {
        "en": "Polkacity",
        "ar": "بولكا سيتي"
    },
    {
        "en": "RealFevr",
        "ar": "ريال فيفر"
    },
    {
        "en": "Warped Games",
        "ar": "واربد جيمز"
    },
    {
        "en": "Winkies",
        "ar": "وينكيز"
    },
    {
        "en": "Paint Swap",
        "ar": "بينت سواب"
    },
    {
        "en": "AngelBlock",
        "ar": "أنجل بلوك"
    },
    {
        "en": "Aimedis (NEW)",
        "ar": "أيميديس"
    },
    {
        "en": "Atlas Navi",
        "ar": "أطلس نافي"
    },
    {
        "en": "Chain Guardians",
        "ar": "شين جارديانز"
    },
    {
        "en": "Morra",
        "ar": "مورا"
    },
    {
        "en": "Rainbow Token",
        "ar": "رينبو توكن"
    },
    {
        "en": "aiRight",
        "ar": "أي رايت"
    },
    {
        "en": "Seascape Crowns",
        "ar": "سيسكيب كرونز"
    },
    {
        "en": "HeroesChained",
        "ar": "هيروز تشايند"
    },
    {
        "en": "Niftyx Protocol",
        "ar": "نيفتيكس بروتوكول"
    },
    {
        "en": "Forj",
        "ar": "فورج"
    },
    {
        "en": "BSCS",
        "ar": "بي إس سي إس"
    },
    {
        "en": "Pegaxy",
        "ar": "بيجاكسي"
    },
    {
        "en": "Polker",
        "ar": "بولكر"
    },
    {
        "en": "MCH Coin",
        "ar": "إم سي إتش كوين"
    },
    {
        "en": "Juggernaut",
        "ar": "جاجرنوت"
    },
    {
        "en": "Pastel",
        "ar": "باستيل"
    },
    {
        "en": "STEPN Green Satoshi Token on ETH",
        "ar": "ستيبن جرين ساتوشي توكن"
    },
    {
        "en": "Dypius",
        "ar": "ديبيوس"
    },
    {
        "en": "Realm",
        "ar": "ريالم"
    },
    {
        "en": "Vidya",
        "ar": "فيديا"
    },
    {
        "en": "DinoX",
        "ar": "دينو إكس"
    },
    {
        "en": "Materium",
        "ar": "ماتيريوم"
    },
    {
        "en": "Gaia Everworld",
        "ar": "جايا إيفر ورلد"
    },
    {
        "en": "Fief",
        "ar": "فيف"
    },
    {
        "en": "K21",
        "ar": "كي 21"
    },
    {
        "en": "Hello Pets",
        "ar": "هيلو بيتس"
    },
    {
        "en": "Bullieverse",
        "ar": "بوليفيرس"
    },
    {
        "en": "Polychain Monsters",
        "ar": "بوليتشاين مونسترز"
    },
    {
        "en": "MurAll",
        "ar": "مور أول"
    },
    {
        "en": "XMON",
        "ar": "إكس مون"
    },
    {
        "en": "Alfa Romeo Racing ORLEN Fan Token",
        "ar": "ألفا روميو ريسينج أورلين فان توكن"
    },
    {
        "en": "The Cocktailbar",
        "ar": "ذا كوكتيل بار"
    },
    {
        "en": "Monavale",
        "ar": "مونافال"
    },
    {
        "en": "FEAR",
        "ar": "فير"
    },
    {
        "en": "Tower",
        "ar": "تاور"
    },
    {
        "en": "VEMP",
        "ar": "فيمب"
    },
    {
        "en": "Goons of Balatroon",
        "ar": "جونز أوف بالاترون"
    },
    {
        "en": "My DeFi Pet",
        "ar": "ماي ديفي بيت"
    },
    {
        "en": "ZooKeeper",
        "ar": "زو كيبر"
    },
    {
        "en": "PRIMAL",
        "ar": "برايمال"
    },
    {
        "en": "Revomon",
        "ar": "ريفومون"
    },
    {
        "en": "BlackPool",
        "ar": "بلاك بول"
    },
    {
        "en": "Charged Particles",
        "ar": "تشارجد بارتيكلز"
    },
    {
        "en": "Chappyz",
        "ar": "تشابيز"
    },
    {
        "en": "Derby Stars RUN",
        "ar": "ديربي ستارز رن"
    },
    {
        "en": "STEPN Green Satoshi Token on BSC",
        "ar": "ستيبن جرين ساتوشي توكن"
    },
    {
        "en": "Etherland",
        "ar": "إيثرلاند"
    },
    {
        "en": "Avocado DAO",
        "ar": "أفوكادو داو"
    },
    {
        "en": "FaraLand",
        "ar": "فارا لاند"
    },
    {
        "en": "Guarded Ether",
        "ar": "جاردد إيثر"
    },
    {
        "en": "Binance ETH staking",
        "ar": "بينانس إيث ستاكينج"
    },
    {
        "en": "DappRadar",
        "ar": "داب رادار"
    },
    {
        "en": "Ambire Wallet",
        "ar": "أمبير واليت"
    },
    {
        "en": "Astra DAO",
        "ar": "أسترا داو"
    },
    {
        "en": "zkSwap Finance",
        "ar": "زد كيه سواب فاينانس"
    },
    {
        "en": "OATH",
        "ar": "أوث"
    },
    {
        "en": "Hord",
        "ar": "هورد"
    },
    {
        "en": "MahaDAO",
        "ar": "ماها داو"
    },
    {
        "en": "Horizon Protocol",
        "ar": "هورايزون بروتوكول"
    },
    {
        "en": "xFUND",
        "ar": "إكس فند"
    },
    {
        "en": "Onooks",
        "ar": "أونوكس"
    },
    {
        "en": "Granary",
        "ar": "جراناري"
    },
    {
        "en": "Enreach",
        "ar": "إنريتش"
    },
    {
        "en": "Collab.Land",
        "ar": "كولاب لاند"
    },
    {
        "en": "Plebbit",
        "ar": "بليبيت"
    },
    {
        "en": "Tomb Shares",
        "ar": "تومب شيرز"
    },
    {
        "en": "Opium",
        "ar": "أوبيوم"
    },
    {
        "en": "Paladin",
        "ar": "بالادين"
    },
    {
        "en": "mStable Governance: Meta",
        "ar": "مستابل جوفرنانس: ميتا"
    },
    {
        "en": "PoolTogether",
        "ar": "بول توجيذر"
    },
    {
        "en": "YfDAI.finance",
        "ar": "واي إف داي إيه فاينانس"
    },
    {
        "en": "Sifchain",
        "ar": "سيف تشاين"
    },
    {
        "en": "DePay",
        "ar": "دي باي"
    },
    {
        "en": "Plasma Finance",
        "ar": "بلازما فاينانس"
    },
    {
        "en": "YAM",
        "ar": "يام"
    },
    {
        "en": "Ethereans",
        "ar": "إيثيريانز"
    },
    {
        "en": "MBD Financials",
        "ar": "إم بي دي فاينانشيالز"
    },
    {
        "en": "Pillar",
        "ar": "بيلار"
    },
    {
        "en": "IDLE",
        "ar": "آي ديل"
    },
    {
        "en": "Pickle Finance",
        "ar": "بيكل فاينانس"
    },
    {
        "en": "Fortune Token",
        "ar": "فورتشن توكن"
    },
    {
        "en": "DAOhaus",
        "ar": "داو هاوس"
    },
    {
        "en": "Unit Protocol",
        "ar": "يونيت بروتوكول"
    },
    {
        "en": "unFederalReserve",
        "ar": "أن فيدرال ريسيرف"
    },
    {
        "en": "Atlas DEX",
        "ar": "أطلس دي إي إكس"
    },
    {
        "en": "NFT Protocol",
        "ar": "إن إف تي بروتوكول"
    },
    {
        "en": "Phuture",
        "ar": "فيوتشر"
    },
    {
        "en": "Pacoca",
        "ar": "باكوكا"
    },
    {
        "en": "Swerve",
        "ar": "سويرف"
    },
    {
        "en": "YF Link",
        "ar": "واي إف لينك"
    },
    {
        "en": "BlackHat Coin",
        "ar": "بلاك هات كوين"
    },
    {
        "en": "Chain of Legends",
        "ar": "شين أوف ليجندز"
    },
    {
        "en": "Raft",
        "ar": "رافت"
    },
    {
        "en": "PieDAO DOUGH v2",
        "ar": "بي داو دوه فرجن 2"
    },
    {
        "en": "Centric Swap",
        "ar": "سنتريك سواب"
    },
    {
        "en": "Filda",
        "ar": "فيلدا"
    },
    {
        "en": "WanSwap [OLD]",
        "ar": "وان سواب"
    },
    {
        "en": "Hakka Finance",
        "ar": "هاكا فاينانس"
    },
    {
        "en": "GamyFi",
        "ar": "جامي فاي"
    },
    {
        "en": "Governor DAO",
        "ar": "جوفرنر داو"
    },
    {
        "en": "OctoFi",
        "ar": "أوكتو فاي"
    },
    {
        "en": "Mochi Market",
        "ar": "موشي ماركت"
    },
    {
        "en": "Delphi",
        "ar": "دلفي"
    },
    {
        "en": "Metacourt",
        "ar": "ميتاكورت"
    },
    {
        "en": "Indexed Finance",
        "ar": "إندكسد فاينانس"
    },
    {
        "en": "Unique One",
        "ar": "يونيك ون"
    },
    {
        "en": "OPEN Governance",
        "ar": "أوبن جوفرنانس"
    },
    {
        "en": "Flurry Finance",
        "ar": "فلوري فاينانس"
    },
    {
        "en": "dfohub",
        "ar": "دي إف أو هاب"
    },
    {
        "en": "WanSwap",
        "ar": "وان سواب"
    },
    {
        "en": "cVault.finance",
        "ar": "سي فولت فاينانس"
    },
    {
        "en": "XDEFI Governance",
        "ar": "إكس ديفي جوفرنانس"
    },
    {
        "en": "S.Finance",
        "ar": "إس فاينانس"
    },
    {
        "en": "GNOME",
        "ar": "جنوم"
    },
    {
        "en": "Governance OHM",
        "ar": "جوفرنانس أوهم"
    },
    {
        "en": "Orkan",
        "ar": "أوركان"
    },
    {
        "en": "Bridged Uniswap (Lightlink)",
        "ar": "يوني سواب معبر"
    },
    {
        "en": "analoS",
        "ar": "أنالوس"
    },
    {
        "en": "Kizuna",
        "ar": "كيزونا"
    },
    {
        "en": "Hoge Finance",
        "ar": "هوج فاينانس"
    },
    {
        "en": "Pikaboss",
        "ar": "بيكابوس"
    },
    {
        "en": "Banano",
        "ar": "بانانو"
    },
    {
        "en": "Kimbo",
        "ar": "كيمبو"
    },
    {
        "en": "SquidGrow",
        "ar": "سكويد جرو"
    },
    {
        "en": "CumRocket",
        "ar": "كوم روكيت"
    },
    {
        "en": "El Hippo",
        "ar": "إل هيبو"
    },
    {
        "en": "Multichain",
        "ar": "مالتي تشاين"
    },
    {
        "en": "Omax",
        "ar": "أوماكس"
    },
    {
        "en": "Moon Tropica",
        "ar": "مون تروبيكا"
    },
    {
        "en": "White Rhinoceros",
        "ar": "وايت راينوسيروس"
    },
    {
        "en": "AltLayer",
        "ar": "ألت لاير"
    },
    {
        "en": "ThetaDrop",
        "ar": "ثيتا دروب"
    },
    {
        "en": "Swarm Markets",
        "ar": "سوارم ماركتس"
    },
    {
        "en": "Dora Factory",
        "ar": "دورا فاكتوري"
    },
    {
        "en": "Wen",
        "ar": "وين"
    },
    {
        "en": "AIT Protocol",
        "ar": "أي تي بروتوكول"
    },
    {
        "en": "SX Network",
        "ar": "إس إكس نتويرك"
    },
    {
        "en": "MAGA",
        "ar": "ماجا"
    },
    {
        "en": "Railgun",
        "ar": "ريل جان"
    },
    {
        "en": "PolySwarm",
        "ar": "بولي سوارم"
    },
    {
        "en": "Router Protocol",
        "ar": "روتر بروتوكول"
    },
    {
        "en": "Chainge",
        "ar": "شينج"
    },
    {
        "en": "DogeBonk",
        "ar": "دوج بونك"
    },
    {
        "en": "BobaCat",
        "ar": "بوبا كات"
    },
    {
        "en": "Sacabam",
        "ar": "ساكابام"
    },
    {
        "en": "DogeGF",
        "ar": "دوج جي إف"
    },
    {
        "en": "KiboShib",
        "ar": "كيبو شيب"
    },
    {
        "en": "Illumicati",
        "ar": "إلوميكاتي"
    },
    {
        "en": "Pepe Token",
        "ar": "بيبي توكن"
    },
    {
        "en": "Cheems Inu [NEW]",
        "ar": "تشيمز إنو"
    },
    {
        "en": "WallStreetBets DApp",
        "ar": "وول ستريت بيتس داب"
    },
    {
        "en": "KUMA",
        "ar": "كوما"
    },
    {
        "en": "Dingocoin",
        "ar": "دينجو كوين"
    },
    {
        "en": "McPepe's",
        "ar": "ماك بيبيز"
    },
    {
        "en": "ElmoERC",
        "ar": "إلمو إي آر سي"
    },
    {
        "en": "SafeMoon Inu",
        "ar": "سيف مون إنو"
    },
    {
        "en": "Ignore Fud",
        "ar": "إيغنور فود"
    },
    {
        "en": "Risitas",
        "ar": "ريسيتاس"
    },
    {
        "en": "ElonDoge.io",
        "ar": "إيلون دوج"
    },
    {
        "en": "Cake Monster",
        "ar": "كيك مونستر"
    },
    {
        "en": "Kuma Inu",
        "ar": "كوما إنو"
    },
    {
        "en": "Oggy Inu [ETH]",
        "ar": "أوجي إنو"
    },
    {
        "en": "Shibavax",
        "ar": "شيبافاكس"
    },
    {
        "en": "Shih Tzu",
        "ar": "شيه تزو"
    },
    {
        "en": "FourCoin",
        "ar": "فور كوين"
    },
    {
        "en": "HarryPotterTrumpHomerSimpson777Inu",
        "ar": "هاري بوتر ترامب هومر سيمبسون إنو"
    },
    {
        "en": "Magicaltux",
        "ar": "ماجيكال توكس"
    },
    {
        "en": "Sharbi",
        "ar": "شاربي"
    },
    {
        "en": "Poodl",
        "ar": "بودل"
    },
    {
        "en": "AVAX HAS NO CHILL",
        "ar": "أفاكس هاس نو تشيل"
    },
    {
        "en": "BananaCat",
        "ar": "بانانا كات"
    },
    {
        "en": "Cramer Coin",
        "ar": "كرامر كوين"
    },
    {
        "en": "Baby Shiba Inu",
        "ar": "بيبي شيبا إنو"
    },
    {
        "en": "Shib Original Vision",
        "ar": "شيب أوريجينال فيجن"
    },
    {
        "en": "Mooncat CAT",
        "ar": "مون كات"
    },
    {
        "en": "GATEWAY TO MARS",
        "ar": "جيتواي تو مارس"
    },
    {
        "en": "DeCats",
        "ar": "دي كاتس"
    },
    {
        "en": "All Your Base",
        "ar": "أول يور بيس"
    },
    {
        "en": "Froggies",
        "ar": "فروجيز"
    },
    {
        "en": "CorgiCoin",
        "ar": "كورجي كوين"
    },
    {
        "en": "Cat",
        "ar": "كات"
    },
    {
        "en": "Chad Coin",
        "ar": "تشاد كوين"
    },
    {
        "en": "Kangal",
        "ar": "كانجال"
    },
    {
        "en": "Duckereum",
        "ar": "داكيريوم"
    },
    {
        "en": "ZilPepe",
        "ar": "زيل بيبي"
    },
    {
        "en": "Pepemon Pepeballs",
        "ar": "بيبيمون بيبيبولز"
    },
    {
        "en": "Kitty Coin Solana",
        "ar": "كيتي كوين سولانا"
    },
    {
        "en": "Catbonk",
        "ar": "كات بونك"
    },
    {
        "en": "DYOR",
        "ar": "ديور"
    },
    {
        "en": "NicCageWaluigiElmo42069Inu",
        "ar": "نيك كاج والويجي إلمو إنو"
    },
    {
        "en": "APE",
        "ar": "إيب"
    },
    {
        "en": "Meme Inu",
        "ar": "ميم إنو"
    },
    {
        "en": "Meta Doge",
        "ar": "ميتا دوج"
    },
    {
        "en": "UpDog",
        "ar": "أب دوج"
    },
    {
        "en": "FarmerDoge",
        "ar": "فارمر دوج"
    },
    {
        "en": "Strawberry Elephant",
        "ar": "ستروبري إليفانت"
    },
    {
        "en": "Elon Xmas",
        "ar": "إيلون كريسماس"
    },
    {
        "en": "Kawakami",
        "ar": "كاواكامي"
    },
    {
        "en": "Doug",
        "ar": "دوج"
    },
    {
        "en": "RabbitKing",
        "ar": "رابيت كينج"
    },
    {
        "en": "Hooked Protocol",
        "ar": "هوكد بروتوكول"
    },
    {
        "en": "Nakamoto Games",
        "ar": "ناكاموتو جيمز"
    },
    {
        "en": "XPLA",
        "ar": "إكس بي إل إيه"
    },
    {
        "en": "Verasity",
        "ar": "فيراسيتي"
    },
    {
        "en": "SIPHER",
        "ar": "سيفر"
    },
    {
        "en": "Voxies",
        "ar": "فوكسيز"
    },
    {
        "en": "KOMPETE",
        "ar": "كومبيت"
    },
    {
        "en": "Cere Network",
        "ar": "سير نتويرك"
    },
    {
        "en": "Artyfact",
        "ar": "آرتيفاكت"
    },
    {
        "en": "PIXL",
        "ar": "بيكسل"
    },
    {
        "en": "Domi",
        "ar": "دومي"
    },
    {
        "en": "PunkCity",
        "ar": "بنك سيتي"
    },
    {
        "en": "UniX",
        "ar": "يوني إكس"
    },
    {
        "en": "Affyn",
        "ar": "أفين"
    },
    {
        "en": "Darwinia Network",
        "ar": "داروينيا نتويرك"
    },
    {
        "en": "DOSE",
        "ar": "دوس"
    },
    {
        "en": "Good Games Guild",
        "ar": "جود جيمز جيلد"
    },
    {
        "en": "Kryptomon",
        "ar": "كريبتومون"
    },
    {
        "en": "Cryowar",
        "ar": "كرايو وار"
    },
    {
        "en": "Champignons of Arborethia",
        "ar": "شامبينونز أوف أربوريثيا"
    },
    {
        "en": "Alaska Gold Rush",
        "ar": "ألاسكا جولد راش"
    },
    {
        "en": "The Three Kingdoms",
        "ar": "ذا ثري كينغدومز"
    },
    {
        "en": "FireStarter",
        "ar": "فاير ستارتر"
    },
    {
        "en": "Wam",
        "ar": "وام"
    },
    {
        "en": "dotmoovs",
        "ar": "دوت موفس"
    },
    {
        "en": "Metastrike",
        "ar": "ميتاسترايك"
    },
    {
        "en": "XAYA",
        "ar": "إكسايا"
    },
    {
        "en": "Rome",
        "ar": "روم"
    },
    {
        "en": "DogemonGo",
        "ar": "دوجيمون جو"
    },
    {
        "en": "Ispolink",
        "ar": "إسبولينك"
    },
    {
        "en": "Fabwelt",
        "ar": "فاب ويلت"
    },
    {
        "en": "Revenant",
        "ar": "ريفينانت"
    },
    {
        "en": "Vigorus",
        "ar": "فيغوروس"
    },
    {
        "en": "Blockchain Monster Hunt",
        "ar": "بلوكشين مونستر هانت"
    },
    {
        "en": "Galaxy Fight Club",
        "ar": "جالاكسي فايت كلوب"
    },
    {
        "en": "Atari",
        "ar": "أتاري"
    },
    {
        "en": "GamesPad",
        "ar": "جيمز باد"
    },
    {
        "en": "Meta Toy DragonZ SAGA (FXERC20)",
        "ar": "ميتا توي دراجونز ساجا"
    },
    {
        "en": "BlockchainSpace",
        "ar": "بلوكشين سبيس"
    },
    {
        "en": "Revolve Games",
        "ar": "ريفولف جيمز"
    },
    {
        "en": "IOI Token",
        "ar": "آي أو آي توكن"
    },
    {
        "en": "Metaverser",
        "ar": "ميتافيرسر"
    },
    {
        "en": "KleeKai",
        "ar": "كلي كاي"
    },
    {
        "en": "DEEPSPACE",
        "ar": "ديب سبيس"
    },
    {
        "en": "Nifty League",
        "ar": "نيفتي ليج"
    },
    {
        "en": "Wanaka Farm",
        "ar": "واناكا فارم"
    },
    {
        "en": "Wizarre Scroll",
        "ar": "ويزار سكرول"
    },
    {
        "en": "Decentral Games (Old)",
        "ar": "ديسنترال جيمز"
    },
    {
        "en": "Chronicle",
        "ar": "كرونيكل"
    },
    {
        "en": "Monsterra",
        "ar": "مونستيرا"
    },
    {
        "en": "Waves Ducks",
        "ar": "ويفز داكس"
    },
    {
        "en": "Dragon Crypto Aurum",
        "ar": "دراجون كريبتو أوروم"
    },
    {
        "en": "Monsta Infinite",
        "ar": "مونستا إنفينيت"
    },
    {
        "en": "Gelato",
        "ar": "جيلاتو"
    },
    {
        "en": "Coinweb",
        "ar": "كوين ويب"
    },
    {
        "en": "ZKBase",
        "ar": "زد كيه بيس"
    },
    {
        "en": "Alvey Chain",
        "ar": "ألفي شين"
    },
    {
        "en": "Cybria",
        "ar": "سايبريا"
    },
    {
        "en": "Wrapped Ether (Mantle Bridge)",
        "ar": "رابط إيثر"
    },
    {
        "en": "Bridged Arbitrum (Lightlink)",
        "ar": "أربترم معبر"
    },
    {
        "en": "Bridged Polygon (Lightlink)",
        "ar": "بوليجون عبر جسر لايت لينك"
    },
    {
        "en": "Arkham",
        "ar": "أركهام"
    },
    {
        "en": "Celer Network",
        "ar": "سيلر نتويرك"
    },
    {
        "en": "WINkLink",
        "ar": "وينك لينك"
    },
    {
        "en": "Beta Finance",
        "ar": "بيتا فاينانس"
    },
    {
        "en": "Alpine F1 Team Fan Token",
        "ar": "ألبين ف1 تيم فان توكن"
    },
    {
        "en": "Tokocrypto",
        "ar": "توكوكريبتو"
    },
    {
        "en": "TROY",
        "ar": "تروي"
    },
    {
        "en": "AC Milan Fan Token",
        "ar": "إي سي ميلان فان توكن"
    },
    {
        "en": "Bread",
        "ar": "بريد"
    },
    {
        "en": "FC Porto",
        "ar": "إف سي بورتو"
    },
    {
        "en": "Bork",
        "ar": "بورك"
    },
    {
        "en": "Findora",
        "ar": "فيندورا"
    },
    {
        "en": "Panther Protocol",
        "ar": "بانثر بروتوكول"
    },
    {
        "en": "Everest",
        "ar": "إيفرست"
    },
    {
        "en": "PureFi",
        "ar": "بيور فاي"
    },
    {
        "en": "Offshift",
        "ar": "أوف شيفت"
    },
    {
        "en": "Hypersign Identity",
        "ar": "هايبرساين إيدنتيتي"
    },
    {
        "en": "Ycash",
        "ar": "واي كاش"
    },
    {
        "en": "ZkTsunami",
        "ar": "زد كيه تسونامي"
    },
    {
        "en": "CHEX Token",
        "ar": "شيكس توكن"
    },
    {
        "en": "WigoSwap",
        "ar": "ويجو سواب"
    },
    {
        "en": "Cetus Protocol",
        "ar": "سيتوس بروتوكول"
    },
    {
        "en": "Spookyswap",
        "ar": "سبوكي سواب"
    },
    {
        "en": "Verse",
        "ar": "فيرس"
    },
    {
        "en": "Spectra",
        "ar": "سبكترا"
    },
    {
        "en": "Thena",
        "ar": "ثينا"
    },
    {
        "en": "Dfyn Network",
        "ar": "ديفين نتويرك"
    },
    {
        "en": "Cap",
        "ar": "كاب"
    },
    {
        "en": "NerveNetwork",
        "ar": "نيرف نتويرك"
    },
    {
        "en": "Aldrin",
        "ar": "ألدرين"
    },
    {
        "en": "Deri Protocol",
        "ar": "ديري بروتوكول"
    },
    {
        "en": "TETU",
        "ar": "تيتو"
    },
    {
        "en": "ZeroSwap",
        "ar": "زيرو سواب"
    },
    {
        "en": "DefiBox",
        "ar": "ديفي بوكس"
    },
    {
        "en": "StellaSwap",
        "ar": "ستيلا سواب"
    },
    {
        "en": "DAEX",
        "ar": "ديكس"
    },
    {
        "en": "ExchangeCoin",
        "ar": "إكسشينج كوين"
    },
    {
        "en": "Trisolaris",
        "ar": "تريسولاريس"
    },
    {
        "en": "Integral",
        "ar": "إنتيجرال"
    },
    {
        "en": "Thorus",
        "ar": "ثوروس"
    },
    {
        "en": "Oiler",
        "ar": "أويلر"
    },
    {
        "en": "Standard Protocol",
        "ar": "ستاندرد بروتوكول"
    },
    {
        "en": "Zyberswap",
        "ar": "زايبر سواب"
    },
    {
        "en": "Hedget",
        "ar": "هيدجت"
    },
    {
        "en": "Autonio",
        "ar": "أوتونيو"
    },
    {
        "en": "SakeSwap",
        "ar": "ساكي سواب"
    },
    {
        "en": "Value DeFi",
        "ar": "فاليو ديفي"
    },
    {
        "en": "Marginswap",
        "ar": "مارجين سواب"
    },
    {
        "en": "Synthetify",
        "ar": "سينثيتيفاي"
    },
    {
        "en": "Huckleberry",
        "ar": "هاكلبيري"
    },
    {
        "en": "StepEx",
        "ar": "ستيب إكس"
    },
    {
        "en": "WannaSwap",
        "ar": "وانا سواب"
    },
    {
        "en": "MilkySwap",
        "ar": "ميلكي سواب"
    },
    {
        "en": "MMOCoin",
        "ar": "إم إم أو كوين"
    },
    {
        "en": "Arable Protocol",
        "ar": "أرابل بروتوكول"
    },
    {
        "en": "MEVFree",
        "ar": "ميف فري"
    },
    {
        "en": "ZoomSwap",
        "ar": "زوم سواب"
    },
    {
        "en": "ArkiTech",
        "ar": "أركي تيك"
    },
    {
        "en": "SkullSwap Exchange",
        "ar": "سكول سواب إكسشينج"
    },
    {
        "en": "SolidLizard",
        "ar": "سوليد ليزارد"
    },
    {
        "en": "Anyswap",
        "ar": "أني سواب"
    },
    {
        "en": "BlackPearl",
        "ar": "بلاك بيرل"
    },
    {
        "en": "KnightSwap",
        "ar": "نايت سواب"
    },
    {
        "en": "Timechain Swap",
        "ar": "تايم تشين سواب"
    },
    {
        "en": "Alium Finance",
        "ar": "أليوم فاينانس"
    },
    {
        "en": "Mora",
        "ar": "مورا"
    },
    {
        "en": "Honey",
        "ar": "هوني"
    },
    {
        "en": "Morphswap",
        "ar": "مورف سواب"
    },
    {
        "en": "Bunicorn",
        "ar": "بونيكورن"
    },
    {
        "en": "Fuzz Finance",
        "ar": "فاز فاينانس"
    },
    {
        "en": "PhotonSwap",
        "ar": "فوتون سواب"
    },
    {
        "en": "Fastswap (BSC)",
        "ar": "فاست سواب"
    },
    {
        "en": "Torum",
        "ar": "توروم"
    },
    {
        "en": "Metaverse Index",
        "ar": "ميتافيرس إندكس"
    },
    {
        "en": "Neos Credits",
        "ar": "نيوز كريديتس"
    },
    {
        "en": "VFOX",
        "ar": "فوكس"
    },
    {
        "en": "Ariva",
        "ar": "أريفا"
    },
    {
        "en": "ZELIX",
        "ar": "زيليكس"
    },
    {
        "en": "Pleasure Coin",
        "ar": "بليجر كوين"
    },
    {
        "en": "Arcona",
        "ar": "أركونا"
    },
    {
        "en": "Kalao",
        "ar": "كالاو"
    },
    {
        "en": "Trace Network Labs",
        "ar": "تريس نتويرك لابز"
    },
    {
        "en": "Decentral Games ICE",
        "ar": "ديسنترال جيمز آي سي إي"
    },
    {
        "en": "Nitro League",
        "ar": "نيترو ليج"
    },
    {
        "en": "Binamon",
        "ar": "بينامون"
    },
    {
        "en": "LakeViewMeta",
        "ar": "ليك فيو ميتا"
    },
    {
        "en": "Benzene",
        "ar": "بنزين"
    },
    {
        "en": "GAX Liquidity Token Reward",
        "ar": "جاكس ليكويديتي توكن ريوارد"
    },
    {
        "en": "Cool Cats Milk",
        "ar": "كول كاتس ميلك"
    },
    {
        "en": "The HUSL",
        "ar": "ذا هاسل"
    },
    {
        "en": "Avaxtars",
        "ar": "أفاكستارز"
    },
    {
        "en": "Museum of Crypto Art",
        "ar": "ميوزيوم أوف كريبتو آرت"
    },
    {
        "en": "Mist",
        "ar": "ميست"
    },
    {
        "en": "PlayNity",
        "ar": "بلاي نيتي"
    },
    {
        "en": "EDDASwap",
        "ar": "إيدا سواب"
    },
    {
        "en": "Ethermon",
        "ar": "إيثرمون"
    },
    {
        "en": "Genesis Worlds",
        "ar": "جينيسيس وورلدز"
    },
    {
        "en": "Aavegotchi KEK",
        "ar": "أف جوتشي كيك"
    },
    {
        "en": "Token IN",
        "ar": "توكن إن"
    },
    {
        "en": "Aavegotchi FOMO",
        "ar": "أف جوتشي فومو"
    },
    {
        "en": "Aavegotchi ALPHA",
        "ar": "أف جوتشي ألفا"
    },
    {
        "en": "ProjectOasis",
        "ar": "بروجكت أواسيس"
    },
    {
        "en": "Aavegotchi FUD",
        "ar": "أف جوتشي فود"
    },
    {
        "en": "NFTmall",
        "ar": "إن إف تي مول"
    },
    {
        "en": "VIBE",
        "ar": "فايب"
    },
    {
        "en": "Dino",
        "ar": "دينو"
    },
    {
        "en": "ShoeFy",
        "ar": "شوفي"
    },
    {
        "en": "Cappasity",
        "ar": "كاباسيتي"
    },
    {
        "en": "ChainCade",
        "ar": "شين كيد"
    },
    {
        "en": "Coco",
        "ar": "كوكو"
    },
    {
        "en": "NANA Token",
        "ar": "نانا توكن"
    },
    {
        "en": "Unicorn Milk",
        "ar": "يونيكورن ميلك"
    },
    {
        "en": "DINOEGG",
        "ar": "دينو إيج"
    },
    {
        "en": "ZED RUN",
        "ar": "زد رن"
    },
    {
        "en": "Storj",
        "ar": "ستورج"
    },
    {
        "en": "Orchid Protocol",
        "ar": "أوركيد بروتوكول"
    },
    {
        "en": "Stratos",
        "ar": "ستراتوس"
    },
    {
        "en": "Sentinel",
        "ar": "سينتينل"
    },
    {
        "en": "Nodle Network",
        "ar": "نودل نتويرك"
    },
    {
        "en": "nuco.cloud",
        "ar": "نوكو كلاود"
    },
    {
        "en": "Geodnet",
        "ar": "جيودنت"
    },
    {
        "en": "FOAM",
        "ar": "فوم"
    },
    {
        "en": "Bware",
        "ar": "بوير"
    },
    {
        "en": "ScPrime",
        "ar": "سي برايم"
    },
    {
        "en": "Precipitate.ai",
        "ar": "بريسيبيتيت"
    },
    {
        "en": "Lambda",
        "ar": "لامبدا"
    },
    {
        "en": "Drife",
        "ar": "دريف"
    },
    {
        "en": "FrysCrypto",
        "ar": "فرايز كريبتو"
    },
    {
        "en": "Wrapped Minima",
        "ar": "رابط مينيما"
    },
    {
        "en": "Wrapped POKT",
        "ar": "رابط بوكت"
    },
    {
        "en": "Nosana",
        "ar": "نوسانا"
    },
    {
        "en": "Krest",
        "ar": "كريست"
    },
    {
        "en": "ioTube Bridged WNT (IoTeX)",
        "ar": "أيو تيوب بريدجد"
    },
    {
        "en": "StackOS",
        "ar": "ستاك أو أس"
    },
    {
        "en": "ioTube Bridged WIFI (IoTeX)",
        "ar": "أيو تيوب بريدجد"
    },
    {
        "en": "Deeper Network",
        "ar": "ديبر نتويرك"
    },
    {
        "en": "Denet File Token",
        "ar": "دينت فايل توكن"
    },
    {
        "en": "Dogs Of Elon",
        "ar": "دوجز أوف إيلون"
    },
    {
        "en": "Web",
        "ar": "ويب"
    },
    {
        "en": "Chief Troll Officer",
        "ar": "تشيف ترول أوفيسر"
    },
    {
        "en": "Floki Musk",
        "ar": "فلوكي ماسك"
    },
    {
        "en": "go fu*k yourself.",
        "ar": "جو فاك يورسيلف"
    },
    {
        "en": "Grok2.0",
        "ar": "جروك 2.0"
    },
    {
        "en": "PromptIDE",
        "ar": "برومبت آي دي إي"
    },
    {
        "en": "AstroElon",
        "ar": "أسترو إيلون"
    },
    {
        "en": "GameFi.org",
        "ar": "جيم فاي"
    },
    {
        "en": "CryptoBlades",
        "ar": "كريبتو بليدز"
    },
    {
        "en": "Kawaii Islands",
        "ar": "كاواي إيلاندز"
    },
    {
        "en": "Crabada",
        "ar": "كرابادا"
    },
    {
        "en": "KCAL",
        "ar": "كال"
    },
    {
        "en": "Demeter",
        "ar": "ديميتر"
    },
    {
        "en": "Forest Knight",
        "ar": "فورست نايت"
    },
    {
        "en": "Wall Street Games",
        "ar": "وول ستريت جيمز"
    },
    {
        "en": "CryptoZoon",
        "ar": "كريبتوزون"
    },
    {
        "en": "Crypto Raiders",
        "ar": "كريبتو رايدرز"
    },
    {
        "en": "Plant vs Undead",
        "ar": "بلانت فرس أند ديد"
    },
    {
        "en": "Metarun",
        "ar": "ميتارن"
    },
    {
        "en": "Game Fantasy",
        "ar": "جيم فانتازي"
    },
    {
        "en": "Snook",
        "ar": "سنوك"
    },
    {
        "en": "BombCrypto",
        "ar": "بومب كريبتو"
    },
    {
        "en": "Cryptomeda",
        "ar": "كريبتوميدا"
    },
    {
        "en": "MicroPets [OLD]",
        "ar": "مايكرو بيتس"
    },
    {
        "en": "MicroPets [OLD]",
        "ar": "مايكرو بيتس"
    },
    {
        "en": "Defi Warrior",
        "ar": "ديفي واريور"
    },
    {
        "en": "My Master War",
        "ar": "ماي ماستر وار"
    },
    {
        "en": "CryptoTanks",
        "ar": "كريبتو تانكس"
    },
    {
        "en": "Treasure Under Sea",
        "ar": "تريجر أندر سي"
    },
    {
        "en": "Crusaders of Crypto",
        "ar": "كروسادرز أوف كريبتو"
    },
    {
        "en": "NFT Soccer Games",
        "ar": "إن إف تي سوكر جيمز"
    },
    {
        "en": "Fancy Games",
        "ar": "فانسي جيمز"
    },
    {
        "en": "Wirtual",
        "ar": "ويرتوال"
    },
    {
        "en": "AnRKey X",
        "ar": "أن آر كيه إكس"
    },
    {
        "en": "Ape In",
        "ar": "إيب إن"
    },
    {
        "en": "EZZY Game",
        "ar": "إيزي جيم"
    },
    {
        "en": "NFT Champions",
        "ar": "إن إف تي تشامبيونز"
    },
    {
        "en": "StarMon",
        "ar": "ستار مون"
    },
    {
        "en": "Cantina Royale",
        "ar": "كانتينا رويال"
    },
    {
        "en": "MOBLAND",
        "ar": "موبلاند"
    },
    {
        "en": "Thetan Arena",
        "ar": "ثيتان أرينا"
    },
    {
        "en": "Mines of Dalarnia",
        "ar": "ماينز أوف دالارنيا"
    },
    {
        "en": "MicroPets",
        "ar": "مايكرو بيتس"
    },
    {
        "en": "MetaverseX",
        "ar": "ميتافيرس إكس"
    },
    {
        "en": "Monster",
        "ar": "مونستر"
    },
    {
        "en": "Metaderby",
        "ar": "ميتاديربي"
    },
    {
        "en": "Valeria",
        "ar": "فاليريا"
    },
    {
        "en": "Meme Lordz",
        "ar": "ميم لوردز"
    },
    {
        "en": "Megaweapon",
        "ar": "ميجاويبون"
    },
    {
        "en": "Magic Beasties",
        "ar": "ماجيك بيستيز"
    },
    {
        "en": "LoserChick EGG",
        "ar": "لوزر تشيك إيج"
    },
    {
        "en": "AVATARA NOX",
        "ar": "أفاتارا نوكس"
    },
    {
        "en": "Supernova Shards Life Coin",
        "ar": "سوبرنوفا شاردز لايف كوين"
    },
    {
        "en": "Ark Rivals",
        "ar": "أرك رايفلز"
    },
    {
        "en": "Green Beli",
        "ar": "جرين بيلي"
    },
    {
        "en": "ZOO Crypto World",
        "ar": "زو كريبتو وورلد"
    },
    {
        "en": "CryptoMines Eternal",
        "ar": "كريبتو ماينز إيتيرنال"
    },
    {
        "en": "Kaby Arena",
        "ar": "كابي أرينا"
    },
    {
        "en": "Dark Energy Crystals",
        "ar": "دارك إنرجي كريستالز"
    },
    {
        "en": "Raider Aurum",
        "ar": "رايدر أوروم"
    },
    {
        "en": "Planet Sandbox",
        "ar": "بلانيت ساندبوكس"
    },
    {
        "en": "Dexioprotocol",
        "ar": "ديكسيو بروتوكول"
    },
    {
        "en": "Hero Arena",
        "ar": "هيرو أرينا"
    },
    {
        "en": "EQIFi",
        "ar": "إيكيفي"
    },
    {
        "en": "Vesper Finance",
        "ar": "فيسبر فاينانس"
    },
    {
        "en": "Tulip Protocol",
        "ar": "توليب بروتوكول"
    },
    {
        "en": "Tarot V1",
        "ar": "تاروت"
    },
    {
        "en": "saffron.finance",
        "ar": "سافرون فاينانس"
    },
    {
        "en": "Bao Finance",
        "ar": "باو فاينانس"
    },
    {
        "en": "Trava Finance",
        "ar": "ترافا فاينانس"
    },
    {
        "en": "SWAG Finance",
        "ar": "سواج فاينانس"
    },
    {
        "en": "88mph",
        "ar": "88 مايلز"
    },
    {
        "en": "Geyser",
        "ar": "جايزر"
    },
    {
        "en": "Goose Finance",
        "ar": "جوس فاينانس"
    },
    {
        "en": "Rook",
        "ar": "روك"
    },
    {
        "en": "Poison Finance",
        "ar": "بويسون فاينانس"
    },
    {
        "en": "Penguin Finance",
        "ar": "بنجوين فاينانس"
    },
    {
        "en": "Bao Finance V2",
        "ar": "باو فاينانس"
    },
    {
        "en": "TosDis",
        "ar": "توس ديس"
    },
    {
        "en": "UniFarm",
        "ar": "يوني فارم"
    },
    {
        "en": "EasyFi V2",
        "ar": "إيزي فاي 2"
    },
    {
        "en": "BNSD Finance",
        "ar": "بي إن إس دي فاينانس"
    },
    {
        "en": "TEN",
        "ar": "تن"
    },
    {
        "en": "Basis Share",
        "ar": "بيسيس شير"
    },
    {
        "en": "APY.Finance",
        "ar": "أي بي واي فاينانس"
    },
    {
        "en": "ARTX",
        "ar": "آر تي إكس"
    },
    {
        "en": "Mithril Share",
        "ar": "ميثريل شير"
    },
    {
        "en": "Azuki",
        "ar": "أزوكي"
    },
    {
        "en": "xToken",
        "ar": "إكس توكن"
    },
    {
        "en": "MacaronSwap",
        "ar": "ماكارون سواب"
    },
    {
        "en": "Rabbit Finance",
        "ar": "رابيت فاينانس"
    },
    {
        "en": "Doki Doki",
        "ar": "دوكي دوكي"
    },
    {
        "en": "50Cent",
        "ar": "50 سنت"
    },
    {
        "en": "Snowswap",
        "ar": "سنو سواب"
    },
    {
        "en": "Crafting Finance",
        "ar": "كرافتينج فاينانس"
    },
    {
        "en": "coreDAO",
        "ar": "كور داو"
    },
    {
        "en": "Convex CRV",
        "ar": "كونفيكس"
    },
    {
        "en": "CheesecakeSwap",
        "ar": "تشيز كيك سواب"
    },
    {
        "en": "KIMCHI.finance",
        "ar": "كيمتشي فاينانس"
    },
    {
        "en": "Salmon",
        "ar": "سالمون"
    },
    {
        "en": "Tarot",
        "ar": "تاروت"
    },
    {
        "en": "Stabilize",
        "ar": "ستابل آيز"
    },
    {
        "en": "Drip Network",
        "ar": "دريب نتويرك"
    },
    {
        "en": "Coldstack",
        "ar": "كولد ستاك"
    },
    {
        "en": "ProximaX",
        "ar": "بروكسيما إكس"
    },
    {
        "en": "Big Data Protocol",
        "ar": "بيج داتا بروتوكول"
    },
    {
        "en": "PAC Protocol",
        "ar": "باك بروتوكول"
    },
    {
        "en": "Opacity",
        "ar": "أوباسيتي"
    },
    {
        "en": "SONM",
        "ar": "سونم"
    },
    {
        "en": "KnoxFS",
        "ar": "نوكس إف إس"
    },
    {
        "en": "BitTorrent [OLD]",
        "ar": "بيت تورنت [OLD]"
    },
    {
        "en": "Binance-Peg Filecoin",
        "ar": "بينانس بيج فايل كوين"
    },
    {
        "en": "ThreeFold",
        "ar": "ثري فولد"
    },
    {
        "en": "Upfire",
        "ar": "أب فاير"
    },
    {
        "en": "Iagon",
        "ar": "آياجون"
    },
    {
        "en": "HAPI",
        "ar": "هابي"
    },
    {
        "en": "Kleros",
        "ar": "كليروس"
    },
    {
        "en": "Umbrella Network",
        "ar": "أمبريلا نتويرك"
    },
    {
        "en": "Skey Network",
        "ar": "سكي نتويرك"
    },
    {
        "en": "Odin Protocol",
        "ar": "أودين بروتوكول"
    },
    {
        "en": "Integritee",
        "ar": "إنتيجريتي"
    },
    {
        "en": "Razor Network",
        "ar": "ريزور نتويرك"
    },
    {
        "en": "Modefi",
        "ar": "موديفي"
    },
    {
        "en": "Gora",
        "ar": "جورا"
    },
    {
        "en": "Idena",
        "ar": "إيدينا"
    },
    {
        "en": "April",
        "ar": "أبريل"
    },
    {
        "en": "Bird.Money",
        "ar": "بيرد موني"
    },
    {
        "en": "Equilibria",
        "ar": "إكويليبريا"
    },
    {
        "en": "Zap",
        "ar": "زاب"
    },
    {
        "en": "OptionRoom",
        "ar": "أوبشن روم"
    },
    {
        "en": "Ares Protocol",
        "ar": "آريس بروتوكول"
    },
    {
        "en": "UTU Coin",
        "ar": "يوتو كوين"
    },
    {
        "en": "DOS Network",
        "ar": "دوس نتويرك"
    },
    {
        "en": "Bogged Finance",
        "ar": "بوجد فاينانس"
    },
    {
        "en": "Berry Data",
        "ar": "بيري داتا"
    },
    {
        "en": "WINkLink BSC",
        "ar": "وينك لينك"
    },
    {
        "en": "Bridged Chainlink (Lightlink)",
        "ar": "شين لينك عبر جسر لايت لينك"
    },
    {
        "en": "IQ",
        "ar": "آي كيو"
    },
    {
        "en": "SingularityDAO",
        "ar": "سنجولاريتي داو"
    },
    {
        "en": "SelfKey",
        "ar": "سيلف كي"
    },
    {
        "en": "VaultTech",
        "ar": "فولت تيك"
    },
    {
        "en": "DexCheck",
        "ar": "ديكس تشيك"
    },
    {
        "en": "TRVL",
        "ar": "ترفل"
    },
    {
        "en": "Openfabric AI",
        "ar": "أوبن فابريك آي"
    },
    {
        "en": "AI Power Grid",
        "ar": "آي بور ور جريد"
    },
    {
        "en": "Aegis Ai",
        "ar": "أيجيس آي"
    },
    {
        "en": "Matrix AI Network",
        "ar": "ماتريكس آي"
    },
    {
        "en": "DeepBrain Chain",
        "ar": "ديب برين تشين"
    },
    {
        "en": "Synesis One",
        "ar": "سينيسيس ون"
    },
    {
        "en": "CheckDot",
        "ar": "تشيك دوت"
    },
    {
        "en": "KWAI",
        "ar": "كواي"
    },
    {
        "en": "Chirpley",
        "ar": "شيربلي"
    },
    {
        "en": "B-cube.ai",
        "ar": "بي كيوب آي"
    },
    {
        "en": "LilAI",
        "ar": "ليل آي"
    },
    {
        "en": "AstroPepeX",
        "ar": "أسترو بيبي إكس"
    },
    {
        "en": "Blacksmith Token",
        "ar": "بلاكسميث توكن"
    },
    {
        "en": "Jim",
        "ar": "جيم"
    },
    {
        "en": "GNY",
        "ar": "جي إن واي"
    },
    {
        "en": "Cindicator",
        "ar": "سينديكاتور"
    },
    {
        "en": "Neuroni AI",
        "ar": "نيوروني آي"
    },
    {
        "en": "2DAI.io",
        "ar": "2 داي"
    },
    {
        "en": "SmartCredit",
        "ar": "سمارت كريديت"
    },
    {
        "en": "Quadency",
        "ar": "كوادنسي"
    },
    {
        "en": "Ojamu",
        "ar": "أوجامو"
    },
    {
        "en": "Meme AI Coin",
        "ar": "ميم آي كوين"
    },
    {
        "en": "LIZA",
        "ar": "ليزا"
    },
    {
        "en": "All In",
        "ar": "أول إن"
    },
    {
        "en": "InsightX",
        "ar": "إنسايت إكس"
    },
    {
        "en": "Generaitiv",
        "ar": "جينيراتيف"
    },
    {
        "en": "DeepFakeAI",
        "ar": "ديب فايك آي"
    },
    {
        "en": "Alpha Radar Bot",
        "ar": "ألفا رادار بوت"
    },
    {
        "en": "inheritance Art",
        "ar": "إنهيرتانس آرت"
    },
    {
        "en": "ETHforestAI",
        "ar": "إي تش فورست آي"
    },
    {
        "en": "AlphaRushAI",
        "ar": "ألفا راش آي"
    },
    {
        "en": "Eve AI",
        "ar": "إيف آي"
    },
    {
        "en": "BlockGPT",
        "ar": "بلوك جي بي تي"
    },
    {
        "en": "CryptoAI",
        "ar": "كريبتو آي"
    },
    {
        "en": "Advantis",
        "ar": "أدفانتيس"
    },
    {
        "en": "Chanalog",
        "ar": "شانالوج"
    },
    {
        "en": "VuzzMind",
        "ar": "فاز مايند"
    },
    {
        "en": "Quick Intel",
        "ar": "كويك إنتل"
    },
    {
        "en": "0xDEFCAFE",
        "ar": "ديف كافي"
    },
    {
        "en": "Neural Radiance Field",
        "ar": "نيورال راديانس فيلد"
    },
    {
        "en": "Sleepless AI",
        "ar": "سليبلس آي"
    },
    {
        "en": "Black Rabbit AI",
        "ar": "بلاك رابيت آي"
    },
    {
        "en": "Spectre AI",
        "ar": "سبيكتر آي"
    },
    {
        "en": "Superstake",
        "ar": "سوبر ستاك"
    },
    {
        "en": "PolkaBridge",
        "ar": "بولكا بريدج"
    },
    {
        "en": "Ubeswap",
        "ar": "يوب سواب"
    },
    {
        "en": "Lien",
        "ar": "لين"
    },
    {
        "en": "renBTC",
        "ar": "رين بي تي سي"
    },
    {
        "en": "pTokens BTC [OLD]",
        "ar": "بي توكن بي تي سي [OLD]"
    },
    {
        "en": "renDOGE",
        "ar": "رين دوج"
    },
    {
        "en": "Polygon WETH (Plenty Bridge)",
        "ar": "بوليجون ويث (بلنتي بريدج)"
    },
    {
        "en": "Wrapped Matic",
        "ar": "ماتيك مغلف"
    },
    {
        "en": "Wrapped AVAX",
        "ar": "أفاكس مغلف"
    },
    {
        "en": "Wrapped BTC (Wormhole)",
        "ar": "بي تي سي مغلف (وورم هول)"
    },
    {
        "en": "Wrapped CRO",
        "ar": "كرو مغلف"
    },
    {
        "en": "Wrapped eHMND",
        "ar": "إي إتش إم إن دي مغلف"
    },
    {
        "en": "Wrapped Fantom",
        "ar": " فانتوم مغلف"
    },
    {
        "en": "Wrapped Kaspa",
        "ar": " كاسبا مغلف"
    },
    {
        "en": "Wrapped BEAM",
        "ar": " بيم مغلف"
    },
    {
        "en": "Wrapped Moonbeam",
        "ar": " مون بيم مغلف"
    },
    {
        "en": "Wrapped Near",
        "ar": " نير مغلف"
    },
    {
        "en": "Wrapped One",
        "ar": " ون مغلف"
    },
    {
        "en": "Wrapped Paycoin",
        "ar": " باي كوين مغلف"
    },
    {
        "en": "Wrapped POM",
        "ar": " بوم مغلف"
    },
    {
        "en": "Wrapped Solana",
        "ar": " سولانا مغلف"
    },
    {
        "en": "Wrapped stETH",
        "ar": " ستيث مغلف"
    },
    {
        "en": "Wrapped TAO",
        "ar": " تاو مغلف"
    },
    {
        "en": "Wrapped Tron",
        "ar": " ترون مغلف"
    },
    {
        "en": "Wrapped USDR",
        "ar": " يو إس دي آر مغلف"
    },
    {
        "en": "Wrapped Velas",
        "ar": " فيلاس مغلف"
    },
    {
        "en": "interBTC",
        "ar": "إنتر بي تي سي"
    },
    {
        "en": "Kintsugi BTC",
        "ar": "كينتسوجي بي تي سي"
    },
    {
        "en": "Matic Aave Interest Bearing AAVE",
        "ar": "ماتيك أف إيف إنترست بيرينج"
    },
    {
        "en": "Matic Aave Interest Bearing USDC",
        "ar": "ماتيك أف إيف إنترست بيرينج"
    },
    {
        "en": "Wrapped BNB",
        "ar": "بي إن بي مغلف"
    },
    {
        "en": "WBTC (Plenty Bridge)",
        "ar": "دبليو بي تي سي (بلنتي بريدج)"
    },
    {
        "en": "Bridged Wrapped BTC (Lightlink)",
        "ar": "بي تي سي مغلف عبر جسر لايت لينك"
    },
    {
        "en": "Radiant Capital",
        "ar": "راديانت كابيتال"
    },
    {
        "en": "Shentu",
        "ar": "شينتو"
    },
    {
        "en": "Automata",
        "ar": "أوتوماتا"
    },
    {
        "en": "Litentry",
        "ar": "ليتنتري"
    },
    {
        "en": "Reef",
        "ar": "ريف"
    },
    {
        "en": "Paris Saint-Germain Fan Token",
        "ar": "باريس سان جيرمان فان توكن"
    },
    {
        "en": "Manchester City Fan Token",
        "ar": "مانشستر سيتي فان توكن"
    },
    {
        "en": "AS Roma Fan Token",
        "ar": "آي إس روما فان توكن"
    },
    {
        "en": "Atletico Madrid Fan Token",
        "ar": "أتلتيكو مدريد فان توكن"
    },
    {
        "en": "Anchor Protocol",
        "ar": "أنكور بروتوكول"
    },
    {
        "en": "BTC Standard Hashrate Token",
        "ar": "بي تي سي ستاندرد هاشريت توكن"
    },
    {
        "en": "Fusionist",
        "ar": "فيوجنيست"
    },
    {
        "en": "NFPrompt",
        "ar": "إن إف برومبت"
    },
    {
        "en": "Verge",
        "ar": "فيرج"
    },
    {
        "en": "MimbleWimbleCoin",
        "ar": "ميمبل ويمبل كوين"
    },
    {
        "en": "Grin",
        "ar": "جرين"
    },
    {
        "en": "Bytecoin",
        "ar": "بايت كوين"
    },
    {
        "en": "Oxen",
        "ar": "أوكسن"
    },
    {
        "en": "Particl",
        "ar": "بارتيكل"
    },
    {
        "en": "Haven",
        "ar": "هافن"
    },
    {
        "en": "Navcoin",
        "ar": "ناف كوين"
    },
    {
        "en": "Crypton",
        "ar": "كريبتون"
    },
    {
        "en": "Ghost",
        "ar": "غوست"
    },
    {
        "en": "BlockWallet",
        "ar": "بلوك واليت"
    },
    {
        "en": "SERO",
        "ar": "سيرو"
    },
    {
        "en": "Ryo Currency",
        "ar": "ريو كيرنسي"
    },
    {
        "en": "OrdiZK",
        "ar": "أوردي زد كي"
    },
    {
        "en": "Saros",
        "ar": "ساروس"
    },
    {
        "en": "Lybra",
        "ar": "ليبرا"
    },
    {
        "en": "Vanar Chain",
        "ar": "فانار تشين"
    },
    {
        "en": "Alias",
        "ar": "ألياس"
    },
    {
        "en": "Lethean",
        "ar": "ليثيان"
    },
    {
        "en": "Aeon",
        "ar": "أيون"
    },
    {
        "en": "Bitcoin Private",
        "ar": "بيتكوين برايفت"
    },
    {
        "en": "ENCRYPT",
        "ar": "إنكريبت"
    },
    {
        "en": "Axe",
        "ar": "أكس"
    },
    {
        "en": "Swap",
        "ar": "سواب"
    },
    {
        "en": "Phore",
        "ar": "فور"
    },
    {
        "en": "TurtleCoin",
        "ar": "تيرتل كوين"
    },
    {
        "en": "Litecash",
        "ar": "لايت كاش"
    },
    {
        "en": "PRivaCY Coin",
        "ar": "برايفسي كوين"
    },
    {
        "en": "Masari",
        "ar": "ماساري"
    },
    {
        "en": "Iridium",
        "ar": "إيريديوم"
    },
    {
        "en": "Raze Network",
        "ar": "رايز نتويرك"
    },
    {
        "en": "Zero",
        "ar": "زيرو"
    },
    {
        "en": "Karbo",
        "ar": "كاربو"
    },
    {
        "en": "Sumokoin",
        "ar": "سومو كوين"
    },
    {
        "en": "Apollo",
        "ar": "أبولو"
    },
    {
        "en": "VEIL",
        "ar": "فيل"
    },
    {
        "en": "Cloakcoin",
        "ar": "كلوك كوين"
    },
    {
        "en": "Conceal",
        "ar": "كونسيل"
    },
    {
        "en": "DeepOnion",
        "ar": "ديب أونيون"
    },
    {
        "en": "Scala",
        "ar": "سكالا"
    },
    {
        "en": "Zclassic",
        "ar": "زد كلاسيك"
    },
    {
        "en": "BitcoinZ",
        "ar": "بيتكوين زد"
    },
    {
        "en": "Veno Finance",
        "ar": "فينو فاينانس"
    },
    {
        "en": "Stader",
        "ar": "ستادر"
    },
    {
        "en": "Robonomics Network",
        "ar": "روبونوميكس نتويرك"
    },
    {
        "en": "Mobius",
        "ar": "موبيوس"
    },
    {
        "en": "GEEQ",
        "ar": "جيك"
    },
    {
        "en": "Internxt",
        "ar": "إنترنكست"
    },
    {
        "en": "Substratum",
        "ar": "سبستراتوم"
    },
    {
        "en": "Friendz",
        "ar": "فريندز"
    },
    {
        "en": "Lynx",
        "ar": "لينكس"
    },
    {
        "en": "Flowchain",
        "ar": "فلوتشاين"
    },
    {
        "en": "Binance-Peg IoTeX",
        "ar": "بينانس بيج إيو تيكس"
    },
    {
        "en": "WXT Token",
        "ar": "دبليو إكس تي توكن"
    },
    {
        "en": "Fringe Finance",
        "ar": "فرينج فاينانس"
    },
    {
        "en": "BiFi",
        "ar": "بي فاي"
    },
    {
        "en": "Qi Dao",
        "ar": "كي داو"
    },
    {
        "en": "Aurigami",
        "ar": "أوريجامي"
    },
    {
        "en": "Nord Finance",
        "ar": "نورد فاينانس"
    },
    {
        "en": "Drops Ownership Power",
        "ar": "دروبس أونرشيب باور"
    },
    {
        "en": "Ripio Credit Network",
        "ar": "ريبيو كريديت نتويرك"
    },
    {
        "en": "Sync Network",
        "ar": "سينك نتويرك"
    },
    {
        "en": "JET",
        "ar": "جي تي"
    },
    {
        "en": "Scream",
        "ar": "سكريم"
    },
    {
        "en": "DeFiner",
        "ar": "ديفاينر"
    },
    {
        "en": "Kanpeki",
        "ar": "كانبيكي"
    },
    {
        "en": "Warp Finance",
        "ar": "وارب فاينانس"
    },
    {
        "en": "Equalizer",
        "ar": "إيكوالايزر"
    },
    {
        "en": "Wrapped Kava",
        "ar": "كافا مغلف"
    },
    {
        "en": "UncleSam Finance",
        "ar": "أنكل سام فاينانس"
    },
    {
        "en": "Larix",
        "ar": "لاريكس"
    },
    {
        "en": "IRON Titanium",
        "ar": "آيرون تيتانيوم"
    },
    {
        "en": "Seamless Protocol",
        "ar": "سيملس بروتوكول"
    },
    {
        "en": "Morpho-Aave Dai Stablecoin",
        "ar": "مورفو أف إيف داي ستابل كوين"
    },
    {
        "en": "Morpho-Aave Wrapped BTC",
        "ar": "مورفو أف إيف بي تي سي مغلف"
    },
    {
        "en": "Orchai",
        "ar": "أورشاي"
    },
    {
        "en": "Morpho-Aave Curve DAO Token",
        "ar": "مورفو أف إيف كيرف داو توكن"
    },
    {
        "en": "Morpho-Aave Tether USD",
        "ar": "مورفو أف إيف تيثر يو إس دي"
    },
    {
        "en": "Morpho-Aave USD Coin",
        "ar": "مورفو أف إيف يو إس دي كوين"
    },
    {
        "en": "Morpho-Aave Wrapped Ether",
        "ar": "مورفو أف إيف إيثر مغلف"
    },
    {
        "en": "Electroneum",
        "ar": "إلكترونيوم"
    },
    {
        "en": "TOKPIE",
        "ar": "توكبي"
    },
    {
        "en": "VeraOne",
        "ar": "فيرا ون"
    },
    {
        "en": "Yield App",
        "ar": "ييلد آب"
    },
    {
        "en": "RigoBlock",
        "ar": "ريجو بلوك"
    },
    {
        "en": "Etherisc DIP",
        "ar": "إيثريسك دي آي بي"
    },
    {
        "en": "SHIBGF",
        "ar": "شيب جي إف"
    },
    {
        "en": "Nervos Network",
        "ar": "نيرفوس نتويرك"
    },
    {
        "en": "Asia Coin",
        "ar": "آسيا كوين"
    },
    {
        "en": "PONKE",
        "ar": "بونكي"
    },
    {
        "en": "Web3Games.com Token",
        "ar": "ويب 3 جيمز توكن"
    },
    {
        "en": "ZKFair",
        "ar": "زد كي فاير"
    },
    {
        "en": "BABB",
        "ar": "باب"
    },
    {
        "en": "cLINK",
        "ar": "سي لينك"
    },
    {
        "en": "PointPay",
        "ar": "بوينت باي"
    },
    {
        "en": "Crypterium",
        "ar": "كريبتيريوم"
    },
    {
        "en": "Mt Pelerin Shares",
        "ar": "مونت بيليرين شيرز"
    },
    {
        "en": "Reddcoin",
        "ar": "ريد كوين"
    },
    {
        "en": "c0x",
        "ar": "سي صفر إكس"
    },
    {
        "en": "Kick",
        "ar": "كيك"
    },
    {
        "en": "TenX",
        "ar": "تن إكس"
    },
    {
        "en": "X-CASH",
        "ar": "إكس كاش"
    },
    {
        "en": "Lunes",
        "ar": "لونيس"
    },
    {
        "en": "Bloom",
        "ar": "بلوم"
    },
    {
        "en": "Humaniq",
        "ar": "هيومانيك"
    },
    {
        "en": "BnkToTheFuture",
        "ar": "بنك تو ذا فيوتشر"
    },
    {
        "en": "Akropolis",
        "ar": "أكروبوليس"
    },
    {
        "en": "Napoleon X",
        "ar": "نابوليون إكس"
    },
    {
        "en": "Everex",
        "ar": "إيفيركس"
    },
    {
        "en": "CoTrader",
        "ar": "كو تريدر"
    },
    {
        "en": "Sentinel Chain",
        "ar": "سينتينل تشين"
    },
    {
        "en": "SeChain",
        "ar": "سي تشين"
    },
    {
        "en": "FintruX",
        "ar": "فينترو إكس"
    },
    {
        "en": "Insula",
        "ar": "إنسولا"
    },
    {
        "en": "ZeroClassic",
        "ar": "زيرو كلاسيك"
    },
    {
        "en": "SafeCapital",
        "ar": "سيف كابيتال"
    },
    {
        "en": "NestEgg Coin",
        "ar": "نيست إيج كوين"
    },
    {
        "en": "MCO",
        "ar": "إم سي أو"
    },
    {
        "en": "Aave BUSD v1",
        "ar": "أف إيف بي يو إس دي v1"
    },
    {
        "en": "Aave DAI v1",
        "ar": "أف إيف داي v1"
    },
    {
        "en": "Aave ETH v1",
        "ar": "أف إيف إيث v1"
    },
    {
        "en": "Aave KNC v1",
        "ar": "أف إيف كي إن سي v1"
    },
    {
        "en": "Aave LINK v1",
        "ar": "أف إيف لينك v1"
    },
    {
        "en": "Aave MANA v1",
        "ar": "أف إيف مانا v1"
    },
    {
        "en": "Aave MKR v1",
        "ar": "أف إيف ميكر v1"
    },
    {
        "en": "Aave SNX v1",
        "ar": "أف إيف سنكس v1"
    },
    {
        "en": "Aave SUSD v1",
        "ar": "أف إيف سوسد v1"
    },
    {
        "en": "Aave TUSD v1",
        "ar": "أف إيف تي يو إس دي v1"
    },
    {
        "en": "Aave USDC v1",
        "ar": "أف إيف يو إس دي سي v1"
    },
    {
        "en": "Aave USDT v1",
        "ar": "أف إيف يو إس دي تي v1"
    },
    {
        "en": "Aave WBTC v1",
        "ar": "أف إيف دبليو بي تي سي v1"
    },
    {
        "en": "Aave ZRX v1",
        "ar": "أف إيف زد آر إكس v1"
    },
    {
        "en": "Azbit",
        "ar": "أزبيت"
    },
    {
        "en": "Bankroll Vault",
        "ar": "بنك رول فولت"
    },
    {
        "en": "Banque Universal",
        "ar": "بانك يونيفيرسال"
    },
    {
        "en": "BitCash",
        "ar": "بيت كاش"
    },
    {
        "en": "Chainpay",
        "ar": "شين باي"
    },
    {
        "en": "Citadel",
        "ar": "سيتاديل"
    },
    {
        "en": "CoinLoan",
        "ar": "كوين لون"
    },
    {
        "en": "Crypto Holding Frank",
        "ar": "كريبتو هولدينج فرانك"
    },
    {
        "en": "Cryptorg",
        "ar": "كريبتورج"
    },
    {
        "en": "EveryCoin",
        "ar": "إيفري كوين"
    },
    {
        "en": "Fox Trading",
        "ar": "فوكس تريدنج"
    },
    {
        "en": "GivingToServices SVS",
        "ar": "جيفينج تو سيرفيسيز سي في إس"
    },
    {
        "en": "Global Trust Coin",
        "ar": "جلوبال ترست كوين"
    },
    {
        "en": "HedgeTrade",
        "ar": "هيدج تريد"
    },
    {
        "en": "HNC Coin",
        "ar": "إتش إن سي كوين"
    },
    {
        "en": "Jobchain",
        "ar": "جوب تشين"
    },
    {
        "en": "Aave BAT v1",
        "ar": "أف إيف بات v1"
    },
    {
        "en": "Novem Gold",
        "ar": "نوفيم جولد"
    },
    {
        "en": "OTOCASH",
        "ar": "أوتوكاش"
    },
    {
        "en": "RatCoin",
        "ar": "رات كوين"
    },
    {
        "en": "Silvercashs",
        "ar": "سيلفر كاشس"
    },
    {
        "en": "Temtum",
        "ar": "تيمتوم"
    },
    {
        "en": "THENODE",
        "ar": "ذا نود"
    },
    {
        "en": "TrustVerse",
        "ar": "ترست فيرس"
    },
    {
        "en": "UCA Coin",
        "ar": "يو سي إيه كوين"
    },
    {
        "en": "VNDC",
        "ar": "في إن دي سي"
    },
    {
        "en": "WemergeToken",
        "ar": "ويميرج توكن"
    },
    {
        "en": "ZEON Network",
        "ar": "زيون نتويرك"
    },
    {
        "en": "Pika Protocol",
        "ar": "بيكا بروتوكول"
    },
    {
        "en": "IPOR",
        "ar": "آي بي أو آر"
    },
    {
        "en": "Level",
        "ar": "ليفل"
    },
    {
        "en": "CVI",
        "ar": "سي في آي"
    },
    {
        "en": "Yieldification",
        "ar": "ييلديفيكيشن"
    },
    {
        "en": "Metavault Trade",
        "ar": "ميتافولت تريد"
    },
    {
        "en": "Dafi Protocol",
        "ar": "دافي بروتوكول"
    },
    {
        "en": "Siren",
        "ar": "سيرين"
    },
    {
        "en": "Phoenix Finance",
        "ar": "فينيكس فاينانس"
    },
    {
        "en": "Auctus",
        "ar": "أوكتوس"
    },
    {
        "en": "FXDX",
        "ar": "إف إكس دي إكس"
    },
    {
        "en": "PalmSwap",
        "ar": "بالم سواب"
    },
    {
        "en": "Foxify",
        "ar": "فوكسيفاي"
    },
    {
        "en": "Dymension",
        "ar": "ديمنشن"
    },
    {
        "en": "TempleDAO",
        "ar": "تيمبل داو"
    },
    {
        "en": "Snowbank",
        "ar": "سنوبانك"
    },
    {
        "en": "tGOLD",
        "ar": "تي جولد"
    },
    {
        "en": "Rai Reflex Index",
        "ar": "راي ريفليكس إندكس"
    },
    {
        "en": "SHOPX",
        "ar": "شوب إكس"
    },
    {
        "en": "AurusX",
        "ar": "أوروس إكس"
    },
    {
        "en": "Digix Gold",
        "ar": "ديجيكس جولد"
    },
    {
        "en": "tSILVER",
        "ar": "تي سيلفر"
    },
    {
        "en": "Xaurum",
        "ar": "زاوروم"
    },
    {
        "en": "Templar DAO",
        "ar": "تمبلر داو"
    },
    {
        "en": "VNX Gold",
        "ar": "في إن إكس جولد"
    },
    {
        "en": "Wonderland TIME",
        "ar": "وندرلاند تايم"
    },
    {
        "en": "Aave v3 DAI",
        "ar": "أف إيف v3 داي"
    },
    {
        "en": "Aave v3 DPI",
        "ar": "أف إيف v3 دي بي آي"
    },
    {
        "en": "Aave v3 ENS",
        "ar": "أف إيف v3 إي إن إس"
    },
    {
        "en": "INSC (Ordinals)",
        "ar": "إن إس سي (أوردينالز)"
    },
    {
        "en": "ALEX $B20",
        "ar": "أليكس $بي 20"
    },
    {
        "en": "PIZA (Ordinals)",
        "ar": "بيزا (أوردينالز)"
    },
    {
        "en": "NALS",
        "ar": "نالس"
    },
    {
        "en": "BANK (Ordinals)",
        "ar": "بانك (أوردينالز)"
    },
    {
        "en": "PEPE (Ordinals)",
        "ar": "بيبي (أوردينالز)"
    },
    {
        "en": "Wrapped OrdBridge",
        "ar": "أورد بريدج مغلف"
    },
    {
        "en": "MOON (Ordinals)",
        "ar": "مون (أوردينالز)"
    },
    {
        "en": "MEME (Ordinals)",
        "ar": "ميم (أوردينالز)"
    },
    {
        "en": "ZBIT (Ordinals)",
        "ar": "زد بي آي تي (أوردينالز)"
    },
    {
        "en": "The Ordinals Council",
        "ar": "مجلس الأوردينالز"
    },
    {
        "en": "ORPO",
        "ar": "أوربو"
    },
    {
        "en": "SHIB (Ordinals)",
        "ar": "شيب (أوردينالز)"
    },
    {
        "en": "XING",
        "ar": "إكسينج"
    },
    {
        "en": "BISO",
        "ar": "بيسو"
    },
    {
        "en": "OSHI",
        "ar": "أوشي"
    },
    {
        "en": "OSPY",
        "ar": "أوسبي"
    },
    {
        "en": "PUPS (Ordinals)",
        "ar": "ببس (أوردينالز)"
    },
    {
        "en": "DRAC (Ordinals)",
        "ar": "دراك (أوردينالز)"
    },
    {
        "en": "$BSV",
        "ar": "$بي إس في"
    },
    {
        "en": "NOOT (Ordinals)",
        "ar": "نوت (أوردينالز)"
    },
    {
        "en": "Black Sats (Ordinals)",
        "ar": "بلاك ساتس (أوردينالز)"
    },
    {
        "en": "RSSC",
        "ar": "آر إس إس سي"
    },
    {
        "en": "WHEE",
        "ar": "وي"
    },
    {
        "en": "JPEG (Ordinals)",
        "ar": "جي بي إي جي (أوردينالز)"
    },
    {
        "en": "Grumpy",
        "ar": "غرومبي"
    },
    {
        "en": "$DOG (Ordinals)",
        "ar": "$دوج (أوردينالز)"
    },
    {
        "en": "MAXI (Ordinals)",
        "ar": "ماكسي (أوردينالز)"
    },
    {
        "en": "Mice (Ordinals)",
        "ar": "مايس (أوردينالز)"
    },
    {
        "en": "MMSS (Ordinals)",
        "ar": "إم إم إس إس (أوردينالز)"
    },
    {
        "en": "BONK BITCOIN (Ordinals)",
        "ar": "بونك بيتكوين (أوردينالز)"
    },
    {
        "en": "Across Protocol",
        "ar": "أكروس بروتوكول"
    },
    {
        "en": "BoringDAO",
        "ar": "بورينج داو"
    },
    {
        "en": "Hop Protocol",
        "ar": "هوب بروتوكول"
    },
    {
        "en": "Allbridge",
        "ar": "أول بريدج"
    },
    {
        "en": "Interport Token",
        "ar": "إنتربورت توكن"
    },
    {
        "en": "O3 Swap",
        "ar": "أو 3 سواب"
    },
    {
        "en": "XY Finance",
        "ar": "إكس واي فاينانس"
    },
    {
        "en": "AllianceBlock Nexera",
        "ar": "أليانس بلوك نيكسيرا"
    },
    {
        "en": "StrikeX",
        "ar": "سترايك إكس"
    },
    {
        "en": "LEOX",
        "ar": "ليو إكس"
    },
    {
        "en": "Brickken",
        "ar": "بريكن"
    },
    {
        "en": "Mimo Governance",
        "ar": "ميمو جوفرنانس"
    },
    {
        "en": "Blocksquare",
        "ar": "بلوك سكوير"
    },
    {
        "en": "Regen",
        "ar": "ريجين"
    },
    {
        "en": "Stobox",
        "ar": "ستوبوكس"
    },
    {
        "en": "KlimaDAO",
        "ar": "كليما داو"
    },
    {
        "en": "Defactor",
        "ar": "ديفاكتور"
    },
    {
        "en": "Landshare",
        "ar": "لاندشير"
    },
    {
        "en": "Xend Finance",
        "ar": "إكسند فاينانس"
    },
    {
        "en": "Credefi",
        "ar": "كريدفي"
    },
    {
        "en": "LandX Governance Token",
        "ar": "لاند إكس جوفرنانس توكن"
    },
    {
        "en": "Soil",
        "ar": "سويل"
    },
    {
        "en": "Empowa",
        "ar": "إمبوا"
    },
    {
        "en": "Decentralized ETF",
        "ar": "ديسنترالايزد إي تي إف"
    },
    {
        "en": "Lucidao",
        "ar": "لوسيداو"
    },
    {
        "en": "IXO",
        "ar": "إكس أو"
    },
    {
        "en": "IMO",
        "ar": "إي إم أو"
    },
    {
        "en": "CryptoFranc",
        "ar": "كريبتو فرانك"
    },
    {
        "en": "TIA",
        "ar": "تي آي إي"
    },
    {
        "en": "Epoch Island",
        "ar": "إيبوك إيلاند"
    },
    {
        "en": "Florence Finance Medici",
        "ar": "فلورنس فاينانس ميديشي"
    },
    {
        "en": "WECOIN",
        "ar": "وي كوين"
    },
    {
        "en": "UREEQA",
        "ar": "يوريكا"
    },
    {
        "en": "Osean",
        "ar": "أوشيان"
    },
    {
        "en": "Barter",
        "ar": "بارتر"
    },
    {
        "en": "Aktionariat Alan Frei Company Tokenized Shares",
        "ar": "أكتيوناريات ألان فراي كومباني توكنايزد شيرز"
    },
    {
        "en": "Draggable Aktionariat AG",
        "ar": "دراجابل أكتيوناريات أي جي"
    },
    {
        "en": "International Stable Currency",
        "ar": "إنترناشيونال ستابل كيرنسي"
    },
    {
        "en": "INX Token",
        "ar": "إن إكس توكن"
    },
    {
        "en": "Backed IB01 $ Treasury Bond 0-1yr",
        "ar": "باكد آي بي 01 $ تريزري بوند 0-1 سنة"
    },
    {
        "en": "Realio",
        "ar": "ريليو"
    },
    {
        "en": "Galatasaray Fan Token",
        "ar": "جالاتاساراي فان توكن"
    },
    {
        "en": "Inter Milan Fan Token",
        "ar": "إنتر ميلان فان توكن"
    },
    {
        "en": "UFC Fan Token",
        "ar": "يو إف سي فان توكن"
    },
    {
        "en": "Trabzonspor Fan Token",
        "ar": "ترابزون سبور فان توكن"
    },
    {
        "en": "Aston Villa Fan Token",
        "ar": "أستون فيلا فان توكن"
    },
    {
        "en": "Napoli Fan Token",
        "ar": "نابولي فان توكن"
    },
    {
        "en": "Digital Fitness",
        "ar": "ديجيتال فتنس"
    },
    {
        "en": "Samsunspor Fan Token",
        "ar": "سامسون سبور فان توكن"
    },
    {
        "en": "Spain National Football Team Fan Token",
        "ar": "إسبانيا فان توكن"
    },
    {
        "en": "Everton Fan Token",
        "ar": "إيفرتون فان توكن"
    },
    {
        "en": "Brazil National Football Team Fan Token",
        "ar": "البرازيل فان توكن"
    },
    {
        "en": "Leeds United Fan Token",
        "ar": "ليدز يونايتد فان توكن"
    },
    {
        "en": "Aston Martin Cognizant Fan Token",
        "ar": "أستون مارتن كوجنيزانت فان توكن"
    },
    {
        "en": "Clube Atlético Mineiro Fan Token",
        "ar": "كلوبي أتليتيكو مينيرو فان توكن"
    },
    {
        "en": "Valencia CF Fan Token",
        "ar": "فالنسيا فان توكن"
    },
    {
        "en": "Young Boys Fan Token",
        "ar": "يونغ بويز فان توكن"
    },
    {
        "en": "İstanbul Başakşehir Fan Token",
        "ar": "إسطنبول باشاك شهير فان توكن"
    },
    {
        "en": "Sao Paulo FC Fan Token",
        "ar": "ساو باولو فان توكن"
    },
    {
        "en": "Pixer Eternity",
        "ar": "بيكسر إيتيرنيتي"
    },
    {
        "en": "Alanyaspor Fan Token",
        "ar": "ألانيا سبور فان توكن"
    },
    {
        "en": "Apollon Limassol Fan Token",
        "ar": "أبولون ليماسول فان توكن"
    },
    {
        "en": "Roush Fenway Racing Fan Token",
        "ar": "روش فينواي ريسينج فان توكن"
    },
    {
        "en": "Dinamo Zagreb Fan Token",
        "ar": "دينامو زغرب فان توكن"
    },
    {
        "en": "Davis Cup Fan Token",
        "ar": "كأس ديفيس فان توكن"
    },
    {
        "en": "Shirtum",
        "ar": "شيرتوم"
    },
    {
        "en": "Sint-Truidense Voetbalvereniging Fan Token",
        "ar": "سينت ترودنس فان توكن"
    },
    {
        "en": "Club Atletico Independiente Fan Token",
        "ar": "كلوب أتليتيكو إنديبندينتي فان توكن"
    },
    {
        "en": "Rangers Fan Token",
        "ar": "رينجرز فان توكن"
    },
    {
        "en": "Novara Calcio Fan Token",
        "ar": "نوفارا كالتشيو فان توكن"
    },
    {
        "en": "NoLimitCoin",
        "ar": "نو ليميت كوين"
    },
    {
        "en": "Karşıyaka Taraftar Fan Token",
        "ar": "كارشياكا تارافتار فان توكن"
    },
    {
        "en": "Türkiye Basketbol Federasyonu Fan Token",
        "ar": "تركيا فان توكن"
    },
    {
        "en": "Rage.Fan",
        "ar": "ريج فان"
    },
    {
        "en": "Ankaragücü Fan Token",
        "ar": "أنقرة جوجو فان توكن"
    },
    {
        "en": "2ACoin",
        "ar": "2 أي كوين"
    },
    {
        "en": "Eskişehir Fan Token",
        "ar": "إسكي شهير فان توكن"
    },
    {
        "en": "Beşiktaş",
        "ar": "بشكتاش"
    },
    {
        "en": "Denizlispor Fan Token",
        "ar": "دينيزلي سبور فان توكن"
    },
    {
        "en": "Duel Network",
        "ar": "شبكة دويل"
    },
    {
        "en": "ZenSports",
        "ar": "زين سبورتس"
    },
    {
        "en": "DexTools",
        "ar": "ديكس تولز"
    },
    {
        "en": "GraphLinq Protocol",
        "ar": "جراف لينك بروتوكول"
    },
    {
        "en": "APY.vision",
        "ar": "أي بي واي.فيجن"
    },
    {
        "en": "DataBot",
        "ar": "داتا بوت"
    },
    {
        "en": "AstroTools",
        "ar": "أسترو تولز"
    },
    {
        "en": "KROWN",
        "ar": "كرون"
    },
    {
        "en": "JDB",
        "ar": "جي دي بي"
    },
    {
        "en": "Vulkania",
        "ar": "فولكانيا"
    },
    {
        "en": "ApeX",
        "ar": "أيب إكس"
    },
    {
        "en": "Tigris",
        "ar": "تيجريس"
    },
    {
        "en": "Mummy Finance",
        "ar": "مامي فاينانس"
    },
    {
        "en": "handle.fi",
        "ar": "هاندل.فاي"
    },
    {
        "en": "Tracer DAO",
        "ar": "تريسر داو"
    },
    {
        "en": "OPX Finance",
        "ar": "أو بي إكس فاينانس"
    },
    {
        "en": "Avalaunch",
        "ar": "أفالانش"
    },
    {
        "en": "Solanium",
        "ar": "سولانيوم"
    },
    {
        "en": "Red Kite",
        "ar": "ريد كايت"
    },
    {
        "en": "PinkSale",
        "ar": "بينك سيل"
    },
    {
        "en": "UNCX Network",
        "ar": "يو إن سي إكس نتويرك"
    },
    {
        "en": "Market Making Pro",
        "ar": "ماركت ميكينج برو"
    },
    {
        "en": "BlackDragon",
        "ar": "بلاك دراجون"
    },
    {
        "en": "Oxbull Tech",
        "ar": "أوكسبول تيك"
    },
    {
        "en": "Gorilla",
        "ar": "غوريلا"
    },
    {
        "en": "Vent Finance",
        "ar": "فينت فاينانس"
    },
    {
        "en": "OccamFi",
        "ar": "أوكام فاي"
    },
    {
        "en": "BullPerks",
        "ar": "بول بيركس"
    },
    {
        "en": "Kommunitas",
        "ar": "كوميونيتاس"
    },
    {
        "en": "Infinity PAD",
        "ar": "إنفينيتي باد"
    },
    {
        "en": "Launchpool",
        "ar": "لانشبول"
    },
    {
        "en": "PEAKDEFI",
        "ar": "بيك ديفي"
    },
    {
        "en": "MoonEdge",
        "ar": "مون إيدج"
    },
    {
        "en": "TrustFi Network",
        "ar": "ترست فاي نتويرك"
    },
    {
        "en": "MoonStarter",
        "ar": "مون ستارتر"
    },
    {
        "en": "Superlauncher",
        "ar": "سوبر لانشر"
    },
    {
        "en": "OneArt",
        "ar": "ون آرت"
    },
    {
        "en": "Dastra Network",
        "ar": "داسترا نتويرك"
    },
    {
        "en": "DxSale Network",
        "ar": "ديكس سيل نتويرك"
    },
    {
        "en": "SphereSXS",
        "ar": "سفير إس إكس إس"
    },
    {
        "en": "CyberFi",
        "ar": "سايبر فاي"
    },
    {
        "en": "Babylons",
        "ar": "بابيلونز"
    },
    {
        "en": "Polinate",
        "ar": "بولينيت"
    },
    {
        "en": "BitStarters",
        "ar": "بيت ستارترز"
    },
    {
        "en": "DLP Duck",
        "ar": "دي إل بي داك"
    },
    {
        "en": "KickPad",
        "ar": "كيك باد"
    },
    {
        "en": "FantomStarter",
        "ar": "فانتوم ستارتر"
    },
    {
        "en": "Poolz Finance [OLD]",
        "ar": "بولز فاينانس [OLD]"
    },
    {
        "en": "Cardence",
        "ar": "كاردنس"
    },
    {
        "en": "Starter.xyz",
        "ar": "ستارتر.إكس واي زد"
    },
    {
        "en": "Levolution",
        "ar": "ليفوليوشن"
    },
    {
        "en": "Eclipse Fi",
        "ar": "إكليبس فاي"
    },
    {
        "en": "SeaPad",
        "ar": "سي باد"
    },
    {
        "en": "Crypto Hub",
        "ar": "كريبتو هاب"
    },
    {
        "en": "CoinxPad",
        "ar": "كوين إكس باد"
    },
    {
        "en": "Cardstarter",
        "ar": "كارد ستارتر"
    },
    {
        "en": "RazrFi",
        "ar": "رازر فاي"
    },
    {
        "en": "StarLaunch",
        "ar": "ستار لانش"
    },
    {
        "en": "Omnisea",
        "ar": "أومنيسيا"
    },
    {
        "en": "Lightning Protocol",
        "ar": "بروتوكول لايتنينج"
    },
    {
        "en": "HOPPY",
        "ar": "هوبي"
    },
    {
        "en": "Tomb",
        "ar": "تومب"
    },
    {
        "en": "Seigniorage Shares",
        "ar": "سينيوراج شيرز"
    },
    {
        "en": "Indigo Protocol",
        "ar": "بروتوكول إنديجو"
    },
    {
        "en": "PERI Finance",
        "ar": "بيري فاينانس"
    },
    {
        "en": "SORA Synthetics",
        "ar": "سورا سينثيتيكس"
    },
    {
        "en": "ANGLE",
        "ar": "أنجل"
    },
    {
        "en": "Yield Yak",
        "ar": "ييلد ياك"
    },
    {
        "en": "Bankless DAO",
        "ar": "بانكليس داو"
    },
    {
        "en": "Thorstarter",
        "ar": "ثور ستارتر"
    },
    {
        "en": "LiquidDriver",
        "ar": "ليكويد درايفر"
    },
    {
        "en": "Gro DAO",
        "ar": "جرو داو"
    },
    {
        "en": "Float Protocol",
        "ar": "بروتوكول فلوت"
    },
    {
        "en": "X World Games",
        "ar": "إكس وورلد جيمز"
    },
    {
        "en": "Spellfire",
        "ar": "سبيل فاير"
    },
    {
        "en": "Calvaria: DoE",
        "ar": "كالفاريا: دو إي"
    },
    {
        "en": "Hunny Finance",
        "ar": "هوني فاينانس"
    },
    {
        "en": "Zodium",
        "ar": "زوديوم"
    },
    {
        "en": "TaleCraft",
        "ar": "تيل كرافت"
    },
    {
        "en": "Operon Origins",
        "ar": "أوبرون أوريجينز"
    },
    {
        "en": "Heroes TD",
        "ar": "هيروز تي دي"
    },
    {
        "en": "Defina Finance",
        "ar": "ديفينا فاينانس"
    },
    {
        "en": "Deesse",
        "ar": "ديس"
    },
    {
        "en": "Galactic Arena: The NFTverse",
        "ar": "جالاكتيك أرينا: ذا إن إف تي فيرس"
    },
    {
        "en": "Mytheria",
        "ar": "ميثيريا"
    },
    {
        "en": "Morpheus Network",
        "ar": "مورفيوس نتويرك"
    },
    {
        "en": "AXEL",
        "ar": "أكسل"
    },
    {
        "en": "Terracoin",
        "ar": "تيراكوين"
    },
    {
        "en": "SafeDeal",
        "ar": "سيف ديل"
    },
    {
        "en": "Energi",
        "ar": "إنرجي"
    },
    {
        "en": "Honest",
        "ar": "هونست"
    },
    {
        "en": "Blocknet",
        "ar": "بلوك نت"
    },
    {
        "en": "BitCore",
        "ar": "بيت كور"
    },
    {
        "en": "Wagerr",
        "ar": "واجر"
    },
    {
        "en": "Stakecube",
        "ar": "ستيك كيوب"
    },
    {
        "en": "SmartCash",
        "ar": "سمارت كاش"
    },
    {
        "en": "Denarius",
        "ar": "ديناريوس"
    },
    {
        "en": "BiblePay",
        "ar": "بايبل باي"
    },
    {
        "en": "Hempcoin",
        "ar": "هيمب كوين"
    },
    {
        "en": "DogeCash",
        "ar": "دوج كاش"
    },
    {
        "en": "Etho Protocol",
        "ar": "بروتوكول إيثو"
    },
    {
        "en": "CatoCoin",
        "ar": "كاتو كوين"
    },
    {
        "en": "Bolivarcoin",
        "ar": "بوليفار كوين"
    },
    {
        "en": "SwiftCash",
        "ar": "سويفت كاش"
    },
    {
        "en": "Teloscoin",
        "ar": "تيلوس كوين"
    },
    {
        "en": "Neutron Coin",
        "ar": "نيوترون كوين"
    },
    {
        "en": "Arion",
        "ar": "أريون"
    },
    {
        "en": "Social Send",
        "ar": "سوشال سند"
    },
    {
        "en": "SparksPay",
        "ar": "سباركس باي"
    },
    {
        "en": "ColossusXT",
        "ar": "كولوسوس إكس تي"
    },
    {
        "en": "Venox",
        "ar": "فينوكس"
    },
    {
        "en": "VIVO",
        "ar": "فيفو"
    },
    {
        "en": "Beenode",
        "ar": "بينود"
    },
    {
        "en": "ZCore",
        "ar": "زد كور"
    },
    {
        "en": "Help The Homeless Coin",
        "ar": "هيلب ذا هومليس كوين"
    },
    {
        "en": "Acreage Coin",
        "ar": "أكريدج كوين"
    },
    {
        "en": "GoByte",
        "ar": "جو بايت"
    },
    {
        "en": "Monk",
        "ar": "مونك"
    },
    {
        "en": "MYCE",
        "ar": "مايس"
    },
    {
        "en": "Dinero",
        "ar": "دينيرو"
    },
    {
        "en": "Dextro",
        "ar": "ديكسترو"
    },
    {
        "en": "CryptoSaga",
        "ar": "كريبتو ساجا"
    },
    {
        "en": "Rupee",
        "ar": "روبي"
    },
    {
        "en": "Creamcoin",
        "ar": "كريم كوين"
    },
    {
        "en": "InsurAce",
        "ar": "إنسور إيس"
    },
    {
        "en": "Neptune Mutual",
        "ar": "نبتون ميوتوال"
    },
    {
        "en": "Tidal Finance",
        "ar": "تايدال فاينانس"
    },
    {
        "en": "ARMOR",
        "ar": "أرمور"
    },
    {
        "en": "Bridge Mutual",
        "ar": "بريدج ميوتوال"
    },
    {
        "en": "Bright Union",
        "ar": "برايت يونيون"
    },
    {
        "en": "UNION Protocol Governance",
        "ar": "يونيون بروتوكول جوفرنانس"
    },
    {
        "en": "Nsure Network",
        "ar": "إنسور نتويرك"
    },
    {
        "en": "Cover Protocol",
        "ar": "كوفر بروتوكول"
    },
    {
        "en": "veCRV-DAO yVault",
        "ar": "في كرف-دي أي أو واي فولت"
    },
    {
        "en": "Yearn Compounding veCRV yVault",
        "ar": "ييرن كومباوندينج في كرف واي فولت"
    },
    {
        "en": "Galvan",
        "ar": "جالفان"
    },
    {
        "en": "MoveZ",
        "ar": "موف زد"
    },
    {
        "en": "MoveCash",
        "ar": "موف كاش"
    },
    {
        "en": "Step",
        "ar": "ستيب"
    },
    {
        "en": "Hippocrat",
        "ar": "هيبوكرات"
    },
    {
        "en": "VitaDAO",
        "ar": "فيتا داو"
    },
    {
        "en": "Data Lake",
        "ar": "داتا ليك"
    },
    {
        "en": "HairDAO",
        "ar": "هير داو"
    },
    {
        "en": "Rejuve.AI",
        "ar": "ريجوفي.أي آي"
    },
    {
        "en": "Molecules of Korolchuk IP-NFT",
        "ar": "موليكيولز أوف كورولتشوك آي بي-إن إف تي"
    },
    {
        "en": "AthenaDAO",
        "ar": "أثينا داو"
    },
    {
        "en": "GenomesDAO",
        "ar": "جينومز داو"
    },
    {
        "en": "ValleyDAO",
        "ar": "فالي داو"
    },
    {
        "en": "ClinTex CTi",
        "ar": "كلين تيكس سي تي"
    },
    {
        "en": "Triall",
        "ar": "تريال"
    },
    {
        "en": "Galeon",
        "ar": "جاليون"
    },
    {
        "en": "MedicalVeda",
        "ar": "ميديكال فيدا"
    },
    {
        "en": "CryoDAO",
        "ar": "كريو داو"
    },
    {
        "en": "KILT Protocol",
        "ar": "بروتوكول كيلت"
    },
    {
        "en": "Metaverse ETP",
        "ar": "ميتافيرس إي تي بي"
    },
    {
        "en": "Authencity",
        "ar": "أوثنسيتي"
    },
    {
        "en": "BaaSid",
        "ar": "با سيد"
    },
    {
        "en": "Lyra Finance",
        "ar": "ليرا فاينانس"
    },
    {
        "en": "Buffer Token",
        "ar": "بافر توكن"
    },
    {
        "en": "SX Network (OLD)",
        "ar": "إس إكس نتويرك (OLD)"
    },
    {
        "en": "Augur",
        "ar": "أوجور"
    },
    {
        "en": "Polkamarkets",
        "ar": "بولكا ماركتس"
    },
    {
        "en": "Zeitgeist",
        "ar": "زايتجيست"
    },
    {
        "en": "PlotX",
        "ar": "بلوت إكس"
    },
    {
        "en": "Finance Vote",
        "ar": "فاينانس فوت"
    },
    {
        "en": "Kineko",
        "ar": "كينيكو"
    },
    {
        "en": "Bepro",
        "ar": "بيبرو"
    },
    {
        "en": "GMBL COMPUTER CHiP",
        "ar": "جي إم بي إل كمبيوتر تشيب"
    },
    {
        "en": "WickedBet Casino",
        "ar": "ويكيد بيت كازينو"
    },
    {
        "en": "BetSwirl",
        "ar": "بيت سويرل"
    },
    {
        "en": "EarnBet",
        "ar": "إيرن بيت"
    },
    {
        "en": "Lotty",
        "ar": "لوتي"
    },
    {
        "en": "Hamsters",
        "ar": "هامسترز"
    },
    {
        "en": "Lunafi",
        "ar": "لونافي"
    },
    {
        "en": "0xCoco",
        "ar": "0xCoco"
    },
    {
        "en": "888tron",
        "ar": "888 ترون"
    },
    {
        "en": "Renzo Restaked ETH",
        "ar": "رينزو ريستاكد إي تي إتش"
    },
    {
        "en": "JPEG'd",
        "ar": "جي بي إي جي دي"
    },
    {
        "en": "Arcade DAO",
        "ar": "أركيد داو"
    },
    {
        "en": "Hytopia",
        "ar": "هايتوبيا"
    },
    {
        "en": "Gold Fever Native Gold",
        "ar": "جولد فيفر نايتيف جولد"
    },
    {
        "en": "SHILL Token",
        "ar": "شيل توكن"
    },
    {
        "en": "Hero Blaze: Three Kingdoms",
        "ar": "هيرو بلايز: ثري كينغدومز"
    },
    {
        "en": "Wizardia",
        "ar": "ويزارديا"
    },
    {
        "en": "StarShip",
        "ar": "ستار شيب"
    },
    {
        "en": "Heroes & Empires",
        "ar": "هيروز آند إمبايرز"
    },
    {
        "en": "Demole",
        "ar": "ديمول"
    },
    {
        "en": "Infinity Skies",
        "ar": "إنفينيتي سكايز"
    },
    {
        "en": "Luna Rush",
        "ar": "لونا راش"
    },
    {
        "en": "MetaGods",
        "ar": "ميتا جودز"
    },
    {
        "en": "Fight Of The Ages",
        "ar": "فايت أوف ذا أيجز"
    },
    {
        "en": "Scotty Beam",
        "ar": "سكوتي بيم"
    },
    {
        "en": "Arker",
        "ar": "أركر"
    },
    {
        "en": "EDEN",
        "ar": "إيدن"
    },
    {
        "en": "B.Protocol",
        "ar": "بي بروتوكول"
    },
    {
        "en": "Alchemist",
        "ar": "ألكيميست"
    },
    {
        "en": "Coinback",
        "ar": "كوين باك"
    },
    {
        "en": "XCAD Network",
        "ar": "إكس كاد نتويرك"
    },
    {
        "en": "Blockasset",
        "ar": "بلوك أسيت"
    },
    {
        "en": "Lossless",
        "ar": "لوسليس"
    },
    {
        "en": "Gamestarter",
        "ar": "جيم ستارتر"
    },
    {
        "en": "BBS Network",
        "ar": "بي بي إس نتويرك"
    },
    {
        "en": "Crowny",
        "ar": "كروني"
    },
    {
        "en": "Convergence",
        "ar": "كونفيرجنس"
    },
    {
        "en": "Bitlocus",
        "ar": "بيتلوكس"
    },
    {
        "en": "ChainPort",
        "ar": "شين بورت"
    },
    {
        "en": "Fantom Maker",
        "ar": "فانتوم ميكر"
    },
    {
        "en": "LOCG",
        "ar": "لوكج"
    },
    {
        "en": "delta.theta",
        "ar": "دلتا.ثيتا"
    },
    {
        "en": "MetaSoccer",
        "ar": "ميتا سوكر"
    },
    {
        "en": "Brokoli",
        "ar": "بروكولي"
    },
    {
        "en": "Smoothy",
        "ar": "سموثي"
    },
    {
        "en": "DeFiato",
        "ar": "ديفياتو"
    },
    {
        "en": "Ftribe Fighters",
        "ar": "فترايب فايترز"
    },
    {
        "en": "Spherium",
        "ar": "سفيريوم"
    },
    {
        "en": "Knit Finance",
        "ar": "نيت فاينانس"
    },
    {
        "en": "KingdomX",
        "ar": "كينغدوم إكس"
    },
    {
        "en": "The Crypto Prophecies",
        "ar": "ذا كريبتو بروفيسيز"
    },
    {
        "en": "ShopNext Loyalty Token",
        "ar": "شوب نكست لويالتي توكن"
    },
    {
        "en": "Yield Protocol",
        "ar": "بروتوكول ييلد"
    },
    {
        "en": "Aluna",
        "ar": "ألونا"
    },
    {
        "en": "Formation FI",
        "ar": "فورميشن فاي"
    },
    {
        "en": "Pera Finance",
        "ar": "بيرا فاينانس"
    },
    {
        "en": "KAKA NFT World",
        "ar": "كاكا إن إف تي وورلد"
    },
    {
        "en": "Lever Network",
        "ar": "ليفر نتويرك"
    },
    {
        "en": "Solice",
        "ar": "سوليس"
    },
    {
        "en": "1Sol",
        "ar": "1 سول"
    },
    {
        "en": "BaconDAO",
        "ar": "بيكون داو"
    },
    {
        "en": "SB Group",
        "ar": "إس بي جروب"
    },
    {
        "en": "MetaGaming Guild",
        "ar": "ميتا جيمنج جيلد"
    },
    {
        "en": "KALM",
        "ar": "كالم"
    },
    {
        "en": "Auto",
        "ar": "أوتو"
    },
    {
        "en": "ACryptoS [OLD]",
        "ar": "أي كريبتو إس [OLD]"
    },
    {
        "en": "Mover",
        "ar": "موفر"
    },
    {
        "en": "Oh! Finance",
        "ar": "أوه! فاينانس"
    },
    {
        "en": "Pancake Bunny",
        "ar": "بانكيك باني"
    },
    {
        "en": "ACryptoS",
        "ar": "أي كريبتو إس"
    },
    {
        "en": "Kinesis Gold",
        "ar": "كينيسيس جولد"
    },
    {
        "en": "Comtech Gold",
        "ar": "كومتيك جولد"
    },
    {
        "en": "Meld Gold",
        "ar": "ميلد جولد"
    },
    {
        "en": "Lyfe Gold",
        "ar": "ليف جولد"
    },
    {
        "en": "Holdstation",
        "ar": "هولد ستيشن"
    },
    {
        "en": "Blocto",
        "ar": "بلوكتو"
    },
    {
        "en": "Send",
        "ar": "سند"
    },
    {
        "en": "A3S",
        "ar": "أي 3 إس"
    },
    {
        "en": "Brillion",
        "ar": "بريليون"
    },
    {
        "en": "THORWallet DEX",
        "ar": "ثور واليت دي إي إكس"
    },
    {
        "en": "Giddy",
        "ar": "جيدي"
    },
    {
        "en": "Aurox",
        "ar": "أوروكس"
    },
    {
        "en": "iMe Lab",
        "ar": "آي مي لاب"
    },
    {
        "en": "PLC Ultima Classic",
        "ar": "بي إل سي ألتيما كلاسيك"
    },
    {
        "en": "PLC Ultima",
        "ar": "بي إل سي ألتيما"
    },
    {
        "en": "Creso",
        "ar": "كريسو"
    },
    {
        "en": "Bracelet",
        "ar": "براسليت"
    },
    {
        "en": "Blink Galaxy",
        "ar": "بلينك جالاكسي"
    },
    {
        "en": "Minted",
        "ar": "مينتد"
    },
    {
        "en": "X2Y2",
        "ar": "إكس 2 واي 2"
    },
    {
        "en": "sudoswap",
        "ar": "سودو سواب"
    },
    {
        "en": "Dream",
        "ar": "دريم"
    },
    {
        "en": "ByteNext",
        "ar": "بايت نكست"
    },
    {
        "en": "OVO",
        "ar": "أوفو"
    },
    {
        "en": "Whales Market",
        "ar": "ويلز ماركت"
    },
    {
        "en": "Jupiter",
        "ar": "جوبيتر"
    },
    {
        "en": "DeFi",
        "ar": "دي فاي"
    },
    {
        "en": "PepeFork",
        "ar": "بيبي فورك"
    },
    {
        "en": "VaultCraft",
        "ar": "فولت كرافت"
    },
    {
        "en": "Datamall Coin",
        "ar": "داتامال كوين"
    },
    {
        "en": "ZetaChain",
        "ar": "زيتا تشين"
        
    },
    {
        "en": "AdEx",
        "ar": "أد إكس"
    },
    {
        "en": "Inscribe",
        "ar": "إنسكرايب"
    },
    {
        "en": "Only1",
        "ar": "أونلي 1"
    },
    {
        "en": "Mint Club",
        "ar": "مينت كلوب"
    },
    {
        "en": "r/FortNiteBR Bricks",
        "ar": "آر/فورت نايت بي آر بريكس"
    },
    {
        "en": "Global Coin Research",
        "ar": "جلوبال كوين ريسيرش"
    },
    {
        "en": "Donut",
        "ar": "دونت"
    },
    {
        "en": "Friends With Benefits Pro",
        "ar": "فريندز ويث بينفيتس برو"
    },
    {
        "en": "Olyverse",
        "ar": "أوليفيرس"
    },
    {
        "en": "Perpy Finance",
        "ar": "بيربي فاينانس"
    },
    {
        "en": "MORK",
        "ar": "مورك"
    },
    {
        "en": "Yup",
        "ar": "يوب"
    },
    {
        "en": "Carbon",
        "ar": "كاربون"
    },
    {
        "en": "Floyx",
        "ar": "فلويكس"
    },
    {
        "en": "Minds",
        "ar": "مايندز"
    },
    {
        "en": "Karma DAO",
        "ar": "كارما داو"
    },
    {
        "en": "SoMee.Social",
        "ar": "سومي.سوشيال"
    },
    {
        "en": "Forefront",
        "ar": "فورفرونت"
    },
    {
        "en": "INTEXCOIN",
        "ar": "إنتكس كوين"
    },
    {
        "en": "Myriad Social",
        "ar": "ميرياد سوشيال"
    },
    {
        "en": "Main",
        "ar": "ماين"
    },
    {
        "en": "Rally (Solana)",
        "ar": "رالي (سولانا)"
    },
    {
        "en": "Reach",
        "ar": "ريتش"
    },
    {
        "en": "ReadFi",
        "ar": "ريد فاي"
    },
    {
        "en": "OpenChat",
        "ar": "أوبن شات"
    },
    {
        "en": "Calaxy",
        "ar": "كالاكسي"
    },
    {
        "en": "Blurt",
        "ar": "بلورت"
    },
    {
        "en": "Heroes of Mavia",
        "ar": "هيروز أوف مافيا"
    },
    {
        "en": "Vector Reserve",
        "ar": "فيكتور ريزيرف"
    },
    {
        "en": "bozo Hybrid",
        "ar": "بوزو هايبريد"
    },
    {
        "en": "DeFrogs",
        "ar": "دي فروجز"
    },
    {
        "en": "Pandora",
        "ar": "باندورا"
    },
    {
        "en": "Cellframe",
        "ar": "سيل فريم"
    },
    {
        "en": "FARCANA",
        "ar": "فاركانا"
    },
    {
        "en": "Reboot",
        "ar": "ريبوت"
    },
    {
        "en": "Veloce",
        "ar": "فيلوس"
    },
    {
        "en": "Rug",
        "ar": "رج"
    },
    {
        "en": "Pet the Dog",
        "ar": "بيت ذا دوج"
    },
    {
        "en": "Anon",
        "ar": "أنون"
    },
    {
        "en": "Crystal",
        "ar": "كريستال"
    },
    {
        "en": "EtherRock404",
        "ar": "إيثر روك 404"
    },
    {
        "en": "YES",
        "ar": "يس"
    },
    {
        "en": "gh0stc0in",
        "ar": "غوست كوين"
    },
    {
        "en": "LootBot",
        "ar": "لوت بوت"
    },
    {
        "en": "HyperGPT",
        "ar": "هايبر جي بي تي"
    },
    {
        "en": "$REKT",
        "ar": "$ريكت"
    },
    {
        "en": "PaLM AI",
        "ar": "بالم آي إي"
    },
    {
        "en": "Mizar",
        "ar": "ميزار"
    },
    {
        "en": "Gyoza",
        "ar": "جيوزا"
    },
    {
        "en": "On-Chain Dynamics",
        "ar": "أون-تشين ديناميكس"
    },
    {
        "en": "PIRB",
        "ar": "بيرب"
    },
    {
        "en": "Axe Cap",
        "ar": "أكس كاب"
    },
    {
        "en": "Fluid",
        "ar": "فلويد"
    },
    {
        "en": "Wagie Bot",
        "ar": "واجي بوت"
    },
    {
        "en": "CrocBot",
        "ar": "كروك بوت"
    },
    {
        "en": "AlphaScan",
        "ar": "ألفا سكان"
    },
    {
        "en": "NerdBot",
        "ar": "نيرد بوت"
    },
    {
        "en": "MoonBot",
        "ar": "مون بوت"
    },
    {
        "en": "Nchart Token",
        "ar": "إن تشارت توكن"
    },
    {
        "en": "BlazeX",
        "ar": "بلايز إكس"
    },
    {
        "en": "Soul Scanner",
        "ar": "سول سكانر"
    },
    {
        "en": "Espresso Bot",
        "ar": "إسبريسو بوت"
    },
    {
        "en": "TokenBot",
        "ar": "توكن بوت"
    },
    {
        "en": "FusionBot",
        "ar": "فيوجن بوت"
    },
    {
        "en": "ZKitty Bot",
        "ar": "زي كيتي بوت"
    },
    {
        "en": "FrenBot",
        "ar": "فرين بوت"
    },
    {
        "en": "BoltBot",
        "ar": "بولت بوت"
    },
    {
        "en": "NeoBot",
        "ar": "نيو بوت"
    },
    {
        "en": "BetBot",
        "ar": "بيت بوت"
    },
    {
        "en": "Niko",
        "ar": "نيكو"
    },
    {
        "en": "FIO Protocol",
        "ar": "فيو بروتوكول"
    },
    {
        "en": "Acet",
        "ar": "أسيت"
    },
    {
        "en": "OpSec",
        "ar": "أوب سيك"
    },
    {
        "en": "Gamer Arena",
        "ar": "جيمر أرينا"
    },
    {
        "en": "PKT",
        "ar": "بي كي تي"
    },
    {
        "en": "Pixels",
        "ar": "بيكسلز"
    },
    {
        "en": "PlatON Network",
        "ar": "بلاتون نتويرك"
    },
    {
        "en": "Aerodrome Finance",
        "ar": "أيرودروم فاينانس"
    },
    {
        "en": "Taki Games",
        "ar": "تاكي جيمز"
    },
    {
        "en": "Portal",
        "ar": "بورتال"
    },
    {
        "en": "Moby",
        "ar": "موبي"
    },
    {
        "en": "Abelian",
        "ar": "أبيليان"
    },
    {
        "en": "Grape",
        "ar": "جريب"
    },
    {
        "en": "Mantle Staked Ether",
        "ar": "مانتل ستاكد إيثر"
    },
    {
        "en": "AtPay",
        "ar": "أت باي"
    },
    {
        "en": "AxonDAO Governance Token",
        "ar": "أكسون داو جوفرنانس توكن"
    },
    {
        "en": "Harambe on Solana",
        "ar": "هارامبي أون سولانا"
    },
    {
        "en": "Planet Token",
        "ar": "بلانيت توكن"
    },
    {
        "en": "Orange",
        "ar": "أورانج"
    },
    {
        "en": "Ozone Chain",
        "ar": "أوزون تشين"
    },
    {
        "en": "SatoshiVM",
        "ar": "ساتوشي في إم"
    },
    {
        "en": "Aevo",
        "ar": "أيفو"
    },
    {
        "en": "Entangle",
        "ar": "إنتانجل"
    },
    {
        "en": "Degen (Base)",
        "ar": "ديجين"
    },
    {
        "en": "REI Network",
        "ar": "ري نتويرك"
    },
    {
        "en": "BOOK OF MEME",
        "ar": "بوك أوف ميم"
    },
    {
        "en": "Metars Genesis",
        "ar": "ميتارز جينيسيس"
    },
    {
        "en": "Virtual Protocol",
        "ar": "فيرتشوال بروتوكول"
    },
    {
        "en": "Fluence",
        "ar": "فلونس"
    },
    {
        "en": "Cratos",
        "ar": "كراتوس"
    },
    {
        "en": "Arcas",
        "ar": "أركاس"
    },
    {
        "en": "Scallop",
        "ar": "سكالوب"
    },
    {
        "en": "Syncus",
        "ar": "سينكوس"
    },
    {
        "en": "TG.Casino",
        "ar": "تي جي كازينو"
    },
    {
        "en": "DGI Game",
        "ar": "دي جي آي جيم"
    },
    {
        "en": "Propbase",
        "ar": "بروب بيس"
    },
    {
        "en": "Base God",
        "ar": "بيس جود"
    },
    {
        "en": "Peng",
        "ar": "بينج"
    },
    {
        "en": "SLG.GAMES",
        "ar": "إس إل جي جيمز"
    },
    {
        "en": "Catcoin",
        "ar": "كات كوين"
    },
    {
        "en": "lmeow",
        "ar": "إلمياو"
    },
    {
        "en": "Solchat",
        "ar": "سولشات"
    },
    {
        "en": "Mintlayer",
        "ar": "مينتلاير"
    },
    {
        "en": "Moonwell",
        "ar": "مونويل"
    },
    {
        "en": "cat in a dogs world",
        "ar": "قط في عالم الكلاب"
    },
    {
        "en": "Ether.fi",
        "ar": "إيثر.فاي"
    },
    {
        "en": "Smart Layer Network",
        "ar": "سمارت لاير نتويرك"
    },
    {
        "en": "LunarCrush",
        "ar": "لونار كرش"
    },
    {
        "en": "Extra Finance",
        "ar": "إكسترا فاينانس"
    },
    {
        "en": "Childrens Aid Foundation",
        "ar": "شيلدرينز إيد فاونديشن"
    },
    {
        "en": "Brett",
        "ar": "بريت"
    },
    {
        "en": "doginme",
        "ar": "دوج إن مي"
    },
    {
        "en": "Jeo Boden",
        "ar": "جيو بودن"
    },
    {
        "en": "NORMIE",
        "ar": "نورمي"
    },
    {
        "en": "Peapods Finance",
        "ar": "بيبودز فاينانس"
    },
    {
        "en": "NodeAI",
        "ar": "نود آي"
    },
    {
        "en": "Ethena",
        "ar": "إيثينا"
    },
    {
        "en": "Wormhole",
        "ar": "وورمهول"
    },
    {
        "en": "Artrade",
        "ar": "أرتريد"
    },
    {
        "en": "MEOW",
        "ar": "مياو"
    },
    {
        "en": "Zeus Network",
        "ar": "زيوس نتويرك"
    },
    {
        "en": "LayerZero",
        "ar": "لاير زيرو"
    },
    {
        "en": "Jarvis",
        "ar": "جارفيس"
    },
    {
        "en": "DOVU",
        "ar": "دوفو"
    },
    {
        "en": "Krav",
        "ar": "كراف"
    },
    {
        "en": "Saga",
        "ar": "ساجا"
    },
    {
        "en": "Lumerin",
        "ar": "لوميرين"
    },
    {
        "en": "VoluMint",
        "ar": "فولومينت"
    },
    {
        "en": "Black Phoenix",
        "ar": "بلاك فينيكس"
    },
    {
        "en": "higher",
        "ar": "هاير"
    },
    {
        "en": "Bitcoin Wizards",
        "ar": "بيتكوين ويزاردز"
    },
    {
        "en": "L7DEX",
        "ar": "إل 7 دي إي إكس"
    },
    {
        "en": "Boba Oppa",
        "ar": "بوبا أوبا"
    },
    {
        "en": "Coinye West",
        "ar": "كويني ويست"
    },
    {
        "en": "Pajamas Cat",
        "ar": "بجاماز كات"
    },
    {
        "en": "OX Coin",
        "ar": "أو إكس كوين"
    },
    {
        "en": "Zynecoin",
        "ar": "زاين كوين"
    },
    {
        "en": "crow with knife",
        "ar": "كرو ويث نايف"
    },
    {
        "en": "RETARDIO",
        "ar": "ريتارديو"
    },
    {
        "en": "Raini Studios Token",
        "ar": "رايني ستوديوز توكن"
    },
    {
        "en": "MUMU THE BULL",
        "ar": "مومو ذا بول"
    },
    {
        "en": "DOG•GO•TO•THE•MOON (Runes)",
        "ar": "دوج جو تو ذا مون"
    },
    {
        "en": "Super Trump",
        "ar": "سوبر ترامب"
    },
    {
        "en": "TARS Protocol",
        "ar": "بروتوكول تارس"
    },
    {
        "en": "Notcoin",
        "ar": "نوت كوين"
    },
    {
        "en": "MAGA Hat",
        "ar": "ماجا هات"
    },
    {
        "en": "MCOIN",
        "ar": "إم كوين"
    },
    {
        "en": "TON FISH MEMECOIN",
        "ar": "تون فيش ميم كوين"
    },
    {
        "en": "Friend.tech",
        "ar": "فريند تيك"
    },
    {
        "en": "ChompCoin",
        "ar": "تشومب كوين"
    },
    {
        "en": "GME",
        "ar": "جي إم إي"
    },
    {
        "en": "Keyboard Cat (Base)",
        "ar": "كيبورد كات"
    },
    {
        "en": "Elon",
        "ar": "إيلون"
    },
    {
        "en": "WUFFI",
        "ar": "وفي"
    },
    {
        "en": "Habibi (Sol)",
        "ar": "حبيبي"
    },
    {
        "en": "Doland Tremp",
        "ar": "دولاند تريمب"
    },
    {
        "en": "tooker kurlson",
        "ar": "توكر كورلسون"
    },
    {
        "en": "MAGA VP",
        "ar": "ماجا في بي"
    },
    {
        "en": "Kennedy Memecoin",
        "ar": "كينيدي ميم كوين"
    },
    {
        "en": "BABYTRUMP",
        "ar": "بيبي ترامب"
    },
    {
        "en": "Pepe Trump",
        "ar": "بيبي ترامب"
    },
    {
        "en": "DT Inu",
        "ar": "دي تي إنو"
    },
    {
        "en": "MAGA Coin BSC",
        "ar": "ماجا كوين بي إس سي"
    },
    {
        "en": "MILEI Token",
        "ar": "ميلي توكن"
    },
    {
        "en": "obema",
        "ar": "أوبيما"
    },
    {
        "en": "PEPE MAGA",
        "ar": "بيبي ماجا"
    },
    {
        "en": "Ruburt F Kenidy Jr",
        "ar": "روبرت إف كينيدي جونيور"
    },
    {
        "en": "TMANIA",
        "ar": "تيمانيا"
    },
    {
        "en": "Alickshundra Occasional-Cortex",
        "ar": "أليكشندرا أوكاشيونال-كورتيكس"
    },
    {
        "en": "TrumpCoin",
        "ar": "ترامب كوين"
    },
    {
        "en": "Karrat",
        "ar": "كارات"
    },
    {
        "en": "Undeads Games",
        "ar": "أنددز جيمز"
    },
    {
        "en": "catwifhat",
        "ar": "كات ويث هات"
    },
    {
        "en": "QuantCheck",
        "ar": "كوانتشيك"
    },
    {
        "en": "AI Analysis Token",
        "ar": "آي آي أناليسيس توكن"
    },
    {
        "en": "Synternet",
        "ar": "سينترنت"
    },
    {
        "en": "Baklava",
        "ar": "باكلافا"
    },
    {
        "en": "io.net",
        "ar": "آي أو.نت"
    },
    {
        "en": "Aethir",
        "ar": "إيثير"
    },
    {
        "en": "Nemo Sum",
        "ar": "نيمو سوم"
    },
    {
        "en": "Mother Iggy",
        "ar": "موذر إيجي"
    },
    {
        "en": "Moca Coin",
        "ar": "موكا كوين"
    },
    {
        "en": "Venom",
        "ar": "فينوم"
    },
    {
        "en": "Spike",
        "ar": "سبايك"
    },
    {
        "en": "HashAI",
        "ar": "هاش آي"
    },
    {
        "en": "Blendr Network",
        "ar": "بليندر نتويرك"
    },
    {
        "en": "Shark Cat",
        "ar": "شارك كات"
    },
    {
        "en": "LandWolf",
        "ar": "لاند وولف"
    },
    {
        "en": "Orby Network USC Stablecoin",
        "ar": "أوربي نتويرك يو إس سي ستابل كوين"
    },
    {
        "en": "WATER Coin",
        "ar": "واتر كوين"
    },
    {
        "en": "PeiPei",
        "ar": "بيبي"
    },
    {
        "en": "Wat",
        "ar": "وات"
    },
    {
        "en": "Artificial Superintelligence Alliance",
        "ar": "آرتيفيشيال سوبر إنتيليجنس أليانس"
    },
    {
        "en": "ZKsync",
        "ar": "زيد كي سينك"
    },
    {
        "en": "Mythos",
        "ar": "ميثوس"
    },
    {
        "en": "Pirate Nation Token",
        "ar": "بايريت نيشن توكن"
    },
    {
        "en": "Iron Fish",
        "ar": "آيرون فيش"
    },
    {
        "en": "Starknet",
        "ar": "ستارك نت"
    },
    {
        "en": "CYBER",
        "ar": "سايبر"
    },
    {
        "en": "UXD Protocol",
        "ar": "يو إكس دي بروتوكول"
    },
    {
        "en": "UXD Stablecoin",
        "ar": "يو إكس دي ستابل كوين"
    },
    {
        "en": "MAD",
        "ar": "ماد"
    },
    {
        "en": "School Hack Coin",
        "ar": "سكول هاك كوين"
    },
    {
        "en": "Zentry",
        "ar": "زينتري"
    },
    {
        "en": "SAD HAMSTER",
        "ar": "ساد هامستر"
    },
    {
        "en": "BurnedFi",
        "ar": "بيرند فاي"
    },
    {
        "en": "Beercoin",
        "ar": "بير كوين"
    },
    {
        "en": "zkLink",
        "ar": "زيد كي لينك"
    },
    {
        "en": "LOCK IN",
        "ar": "لوك إن"
    },
    {
        "en": "IRISnet",
        "ar": "آيريس نت"
    },
    {
        "en": "Gigachad",
        "ar": "جيجاشاد"
    },
    {
        "en": "Resistance Dog",
        "ar": "ريزيستانس دوج"
    },
    {
        "en": "Mongy",
        "ar": "مونجي"
    },
    {
        "en": "Data Ownership Protocol",
        "ar": "بروتوكول ملكية البيانات"
    },
    {
        "en": "Qubic",
        "ar": "كيوبيك"
    },
    {
        "en": "Waffles",
        "ar": "وافلز"
    },
    {
        "en": "Avail",
        "ar": "أفيل"
    },
    {
        "en": "Fight to MAGA",
        "ar": "فايت تو ماجا"
    },
    {
        "en": "Mister Miggles",
        "ar": "ميستر ميجلز"
    },
    {
        "en": "Everyworld",
        "ar": "إيفري وورلد"
    },
    {
        "en": "Blast",
        "ar": "بلاست"
    },
    {
        "en": "Everclear",
        "ar": "إيفركلير"
    },
    {
        "en": "Settled EthXY Token",
        "ar": "سيتلد إيث واي إكس واي توكن"
    },
    {
        "en": "FingerprintsDAO",
        "ar": "فينجربرينتس داو"
    },
    {
        "en": "Alongside Crypto Market Index",
        "ar": "ألونجسايد كريبتو ماركت إندكس"
    },
    {
        "en": "ECO",
        "ar": "إيكو"
    },
    {
        "en": "Reflexer Ungovernance",
        "ar": "ريفليكسر أنجوفيرنانس"
    },
    {
        "en": "zKML",
        "ar": "زيد كي إم إل"
    },
    {
        "en": "NEURALAI",
        "ar": "نيورال آي"
    },
    {
        "en": "Billy",
        "ar": "بيلي"
    },
    {
        "en": "mini",
        "ar": "ميني"
    },
    {
        "en": "ZynCoin",
        "ar": "زين كوين"
    },
    {
        "en": "Commune AI",
        "ar": "كوميون آي"
    },
    {
        "en": "Ekubo Protocol",
        "ar": "إيكوبو بروتوكول"
    },
    {
        "en": "zkLend",
        "ar": "زيد كي ليند"
    },
    {
        "en": "Continuum Finance",
        "ar": "كونتينيوم فاينانس"
    },
    {
        "en": "USDH",
        "ar": "يو إس دي إتش"
    },
    {
        "en": "Apricot",
        "ar": "أبريكوت"
    },
    {
        "en": "Orion Money",
        "ar": "أوريون موني"
    },
    {
        "en": "Solrise Finance",
        "ar": "سولرايز فاينانس"
    },
    {
        "en": "Arrow Token",
        "ar": "أرو توكن"
    },
    {
        "en": "ALL.ART",
        "ar": "آل.آر.تي"
    },
    {
        "en": "Juice Finance",
        "ar": "جوس فاينانس"
    },
    {
        "en": "Param",
        "ar": "بارام"
    },
    {
        "en": "Crypto Unicorns",
        "ar": "كريبتو يونيكورنز"
    },
    {
        "en": "Covalent Query Token",
        "ar": "كوفالنت كويري توكن"
    },
    {
        "en": "Ctrl",
        "ar": "كنترول"
    },
    {
        "en": "Nyan Heroes",
        "ar": "نيان هيروز"
    },
    {
        "en": "STON",
        "ar": "ستون"
    },
    {
        "en": "HYCHAIN",
        "ar": "هاي تشين"
    },
    {
        "en": "MON Protocol",
        "ar": "مون بروتوكول"
    },
    {
        "en": "BILLION•DOLLAR•CAT (Runes)",
        "ar": "بيليون دولار كات"
    },
    {
        "en": "QuarkChain",
        "ar": "كوارك تشين"
    },
    {
        "en": "CyberDEX",
        "ar": "سايبر دي إي إكس"
    },
    {
        "en": "michi",
        "ar": "ميشي"
    },
    {
        "en": "PAW",
        "ar": "باو"
    },
    {
        "en": "Basenji",
        "ar": "باسينجي"
    },
    {
        "en": "Kitten Haimer",
        "ar": "كيتن هايمر"
    },
    {
        "en": "Apu Apustaja",
        "ar": "أبو أبوستاجا"
    },
    {
        "en": "MANEKI",
        "ar": "مانيكي"
    },
    {
        "en": "Ancient8",
        "ar": "أنشينت 8"
    },
    {
        "en": "ANyONe Protocol",
        "ar": "أنيون بروتوكول"
    },
    {
        "en": "Neiro",
        "ar": "نيرو"
    },
    {
        "en": "Zero1 Labs",
        "ar": "زيرو 1 لابز"
    },
    {
        "en": "Bill the Bear",
        "ar": "بيل ذا بير"
    },
    {
        "en": "Kamala Horris",
        "ar": "كامالا هوريس"
    },
    {
        "en": "GraphLinq Chain",
        "ar": "جراف لينك تشين"
    },
    {
        "en": "Archway",
        "ar": "آرشواي"
    },
    {
        "en": "0L Network",
        "ar": "صفر إل نتويرك"
    },
    {
        "en": "Massa",
        "ar": "ماسا"
    },
    {
        "en": "BitSong",
        "ar": "بيت سونج"
    },
    {
        "en": "White Whale",
        "ar": "وايت ويل"
    },
    {
        "en": "Chain4Energy",
        "ar": "شين فور إنرجي"
    },
    {
        "en": "IXI",
        "ar": "إكس آي"
    },
    {
        "en": "Desmos",
        "ar": "ديسموس"
    },
    {
        "en": "EquityPay",
        "ar": "إيكويتي باي"
    },
    {
        "en": "Busy",
        "ar": "بيزي"
    },
    {
        "en": "Partisia Blockchain",
        "ar": "بارتيسيا بلوكشين"
    },
    {
        "en": "Metchain",
        "ar": "ميتشين"
    },
    {
        "en": "Nibiru",
        "ar": "نيبيرو"
    },
    {
        "en": "Pyrin",
        "ar": "بيرين"
    },
    {
        "en": "Wrapped Chiliz",
        "ar": "رابد تشيليز"
    },
    {
        "en": "Layer One X",
        "ar": "لاير ون إكس"
    },
    {
        "en": "3DPass",
        "ar": "3 دي باس"
    },
    {
        "en": "Waterfall",
        "ar": "واترفول"
    },
    {
        "en": "Minima",
        "ar": "مينيما"
    },
    {
        "en": "Monad",
        "ar": "موناد"
    },
    {
        "en": "PHPCoin",
        "ar": "بي إتش بي كوين"
    },
    {
        "en": "Core Blockchain",
        "ar": "كور بلوكشين"
    },
    {
        "en": "Save",
        "ar": "سايف"
    },
    {
        "en": "Groestlcoin",
        "ar": "جروستل كوين"
    },
    {
        "en": "Peercoin",
        "ar": "بير كوين"
    },
    {
        "en": "Gleec Coin",
        "ar": "جليك كوين"
    },
    {
        "en": "Goldcoin",
        "ar": "جولد كوين"
    },
    {
        "en": "Vertcoin",
        "ar": "فيرت كوين"
    },
    {
        "en": "BLOCX.",
        "ar": "بلوكس"
    },
    {
        "en": "Wownero",
        "ar": "واونيرو"
    },
    {
        "en": "Electronic Gulden",
        "ar": "إلكترونيك جولدن"
    },
    {
        "en": "Litecoin Cash",
        "ar": "لايتكوين كاش"
    },
    {
        "en": "Feathercoin",
        "ar": "فيذر كوين"
    },
    {
        "en": "SafeCoin",
        "ar": "سايف كوين"
    },
    {
        "en": "Fedoracoin",
        "ar": "فيدورا كوين"
    },
    {
        "en": "Gridcoin",
        "ar": "جريد كوين"
    },
    {
        "en": "Solarcoin",
        "ar": "سولار كوين"
    },
    {
        "en": "PUTinCoin",
        "ar": "بوتين كوين"
    },
    {
        "en": "Phoenixcoin",
        "ar": "فينيكس كوين"
    },
    {
        "en": "0xBitcoin",
        "ar": "صفر إكس بيتكوين"
    },
    {
        "en": "Garlicoin",
        "ar": "جارليكوين"
    },
    {
        "en": "Potcoin",
        "ar": "بوت كوين"
    },
    {
        "en": "Dimecoin",
        "ar": "دايم كوين"
    },
    {
        "en": "Infinitecoin",
        "ar": "إنفينيت كوين"
    },
    {
        "en": "Einsteinium",
        "ar": "أينشتاينيوم"
    },
    {
        "en": "StrongHands",
        "ar": "سترونغ هاندز"
    },
    {
        "en": "Curecoin",
        "ar": "كيور كوين"
    },
    {
        "en": "Uniform Fiscal Object",
        "ar": "يونيفورم فيسكال أوبجكت"
    },
    {
        "en": "Ixcoin",
        "ar": "إكس كوين"
    },
    {
        "en": "EUNO",
        "ar": "يونو"
    },
    {
        "en": "HTMLCOIN",
        "ar": "إتش تي إم إل كوين"
    },
    {
        "en": "Myriad",
        "ar": "ميرياد"
    },
    {
        "en": "Meter Stable",
        "ar": "ميتر ستابل"
    },
    {
        "en": "Pakcoin",
        "ar": "باك كوين"
    },
    {
        "en": "Callisto Network",
        "ar": "كاليستو نتويرك"
    },
    {
        "en": "MotaCoin",
        "ar": "موتا كوين"
    },
    {
        "en": "DigitalNote",
        "ar": "ديجيتال نوت"
    },
    {
        "en": "Rito",
        "ar": "ريتو"
    },
    {
        "en": "DeVault",
        "ar": "ديفولت"
    },
    {
        "en": "Bismuth",
        "ar": "بيزموث"
    },
    {
        "en": "SparkPoint Fuel",
        "ar": "سبارك بوينت فويل"
    },
    {
        "en": "TrezarCoin",
        "ar": "تريزار كوين"
    },
    {
        "en": "uPlexa",
        "ar": "يوبليكسا"
    },
    {
        "en": "WorldCoin",
        "ar": "وورلد كوين"
    },
    {
        "en": "LiteDoge",
        "ar": "لايت دوج"
    },
    {
        "en": "Lightning Bitcoin",
        "ar": "لايتنينج بيتكوين"
    },
    {
        "en": "Ravencoin Classic",
        "ar": "ريفن كوين كلاسيك"
    },
    {
        "en": "Kzcash",
        "ar": "كيه زي كاش"
    },
    {
        "en": "Innova",
        "ar": "إنوفا"
    },
    {
        "en": "ILCOIN",
        "ar": "إل كوين"
    },
    {
        "en": "Sugarchain",
        "ar": "سوجار كوين"
    },
    {
        "en": "GameCredits",
        "ar": "جيم كريديتس"
    },
    {
        "en": "Freicoin",
        "ar": "فري كوين"
    },
    {
        "en": "DisChain",
        "ar": "ديس تشين"
    },
    {
        "en": "Digitalcoin",
        "ar": "ديجيتال كوين"
    },
    {
        "en": "Unitus",
        "ar": "يونيتوس"
    },
    {
        "en": "Unobtanium",
        "ar": "أنوبتانيوم"
    },
    {
        "en": "Deutsche eMark",
        "ar": "دويتش إي مارك"
    },
    {
        "en": "Clams",
        "ar": "كلامز"
    },
    {
        "en": "Viacoin",
        "ar": "فيا كوين"
    },
    {
        "en": "Casinocoin",
        "ar": "كازينو كوين"
    },
    {
        "en": "Xenios",
        "ar": "زينيوس"
    },
    {
        "en": "BitcoinX",
        "ar": "بيتكوين إكس"
    },
    {
        "en": "YENTEN",
        "ar": "ينتين"
    },
    {
        "en": "Bitcoin Vault",
        "ar": "بيتكوين فولت"
    },
    {
        "en": "Bitcoin Atom",
        "ar": "بيتكوين أتوم"
    },
    {
        "en": "Aryacoin",
        "ar": "أريا كوين"
    },
    {
        "en": "ArtByte",
        "ar": "آرت بيت"
    },
    {
        "en": "ABBC",
        "ar": "أي بي بي سي"
    },
    {
        "en": "NewYorkCoin",
        "ar": "نيويورك كوين"
    },
    {
        "en": "Zetacoin",
        "ar": "زيتا كوين"
    },
    {
        "en": "NevaCoin",
        "ar": "نيفا كوين"
    },
    {
        "en": "Namecoin",
        "ar": "نيم كوين"
    },
    {
        "en": "MonaCoin",
        "ar": "مونا كوين"
    },
    {
        "en": "PlatinCoin",
        "ar": "بلاتين كوين"
    },
    {
        "en": "MoneroV",
        "ar": "مونيرو في"
    },
    {
        "en": "Primecoin",
        "ar": "برايم كوين"
    },
    {
        "en": "Marscoin",
        "ar": "مارس كوين"
    },
    {
        "en": "ChainSwap",
        "ar": "شين سواب"
    },
    {
        "en": "CargoX",
        "ar": "كارجو إكس"
    },
    {
        "en": "Neiro on ETH",
        "ar": "نيرو"
    },
    {
        "en": "Cloud",
        "ar": "كلاود"
    },
    {
        "en": "Layer3",
        "ar": "لاير 3"
    },
    {
        "en": "Hoppy",
        "ar": "هوبي"
    },
    {
        "en": "Powerledger",
        "ar": "باور ليدجر"
    },
    {
        "en": "Cat-Dog",
        "ar": "كات-دوج"
    },
    {
        "en": "Sundog",
        "ar": "ساندوج"
    },
    {
        "en": "Ginnan The Cat",
        "ar": "جينان ذا كات"
    },
    {
        "en": "Smoking Chicken Fish",
        "ar": "سموكينج تشيكن فيش"
    },
    {
        "en": "OORT",
        "ar": "أورت"
    },
    {
        "en": "Lista DAO",
        "ar": "ليستا داو"
    },
    {
        "en": "Burn",
        "ar": "بيرن"
    },
    {
        "en": "PULSR",
        "ar":  "بولسر"
    },
    {
        "en": "Gems VIP",
        "ar": "جيمز في آي بي"
    },
    {
        "en": "Fwog",
        "ar": "فوج"
    },
    {
        "en": "Wrapped eETH",
        "ar": "رابد إي إي تش"
    },
    {
        "en": "Kelp DAO Restaked ETH",
        "ar": "كيلب داو ريستاكد إيث"
    },
    {
        "en": "Matr1x Fire",
        "ar": "ماتريكس فاير"
    },
    {
        "en": "Meta Monopoly",
        "ar": "ميتا مونوبولي"
    },
    {
        "en": "EUROe Stablecoin",
        "ar": "يورو إي ستابل كوين"
    },
    {
        "en": "USD Stable Colb",
        "ar": "يو إس دي ستابل كولب"
    },
    {
        "en": "Monerium GBP emoney",
        "ar": "مونيريوم جي بي بي إي موني"
    },
    {
        "en": "ARYZE eEUR",
        "ar": "آرايز إي يورو"
    },
    {
        "en": "ARYZE eUSD",
        "ar": "آرايز إي يو إس دي"
    },
    {
        "en": "ARYZE eGBP",
        "ar": "آرايز إي جي بي بي"
    },
    {
        "en": "Classic USD",
        "ar": "كلاسيك يو إس دي"
    },
    {
        "en": "Cygnus Finance Global USD",
        "ar": "سيجنوس فاينانس جلوبال يو إس دي"
    },
    {
        "en": "Anzen USDz",
        "ar": "أنزن يو إس دي زد"
    },
    {
        "en": "Philippine Peso Coin",
        "ar": "فيليبين بيزو كوين"
    },
    {
        "en": "USDtez",
        "ar": "يو إس دي تيز"
    },
    {
        "en": "Usual USD",
        "ar": "يوجوال يو إس دي"
    },
    {
        "en": "Quantoz EURD",
        "ar": "كوانتوز يور دي"
    },
    {
        "en": "BlackRock USD Institutional Digital Liquidity Fund",
        "ar": "بلاك روك يو إس دي إنستيتوشنال ديجيتال ليكويديتي فند"
    },
    {
        "en": "Elixir deUSD",
        "ar": "إليكسير دي يو إس دي"
    },
    {
        "en": "USDB",
        "ar": "يو إس دي بي"
    },
    {
        "en": "Magic Internet Money (Ethereum)",
        "ar": "ماجيك إنترنت موني"
    },
    {
        "en": "AUSD",
        "ar": "أو إس دي"
    },
    {
        "en": "Lift Dollar",
        "ar": "ليفت دولار"
    },
    {
        "en": "Move Dollar",
        "ar": "موف دولار"
    },
    {
        "en": "USC",
        "ar": "يو إس سي"
    },
    {
        "en": "Autonomous Secure Dollar",
        "ar": "أوتونوموس سيكيور دولار"
    },
    {
        "en": "sUSD (Optimism)",
        "ar": "إس يو إس دي"
    },
    {
        "en": "LP-sCurve",
        "ar": "إل بي-إس كيرف"
    },
    {
        "en": "Staked FRAX",
        "ar": "ستاكد فراكس"
    },
    {
        "en": "f(x) Protocol fxUSD",
        "ar": "إف إكس بروتوكول إف إكس يو إس دي"
    },
    {
        "en": "f(x) rUSD",
        "ar": "إف إكس آر يو إس دي"
    },
    {
        "en": "Worldwide USD",
        "ar": "وورلد وايد يو إس دي"
    },
    {
        "en": "Bridged Tether (Lightlink)",
        "ar": "بريدجد تيثر"
    },
    {
        "en": "Lightlink Bridged USDC (Lightlink)",
        "ar": "ليت لينك بريدجد يو إس دي سي"
    },
    {
        "en": "Megalink",
        "ar": "ميجالينك"
    },
    {
        "en": "Kamino",
        "ar": "كامينو"
    },
    {
        "en": "Marinade Staked SOL",
        "ar": "ماريناد ستاكد سول"
    },
    {
        "en": "Crypto-AI-Robo",
        "ar": "كريبتو آي روبو"
    },
    {
        "en": "Hatom",
        "ar": "هاتوم"
    },
    {
        "en": "Sun Token",
        "ar": "سان توكن"
    },
    {
        "en": "Brainlet",
        "ar": "برينليت"
    },
    {
        "en": "Daddy Tate",
        "ar": "دادي تيت"
    },
    {
        "en": "FoFar",
        "ar": "فوفار"
    },
    {
        "en": "Parcl",
        "ar": "باركل"
    },
    {
        "en": "YAWN",
        "ar": "ياون"
    },
    {
        "en": "Simon's Cat",
        "ar": "سيمونز كات"
        
    },
    {
        "en": "SunWukong",
        "ar": "سان ووكونغ"
    },
    {
        "en": "Kendu Inu",
        "ar": "كيندو إنو"
    },
    {
        "en": "Omni Network",
        "ar": "أومني نتويرك"
    },
    {
        "en": "Router Protocol [OLD]",
        "ar": "روتر بروتوكول"
    },
    {
        "en": "Graviton",
        "ar": "جرافيتون"
    },
    {
        "en": "Supra",
        "ar": "سوبرا"
    },
    {
        "en": "DexCheck AI",
        "ar": "ديكس تشيك آي"
    },
    {
        "en": "Maha",
        "ar": "ماها"
    },
    {
        "en": "Fjord Foundry",
        "ar": "فيورد فاوندري"
    },
    {
        "en": "1intro",
        "ar": "وان إنترو"
    },
    {
        "en": "Fuzion",
        "ar": "فيوزيون"
    },
    {
        "en": "FOMO BULL CLUB",
        "ar": "فومو بول كلوب"
    },
    {
        "en": "Dragon Sun",
        "ar": "دراجون سان"
    },
    {
        "en": "Wrapped QUIL",
        "ar": "رابد كويل"
    },
    {
        "en": "Dogs",
        "ar": "دوجز"
    }
];