//@ts-nocheck
import React from 'react';
import ButtonPrimary from "../Button/ButtonPrimary";
import {coinsArabicName} from "../../data/jsons/translations/arCoins";

// @ts-ignore
const TopCoins = ({ data, trendingData }) => {
    const topGainers = data.top_gainers.slice(0, 10);
    const topLosers = data.top_losers.slice(0, 10);

    const renderCoins = (coins: any[], type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined) => (
        <div className="flex flex-col relative">
            <h2 className="text-xl font-bold mb-4 dark:text-white">
                الأكثر ربحًا
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {coins.map(coin => (
                    <div key={coin.id} className="p-4 rounded-lg bg-white dark:bg-black dark:bg-opacity-20  border-green-500 border-2 dark:shadow-gray-300 dark:shadow-sm shadow-lg flex justify-between items-center">
                        <div className={"flex flex-col"}>
                            <img src={coin.image} alt={coin.name} className="w-12 h-12 rounded-full mb-2" />
                            <h3 className="text-md font-semibold dark:text-white">
                                {coinsArabicName.find(item => item.en === coin.name) ? coinsArabicName.find(item => item.en === coin.name).ar : coin.name}
                            </h3>
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                ({coin.symbol.toUpperCase()})
                            </span>
                        </div>
                        <div className={"flex flex-col justify-between "}>
                            <div className={"flex flex-col justify-center items-center mb-2"}>
                                <p>24 ساعة</p>
                                <p className={(coin.usd_24h_change || 0) > 0 ? "font-bold mt-2 text-xl text-green-500" : "font-bold mt-2 text-xl text-primary-6000"} dir={"ltr"}>
                                    {coin.usd_24h_change.toFixed(2)}%
                                </p>
                            </div>
                            <div className={"flex flex-col justify-center items-center mt-2"}>
                                <p>السعر</p>
                                <p className={"font-bold mt-2 text-xl text-black dark:text-white"} dir={"ltr"}>
                                    {coin.usd.toFixed(6)}$
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ButtonPrimary href={"/top-gainers"} className={"w-1/4 mx-auto my-8"}>المزيد</ButtonPrimary>
        </div>
    );

    const renderLoosersCoins = (coins: any[], type: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined) => (
        <div className="flex flex-col relative">
            <h2 className="text-xl font-bold mb-4 dark:text-white">
                الأكثر خسارة
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {coins.map(coin => (
                    <div key={coin.id} className="p-4 rounded-lg bg-white dark:bg-black dark:bg-opacity-20 border-red-500 border-2 dark:shadow-gray-300 dark:shadow-sm shadow-lg flex justify-between items-center">
                        <div className={"flex flex-col"}>
                            <img src={coin.image} alt={coin.name} className="w-12 h-12 rounded-full mb-2" />
                            <h3 className="text-md font-semibold dark:text-white">
                                {coinsArabicName.find(item => item.en === coin.name) ? coinsArabicName.find(item => item.en === coin.name).ar : coin.name}
                            </h3>
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                ({coin.symbol.toUpperCase()})
                            </span>
                        </div>
                        <div className={"flex flex-col justify-between "}>
                            <div className={"flex flex-col justify-center items-center mb-2"}>
                                <p>24 ساعة</p>
                                <p className={(coin.usd_24h_change || 0) > 0 ? "font-bold mt-2 text-xl text-green-500" : "font-bold mt-2 text-xl text-primary-6000"} dir={"ltr"}>
                                    {coin.usd_24h_change.toFixed(2)}%
                                </p>
                            </div>
                            <div className={"flex flex-col justify-center items-center mt-2"}>
                                <p>السعر</p>
                                <p className={"font-bold mt-2 text-xl text-black dark:text-white"} dir={"ltr"}>
                                    {coin.usd.toFixed(6)}$
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ButtonPrimary href={"/top-loosers"} className={"w-1/4 mx-auto my-8"}>المزيد</ButtonPrimary>
        </div>
    );

    console.log("Trending Data from componenet",trendingData)
    const renderTrendingCoins = () => (
        <div className="flex flex-col relative">
            <h2 className="text-xl font-bold mb-4 dark:text-white">
                تريند اليوم
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
                {trendingData.map(coin => (
                    <div key={coin.id}
                         className="p-4 rounded-lg bg-white dark:bg-black dark:bg-opacity-20  border-purple-500 border-2 dark:shadow-gray-300 dark:shadow-sm shadow-lg flex justify-between items-center">
                        <div className={"flex flex-col"}>
                            <img src={coin.image} alt={coin.name} className="w-12 h-12 rounded-full mb-2"/>
                            <h3 className="text-md font-semibold dark:text-white">
                                {coinsArabicName.find(item => item.en === coin.name) ? coinsArabicName.find(item => item.en === coin.name).ar : coin.name}
                            </h3>
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
                                ({coin.symbol.toUpperCase()})
                            </span>
                        </div>
                        <div className={"flex flex-col justify-between "}>
                            <div className={"flex flex-col justify-center items-center mb-2"}>
                                <p>24 ساعة</p>
                                <p className={(coin.price_change_percentage_24h || 0) > 0 ? "font-bold mt-2 text-xl text-green-500" : "font-bold mt-2 text-xl text-primary-6000"}
                                   dir={"ltr"}>
                                    {coin.price_change_percentage_24h.toFixed(2)}%
                                </p>
                            </div>
                            <div className={"flex flex-col justify-center items-center mt-2"}>
                                <p>السعر</p>
                                <p className={"font-bold mt-2 text-xl text-black dark:text-white"} dir={"ltr"}>
                                    {coin.current_price}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ButtonPrimary href={"/trending"} className={"w-1/4 mx-auto my-8"}>المزيد</ButtonPrimary>
        </div>
    )

    return (
        <div className="flex flex-col gap-8 justify-around">
            {renderCoins(topGainers, 'Gainers')}
            {renderLoosersCoins(topLosers, 'Losers')}
            {renderTrendingCoins(trendingData)}
        </div>
    );
};

export default TopCoins;
