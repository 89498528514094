//@ts-nocheck
import React, {Suspense} from "react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {persistor, store} from "./app/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import ReactDOM from "react-dom/client";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'


// STYLE
import "./index.css";
import "./styles/index.scss";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
//
document
    .getElementsByTagName("html")[0]
    .setAttribute("dir", import.meta.env.VITE_LRT_OR_RTL);

const root = ReactDOM.createRoot(
    document.getElementById("mainApp") as HTMLElement
);

// Create a client
const queryClient = new QueryClient()

// @ts-ignore
root.render(
    <React.StrictMode>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>

                      <QueryClientProvider client={queryClient} context={undefined}>


                            <App/>


                      </QueryClientProvider>

                    </PersistGate>
                </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
