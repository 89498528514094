//@ts-nocheck
import React, {FC, useState, useEffect} from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/ar'; // import Arabic locale
import relativeTime from 'dayjs/plugin/relativeTime';
import moment from "moment";
import 'moment-timezone';
import {toNumber} from "lodash";
import axios from "axios";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import yellowCard from "images/stats/yellowCard.png"
import redCard from "images/stats/redCard.png"
import corner from "images/stats/corner.png"
import possession from "images/stats/possession.png"
import onTarget from "images/stats/onTarget.png"
import offTarget from "images/stats/offTarget.png"
import goalSave from "images/stats/goalSave.png"
import attack from "images/stats/attack.png"
import penalty from "images/stats/penalty.png"
import {arTeamsNames} from "../../data/jsons/translations/arTeams";
import {arLeaguesNames} from "../../data/jsons/translations/arLeagues";
import EventCard from "./EventCard";
import CoinChart from "./CoinChart";
import CoinHighChart from "./CoinEchart";
import {coinsArabicName} from "../../data/jsons/translations/arCoins";
import {useAppSelector} from "../../app/hooks";
import {selectDarkmodeState} from "../../app/darkmode/darkmode";

dayjs.extend(relativeTime);
dayjs.locale('ar'); // use Arabic locale globally

export interface CoinCardProps {
    className?: string;
    data: {
        "id": string,
        "symbol": string,
        "name": string,
        "image": string,
        "current_price": number,
        "market_cap": number,
        "market_cap_rank": number,
        "fully_diluted_valuation": number,
        "total_volume": number,
        "high_24h": number,
        "low_24h": number,
        "price_change_24h": number,
        "price_change_percentage_24h": number,
        "market_cap_change_24h": number,
        "market_cap_change_percentage_24h": number,
        "circulating_supply": number,
        "total_supply": number,
        "max_supply": number,
        "ath": number,
        "ath_change_percentage": number,
        "ath_date": string,
        "atl": number,
        "atl_change_percentage": number,
        "atl_date": string,
        "roi": null,
        "last_updated": string,
        "sparkline_in_7d": { "price": number[] },
        "price_change_percentage_24h_in_currency": number,
        "price_change_percentage_7d_in_currency": number,
        "price_change_percentage_14d_in_currency": number,
        "price_change_percentage_30d_in_currency": number,
        "price_change_percentage_60d_in_currency": number,
        "price_change_percentage_200d_in_currency": number,
        "price_change_percentage_1y_in_currency": number,

    }
}


const CoinCard: FC<CoinCardProps> = ({className = "", data}) => {
    const matchDate = moment.unix(toNumber(data.time)).utcOffset('+0100').format('DD/MM/YYYY');
    const matchTime = moment.unix(toNumber(data.time)).utcOffset('+0100').format('h:mm A');
    const [isOpen, setIsOpen] = useState(false);
    const [history, setHistory] = useState<any[]>([]);
    const [matchStats, setMatchStats] = useState<any[]>([]);
    const [loadingStats, setLoadingStats] = useState(true);
    const [loadingHistory, setLoadingHistory] = useState(true);
    const [filteredCoinData, setFilteredCoinData] = useState(null);
    const [coinPrices, setCoinPrices] = useState([]);
    const itemsPerPage = 10; // Number of tickers to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const isDarkMode = useAppSelector(selectDarkmodeState);

    // Calculate the start and end indexes for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get the tickers for the current page
    const [currentTickers, setCurrentTickers] = useState([]);
    const matchesApi = axios.create({
        baseURL: import.meta.env.VITE_API_BASE_URL,
    });
    const getStats = async () => {
        const response = await matchesApi.get(`/api/stats/${data.id}`);
        return response.data;
    }

    const formattedAthDate = dayjs(data.ath_date).fromNow();
    const exactAthDate = dayjs(data.ath_date).format('DD/MM/YYYY');

    const formattedAtlDate = dayjs(data.atl_date).fromNow();
    const exactAtlDate = dayjs(data.atl_date).format('DD/MM/YYYY');
    const InfoCard = ({ title, value, direction="rtl" }) => (
        <div className="flex flex-col items-center justify-center bg-white dark:bg-gray-800 p-4 m-2 rounded-lg shadow-lg w-60 border-2 border-slate-400 dark:border-white">
            <p className="font-bold text-lg mb-2 text-gray-900 dark:text-white">{title}</p>
            <p className="text-gray-600 dark:text-gray-300 font-semibold" dir={direction}>{value}</p>
        </div>
    );

    const formatNumber = (num, isSupply = false, isRound = true) => {
        if (num == null || num === undefined) return 'غير متوفر'; // Add this line to handle null or undefined values

        let formattedNumber;
        if (num >= 1e12) {
            formattedNumber = ((isSupply && !isRound) ? (num / 1e12).toFixed(2) : (isSupply ? Math.floor(num / 1e12) : (num / 1e12).toFixed(2))) + ' تريليون';
        }
        else if (num >= 1e9) {
            formattedNumber = ((isSupply && !isRound) ? (num / 1e9).toFixed(2) : (isSupply ? Math.floor(num / 1e9) : (num / 1e9).toFixed(2))) + ' مليار';
        } else if (num >= 1e6) {
            formattedNumber = ((isSupply && !isRound) ? (num / 1e6).toFixed(2) : (isSupply ? Math.floor(num / 1e6) : (num / 1e6).toFixed(2))) + ' مليون';
        } else if (num >= 1e3 && !isSupply) {
            formattedNumber = (num / 1e3).toFixed(2) + ' ألف';
        } else {
            formattedNumber = num.toString();
        }
        return formattedNumber;
    }

    const exchangeTranslations = [
        {
            "en": "Binance",
            "ar": "بينانس"
        },
        {
            "en": "Tapbit",
            "ar": "تاببيت"
        },
        {
            "en": "WEEX",
            "ar": "ويكس"
        },
        {
            "en": "Hotcoin Global",
            "ar": "هوت كوين جلوبال"
        },
        {
            "en": "Coinbase Exchange",
            "ar": "كوينبيس"
        },
        {
            "en": "Kraken",
            "ar": "كراكن"
        },
        {
            "en": "Bitfinex",
            "ar": "بتفينكس"
        },
        {
            "en": "Bitstamp",
            "ar": "بيتستامب"
        },
        {
            "en": "Gemini",
            "ar": "جيميني"
        },
        {
            "en": "Crypto.com Exchange",
            "ar": "كريبتو.كوم"
        },
        {
            "en": "KuCoin",
            "ar": "كوكوين"
        },
        {
            "en": "Binance US",
            "ar": "بينانس الولايات المتحدة"
        },
        {
            "en": "Bybit",
            "ar": "بايبيت"
        },
        {
            "en": "HTX",
            "ar": "إتش تي إكس"
        },
        {
            "en": "OKX",
            "ar": "أوكيكس"
        },
        {
            "en": "Gate.io",
            "ar": "جيت.آي.أو"
        },
        {
            "en": "Bitget",
            "ar": "بيتجيت"
        },
        {
            "en": "BingX",
            "ar": "بينج إكس"
        },
        {
            "en": "BTSE",
            "ar": "بي تي إس إي"
        },
        {
            "en": "Phemex",
            "ar": "فيميكس"
        },
        {
            "en": "Bitso",
            "ar": "بيتسو"
        },
        {
            "en": "Fastex",
            "ar": "فاستكس"
        },
        {
            "en": "DigiFinex",
            "ar": "ديجيفينكس"
        },
        {
            "en": "CoinW",
            "ar": "كوين دبليو"
        },
        {
            "en": "BitMart",
            "ar": "بيت مارت"
        },
        {
            "en": "MEXC",
            "ar": "ميكس"
        },
        {
            "en": "P2B",
            "ar": "بي تو بي"
        },
        {
            "en": "Bitrue",
            "ar": "بيترو"
        },
        {
            "en": "XT.COM",
            "ar": "إكس تي"
        },
        {
            "en": "Upbit",
            "ar": "أببيت"
        },
        {
            "en": "BitMEX",
            "ar": "بيتمكس"
        },
        {
            "en": "NiceHash",
            "ar": "نايس هاش"
        },
        {
            "en": "Blockchain.com",
            "ar": "بلوكشين"
        },
        {
            "en": "Coins.ph",
            "ar": "كوينز"
        },
        {
            "en": "Indodax",
            "ar": "إندوداكس"
        },
        {
            "en": "Bullish",
            "ar": "بوليش"
        },
        {
            "en": "Max Maicoin",
            "ar": "مايكوين"
        },
        {
            "en": "Bitbank",
            "ar": "بيت بانك"
        },
        {
            "en": "C-Patex",
            "ar": "سي باتكس"
        },
        {
            "en": "Cryptology",
            "ar": "كريبتولوجي"
        },
        {
            "en": "Bitkub",
            "ar": "بيتكوب"
        },
        {
            "en": "WOO X",
            "ar": "وو إكس"
        },
        {
            "en": "Dex-Trade",
            "ar": "ديكس تريد"
        },
        {
            "en": "QMall",
            "ar": "كيو مول"
        },
        {
            "en": "PointPay",
            "ar": "بوينت باي"
        },
        {
            "en": "BigONE",
            "ar": "بيج ون"
        },
        {
            "en": "BigONE",
            "ar": "بيج ون"
        },
        {
            "en": "EXMO",
            "ar": "إكسمو"
        },
        {
            "en": "WhiteBIT",
            "ar": "وايت بيت"
        },
        {
            "en": "LATOKEN",
            "ar": "لا توكن"
        },
        {
            "en": "LBank",
            "ar": "إل بانك"
        },
        {
            "en": "Coinsbit",
            "ar": "كوينزبيت"
        },
        {
            "en": "Coinstore",
            "ar": "كوين ستور"
        },
        {
            "en": "Poloniex",
            "ar": "بولونيكس"
        },
        {
            "en": "Toobit",
            "ar": "توبيت"
        },
        {
            "en": "VALR",
            "ar": "فالر"
        },
        {
            "en": "Kuna Exchange",
            "ar": "كونا إكسشينج"
        },
        {
            "en": "Coinmetro",
            "ar": "كوين ميترو"
        },
        {
            "en": "BitoPro",
            "ar": "بيتوبرو"
        },
        {
            "en": "FameEX",
            "ar": "فيم إكس"
        },
        {
            "en": "OrangeX",
            "ar": "أورانج إكس"
        },
        {
            "en": "Icrypex",
            "ar": "آي كريبكس"
        },
        {
            "en": "Bitforex",
            "ar": "بيت فوركس"
        },
        {
            "en": "TokoCrypto",
            "ar": "توكو كريبتو"
        },
        {
            "en": "Nominex",
            "ar": "نومينكس"
        },
        {
            "en": "Curve (Ethereum)",
            "ar": "كيرف (إيثيريوم)"
        },
        {
            "en": "Deepcoin",
            "ar": "ديب كوين"
        },
        {
            "en": "CITEX",
            "ar": "سيتكس"
        },
        {
            "en": "ExMarkets",
            "ar": "إكس ماركتس"
        },
        {
            "en": "Uniswap V3 (Ethereum)",
            "ar": "يوني سواب"
        },
        {
            "en": "Pionex",
            "ar": "بيونكس"
        },
        {
            "en": "Bitunix",
            "ar": "بيت يونكس"
        },
        {
            "en": "Uniswap V2 (Ethereum)",
            "ar": "يوني سواب"
        },
        {
            "en": "Azbit",
            "ar": "أزبيت"
        },
        {
            "en": "Bitazza",
            "ar": "بيتازا"
        },
        {
            "en": "CoinTR Pro",
            "ar": "كوين تي آر برو"
        },
        {
            "en": "Tidex",
            "ar": "تيديكس"
        },
        {
            "en": "BW.com",
            "ar": "بي دبليو"
        },
        {
            "en": "BiONE",
            "ar": "بي ون"
        },
        {
            "en": "Nami.Exchange",
            "ar": "نامي إكسشينج"
        },
        {
            "en": "CoinEx",
            "ar": "كوين إكس"
        },
        {
            "en": "XeggeX",
            "ar": "إكسجيكس"
        },
        {
            "en": "Orderly Network",
            "ar": "أوردرلي نتورك"
        },
        {
            "en": "Balancer V2",
            "ar": "بالانسر"
        },
        {
            "en": "Uniswap V3 (Arbitrum One)",
            "ar": "يوني سواب"
        },
        {
            "en": "Uniswap V3 (Optimism)",
            "ar": "يوني سواب"
        },
        {
            "en": "Beethoven X (Optimism)",
            "ar": "بيتهوفن إكس"
        },
        {
            "en": "Pancakeswap V3 (Ethereum)",
            "ar": "بانكيك سواب"
        },
        {
            "en": "Fraxswap (Ethereum)",
            "ar": "فراكس سواب"
        },
        {
            "en": "THENA",
            "ar": "ثينا"
        },
        {
            "en": "Velodrome Finance v2",
            "ar": "فيلودروم"
        },
        {
            "en": "Maverick Protocol",
            "ar": "مافريك بروتوكول"
        },
        {
            "en": "SpiritSwap",
            "ar": "سبيريت سواب"
        },
        {
            "en": "THENA FUSION",
            "ar": "ثينا فيوجن"
        },
        {
            "en": "BYDFi",
            "ar": "بي واي دي فاي"
        },
        {
            "en": "BitVenus",
            "ar": "بيت فينوس"
        },
        {
            "en": "Tarmex",
            "ar": "تارمكس"
        },
        {
            "en": "Orca",
            "ar": "أوركا"
        },
        {
            "en": "Bithumb",
            "ar": "بيثامب"
        },
        {
            "en": "Bitci TR",
            "ar": "بيتسي"
        },
        {
            "en": "Trubit",
            "ar": "تروبيت"
        },
        {
            "en": "AscendEX (BitMax)",
            "ar": "أسند إكس"
        },
        {
            "en": "Retro",
            "ar": "ريترو"
        },
        {
            "en": "Ramses",
            "ar": "رامسيس"
        },
        {
            "en": "Chronos",
            "ar": "كرونوس"
        },
        {
            "en": "Bitvavo",
            "ar": "بيتفافو"
        },
        {
            "en": "Bitcointry Exchange",
            "ar": "بيتكوين تري"
        },
        {
            "en": "Tokenize",
            "ar": "توكنايز"
        },
        {
            "en": "HitBTC",
            "ar": "هيت بي تي سي"
        },
        {
            "en": "FMFW.io",
            "ar": "إف إم إف دبليو"
        },
        {
            "en": "Biconomy",
            "ar": "بيكونومي"
        }
    ]

    const filteredTickers = currentTickers.filter((ticker) => {
        // Customize this condition based on your criteria
        return !(ticker.base.startsWith('0X') || ticker.target.startsWith('0x'));
    });

    const modifiedTickers = currentTickers.map((ticker) => {
        const baseShortened = ticker.base.length > 6 ? `${ticker.base.slice(0, 2)}.${ticker.base.slice(-3)}` : ticker.base;
        const targetShortened = ticker.target.length > 6 ? `${ticker.target.slice(0, 2)}.${ticker.target.slice(-3)}` : ticker.target;

        return {
            ...ticker,
            base: baseShortened,
            target: targetShortened,
        };
    });




    const renderDetail = () => {
        if (!isOpen) return null;
        return (
            <div className="p-2 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">

                <h2 className={"flex justify-center font-bold text-xl mb-6 "}>التفاصيل</h2>
                <div className="border-t border-neutral-200 dark:border-neutral-700"/>

                <div className="lg:p-10 mb-6">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
                        <InfoCard title="الترتيب" value={data.market_cap_rank} className={"font-semibold"}/>
                        <InfoCard title="القيمة السوقية" value={formatNumber(data.market_cap)  + " دولار"} />
                        <InfoCard title="حجم التداول" value={formatNumber(data.total_volume)  + " دولار"} />
                        <InfoCard title="العرض المتداول" value={formatNumber(data.circulating_supply, true, false) + `  (${Math.floor(data.circulating_supply *100 / data.total_supply)} % ) ` }/>
                        <InfoCard title="إجمالي العرض" value={formatNumber(data.total_supply, true, false)} />
                        <InfoCard title="العرض الأقصى" value={formatNumber(data.max_supply, true, false)} />
                        <InfoCard title="أعلى سعر" value={data.ath + " $"} />
                        <InfoCard title="نسبة تغيير أعلى سعر" value={data.ath_change_percentage.toFixed(2) + " %"} direction={"ltr"}/>
                        <InfoCard title="تاريخ أعلى سعر" value={`${formattedAthDate} (${exactAthDate})`} />
                        <InfoCard title="أقل سعر" value={data.atl + " $"} />
                        <InfoCard title="نسبة تغيير أقل سعر" value={data.atl_change_percentage.toFixed(2) + " %"} direction={"ltr"}/>
                        <InfoCard title="تاريخ أقل سعر" value={`${formattedAtlDate} (${exactAtlDate})`} />
                    </div>
                </div>

                <div className="border-t border-neutral-200 dark:border-neutral-700"/>
                <h2 className={"flex justify-center font-bold text-xl mt-10 mb-4 "}>الرسم البياني</h2>
                <div className="flex flex-col items-center max-w-full" >
                    {/* ... Your cards grid ... */}
                    <div className="mt-8 w-full">
                        {/*<CoinChart prices={coinPrices} />*/}
                        <CoinHighChart prices={coinPrices} theme={isDarkMode ? 'dark' : 'light'} />
                    </div>
                </div>
                <div className="border-t border-neutral-200 dark:border-neutral-700 mt-16"/>
                <h2 className={"flex justify-center font-bold text-xl mt-8 mb-8 "}>الأسواق</h2>

                <div className="max-w-full flex flex-col ">
                    {/* Render the tickers for the current page */}
                    {modifiedTickers.slice(0,10).map((ticker: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; base: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; market: { logo: string | undefined; name: string | undefined; }; }, index: React.Key | null | undefined) => renderTicker(ticker, index))}

                    {/* Pagination controls */}

                </div>

            </div>
        );
    };



    const fetchH2H = async function () {
        const coinPrices = function () {
            axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/prices/${data.id}`)
                .then(function (response) {
                    setCoinPrices(response.data);
                }).catch(function (error) {
                console.log(error);
            })
        }

        const coinTickers = function () {
            axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/coins/tickers/${data.id}`)
                .then(function (response) {
                    setCurrentTickers(response.data.tickers);
                }).catch(function (error) {
                console.log(error);
            })
        }

        console.log("coinTickers", currentTickers)


        const getStats = function () {
            axios.get(`${import.meta.env.VITE_API_BASE_URL}/api/stats/${data.id}`)
                .then(function (response) {
                    setMatchStats(response.data);
                    setLoadingStats(false);
                }).catch(function (error) {
                console.log(error);
            })
        }

        Promise.all([coinPrices(), getStats(), coinTickers()]). then(() => {
            setIsOpen(!isOpen)
        })
            .catch((error) => console.log(error))

    }






    const renderTicker = (ticker: { name: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; base: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; market: { logo: string | undefined; name: string | undefined; }; }, index: React.Key | null | undefined) => (
        <div key={index} className="flex lg:flex-row flex-col space-y-6 lg:space-y-2 items-center p-4 border border-gray-300 dark:border-gray-700 mb-2 rounded-lg">
            <div className={"flex lg:flex-row lg:w-1/3 lg:justify-around items-center"}>
                <div className="flex flex-col justify-center items-center md:pt-3 mr-8 lg:mr-2 md:mr-24">
                    <img src={ticker.market.logo} alt={ticker.market.name} className="h-10 w-10 rounded-full" />
                    <p className={"font-semibold mt-0.5"}>{exchangeTranslations.find(item => item.en === ticker.market.name) ? exchangeTranslations.find(item => item.en === ticker.market.name).ar : ticker.market.name}</p>
                </div>
                <div className="flex flex-col justify-center items-center ml-8 lg:ml-2 md:ml-24">
                    <p>الزوج</p>
                    <p className="font-bold mt-2 text-lg text-slate-700 dark:text-white" dir={"ltr"}>{ticker.base} / {ticker.target}</p>
                </div>
            </div>

            <div className={"flex lg:flex-row lg:w-1/3 justify-around items-center"}>
                <div className="flex flex-col items-center mr-8 lg:mr-2 md:mr-24">
                    <p>السعر</p>
                    <p className="font-bold mt-2 text-lg text-slate-700 dark:text-white" dir={"ltr"}>{ticker.last}</p>
                </div>

                <div className="flex flex-col items-center  ml-8 lg:ml-2 md:ml-24">
                    <p>حجم التداول<span className={"text-sm ml-2 font-bold"}>$</span></p>
                    <p className="font-bold mt-2 text-lg text-slate-700 dark:text-white" dir={"rtl"}>{formatNumber(ticker.converted_volume.usd)}</p>
                </div>
            </div>

            <div className={"flex lg:flex-row lg:w-1/3 justify-around items-center"}>
                <div className="flex flex-col items-center mr-8 lg:mr-2 md:mr-24">
                    <p>الثقة</p>
                    {ticker.trust_score === "green" ? <p className="font-bold mt-2 text-lg text-green-500 dark:text-green-500">موثوق</p> : <p className="font-bold mt-2 text-lg text-red-500 dark:text-red-500">غير موثوق</p>}
                </div>

                <div className="flex flex-col items-center ml-8 lg:ml-2 md:ml-24">
                    <p></p>
                    <a href={ticker.trade_url} target={"_blank"}>
                        <p className="font-bold mt-2 text-lg text-slate-700 dark:text-white" dir={"ltr"}>رابط التداول</p>
                    </a>
                </div>
            </div>
            {/* Display other ticker information */}
        </div>
    );





    return (
        <div
            className={`cursor-pointer nc-FlightCardgroup p-4 sm:p-6 mb-5 relative bg-white dark:bg-neutral-900 border-2 border-slate-200
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6  dark:border-2 dark:border-white ${className}`}
            data-nc-id="FlightCard"
        >
            <div
                className={` sm:pr-20 relative  ${className}`}
                data-nc-id="FlightCard"
            >
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}

                <span
                    className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-8 h-8 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer  dark:border-2 dark:border-white ${
                        isOpen ? "transform -rotate-180" : ""
                    }`}
                    onClick={!isOpen ? fetchH2H : () => setIsOpen(!isOpen)}
                >
          <i className="text-xl las la-angle-down"></i>
        </span>

                <div className="flex flex-col lg:flex-row space-y-6 sm:space-y-0">
                    {/* LOGO IMG */}


                    {/* FOR MOBILE RESPONSIVE */}
                    <div className="block lg:hidden space-y-1">
                        <div className=" lg:flex flex-col justify-center items-center text-center ">
                            <div className={"flex"}>
                                <div className={"flex flex-col justify-items-center items-center mt-2 w-1/2 md:w-1/3"}>
                                    <img src={data.image} className="object-contain w-12 h-12 rounded-full" alt=""/>
                                    <span className={"font-bold font-display mt-4 text-sm md:text-xl"}>{coinsArabicName.find(item => item.en === data.name) ? coinsArabicName.find(item => item.en === data.name).ar : data.name}</span>
                                    <span className="text-sm font-medium text-gray-500 dark:text-gray-400 mt-2">
                                        ({data.symbol.toUpperCase()})
                                    </span>
                                </div>

                                <div className={"w-1/2 md:w-1/3 flex flex-col justify-items-center items-center my-auto"}>
                                    <p>السعر</p>
                                    <p className={"font-bold mt-2 text-md md:text-2xl lg:text-3xl text-neutral-700"}>{data.current_price} $</p>
                                </div>

                                <div className={"w-1/2 md:w-1/3 flex flex-col justify-items-center items-center my-auto"}>
                                    <div dir={"rtl"}><p>24 ساعة</p></div>
                                    <p className={data.price_change_percentage_24h > 0 ? "font-bold mt-2  text-md md:text-lg lg:text-xl text-green-500" : "font-bold mt-2  text-md md:text-xl text-primary-6000"} dir={"ltr"}>{data.price_change_percentage_24h && data.price_change_percentage_24h.toFixed(2)} %</p>
                                </div>

                             {/*   <div className={"w-1/3"}>
                                    <p className={"text-4xl font-extrabold"} dir={"rtl"}>{data.ss.split('-').reverse().join('-')}</p>
                                    <p className={"text-sm font-semibold mt-2"}>{arLeaguesNames.find(item => item.en === data.league.name) ? arLeaguesNames.find(item => item.en === data.league.name).ar : data.league.name}</p>
                                    <p className={"text-sm font-bold mt-2 font-display"}>{sport}</p>
                                    <p className={"mt-4"}>{matchDate}</p>
                                    <p className={"mt-2"}>{matchTime}</p>
                                </div>
                                <div className={"flex flex-col justify-items-center items-center mt-16"}>
                                    <img src={`https://assets.b365api.com/images/team/m/${data.away.image_id}.png`} className="object-contain  w-12 h-12" alt=""/>
                                    <span className={"mt-1 text-xs font-bold"}>{arTeamsNames.find(item => item.en === data.away.name) ? arTeamsNames.find(item => item.en === data.away.name).ar : data.away.name}</span>
                                </div>*/}

                            </div>

                        </div>
                    </div>



                    <div className={"flex justify-around lg:justify-between lg:items-center w-full"}>
                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center md:pt-3 w-1/6">
                            <img src={data.image} className="w-2/5 rounded-full" alt=""/>
                            <div className="text-black dark:text-white font-bold font-display mt-4 text-sm">
                                {coinsArabicName.find(item => item.en === data.name) ? coinsArabicName.find(item => item.en === data.name).ar : data.name}
                            </div>
                            <span className="text-sm font-medium text-gray-500 dark:text-gray-400 mt-2">
                                ({data.symbol.toUpperCase()})
                            </span>
                        </div>

                        <div className="hidden lg:flex flex-col justify-center items-center w-1/6">
                            <p>السعر</p>
                            <p className={"font-bold mt-2 text-2xl text-slate-700 dark:text-white"}>{data.current_price} $</p>
                        </div>

                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center md:pt-3 w-1/6" dir={"ltr"}>
                            <div dir={"rtl"}><p>24 ساعة</p></div>
                            <p className={(data.price_change_percentage_24h || 0) > 0 ? "font-bold mt-2 text-2xl text-green-500" : "font-bold mt-2 text-2xl text-primary-6000"}>
                                {((data.price_change_percentage_24h || 0)).toFixed(2)} %
                            </p>

                        </div>

                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center md:pt-3 w-1/6" dir={"ltr"}>
                            <div dir={"rtl"}><p>7 أيام</p></div>
                            <p className={data?.price_change_percentage_7d_in_currency && data?.price_change_percentage_7d_in_currency > 0 ? "font-bold mt-2 text-2xl text-green-500" : "font-bold mt-2 text-2xl text-primary-6000"}>{data?.price_change_percentage_7d_in_currency ? data?.price_change_percentage_7d_in_currency.toFixed(2) + " %" : "غير متوفر"} </p>
                        </div>

                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center md:pt-3 w-1/6" dir={"ltr"}>
                            <div dir={"rtl"}><p>30 يوم</p></div>
                            <p className={data?.price_change_percentage_30d_in_currency && data?.price_change_percentage_30d_in_currency > 0 ? "font-bold mt-2 text-2xl text-green-500" : "font-bold mt-2 text-2xl text-primary-6000"}>{data?.price_change_percentage_30d_in_currency ? data?.price_change_percentage_30d_in_currency.toFixed(2)+ " %" : "غير متوفر"} </p>
                        </div>

                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center md:pt-3 w-1/6" dir={"ltr"}>
                            <div dir={"rtl"}><p>سنة</p></div>
                            <p className={data?.price_change_percentage_1y_in_currency && data?.price_change_percentage_1y_in_currency >= 0 ? "font-bold mt-2 text-2xl text-green-500" : "font-bold mt-2 text-2xl text-primary-6000"}>{data?.price_change_percentage_1y_in_currency ? data?.price_change_percentage_1y_in_currency.toFixed(2) + " %" : "غير متوفر"}</p>
                        </div>



                        {/* MATCH INFOS */}
                        {/*<div className="hidden lg:flex flex-col justify-center items-center   ">
                            <p className={"font-bold mt-2 text-5xl"}>{data.ss.split('-').reverse().join('-')}</p>
                            <p className={"text-lg font-bold mt-2"}>{arLeaguesNames.find(item => item.en === data.league.name) ? arLeaguesNames.find(item => item.en === data.league.name).ar : data.league.name}</p>
                            <p className={"text-lg font-bold mt-2 font-display"}>{sport}</p>
                            <p className={"mt-4"}>{matchDate}</p>
                            <p className={"mt-2"}>{matchTime}</p>
                        </div>


                         AWAY TEAM
                        <div className="hidden lg:flex w-24 lg:w-32 flex flex-col items-center">
                            <img src={`https://assets.b365api.com/images/team/m/${data.away.image_id}.png`} className="w-2/5 pt-4" alt=""/>
                            <div className="text-sm text-neutral-500 dark:text-white font-bold mt-2 text-center">
                                {arTeamsNames.find(item => item.en === data.away.name) ? arTeamsNames.find(item => item.en === data.away.name).ar : data.away.name}
                            </div>
                        </div>*/}
                    </div>


                </div>
            </div>

            {/* DETAIL */}
            {renderDetail()}
        </div>
    );
};

export default CoinCard;
